import { makeStyles } from '@mui/styles';

const useAdminLayoutStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        height: '100vh',
        overflow: "hidden",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: 'center',
        overflow: "hidden",
    }

});

export default useAdminLayoutStyles;
