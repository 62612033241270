import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding: '8px',
        minHeight: '60px',
        borderRadius: '16px',
        backgroundColor: theme.colors.aqua
    },
    profile: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    avatar: {
      width: '50px',
    },
    inactive: {
        filter: 'grayscale(70%) opacity(70%)',
    },
    buttons: {
        flexDirection: 'row',
        justifySelf: 'flex-end'
    }
}));

export default useStyles;
