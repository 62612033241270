import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { winnerColor } from "theme";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        overflow: "hidden"
    },
    leaderRow: {
        outline: `2px solid ${winnerColor}`,
        outlineOffset: '-2px',
    }

}));

export default useStyles;