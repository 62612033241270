import useLoadingPageStyles from "./index.styles";

import React from "react";
import { Typography } from "@mui/material";

function LoadingPage() {
  const classes = useLoadingPageStyles();
  return <div className={classes.container}>
    <Typography variant="h1">Loading</Typography>
  </div>
}

export default LoadingPage;
