import useStyles from './index.styles';

import React, { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import Loader from 'components/common/Loader';
import tableConfig from "config/table.config";
import { Table } from "components/common";
import { Button, Container, Stack } from "@mui/material";
import { useTable } from "hooks/useTable";
import { TableQueryInfoType, TableRowValueType } from "types/table.types";
import { routes } from "constants/routes";
import ManageTournament from "./ManageTournament";
import { useNavigate } from "react-router-dom";
import { prepareUrl } from "utils/route.utils";
import { useTournamentApi } from "hooks/useTournament";
import { useSnackbar } from "notistack";

interface TournamentsProps {}

const Tournaments: React.FC<TournamentsProps> =
  ({}: TournamentsProps): React.ReactElement => {
    const classes = useStyles();
      const navigate = useNavigate();
    const {
        rows,
        pageInfo,
        dataIsLoading,
        onLazyLoading,
    } = useTable(tableConfig.adminTournaments as TableQueryInfoType);

    const { enqueueSnackbar } = useSnackbar();
    const { editTournament, deleteTournament } = useTournamentApi();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const onAddTournament = () => {
        setIsOpen(true);
    }

    const onCellClicked = (row: TableRowValueType, action?: string): void => {
        switch(action) {
            case 'details':
                return navigate(prepareUrl(routes.admin.tournamentDetails.path, { tournamentId: row.id }))
            case 'disable':
                editTournament({tournamentId: row.id || '' , isActive: false })
                break;
            case 'enable':
                editTournament({tournamentId: row.id || '' , isActive: true })
                break;
            case 'delete':
                deleteTournament(row.id)
                    .then(result => {
                        if (result.success) {
                            enqueueSnackbar('Tournament deleted');
                        }
                    });
                break;


        }
    }
    const renderTournaments = useCallback(() => {
      return <Table
          rows={rows}
          isLoading={dataIsLoading}
          columns={tableConfig.adminTournaments.columns}
          onCellClicked={onCellClicked}
          headerClassNames={classes.tableHeader}
          pageInfo={pageInfo}
          onLazyLoading={onLazyLoading}
      />
    }, [rows]);

    return (
        <Stack className={classes.container}>
            <ManageTournament
                title="Add Tournament"
                open={isOpen}
                handleClose={() => setIsOpen(false)}
            />
            <div className={classes.header}>
                <Typography variant="h1">
                    Tournaments List
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddTournament}
                >
                    Add Tournament
                </Button>

            </div>
          {renderTournaments()}
      </Stack>
    );
  };
export default Tournaments;
