import {ButtonsNavStyle} from "./index.styles";
import React from "react";
import {Button} from "@mui/material";
import isMobileScreen from "hooks/useIsMobile";
import cx from "classnames";

const ButtonsNav = () => {
    const classes = ButtonsNavStyle();
    const isMobile = isMobileScreen();

    const ButtonsNavClassNames = cx(classes.ButtonsNav, {
        [classes.ButtonsNavMobile]: isMobile,
    });
    const ButtonsNavItemClassNames = cx(classes.ButtonsNavItem, {
        [classes.ButtonsNavItemMobile]: isMobile,
    });
    return (
        <div className={ButtonsNavClassNames}>
            <div className={ButtonsNavItemClassNames}>
                <Button variant="text">
                    About us
                </Button>
                <Button variant="text">
                    Out Team
                </Button>
                <Button variant="text">
                    Contact us
                </Button>
            </div>
            <div className={ButtonsNavItemClassNames}>
                <Button variant="text">
                    Future Games
                </Button>
                <Button variant="text">
                    Available Game
                </Button>
                <Button variant="text">
                    View All Games
                </Button>
            </div>
        </div>
    )
};

export default ButtonsNav;
