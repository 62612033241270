import {makeStyles} from '@mui/styles';
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    pageBanner: {
        height: "auto",
        width: '100%',
        backgroundColor: '#0F1327'
    },
    pageBannerLeft: {
        overflow: "hidden",
        position: 'relative',
        display: 'flex',
        justifyContent: 'Center',
        padding: '20px 15px 20px 15px',
        minHeight: '550px',
    },
    pageBannerLeftImage: {
      
        width: "200px",
        height: "200px;",
        zIndex: "30",
    },
    
    pageBannerBGImage: {
        position: "absolute",
        zIndex: "20",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: 'left'
    },

    pageBannerLeftContent: {
        display: 'flex',
        position: "relative",
        width: "100%",
        zIndex: "30",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '1272px',
        margin: "0",
        border: "10px solid #1E2647",
        "&:after": {
            content: "''",
            width: "10px",
            height: "30px",
            backgroundColor: "#1E2647",
            display: "block",
            position: "absolute",
            bottom: "0",
            left: "50%"
        },
        "&:before": {
            content: "''",
            width: "10px",
            height: "30px",
            backgroundColor: "#1E2647",
            display: "block",
            position: "absolute",
            top: "0",
            left: "50%"
        }
    },
    pageBannerRight: {
        display: "flex",
        flex: 0.40,
        overflow: "hidden",
        position: 'relative',
    },
    pageBannerRightImage: {
        position: 'absolute',
        left: "2px",
        top: 0,
        minWidth: "100%",
        minHeight: "100px",
        zIndex: -1,
        height: '100%',
        objectFit: 'cover'
    },
    pageBannerRightContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        maxWidth: '572px',
        margin: "0 0 236px 56px",
    },
    textDisplayBlock: {
        display: 'block',
    },
    textListStyle: {
        listStyleType: 'disc',
        color: '#FFFFFF',
        margin: '0 0 24px 20px',
        padding: '12px 0 0 0'
    },
    pageBannerMobile: {
        display: 'flex',
    },
    imgMobile: {
       
    },
    pageBannerLeftMobile: {
        height: 'auto',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,

    },
    pageBannerLeftContentMobile: {
        
    },
    pageBannerLeftMobileTitle: {
        color: theme.colors.white,
        fontSize: '50px',
        fontWeight: '900',
        lineHeight: '1.2',
        paddingBottom: '20px'
    },
    pageBannerRightContentMobile: {
        marginBottom: '200px',
    },
    pageBannerRighMobileTitle: {
        color: theme.colors.white,
        fontSize: '35px',
        lineHeight: '1.2'
    },
    pageBannerSmallMobileTitle: {
        color: theme.colors.white,
        fontSize: '20px',
        borderBottom: '3px solid #fff',
        paddingBottom: '3px',
        paddingTop: '20px',
        lineHeight: '1.2',
        marginBottom: '30px'
    },
    pageBannerRightHide: {
        display: 'none'
    },
    textListStyleMobile: {
        padding: '0',
        '& li': {
            fontSize: '21px',
        },
    },
}));

export default useStyles;
