import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'


const TableStyles = makeStyles((theme: Theme) => ({

    tableRoot: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.colors.white}`,
        boxSizing: 'border-box',
        borderRadius: '24px',
        justifyContent: 'flexStart',
        flexDirection: 'column',
        alignItems: 'stretch',
        overflow: 'hidden',
        flexWrap: 'nowrap',
    },
    tableHead: {
        color: theme.colors.lightGray,
        display: 'flex',
        alignItems: 'center',
        minHeight: '44px',
        margin: '0 8px 0 8px',
        padding: '0 8px 0 8px',
        width: "auto",
        flexGrow: 1,
        ...theme.typography.tableHead,
    },
    headerCell: {
        display: "flex",
        padding: '0 8px',
    },
    tableRow: {
        display: 'flex',
        backgroundColor: theme.colors.white,
        borderRadius: "16px",
        flexBasis: "72px",
        flexShrink: 0,
        margin: '0 8px 8px',
        padding: '8px',
        width: "auto",
        flexGrow: 1,
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.typography.tableCell,
    },
    tableCellCenter: {
      justifyContent: "center",
    },
    tableCellRight: {
      justifyContent: "flex-end",
    },
    paginationRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        flexGrow: 1,
        padding: '0 8px',
    },
    grid: {
        flexGrow: 1,
        alignItems: 'stretch',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    root: {
        width: '100%',
        flexGrow: 1,
        justifyContent: 'stretch',
        overflow: 'hidden',
        "& .MuiDataGrid-root": {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.colors.white}`,
            boxSizing: 'border-box',
            borderRadius: '24px',
            "& .MuiDataGrid-renderingZone": {
              maxHeight: 'initial !important',
            },
            "& .MuiDataGrid-viewport": {
                maxHeight: 'initial !important',
            },
            "& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus": {
                outline: 'none',
            },
            "& .MuiDataGrid-columnsContainer": {
                borderBottom: 'none',
            },
            "& .MuiDataGrid-columnSeparator": {
                display: 'none',
            },
            "& .MuiDataGrid-columnHeaderTitle": {

                color: theme.colors.white,
                ...theme.typography.tableHead,
            },
            "& .MuiDataGrid-row": {
                backgroundColor: theme.colors.white,
                borderRadius: "16px",
                margin: '0 8px 8px',
                width: "auto",
                '&.Mui-selected': {
                    backgroundColor: theme.colors.white,
                    '&:hover': {
                        backgroundColor: theme.colors.white,
                    }
                },
                '&:hover': {
                    backgroundColor: theme.colors.white,
                }
            },
            "& .MuiDataGrid-cell": {
                borderBottom: 'none',
            }
        },
    },
}));

export { TableStyles };
