import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'

const useGameListsStyles = makeStyles((theme: Theme) => ({
    GameList: {
        backgroundColor: "#105ea2",
        padding: '72px 0 88px 0',
        display: 'flex',
        flexGrow: 1,
    },
    tableHeader: {
        "& .MuiGrid-root": {
            color: theme.colors.white,
        }
    },
    gameLogo: {
      width: '96px',
      height: '96px'
    },
    Container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        paddingBottom: '48px',
    },
    GameListHeadItem: {
        display: 'flex',
        alignItems: 'center',
    },
    GameListHeadText: {
        lineHeight: 1.1,
        marginLeft: '36px',
        color: theme.colors.white,
    },
    GameListTable: {
        display: 'flex',
        flexGrow: 1,
        marginBottom: '88px',
    },
}));

export default useGameListsStyles;
