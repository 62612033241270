import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        overflow: 'hidden'
    },
    players: {
        flexDirection: 'column',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '24px',
        margin: '10px 0',
        padding: '8px 0',
        gap: '8px',
        backgroundColor: 'transparent',
        overflowY: 'auto'
    },
    count: {
        ...theme.typography.regular1216gray,
        flexGrow: 0,
        paddingTop: '8px',
        flexDirection: 'row',
        gap: '8px'
    },
    tier1: {
        color: theme.colors.A_1,
    },
    tier2: {
        color: theme.colors.A_2,
    },
    tier3: {
        color: "#e59b0f"
    },
    tier4: {
        color: theme.colors.A_4
    },
    applyBtn: {
        padding: 0,
        '& svg': {
            fill: theme.colors.primary
        },
        '&.Mui-disabled': {
            '& svg': {
                fill: theme.colors.lightGray
            },
        }

    },
    searchBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
        minWidth: '500px',
        '& input': {
            height: '32px',
            minWidth: '250px',
        },
        '& label': {
          display: 'none',
        },
    }
}));

export default useStyles;
