import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    gameLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& img": {
            maxWidth: '40px',
            maxHeight: '40px',
        }
    },
}));

export default useStyles;
