import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'
import { winnerColor } from "theme";

const useStyles = makeStyles((theme: Theme) => ({
    leaderboard: {
        position: 'fixed',
        bottom: '52px',
        right: '48px',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    closeButton: {
        borderRadius: '50%',
        width: '67px',
        height: '67px',
        padding: 0,
    },
    card: {
        width: '336px',
        height: '438px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        height: '90px',
        margin: '0 -4px',
    },
    cardContent: {
        overflowY: 'scroll',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 4px',
        ...theme.typography.semiBold2232white,
    },
    headerImage: {
        height: '32px',
        margin: '0 4px'
    },
    leaderBoardRow: {
        height: '58px',
        borderBottom: `2px solid ${theme.colors.light}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    leaderBoardRank: {
        margin: '0 0 0 26px',
        display: 'flex',
        backgroundColor: theme.colors.light,
        width: '32px',
        height: '34px',
        borderRadius: '6px',
        alignItems: 'center',
        justifyContent: 'center',
        ...theme.typography.semiBold1420gray,
    },
    leaderBoardName: {
        flexGrow: 1,
        marginLeft: '16px',
        ...theme.typography.semiBold1420gray,
        color: theme.colors.black,
    },
    leaderBoardScore: {
        ...theme.typography.semiBold1420gray,
        color: theme.colors.black,
        marginRight: '34px',
    },
    leaderboardMobile: {
        display: 'none',
    },
    winner: {
        backgroundColor: winnerColor,
    }
}));

export default useStyles;
