import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'

const PickTeamStyles = makeStyles((theme: Theme) => ({
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: "200px",
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
}));

export { PickTeamStyles };
