import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useGameBracketStyles = makeStyles((theme: Theme) => ({
  bracket: {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    border: '1px solid #FFFFFF33',
  },
  bracketHolder: {
    display: 'flex',
    flexShrink: 0,
    width: '142px',
    height: '40px',
  },
  finalBracketHolder: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '142px',
    alignItems: 'center',
  },
  predictionCorrect: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid green',
      },
    },
  },
  predictionWrong: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid red',
      },
    },
  },
  bracketLines: {
    display: 'flex',
    flexShrink: 0,
    alignSelf: 'stretch',
    width: '10px',
  },
  bracketFigureLines: {
    display: 'flex',
    flexShrink: 0,
    alignSelf: 'stretch',
    width: '12px',
  },
  figureLine: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  centerLine: {
    display: 'flex',
    width: '100%',
    borderTop: `2px solid ${theme.colors.lightGray}`,
    alignSelf: 'center',
  },
  lines: {
    display: 'flex',
    flexGrow: 1,
  },
  topLine: {
    borderRight: `2px solid ${theme.colors.lightGray}`,
    borderBottomRightRadius: '8px',
    borderBottom: `2px solid ${theme.colors.lightGray}`,
  },
  bottomLine: {
    borderRight: `2px solid ${theme.colors.lightGray}`,
    borderTopRightRadius: '8px',
    borderTop: `2px solid ${theme.colors.lightGray}`,
  },
  bracketParents: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
}));

export default useGameBracketStyles;
