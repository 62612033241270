import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  scrollToTopButton: {
    width: 50,
    bottom: '25px',
    position: 'sticky',
    backgroundColor: theme.colors.white,
    boxShadow: `0px 5px 17.5px 0px #00000057`,
    '&:hover': {
      backgroundColor: `${theme.colors.darkGreen}`,
    },
  },
}));

export default useStyles;
