import React from 'react';
import { createTheme } from '@mui/material/styles';
import {
  montserratRegular,
  montserratMedium,
  montserratSemiBold,
  montserratBold,
  montserratLight,
  bebasNeueRegular,
} from 'styles/fonts';

export const white = '#FFFFFF';
const black = '#000';
export const lightGray = '#91969E';
const gray = '#555961';
const basicLine = '#D3D7E0';
const light = '#F4F5F7';

export const winnerColor = '#ffd914';
export const red = '#F24747';
export const green = '#46C0C5';
export const lightGreen = '#91F0F4';
export const tier4Green = '#007649';
export const orange = '#FF7500';
export const yellow = '#FFCF31';
export const blue = '#483EF2';
export const purple = '#C230EE';
export const lightBrown = '#AF9150';
export const lightBlue = '#3989E2';
export const redBrown = '#A72F2F';
export const darkGreen = '#0BAAAE';
export const brown = '#BC6318';
export const orangeRed = '#D5622A';
export const whiteGray = '#F1F1F1';
export const redError = '#FF0000';
export const grayBlack = '#090A0B';
export const tier1Color = '#5E0789';
export const tier2Color = '#2D8C54';
export const tier3Color = '#E8B828';
export const tier4Color = '#C9BDB4';
export const darkBlue = '#0E1327';

export const aqua = '#BBF3DE';

const primaryLight = '#8348d0';
export const primary = '#6939A8';
const primaryDark = '#472673';

const secondaryLight = '#fde1a3';
const secondary = '#EFD487';
const secondaryDark = '#cbb16f';

export const fontFamilies = {
  montserratRegular,
  montserratMedium,
  montserratSemiBold,
  montserratBold,
  montserratLight,
  bebasNeueRegular,
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableHead: React.CSSProperties;
    tableCell: React.CSSProperties;
    regular1420?: React.CSSProperties;
    regular1618gray: React.CSSProperties;
    regular1618: React.CSSProperties;
    regularMontserrat1618: React.CSSProperties;
    regular1624: React.CSSProperties;
    semiBold1618black: React.CSSProperties;
    semiBold1618white: React.CSSProperties;
    semiBoldMontserrat1618: React.CSSProperties;
    regular1216gray: React.CSSProperties;
    regular1420gray: React.CSSProperties;
    regular2232: React.CSSProperties;
    medium1420: React.CSSProperties;
    medium1618: React.CSSProperties;
    semiBold1420gray: React.CSSProperties;
    semiBold2232white: React.CSSProperties;
    bold16: React.CSSProperties;
    regularBebasNeue: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tableHead?: React.CSSProperties;
    tableCell?: React.CSSProperties;
    regular1420?: React.CSSProperties;
    regular1618: React.CSSProperties;
    regularMontserrat1618: React.CSSProperties;
    regular1624: React.CSSProperties;
    regular1618gray?: React.CSSProperties;
    semiBold1618black: React.CSSProperties;
    semiBold1618white: React.CSSProperties;
    semiBoldMontserrat1618: React.CSSProperties;
    regular1216gray: React.CSSProperties;
    regular1420gray: React.CSSProperties;
    regular2232: React.CSSProperties;
    medium1420: React.CSSProperties;
    medium1618: React.CSSProperties;
    semiBold1420gray: React.CSSProperties;
    semiBold2232white: React.CSSProperties;
    bold16: React.CSSProperties;
    regularBebasNeue: React.CSSProperties;
  }

  interface Theme {
    colors: {
      winnerColor: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
      darkGreen: React.CSSProperties['color'];
      gray: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      light: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      primary: React.CSSProperties['color'];
      orangeRed: React.CSSProperties['color'];
      aqua: React.CSSProperties['color'];
      whiteGray: React.CSSProperties['color'];
      redError: React.CSSProperties['color'];
      grayBlack: React.CSSProperties['color'];
      basicLine: React.CSSProperties['color'];
      darkBlue: React.CSSProperties['color'];
      A_1: React.CSSProperties['color'];
      A_2: React.CSSProperties['color'];
      A_3: React.CSSProperties['color'];
      A_4: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    colors: {
      winnerColor: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
      darkGreen: React.CSSProperties['color'];
      gray: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      light: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      primary: React.CSSProperties['color'];
      orangeRed: React.CSSProperties['color'];
      aqua: React.CSSProperties['color'];
      whiteGray: React.CSSProperties['color'];
      redError: React.CSSProperties['color'];
      grayBlack: React.CSSProperties['color'];
      basicLine: React.CSSProperties['color'];
      A_1: React.CSSProperties['color'];
      A_2: React.CSSProperties['color'];
      A_3: React.CSSProperties['color'];
      A_4: React.CSSProperties['color'];
    };
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableHead: true;
    tableCell: true;
    regular1420: true;
    regular1618gray: true;
    regular1618: true;
    regularMontserrat1618: true;
    regular1624: true;
    semiBold1618black: true;
    semiBold1618white: true;
    semiBoldMontserrat1618: true;
    regular1216gray: true;
    regular1420gray: true;
    regular2232: true;
    medium1420: true;
    medium1618: true;
    semiBold1420gray: true;
    semiBold2232white: true;
    bold16: true;
    regularBebasNeue: true;
  }
}

let theme = createTheme();

export const appTheme = createTheme(theme, {
  colors: {
    winnerColor,
    gray,
    white,
    black,
    green,
    lightGreen,
    darkGreen,
    yellow,
    primary,
    orangeRed,
    lightGray,
    light,
    aqua,
    whiteGray,
    redError,
    grayBlack,
    basicLine,
    darkBlue,
    A_1: tier1Color,
    A_2: tier2Color,
    A_3: tier3Color,
    A_4: tier4Color,
  },
  palette: {
    primary: {
      light: primaryLight,
      main: primary,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      light: secondaryLight,
      main: secondary,
      dark: secondaryDark,
      contrastText: black,
    },
  },
  typography: {
    fontFamily: Object.values(fontFamilies)
      .map((font) => font.fontFamily)
      .join(','),
    h1: {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
    },
    h2: {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '72px',
    },
    tableHead: {
      fontFamily: fontFamilies.montserratMedium.fontFamily,
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
    tableCell: {
      fontFamily: fontFamilies.montserratMedium.fontFamily,
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
    },
    regular1618: {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    regularMontserrat1618: {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    semiBoldMontserrat1618: {
      fontFamily: fontFamilies.montserratSemiBold.fontFamily,
      fontWeight: fontFamilies.montserratSemiBold.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    regular1624: {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '16px',
      lineHeight: '24px',
    },
    regular1618gray: {
      color: gray,
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    semiBold1618black: {
      color: black,
      fontFamily: fontFamilies.montserratSemiBold.fontFamily,
      fontWeight: fontFamilies.montserratSemiBold.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    semiBold1618white: {
      color: white,
      fontFamily: fontFamilies.montserratSemiBold.fontFamily,
      fontWeight: fontFamilies.montserratSemiBold.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    regular1420: {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '14px',
      lineHeight: '20px',
    },
    regular1420gray: {
      color: gray,
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '14px',
      lineHeight: '20px',
    },
    regular1216gray: {
      color: gray,
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: fontFamilies.montserratRegular.fontWeight,
      fontSize: '12px',
      lineHeight: '16px',
    },
    regular2232: {
      fontFamily: fontFamilies.montserratSemiBold.fontFamily,
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '32px',
    },
    medium1420: {
      fontFamily: fontFamilies.montserratMedium.fontFamily,
      fontWeight: fontFamilies.montserratMedium.fontWeight,
      fontSize: '14px',
      lineHeight: '20px',
    },
    medium1618: {
      fontFamily: fontFamilies.montserratMedium.fontFamily,
      fontWeight: fontFamilies.montserratMedium.fontWeight,
      fontSize: '16px',
      lineHeight: '18px',
    },
    semiBold1420gray: {
      color: gray,
      fontFamily: fontFamilies.montserratSemiBold.fontFamily,
      fontWeight: fontFamilies.montserratSemiBold.fontWeight,
      fontSize: '14px',
      lineHeight: '20px',
    },
    semiBold2232white: {
      color: white,
      fontFamily: fontFamilies.montserratSemiBold.fontFamily,
      fontWeight: fontFamilies.montserratSemiBold.fontWeight,
      fontSize: '22px',
      lineHeight: '32px',
    },
    bold16: {
      fontFamily: fontFamilies.montserratBold.fontFamily,
      fontWeight: fontFamilies.montserratBold.fontWeight,
      fontSize: '16px',
      lineHeight: '20px',
    },
    regularBebasNeue: {
      fontFamily: fontFamilies.bebasNeueRegular.fontFamily,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': Object.keys(fontFamilies),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '0',
        },
        paperWidthSm: {
          maxWidth: '376px',
          boxShadow: '0 16px 32px rgba(18, 50, 67, 0.1)',
          borderRadius: '16px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 32px 32px 32px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat-SemiBold',
          fontWeight: 600,
          fontSize: '22px',
          lineHeight: '32px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '32px 32px 16px 32px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          color: '#555961',
          '&$focused': {
            color: primary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
        outlined: {
          transform: 'none',
          marginBottom: '8px',
        },
        formControl: {
          position: 'initial',
          transform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '8px',
          padding: '8px 12px',
        },
        root: {
          textarea: {
            fontFamily: fontFamilies.montserratRegular.fontFamily,
            borderRadius: '8px',
            color: lightGray,
            fontSize: '16px',
            lineHeight: '24px',
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat-SemiBold',
          fontWeight: 600,
          boxSizing: 'border-box',
          borderRadius: '8px',
          fontSize: '16px',
          lineHeight: '24px',
          textTransform: 'initial',
          padding: '12.3px 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '4.35px 12px',
            borderRadius: '6px',
          },
        },
        outlined: {
          padding: '11.3px 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '3.35px 12px',
          },
        },
        text: {
          color: black,
          fontWeight: 400,
          fontFamily: 'SpaceGrotesk-Regular',
          padding: '12.3px 24px',
        },
        sizeSmall: {
          fontFamily: 'Montserrat-SemiBold',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '18px',
          padding: '7.35px 12px',
        },
        sizeLarge: {
          fontFamily: 'Montserrat-SemiBold',
          fontWeight: 600,
          fontSize: '22px',
          lineHeight: '32px',
          padding: '17.7px 24px',
        },
        iconSizeLarge: {
          height: '30px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '96px !important',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'SpaceGrotesk-Regular',
          fontWeight: 400,
          color: black,
          fontSize: '48px',
          lineHeight: '72px',
        },
        h2: {
          fontSize: '48px',
          lineHeight: '72px',
        },
        h3: {
          fontSize: '14px',
          lineHeight: '24px',
          color: primary,
          fontWeight: 500,
          textAlign: 'right',
        },
        h4: {
          fontFamily: 'Montserrat-SemiBold',
          fontWeight: 600,
          fontSize: '22px',
          lineHeight: '32px',
          color: black,
        },
        h6: {
          fontSize: '14px',
          lineHeight: '20px',
          color: black,
        },
        body1: {
          fontSize: '16px',
          lineHeight: '24px',
        },
        body2: {
          fontSize: '16px',
          lineHeight: '24px',
          color: black,
          fontWeight: 400,
          opacity: 0.6,
        },
        subtitle1: {
          fontSize: '22px',
          lineHeight: '32px',
          fontWeight: 300,
          color: black,
        },
        subtitle2: {
          fontFamily: 'SpaceGrotesk-Regular',
          color: black,
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '32px',
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
