import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const usePageHeaderStyles = makeStyles((theme: Theme) => ({
  small: {
    '& svg': {
      width: '111px',
    },
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: ' #0E1327',
  },
  buttonsGroup: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    gap: '8px',
    backgroundColor: ' #0E1327',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
      padding: '10px',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      gap: '8px',
      '& button': {
        width: '100%',
      },
    },
  },
  menuIconButton: {
    display: 'flex',
    cursor: 'pointer',
    '& svg': {
      width: '2.5em',
      height: '2.5em',
      '& *': {
        stroke: theme.colors.white,
      },
    },
  },
  mobileMenu: {
    top: '96px',
    '& .MuiBackdrop-root': {
      top: '96px',
      backgroundColor: 'none',
      '& .MuiDrawer-paper': {
        top: '96px',
        boxShadow: 'none',
      },
    },
  },
  buttonTitle: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: '24px',
  },
}));

export default usePageHeaderStyles;
