import {makeStyles} from "@mui/styles";
import {Theme} from '@mui/material/styles'
import BgContactUs from 'assets/images/bgContactUs.png'
import BgContactUsMobile from 'assets/images/bgContactUsMobile.png'

const useContactUsStyles = makeStyles((theme: Theme) => ({
    ContactUs: {
        backgroundColor: theme.colors.light,
        height: '100%',
        paddingTop: '34px',
    },
    ContactUsTitle: {
        backgroundImage: `url(${BgContactUs})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    ContactUsTitleText: {
        color: theme.colors.white,
        padding: '68px 0 60px 0',
    },
    ContactUsContent: {
        display: 'flex',
    },
    ContactUsContentItem: {
        width: '50%',
    },
    ContactUsInfo: {
        width: '238px',
        marginTop: '47px',
        "& a": {
            margin: '25px 28px 0 0',
        },
        "& div": {
            justifyContent: 'flex-start',
        },
    },
    ContactUsContentForm: {
        maxWidth: '480px',
        width: '100%',
        marginTop: '96px',
    },
    ContactUsFooterNav: {
        borderTop: '1px solid #D3D7E0',
        marginTop: '72px',
        padding: '24px 0',
    },
    ContactUsMobile: {
        padding: 0,
    },
    ContactUsContentMobile: {
        flexDirection: 'column-reverse',
    },
    ContactUsTitleMobile: {
        backgroundImage: `url(${BgContactUsMobile})`,
    },
    ContactUsTitleTextMobile: {
        fontSize: '36px',
    },
    ContactUsInfoMobile: {
        width: '100%',
        marginTop: '0',
        "& div": {
            alignItems: 'flex-start',
            width: '215px',
            margin: '0 auto',
        },
    },
    ContactUsContentItemMobile: {
        flexDirection: 'column',
        width: '100%',
    },
    ContactUsContentFormMobile: {
        MaxWidth: '384px',
        margin: '30px auto 0 auto',
    },
    ContactUsFooterNavMobile: {
        marginTop: 0,
    }
}));

export default useContactUsStyles;
