import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '12px'
    },
    ButtonGamePageText: {
        color: theme.colors.redError,
        fontWeight: 500,
    },
    ButtonGamePageBtn: {
        lineHeight: '18px',
        fontWeight: 500,
        padding: '7px 16px'
    },
}));

export default useStyles;
