import { gql } from '@apollo/client';

export const ROUND_INFO = gql`
  fragment RoundFields on RoundType {
    id
    title
    points
    pointsText
    coefficient
    start
    end
    status {
      key
      val
    }
  }
`;
