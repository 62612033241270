import useStyles from './index.styles';

import React, { useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useGame } from "hooks/useGame";
import GameForm from "components/form/GameForm";
import { IGame } from "models/game.model";

interface IGameDialog {
    tournamentId: string;
    gameId?: string
    title: string
    open: boolean;
    handleClose: () => void;
}

const GameDialog: React.FC<IGameDialog> = ({
        tournamentId,
        gameId,
        title,
        open,
        handleClose,
    }: IGameDialog): React.ReactElement => {
    const {game, getGame} = useGame()

    const gameToEdit: IGame | undefined = useMemo(() => {
        if (gameId && game) return game
        return undefined
    }, [game, gameId])

    useEffect(() => {
        if (gameId) {
            getGame(gameId)
        }
    }, [gameId])

    return (
        <Dialog onClose={ handleClose } open={ open }>
            <Stack
                direction="row"
                spacing={ { xs: 1, sm: 2, md: 4 } }
                justifyContent="space-between"
                alignItems="center"
                sx={ { p: 1 } }
            >
                <DialogTitle sx={ { p: 1 } }>{ title }</DialogTitle>
                <Tooltip title="Close">
                    <IconButton onClick={ handleClose }>
                        <CloseOutlinedIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>
            <DialogContent>
                <GameForm game={gameToEdit} tournamentId={tournamentId} onClose={ handleClose }/>
            </DialogContent>
        </Dialog>
    );
};
export default GameDialog;
