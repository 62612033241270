import { makeStyles } from '@mui/styles';

const useSignUpFormStyles = makeStyles({
    FormInputStyle: {
        marginBottom: "16px",
    },
    FormLineTextStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },
    FormLineStyle: {
        background: '#000000',
        opacity: 0.1,
        width: '90px',
    },
    FormTitleAfter: {
        color: '#91969E',
        width: '132px',
        textAlign: 'center',
    },
    PagePopupSocNet: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 24,
        margin: '15px 0'
    },
    socialBtn: {
        display: "flex",
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            cursor: 'pointer'
        }
    }

});

export default useSignUpFormStyles;
