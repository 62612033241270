import useStyles from './index.styles';
import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import AppRemoveIcon from 'assets/images/AppRemoveIcon';
import { CustomCheckbox } from 'components/common/index';
import { IPaymentMethod } from "models/payment.model";

interface ICardItem {
    card: IPaymentMethod,
    selectorTitle?: string
    isActive: boolean,
    setIsActive: (id: string) => void
}

const CardItem = ({card: {
    expMonth,
    expYear,
    last4,
    id,
    cardholderName
}, isActive, setIsActive, selectorTitle}: ICardItem) => {
    const classes = useStyles();
    return (
        <Stack className={classes.container}>
            <Box className={classes.card}>
                <Box>
                    <Box className={classes.cardNumberField}>
                        <span>••••</span>
                        <span>••••</span>
                        <span>••••</span>
                        <span>{last4}</span>
                    </Box>
                    <Stack className={classes.cardBottomn}>
                        <Typography variant="regular1216gray" component="p">
                            Expired: {expMonth < 10 ? '0' + expMonth : expMonth}/
                            {String(expYear).slice(2)}
                        </Typography>
                        <Typography variant="regular1216gray" component="p">
                            {cardholderName}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Box className={classes.saveCard}>
                <CustomCheckbox checked={isActive} onChange={(): void => setIsActive(id || '')} />
                <Typography noWrap variant="regular1420gray" sx={{color: '#000'}}>{selectorTitle || 'Make Default'}</Typography>
            </Box>
        </Stack>
    );
};

export default CardItem;
