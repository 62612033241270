import useGameBracketStyles from './index.styles';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Stack, alpha, useTheme } from '@mui/material';
import SelectField from '../SelectField';
import { groupColors } from 'constants/colors';
import cx from 'classnames';
import { GameBracketsContext } from 'models/prediction.model';
import PlayerBracket from '../PlayerBracket';
import { ITournamentPlayer } from 'models/tournamentPlayer.model';
import { BracketGameType } from 'components/pages/FillBrackets';

interface GameBracketProps {
  index?: number;
  isRootElement?: boolean;
  isLeftChildren?: boolean;
  isRightBracket?: boolean;
  gameId: string;
  isFinal?: boolean;
  finalLeftParent?: boolean;
  finalRightParent?: boolean;
  setColor?: (color: string, isLeft?: boolean) => void;
}

interface BracketParentsType {
  left?: string;
  right?: string;
}

const roundTitles: Array<string> = [
  'R1',
  'R2',
  'R3',
  'ROUND of 16',
  'Quarters',
  'Semis',
  'Final',
  'Champion',
];

const GameBracket = (props: GameBracketProps) => {
  const {
    index,
    gameId,
    isRootElement,
    isLeftChildren,
    setColor,
    isFinal,
    finalLeftParent,
    finalRightParent,
    isRightBracket,
  } = props;

  const [firstParentSelected, setFirstParentSelected] =
    useState<ITournamentPlayer | null>(null);
  const [secondParentSelected, setSecondParentSelected] =
    useState<ITournamentPlayer | null>(null);

  const [leftParentColor, setLeftParentColor] = useState<string>('');
  const [rightParentColor, setRightParentColor] = useState<string>('');

  const [selectedPlayer, setSelectedPlayer] = useState<string>('');
  const [playersOptions, setPlayersOptions] = useState<ITournamentPlayer[]>([]);

  const [backgroundColor, setBackgroundColor] = useState<string>('');

  const theme = useTheme();

  const classes = useGameBracketStyles();

  const {
    activeRound,
    games,
    players,
    curBracket,
    selectPlayer,
    selectedPlayers,
    predictions,
    readonly,
  } = useContext(GameBracketsContext);

  const getPlayer = useCallback(
    (id?: string): ITournamentPlayer | null => {
      if (id && players.hasOwnProperty(id) && players[id]) {
        return players[id];
      }
      return null;
    },
    [players],
  );

  const bracket: BracketGameType | null = useMemo(() => {
    const bracketGame = games?.find((item) => item?.match?.id === gameId);
    return gameId && bracketGame ? bracketGame : null;
  }, [games, gameId]);

  const firstPrevGame: BracketGameType | null = useMemo(() => {
    const prevGame = games?.find(
      (item) => item?.match?.id === bracket?.previous[0],
    );
    return gameId && prevGame ? prevGame : null;
  }, [bracket?.previous, gameId, games]);

  const secondPrevGame: BracketGameType | null = useMemo(() => {
    const prevGame = games?.find(
      (item) => item?.match?.id === bracket?.previous[1],
    );
    return gameId && prevGame ? prevGame : null;
  }, [bracket?.previous, gameId, games]);

  const predictionPlayer = useMemo(() => {
    if (bracket?.match?.id && predictions?.[bracket?.match?.id]) {
      return getPlayer(predictions?.[bracket?.match?.id]);
    }
    return null;
  }, [bracket?.match?.id, getPlayer, predictions]);

  const isPredictionWrongPlayer = useMemo(() => {
    if (
      !bracket?.match?.winner?.id &&
      !selectedPlayer &&
      predictions?.[gameId]
    ) {
      return !(
        firstParentSelected?.id === predictions?.[gameId] ||
        secondParentSelected?.id === predictions?.[gameId]
      );
    }
    if (!bracket?.match?.winner?.id && selectedPlayer) {
      return !(
        firstParentSelected?.id === selectedPlayer ||
        secondParentSelected?.id === selectedPlayer
      );
    }
  }, [
    bracket?.match?.winner?.id,
    firstParentSelected?.id,
    gameId,
    predictions,
    secondParentSelected?.id,
    selectedPlayer,
  ]);

  const parents: BracketParentsType = useMemo(() => {
    const getBracketPlayers = (bracketId: string) => {
      const bracketFound = games?.find((item) => item?.match?.id === bracketId);

      if (bracketId && bracketFound) {
        return [bracketFound.match?.first?.id, bracketFound.match?.second?.id];
      } else {
        return [];
      }
    };
    if (bracket?.match?.first?.id) {
      const bracket2Players = getBracketPlayers(bracket?.previous[0]);
      if (bracket2Players.indexOf(bracket?.match?.first?.id) !== -1) {
        return {
          left: bracket?.previous[0],
          right: bracket?.previous[1],
        };
      }
    }
    return {
      left: bracket?.previous[0],
      right: bracket?.previous[1],
    };
  }, [bracket, games]);

  // set selectedPlayer if it exist in selectedPlayers
  useEffect(() => {
    if (selectedPlayers && bracket) {
      const currentGameId = bracket?.match?.id || '';
      if (!bracket?.match?.winner?.id) {
        const selected = selectedPlayers[currentGameId] || '';
        if (selected !== selectedPlayer) {
          setSelectedPlayer(selected);
        }
      }
    }
  }, [bracket, selectedPlayer, selectedPlayers]);

  useEffect(() => {
    const leftChildrenId = bracket?.previous[0] || '';
    const rightChildrenId = bracket?.previous[1] || '';
    const firstPlayer =
      selectedPlayers?.[leftChildrenId] ||
      predictions?.[leftChildrenId] ||
      firstPrevGame?.match?.winner?.id ||
      bracket?.match?.first?.id;
    const secondPlayer =
      selectedPlayers?.[rightChildrenId] ||
      predictions?.[rightChildrenId] ||
      secondPrevGame?.match?.winner?.id ||
      bracket?.match?.second?.id;
    if (firstParentSelected?.id !== firstPlayer) {
      if (firstPlayer) {
        setFirstParentSelected(getPlayer(firstPlayer));
      } else {
        setFirstParentSelected(null);
      }
    }
    if (secondParentSelected?.id !== secondPlayer) {
      if (secondPlayer) {
        setSecondParentSelected(getPlayer(secondPlayer));
      } else {
        setSecondParentSelected(null);
      }
    }
  }, [
    bracket?.match?.first?.id,
    bracket?.match?.second?.id,
    bracket?.previous,
    firstParentSelected?.id,
    firstPrevGame?.match?.winner?.id,
    getPlayer,
    predictions,
    secondParentSelected?.id,
    secondPrevGame?.match?.winner?.id,
    selectedPlayers,
  ]);

  // set bg colors
  useEffect(() => {
    const winnerId = bracket?.match?.winner?.id || selectedPlayer;
    if (
      leftParentColor &&
      firstParentSelected &&
      firstParentSelected.id === winnerId
    ) {
      setBackgroundColor(leftParentColor);
    } else if (
      rightParentColor &&
      secondParentSelected &&
      secondParentSelected.id === winnerId
    ) {
      setBackgroundColor(rightParentColor);
    } else if (leftParentColor) {
      setBackgroundColor(leftParentColor);
    }
  }, [
    firstParentSelected,
    secondParentSelected,
    selectedPlayer,
    leftParentColor,
    rightParentColor,
    setBackgroundColor,
    bracket?.match?.winner?.id,
  ]);

  useEffect(() => {
    backgroundColor && setColor && setColor(backgroundColor, isLeftChildren);
  }, [backgroundColor, isLeftChildren, setColor]);

  useEffect(() => {
    if (!parents.left && !parents.right) {
      const colorNum = (index || 0) % groupColors.length;
      if (groupColors[colorNum]) {
        setBackgroundColor(
          groupColors[colorNum] ? groupColors[colorNum] : groupColors[0],
        );
      }
    }
  }, [parents, index]);

  const onColorChanged = (color: string, isLeft?: boolean) => {
    isLeft ? setLeftParentColor(color) : setRightParentColor(color);
  };
  // set players options for select
  useEffect(() => {
    if (bracket?.match?.winner?.id) {
      const winner = getPlayer(bracket?.match?.winner?.id);
      const plrs = [winner, predictionPlayer].filter(
        (item) => item !== null,
      ) as ITournamentPlayer[];
      setPlayersOptions(plrs);
    } else if (predictionPlayer) {
      const plrs = [
        !(
          predictionPlayer?.id === firstParentSelected?.id ||
          predictionPlayer?.id === secondParentSelected?.id
        )
          ? predictionPlayer
          : null,
        firstParentSelected,
        secondParentSelected,
      ].filter((item) => item !== null) as ITournamentPlayer[];
      setPlayersOptions(plrs);
    } else if (selectedPlayer) {
      const selected = getPlayer(selectedPlayer);
      const plrs = [
        !(
          selected?.id === firstParentSelected?.id ||
          selected?.id === secondParentSelected?.id
        )
          ? selected
          : null,
        firstParentSelected,
        secondParentSelected,
      ].filter((item) => item !== null) as ITournamentPlayer[];
      setPlayersOptions(plrs);
    } else {
      const plrs = [firstParentSelected, secondParentSelected].filter(
        (item) => item !== null,
      ) as ITournamentPlayer[];
      setPlayersOptions(plrs);
    }
  }, [
    bracket?.match?.winner?.id,
    firstParentSelected,
    getPlayer,
    predictionPlayer,
    secondParentSelected,
    selectedPlayer,
  ]);

  const bracketBackgroundColor: string = useMemo(() => {
    const winnerId = bracket?.match?.winner?.id || selectedPlayer;

    if (winnerId) {
      const player = getPlayer(winnerId);
      if (player && player.tier) {
        if (theme.colors.hasOwnProperty(player.tier)) {
          return theme.colors[player.tier] || alpha(groupColors[0], 0.4);
        }
      }
    }

    if (readonly && playersOptions?.length > 1) {
      const player = playersOptions?.find(
        (item) => item.player.id === selectedPlayer,
      );
      if (player && player.tier) {
        if (theme.colors.hasOwnProperty(player.tier)) {
          return theme.colors[player.tier] || alpha(groupColors[0], 0.4);
        }
      }
    }

    if (predictionPlayer) {
      if (theme.colors.hasOwnProperty(predictionPlayer.tier)) {
        return (
          theme.colors[predictionPlayer.tier] || alpha(groupColors[0], 0.4)
        );
      }
    }

    return backgroundColor
      ? alpha(backgroundColor, 0.4)
      : alpha(groupColors[0], 0.4);
  }, [
    backgroundColor,
    bracket?.match?.winner?.id,
    getPlayer,
    playersOptions,
    predictionPlayer,
    readonly,
    selectedPlayer,
    theme.colors,
  ]);

  const onSelectPlayer = (id: string) => {
    const selectedPlayer = players?.hasOwnProperty(id);
    if (selectedPlayer) {
      selectPlayer(gameId, id);
      setSelectedPlayer(id);
    }
  };

  const isPredictionCorrect = (): boolean =>
    !!(
      bracket?.match?.winner?.id &&
      predictions?.[gameId] &&
      bracket?.match?.winner?.id === predictions?.[gameId]
    );

  const isPredictionWrong = (): boolean => {
    if (bracket?.match?.winner?.id) {
      return !!(
        predictions?.[gameId] &&
        bracket?.match?.winner?.id !== predictions?.[gameId]
      );
    }
    if (
      !bracket?.match?.winner?.id &&
      !selectedPlayer &&
      predictions?.[gameId] &&
      firstPrevGame?.match?.winner?.id &&
      secondPrevGame?.match?.winner?.id
    ) {
      return !(
        firstPrevGame?.match?.winner?.id === predictions?.[gameId] ||
        secondPrevGame?.match?.winner?.id === predictions?.[gameId]
      );
    }
    if (!bracket?.match?.winner?.id && selectedPlayer) {
      return !(
        firstParentSelected?.id === selectedPlayer ||
        secondParentSelected?.id === selectedPlayer
      );
    }
    return false;
  };

  const bracketStyle: { [key: string]: string } = {
    position: 'relative',
    borderRadius: '4px',
    alignSelf: index === 0 || (index && index % 2 === 0) ? 'end' : 'flex-start',
    width: curBracket === 4 && !bracket?.round ? '0px' : '142px',
  };

  const BracketClasses = cx(
    isFinal ? classes.finalBracketHolder : classes.bracketHolder,
    {
      [classes.predictionCorrect]: isPredictionCorrect(),
      [classes.predictionWrong]: isPredictionWrong(),
    },
  );

  const renderRoundTitle = (type?: string) => {
    if (type === 'R1' && (index === 0 || (index && index % 4 === 0))) {
      return (
        <Box
          position="absolute"
          color="white"
          fontFamily={theme.typography.regularBebasNeue.fontFamily}
          fontSize={20}
          letterSpacing="1px"
          top={15}
          right={isRightBracket ? 10 : undefined}
          left={isRightBracket ? undefined : 10}
        >
          {roundTitles[0]}
        </Box>
      );
    } else if (bracket?.round === 0 && (index === 0 || index === 4)) {
      return (
        <Box
          position="absolute"
          color="white"
          fontFamily={theme.typography.regularBebasNeue.fontFamily}
          fontSize={20}
          letterSpacing="1px"
          top={-25}
          left={isRightBracket ? 10 : undefined}
          right={isRightBracket ? undefined : 10}
        >
          {roundTitles[1]}
        </Box>
      );
    } else if (bracket?.round === 1 && (index === 0 || index === 2)) {
      return (
        <Box
          position="absolute"
          color="white"
          fontFamily={theme.typography.regularBebasNeue.fontFamily}
          fontSize={20}
          letterSpacing="1px"
          top={-25}
          left={isRightBracket ? 10 : undefined}
          right={isRightBracket ? undefined : 10}
        >
          {roundTitles[2]}
        </Box>
      );
    } else if (
      bracket?.round === 2 &&
      index === 0 &&
      isRightBracket &&
      curBracket !== 4
    ) {
      return (
        <Box
          position="absolute"
          color="white"
          fontFamily={theme.typography.regularBebasNeue.fontFamily}
          fontSize={20}
          letterSpacing="1px"
          top={-25}
          left={-55}
        >
          {roundTitles[3]}
        </Box>
      );
    } else if (bracket?.round && curBracket === 4 && index === 0) {
      return (
        <Box
          position="absolute"
          color="white"
          fontFamily={theme.typography.regularBebasNeue.fontFamily}
          fontSize={20}
          letterSpacing="1px"
          top={-25}
          left={bracket?.round === 2 || isRightBracket ? 10 : undefined}
          right={
            (isRightBracket && bracket?.round !== 2) ||
            (!isRightBracket && bracket?.round === 2)
              ? undefined
              : 10
          }
        >
          {bracket?.round === 2 ? 'R16' : roundTitles[bracket?.round + 1]}
        </Box>
      );
    } else if (isFinal && !finalRightParent) {
      return (
        <Box
          color="white"
          fontFamily={theme.typography.regularBebasNeue.fontFamily}
          fontSize={finalLeftParent ? 20 : 24}
          letterSpacing="1px"
          pb={1}
        >
          {finalLeftParent ? 'FINAL' : 'CHAMPION'}
        </Box>
      );
    }
    return null;
  };

  const disableSelect = useMemo(
    () =>
      !!bracket?.match?.winner?.id ||
      readonly ||
      (!!bracket?.round && bracket?.round + 1 <= activeRound),
    [activeRound, bracket?.match?.winner?.id, bracket?.round, readonly],
  );

  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
      flexDirection={isRightBracket ? 'row' : 'row-reverse'}
    >
      {isRootElement || isFinal || curBracket === 5 ? null : (
        <div
          className={BracketClasses}
          style={{
            ...bracketStyle,
          }}
        >
          {renderRoundTitle()}
          {(curBracket === 4 && bracket?.round && playersOptions.length) ||
          (curBracket !== 4 && playersOptions.length) ? (
            <SelectField
              size="small"
              onSelectPlayer={onSelectPlayer}
              value={bracket?.match?.winner?.id || selectedPlayer || ''}
              prediction={predictions?.[gameId] || ''}
              isPredictionWrong={isPredictionWrongPlayer}
              isWinner={!!bracket?.match?.winner?.id}
              disabled={disableSelect}
              options={playersOptions}
              style={{
                ...bracketStyle,
                backgroundColor: bracketBackgroundColor,
              }}
            />
          ) : curBracket === 4 && !bracket?.round ? null : (
            <div
              className={classes.bracket}
              style={{
                ...bracketStyle,
                backgroundColor: bracketBackgroundColor,
              }}
            />
          )}
        </div>
      )}
      {isFinal && curBracket !== 5 && (
        <div className={BracketClasses}>
          {renderRoundTitle()}
          {playersOptions?.length ? (
            <SelectField
              size="small"
              onSelectPlayer={onSelectPlayer}
              value={bracket?.match?.winner?.id || selectedPlayer || ''}
              prediction={predictions?.[gameId] || ''}
              isPredictionWrong={isPredictionWrongPlayer}
              isWinner={!!bracket?.match?.winner?.id}
              disabled={disableSelect}
              options={playersOptions}
              style={{
                ...bracketStyle,
                position: isFinal ? 'relative' : 'unset',
                backgroundColor: bracketBackgroundColor,
              }}
            />
          ) : (
            <div
              className={classes.bracket}
              style={{
                height: 40,
                backgroundColor: bracketBackgroundColor,
              }}
            />
          )}
        </div>
      )}
      {parents.left && parents.right && !isFinal ? (
        <div className={classes.bracketParents}>
          <GameBracket
            index={index ? index * 2 : 0}
            gameId={parents.left}
            setColor={onColorChanged}
            isLeftChildren
            isRightBracket={isRightBracket}
          />
          <GameBracket
            index={index ? index * 2 + 1 : 1}
            setColor={onColorChanged}
            gameId={parents.right}
            isRightBracket={isRightBracket}
          />
        </div>
      ) : curBracket === 4 ? null : (
        <div className={classes.bracketParents}>
          {renderRoundTitle('R1')}
          <PlayerBracket
            selectedPlayer={firstParentSelected}
            isFullDraw={curBracket === 5}
          />
          <PlayerBracket
            selectedPlayer={secondParentSelected}
            isSecondPlayer
            isFullDraw={curBracket === 5}
          />
        </div>
      )}
    </Stack>
  );
};

export default GameBracket;
