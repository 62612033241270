import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useFillBracketsStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.colors.green,
  },
  roundTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: '164px',
    flexGrow: 0,
    flexShrink: 0,
  },
  gameLogo: {
    width: '96px',
    height: '96px',
  },
  gameLogoMobile: {
    width: '60px',
    height: '60px',
  },
  leaderboard: {
    position: 'fixed',
    bottom: '52px',
    right: '48px',
    zIndex: 2,
  },
  closeButton: {
    borderRadius: '50%',
    width: '67px',
    height: '67px',
    padding: 0,
  },
  roundRow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-around',
    margin: '6px 0',
  },
  content: {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: '#3D628E',
  },
  tournamentTitle: {
    textTransform: 'uppercase',
  },
  roundDescription: {
    ...theme.typography.regular1420gray,
  },
  paymentInfo: {
    gap: '8px',
  },
}));

export default useFillBracketsStyles;
