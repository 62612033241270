import { useTournamentViewStyles } from './index.styles';
import GameBracket from './GameBracket';
import { Loader } from 'components/common/index';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AdminGameBracketsContext,
  AdminMatchPlayerType,
  AdminMatchPredictionType,
} from 'models/prediction.model';
import { ITournamentPlayer, MappedType } from 'models/tournamentPlayer.model';
import { MatchType } from 'models/match.model';
import useDraggableScroll from 'hooks/useDragabbleScroll';
import { IGame } from 'models/game.model';
import { Button, Stack, Typography } from '@mui/material';
import { useGameApi, useGameBrackets } from 'hooks/useGame';
import ConfirmationPopup from '../ConfirmationPopup';

export interface BracketGameType {
  match?: MatchType;
  userChoice?: string;
  color?: string;
  previous: Array<string>;
  round: number;
}

export interface BracketGamesType {
  [id: string]: BracketGameType;
}

interface TournamentViewProps {
  players?: Array<ITournamentPlayer>;
  excludedPlayers?: Array<string>;
  game: IGame;
  tournamentPlayers: MappedType<ITournamentPlayer>;
  onSelectPlayer: (gameId: string, player: AdminMatchPredictionType) => void;
  selectedPlayers: AdminMatchPlayerType;
  isAdmin?: boolean;
  savePrediction: () => Promise<void>;
}

const AdminTournamentView = ({
  game,
  players,
  excludedPlayers = [],
  onSelectPlayer,
  tournamentPlayers,
  selectedPlayers,
  savePrediction,
}: TournamentViewProps) => {
  const classes = useTournamentViewStyles();
  const tournamentRef = useRef<HTMLDivElement | null>(null);
  const { onMouseDown } = useDraggableScroll(tournamentRef);
  const { refetchGame, gameLoading } = useGameBrackets(game?.id);
  const { changeRoundStatus } = useGameApi();
  const [fillAllMatchesPopup, setFillAllMatchesPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bracketGames, setBracketGames] = useState<BracketGamesType>({});

  const finalGameId = useMemo(() => {
    const round = game?.rounds?.find((round) => round.matches?.length === 1);
    if (round) {
      return round.matches && round.matches[0]?.id;
    }
  }, [game?.rounds]);

  const firstRound = useMemo(() => {
    const round = game?.rounds?.find((round) => round.title === 'R1');
    return round;
  }, [game?.rounds]);

  const notStartedRound = useMemo(() => {
    const round = game?.rounds?.findIndex((round) => round.status.key === 1);
    return round || 0;
  }, [game?.rounds]);

  const activeRound = useMemo(() => {
    const round = game?.rounds?.find((round) => round.status.key === 2);
    return round;
  }, [game?.rounds]);

  const firstRoundFilled = useMemo(() => {
    return !firstRound?.matches
      ?.filter((match) => !match?.first && !match?.second)
      .find(
        (match) =>
          !(
            selectedPlayers?.[match.id]?.firstId ||
            selectedPlayers?.[match.id]?.secondId
          ),
      );
  }, [firstRound?.matches, selectedPlayers]);

  const activeRoundFilled = useMemo(() => {
    const round = game?.rounds
      ?.find((round) => round.status.key === 2)
      ?.matches?.find(
        (match) =>
          !(
            selectedPlayers?.[match.id]?.firstId &&
            selectedPlayers?.[match.id]?.secondId
          ),
      );
    if (round) {
      return !round;
    } else {
      return true;
    }
  }, [selectedPlayers, game?.rounds]);

  useEffect(() => {
    const result: BracketGamesType = {};
    game?.rounds?.forEach((round, index) => {
      round?.matches?.forEach((match) => {
        result[match.id] = {
          match,
          previous: match.previousMatches?.length
            ? [...match.previousMatches?.map((item) => item.id as string)]
            : [],
          round: index || 0,
        };
      });
    });

    setBracketGames(result);
  }, [game?.rounds]);

  useEffect(() => {
    if (
      activeRound &&
      finalGameId &&
      selectedPlayers?.[finalGameId]?.winnerId
    ) {
      const finalRoundId = game?.rounds?.find(
        (round) => round.matches?.length === 1,
      )?.id;
      if (finalRoundId) {
        savePrediction().then(() => {
          changeRoundStatus(finalRoundId, 3).then(() => refetchGame());
        });
      }
    }
  }, [
    activeRound,
    changeRoundStatus,
    finalGameId,
    game?.rounds,
    refetchGame,
    savePrediction,
    selectedPlayers,
  ]);

  const onChampionSelect = () => {};
  const handleStartRound = async (roundId: string) => {
    setLoading(true);
    await savePrediction();
    if (
      (activeRound?.title === 'R1' && firstRoundFilled) ||
      activeRoundFilled
    ) {
      try {
        const roundsPromises = game?.rounds
          ?.filter((item) => item.status.key === 2)
          ?.map(
            (r) =>
              new Promise((res, rej) =>
                changeRoundStatus(r.id, 3)
                  .then((result) => {
                    if (result?.data?.adminRoundStatusChange?.success) {
                      res(true);
                    }
                  })
                  .catch((err) => {
                    if (err) {
                      rej(false);
                    }
                  }),
              ),
          );
        if (roundsPromises?.length) {
          await Promise.all(roundsPromises).then(() => {
            changeRoundStatus(roundId, 2).then(() => refetchGame());
          });
        } else {
          const firstRound = game?.rounds?.find(
            (round) => round.title === 'R1',
          );
          if (
            firstRound?.id &&
            game?.rounds?.find((round) => round.title === 'R1')?.status?.key ===
              1
          ) {
            await changeRoundStatus(firstRound?.id, 2).then(() =>
              refetchGame(),
            );
          }
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    } else {
      setFillAllMatchesPopup(true);
      setLoading(false);
    }
  };

  return (
    <div
      ref={tournamentRef}
      onMouseDown={onMouseDown}
      className={classes.tournamentGrid}
    >
      <div className={classes.tournamentHeader}>
        {game?.rounds?.map((round, index) => (
          <Stack key={round.id} flexDirection={'column'} gap={1}>
            <div className={classes.roundTitle}>{round.title}</div>
            {((activeRound && notStartedRound === -1) ||
              (notStartedRound === 0 && index === notStartedRound) ||
              (notStartedRound <= 6 && index <= notStartedRound) ||
              activeRound?.title === 'Final') && (
              <Stack
                px={3}
                alignItems="center"
                justifyContent="center"
                height="49px"
              >
                {round.status?.key === 1 ? (
                  <Button
                    variant={round.status?.key === 1 ? 'outlined' : 'text'}
                    fullWidth
                    disabled={round.status?.key !== 1 || loading || gameLoading}
                    onClick={() => handleStartRound(round.id)}
                  >
                    Start Round
                  </Button>
                ) : (
                  <Typography
                    variant={
                      round.status?.key === 3
                        ? 'regularMontserrat1618'
                        : 'semiBoldMontserrat1618'
                    }
                    color={round.status?.key === 3 ? 'GrayText' : 'primary'}
                  >
                    {round.status?.key === 3 ? 'Finished' : 'Active'}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        ))}
        <Stack flexDirection={'column'} gap={1}>
          <div className={classes.roundTitle}>Champion</div>
        </Stack>
      </div>
      <div className={classes.bracketsFillContainer}>
        <AdminGameBracketsContext.Provider
          value={{
            activeRound: activeRound?.title || '',
            games: bracketGames,
            players: tournamentPlayers,
            excludedPlayers: excludedPlayers,
            playersList: players || [],
            predictions: selectedPlayers,
            selectPlayer: onSelectPlayer,
          }}
        >
          {finalGameId ? (
            <GameBracket
              gameId={finalGameId}
              isRootElement={true}
              onPlayerSelect={onChampionSelect}
            />
          ) : (
            <Loader />
          )}
        </AdminGameBracketsContext.Provider>
      </div>
      <ConfirmationPopup
        title={'Please fill all matches in active round'}
        isOpen={fillAllMatchesPopup}
        onClose={() => setFillAllMatchesPopup(false)}
      />
    </div>
  );
};
export default AdminTournamentView;
