import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { PaymentAPI } from 'api';
import { MutationResponseType } from "models/api.model";
import { IPaymentMethod } from "models/payment.model";
import { IResultEdge } from "types/api.types";
import { useMemo } from "react";

interface IUsePaymentMethods {
    payments: Array<IPaymentMethod>
    fetchMorePayments: () => void
    hasNextPage: boolean
    paymentsLoading: boolean
    paymentsError?: ApolloError
}

const mapEdges = ({ node }: IResultEdge<IPaymentMethod>): IPaymentMethod => node

export const usePaymentMethods = (
): IUsePaymentMethods => {
    const { data, fetchMore, loading, error } = useQuery(
        PaymentAPI.paymentMethodList(),
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-first',
            variables: {
                first: 10,
            },
        }
    )

    const payments: Array<IPaymentMethod> = useMemo(() => data?.paymentMethodList.edges.map(mapEdges) || [], [data])
    const pageInfo = data?.paymentMethodList.pageInfo

    const fetchMorePayments = (): void => {
        if (pageInfo.hasNextPage) {
            fetchMore({ variables: { offset: payments.length } })
        }
    }

    return {
        payments,
        fetchMorePayments,
        hasNextPage: pageInfo?.hasNextPage,
        paymentsLoading: loading,
        paymentsError: error,
    }
}


export const usePaymentApi = () => {
    const [setDefaultApi] = useMutation(
        PaymentAPI.setDefaultPaymentMethod(),
        {
            update: (cache) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'paymentMethodList' });
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    )

    const [create, { loading: addPaymentLoading }] = useMutation(
        PaymentAPI.createPaymentMethod(),
        {
            update: (cache) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'paymentMethodList' });
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );

    const addPaymentMethod = async (data: IPaymentMethod) => {
        return create({ variables: { input: data} })
            .then(response => {
                const result = response as MutationResponseType<'createPaymentMethod', 'paymentMethod', IPaymentMethod>;
                return result.data.createPaymentMethod
            });

    };
    const makeDefaultPaymentMethod = async (paymentMethodId: string) => {
        return setDefaultApi({ variables: { input: {paymentMethodId}} })
            .then(response => {
                return response
            }).catch(error => console.log(error));

    };

    return {
        addPaymentMethod,
        addPaymentLoading,
        makeDefaultPaymentMethod
    };
};

