import { IconButton } from '@mui/material';
import AppFormGoogle from 'assets/images/AppFormGoogle';
import React, { useState } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { useAuth } from "hooks/useAuth";
import { useSnackbar } from "notistack";
import { GOOGLE_CLIENT_ID } from "config/env.config";

const clientId = GOOGLE_CLIENT_ID || '';

interface GoogleAuthProps {
    isSignedIn?: boolean,
    onLoginProcessing: (isProcessing: boolean) => void
    onLogin?: () => void
}

const GoogleAuth = (props: GoogleAuthProps) => {
    const { isSignedIn = false, onLogin, onLoginProcessing } = props;
    const { socialAuth } = useAuth();
    const {enqueueSnackbar} = useSnackbar()

    const isGoogleLoginResponse = (response: GoogleLoginResponse | GoogleLoginResponseOffline):
        response is GoogleLoginResponse => {
        return !!response &&
            typeof response === 'object' &&
            !!(response as GoogleLoginResponse).tokenObj;
    };
    const onSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        if(!isGoogleLoginResponse(response)) {
            onLoginProcessing(false)
            return;// Handle it however you want
        }
        socialAuth(response.accessToken, 'google-oauth2')
            .then(response => {
                onLoginProcessing(false)
                if (response?.error) {
                    enqueueSnackbar(response.error, { variant: 'error' })
                } else {
                    if (onLogin) onLogin()
                }
            })
            .catch(error => {
                onLoginProcessing(false)
                console.log(error)
            })

    };

    const onFailure = (error: any) => {
        onLoginProcessing(false)
        console.log( error );
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        autoLoad: false,
        clientId,
        isSignedIn,
        accessType: 'offline'
    });

    const onLoginClick = (): void => {
        onLoginProcessing(true);
        signIn()
    }

    return <IconButton onClick={onLoginClick}>
        <AppFormGoogle/>
    </IconButton>
}

export default GoogleAuth;