import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { PlayersAPI } from 'api';
import { GetPlayersParams, PlayerTypeEdge } from 'models/player.model';
import { MutationResponseType } from "models/api.model";
import { PlayerType } from "models/player.model";
import { IPlayerFormValues } from "components/form/PlayerForm/index.schema";

export const usePlayer = (id: string) => {
    const { data, loading } = useQuery(PlayersAPI.playerDetails(), {
        variables: { id },
        errorPolicy: 'all'
    });

    let player: PlayerType | undefined = undefined;

    if (data?.playerDetails) {
        player = data.playerDetails;
    }

    return { player, isLoading: loading };
}

export const usePlayers = () => {
    /* ADD NEW PLAYER */
    const [create, { loading: addPlayerLoading }] = useMutation(
        PlayersAPI.addPlayer(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'playerSearch' });
                // garbage collector
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );
    const [update, { loading: editPlayerLoading }] = useMutation(
        PlayersAPI.updatePlayer(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'playerSearch' });
                // garbage collector
                console.log('clear cache');
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );

    const addPlayer = async ({ firstName, lastName, gender, image }: IPlayerFormValues) => {
        return create({ variables: { input: {firstName, lastName, gender, image }} })
            .then(response => {
                const result = response as MutationResponseType<'adminCreatePlayer', 'player', PlayerType>;
                return result.data.adminCreatePlayer
            });

    };
    const editPlayer = async ({ playerId, firstName, lastName, gender, image }: IPlayerFormValues) => {
        return update({ variables: { input: {playerId, firstName, lastName, gender, image }} })
            .then(response => {
                const result = response as MutationResponseType<'adminUpdatePlayer', 'player', PlayerType>;
                return result.data.adminUpdatePlayer
            });

    };

    /* GET PLAYERS */
    const [getPlayersData, { loading: getPlayersLoading }] = useLazyQuery(
        PlayersAPI.getPlayersList(),
        {
            notifyOnNetworkStatusChange: true,
        },
    );

    const getPlayers = async ({
                                  offset,
                                  before,
                                  after,
                                  first,
                                  last,
                                  tournamentId,
                              }: GetPlayersParams) => {
        try {
            await getPlayersData({
                variables: { offset, before, after, first, last, tournamentId },
            });
        } catch (err) {}
    };

    return {
        addPlayer,
        addPlayerLoading,
        editPlayer,
        editPlayerLoading,
        getPlayers,
        getPlayersLoading,
    };
};

export const usePlayersData = () => {
    const { data, loading, error } = useQuery(PlayersAPI.getPlayersList());

    let players;
    if (data?.playerSearch) {
        players = data.playerSearch?.edges ?
            data.playerSearch?.edges.map((edge: PlayerTypeEdge) => edge.node) :
            [];// players = data?.tournamentList?.edges;
    }

    return {
        players,
        playersLoading: loading,
        playersError: error,
    };
};
