import { TierEnum } from "models/tournamentPlayer.model";
import { GenderEnum, GenderEnumNumeric } from "models/player.model";

export const formatTier = (tier: TierEnum, toNumber: boolean = false): string | number => {
    switch (tier) {
        case TierEnum.TIER1: return toNumber? 1: "Tier 1";
        case TierEnum.TIER2: return toNumber? 2: "Tier 2";
        case TierEnum.TIER3: return toNumber? 3: "Tier 3";
        case TierEnum.TIER4: return toNumber? 4: "Tier 4";
    }
}
export const formatGender = (gender: GenderEnum | undefined, toNumber: boolean = false): string | GenderEnumNumeric => {
    switch (gender) {
        case GenderEnum.MALE: return toNumber? GenderEnumNumeric.MALE: "Male";
        case GenderEnum.FEMALE: return toNumber? GenderEnumNumeric.FEMALE: "Female";
        case GenderEnum.UNKNOWN: return toNumber? GenderEnumNumeric.UNKNOWN: "Unknown";
    }
    return ''
}