import { gql } from "@apollo/client";

export const CREDIT_CARD_INFO = gql`
    fragment CreditCardFields on CustomPaymentMethodType {
        id
        foreignId
        cardholderName
        isDefault
        type
        brand
        expMonth
        expYear
        funding
        last4
        generatedFrom
        threeDSecureUsage
        addressLine1Check
        addressPostalCodeCheck
        cvcCheck
        wallet
        city
        country
        addressLine1
        addressLine2
        postalCode
        state
    }
`