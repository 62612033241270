import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'

const useNotFoundPageStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexGrow: 1,
    width: '100%',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.light
  },
  heading: {
    fontSize: "200px",
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
}));

export default useNotFoundPageStyles;
