import { FantasyStyles } from './index.styles';

import React, { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router';

import { usePredictionList } from 'hooks/usePrediction';
import tableConfig from 'config/table.config';
import { Table } from 'components/common';
import {
  TableQueryInfoType,
  TableRowType,
  TableRowValueType,
} from 'types/table.types';
import { routes } from 'constants/routes';
import { prepareUrl } from 'utils/route.utils';
import { useTable } from 'hooks/useTable';
import { Stack } from '@mui/material';
import { PredictionType } from 'models/prediction.model';
import { reduceRedundantColumns } from 'utils/filter.utils';
import useIsMobile from 'hooks/useIsMobile';
import { useExtQuery } from 'hooks/useExtQuery';

interface IBracketTab {
  userId?: string;
  search: string;
}

const BracketTab = ({ userId, search }: IBracketTab) => {
  const isMobile = useIsMobile();
  const { rows, setParam, setSearch, dataIsLoading, loadMore, hasNextPage } =
    useExtQuery(tableConfig.myBrackets as TableQueryInfoType, {
      ownerId: userId || '',
    });

  useEffect(() => {
    setParam('ownerId', userId || '');
  }, [userId]);

  useEffect(() => {
    setSearch(search);
  }, [search]);

  const navigate = useNavigate();

  const classes = FantasyStyles();

  const { predictions } = usePredictionList();

  const onCellClicked = (row: TableRowValueType) => {
    navigate(
      prepareUrl(routes.admin.viewPrediction.path, { predictionId: row.id }),
    );
  };
  const rowClassName = (id: string): string => {
    const row = rows.find((row) => row.id === id) as unknown as
      | PredictionType
      | undefined;
    if (row && row.leaderboardPlace && row.leaderboardPlace === 1) {
      return classes.leaderRow;
    }
    return '';
  };

  const tableColumns: Array<TableRowType> = useMemo(() => {
    return reduceRedundantColumns(
      tableConfig.myBrackets.columns,
      isMobile ? ['game', 'newPoints', 'tieBreaker', 'actions'] : ['mobile'],
    );
  }, [isMobile]);

  return (
    <Stack className={classes.container}>
      <Table
        rows={rows}
        hasNextPage={hasNextPage}
        rowClassName={rowClassName}
        isLoading={dataIsLoading}
        columns={tableColumns}
        headerClassNames={classes.tableHeader}
        onCellClicked={onCellClicked}
        onLazyLoading={loadMore}
      />
    </Stack>
  );
};
export default BracketTab;
