
export enum HandnessEnum {
  RIGHT = 'A_0',
  LEFT = 'A_1',
}

export enum GenderEnum {
  UNKNOWN = 'A_0',
  MALE = 'A_1',
  FEMALE = 'A_2',
}

export enum GenderEnumNumeric {
  UNKNOWN = 0,
  MALE = 1,
  FEMALE = 2,
}

export interface PlayerType {
  id: string;
  image?: string;
  imageUrl?: string;
  firstName: string;
  lastName: string;
  country: string;
  abbreviation: string;
  gender: GenderEnum;
  dob: Date;
  proYear: number;
  handness: HandnessEnum;
  weight: number;
  height: number;
  singlesRanking: number;
  highestSinglesRanking: number;
  active?: boolean; // test property
}

export interface PlayerTypeEdge {
  node: PlayerType;
}

export interface EditPlayerParams {
  firstName: string;
  lastName: string;
  tier: number;
}

export interface GetPlayersParams {
  offset?: number;
  before?: string;
  after?: string;
  first?: number;
  last?: number;
  tournamentId?: string[];
}
