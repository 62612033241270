import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material/styles";
import { whiteGray } from "theme";

const useLayoutStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        minHeight: '100vh',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    }
}));

export { useLayoutStyles };
