import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useViewPredictionStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.colors.green,
    padding: '0 16px',
    width: '100%',
    overflow: 'auto',
  },
  viewPredictionMobile: {
    fontSize: '36px',
  },
  content: {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  gameLogo: {
    width: '96px',
    height: '96px',
  },
  headerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '12px',
  },
}));

export { useViewPredictionStyles };
