import { ButtonGamePageStyles } from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { Button, Stack } from '@mui/material';
import { IPick6 } from 'models/prediction.model';
import { UserRoles } from 'models/auth.model';
import { useUser } from 'hooks/useUser';
import { useFantasyListCount } from 'hooks/usePrediction';

interface IPick6DetailsButton {
  data: TableDataType<IPick6>;
  isMobile?: boolean;
}

const Pick6DetailsButton = ({
  data: { row, onCellClicked },
  isMobile,
}: IPick6DetailsButton): JSX.Element => {
  const classes = ButtonGamePageStyles();
  const { user } = useUser();
  const isAdmin = user.role === UserRoles.ADMIN;
  const { pick6CountList, pick6Loading } = useFantasyListCount();
  const pick6Count = pick6CountList?.filter(
    (item) => item?.game?.id === row.game?.id,
  )?.length;

  const onButtonClick = (action?: string) => {
    onCellClicked && onCellClicked(row, action);
  };

  return (
    <Stack flexDirection={isMobile ? 'column' : 'row'} gap={1}>
      {isMobile && (
        <>
          <Stack>Game: {row.game?.title || ''}</Stack>
          <Stack>Points: {row.points || ''}</Stack>
          <Stack>Tiebreaker: {row.tieBreaker || ''}</Stack>
        </>
      )}
      <Button
        className={classes.ButtonGamePageBtn}
        variant="contained"
        color="primary"
        disabled={pick6Loading || (!isAdmin && pick6Count >= 3)}
        onClick={() => onButtonClick()}
      >
        {isAdmin ? 'Details' : 'Add'}
      </Button>
      {!isAdmin && (
        <Button
          className={classes.ButtonGamePageBtn}
          variant="contained"
          color="primary"
          onClick={() => onButtonClick('edit')}
        >
          Edit
        </Button>
      )}
    </Stack>
  );
};

export default Pick6DetailsButton;
