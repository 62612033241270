import { useState } from 'react';

import { Button, Card, CardContent, CardHeader } from '@mui/material';
import isMobileScreen from 'hooks/useIsMobile';
import { useLeaderboard } from 'hooks/useLeaderboard';
import { ILeaderboard } from 'models/leaderboard.model';
import CloseIcon from 'assets/images/CloseIcon';
import LeaderboardIcon from 'assets/images/leaderboard.png';

import cx from 'classnames';
import useStyles from './index.styles';

interface ILeaderboardProps {
  gameId: string;
}

const LeaderboardModal = ({ gameId }: ILeaderboardProps) => {
  const { data } = useLeaderboard({ gameId });
  const classes = useStyles();
  const isMobile = isMobileScreen();

  const [leaderboardIsOpened, setLeaderboardIsOpened] =
    useState<boolean>(false);

  const leaderboardClassNames = cx(classes.leaderboard, {
    [classes.leaderboardMobile]: isMobile,
  });
  const getRankClasses = (row: ILeaderboard): string =>
    cx(classes.leaderBoardRank, {
      [classes.winner]: row.leaderboardPlace === 1,
    });

  return (
    <div className={leaderboardClassNames}>
      {!leaderboardIsOpened && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setLeaderboardIsOpened(true)}
          startIcon={<img src={LeaderboardIcon} alt="Leaderboard" />}
        >
          Leaderboard (Score)
        </Button>
      )}
      {leaderboardIsOpened && (
        <>
          <Card className={classes.card}>
            <CardHeader
              disableTypography={true}
              title={
                <span className={classes.header}>
                  <img
                    className={classes.headerImage}
                    src={LeaderboardIcon}
                    alt="Leaderboard"
                  />
                  Leaderboard (Score)
                </span>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardContent}>
              {!!data.length ? (
                data.map((item, key) => (
                  <div
                    key={`leaderboard-${item.id}`}
                    className={classes.leaderBoardRow}
                  >
                    <div className={getRankClasses(item)}>
                      {item.leaderboardPlace}
                    </div>
                    <div className={classes.leaderBoardName}>
                      {item.owner?.nickname || 'Unknown'}
                    </div>
                    <div className={classes.leaderBoardScore}>
                      {item.newPoints}
                    </div>
                  </div>
                ))
              ) : (
                <div className={classes.leaderBoardRow}>
                  There are no scores yet
                </div>
              )}
            </CardContent>
          </Card>
          <Button
            className={classes.closeButton}
            color="primary"
            variant="contained"
            size="large"
            onClick={() => setLeaderboardIsOpened(false)}
          >
            <CloseIcon />
          </Button>
        </>
      )}
    </div>
  );
};

export default LeaderboardModal;
