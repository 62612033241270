import usePlayerSelectorStyles from './index.styles';

import React, { SyntheticEvent, useContext, useMemo, useState } from 'react';
import { alpha, TextField, Autocomplete, useTheme } from '@mui/material';
import { groupColors } from 'constants/colors';
import cx from 'classnames';
import {
  AdminGameBracketsContext,
  AdminMatchPredictionType,
} from 'models/prediction.model';
import { white } from 'theme';
import { ITournamentPlayer } from 'models/tournamentPlayer.model';

interface PlayerSelectorProps {
  gameId: string;
  isLeftChildren?: boolean;
  onPlayerSelect?: (player: string, isLeft?: boolean) => void;
  selectedPlayer: ITournamentPlayer | null;
  disabled: boolean;
}

interface BracketParentsType {
  left?: string;
  right?: string;
}

const PlayerSelector = (props: PlayerSelectorProps) => {
  const { gameId, onPlayerSelect, isLeftChildren, selectedPlayer, disabled } =
    props;
  const classes = usePlayerSelectorStyles();
  const theme = useTheme();
  const { games, players, selectPlayer, playersList, excludedPlayers } =
    useContext(AdminGameBracketsContext);

  const bracketBackgroundColor: string = useMemo(() => {
    if (selectedPlayer && selectedPlayer.tier) {
      if (theme.colors.hasOwnProperty(selectedPlayer.tier)) {
        return theme.colors[selectedPlayer.tier] || white;
      }
    }
    return white;
  }, [selectedPlayer]);

  const [inputValue, setInputValue] = useState<string>('');
  const [currentSelection, setCurrentSelection] = useState<string>('');

  const bracketStyle = {
    backgroundColor: alpha(bracketBackgroundColor, 0.4),
  };

  const onSelectPlayer = (
    event: SyntheticEvent<Element, Event>,
    player: ITournamentPlayer | null,
  ) => {
    onPlayerSelect && onPlayerSelect(player?.id || '', isLeftChildren);
    setCurrentSelection(player?.id || '');
    const prediction: AdminMatchPredictionType = {
      matchId: gameId,
      [isLeftChildren ? 'firstId' : 'secondId']: player?.id || '',
    };
    selectPlayer(gameId, prediction);
  };

  const topLineClasses = cx(classes.lines, {
    [classes.topLine]: !isLeftChildren,
  });

  const bottomLineClasses = cx(classes.lines, {
    [classes.bottomLine]: isLeftChildren,
  });

  return (
    <div className={classes.root}>
      <div className={classes.bracketFigureLines}>
        <div className={classes.figureLine}>
          <div className={topLineClasses}>&nbsp;</div>
          <div className={bottomLineClasses}>&nbsp;</div>
        </div>
      </div>
      <div className={classes.bracketHolder} style={bracketStyle}>
        <Autocomplete
          size="small"
          classes={{
            input: classes.inputStyle,
            option: classes.inputStyle,
          }}
          value={selectedPlayer || null}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={
                playersList.length ? 'Choose player' : 'Players not found'
              }
            />
          )}
          getOptionLabel={(option) =>
            `${option.player.firstName} ${option.player.lastName}` || ''
          }
          filterOptions={(options: Array<ITournamentPlayer>, params) => {
            return options.filter(
              (option) =>
                (option.id === selectedPlayer?.id ||
                  excludedPlayers.indexOf(option.id) === -1) &&
                `${option.player.firstName} ${option.player.lastName}`
                  .toLocaleLowerCase()
                  .includes(params.inputValue.toLocaleLowerCase()),
            );
          }}
          onChange={onSelectPlayer}
          options={playersList}
          filterSelectedOptions={true}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PlayerSelector;
