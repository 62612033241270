import useStyles from "./index.styles";
import React from "react";
import isMobileScreen from "hooks/useIsMobile";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DialogContent from "@mui/material/DialogContent";
import { CheckCircle } from "@mui/icons-material";
import { Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { routes } from "constants/routes";

interface IPaymentSuccessDialog {
    isOpen: boolean
    title: string
    onClose: () => void
}

const PaymentSuccessDialog = ({isOpen, onClose, title}: IPaymentSuccessDialog) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const isMobile = isMobileScreen();
    const onBracketsClick = () => {
        return navigate(routes.user.myBrackets.path)
    }
    return (
        <Dialog onClose={onClose} open={isOpen}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >

                <DialogTitle className={classes.title} sx={{ p: 1 }}>{title}</DialogTitle>
                <Tooltip title="Close">
                    <IconButton className={classes.closeBtn} onClick={onClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            <DialogContent>
                <Stack className={classes.container} gap={3}>
                    <Stack className={classes.content}>
                        <Stack className={classes.successIcon}><CheckCircle /></Stack>
                        <Typography className={classes.text} variant="regular1420gray">Your payment has been successful. Please see <Link onClick={onBracketsClick}>your brackets list</Link> to view scores when the matches are played.</Typography>
                    </Stack>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(routes.common.gameList.path) }
                    >
                        Games list
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
};

export default PaymentSuccessDialog;
