import { CustomCheckboxStyles } from './index.styles';
import AppArrowIcon from 'assets/images/AppArrowIcon';
import { useState } from 'react';
import { CheckboxVariants } from 'constants/checkboxVariants';

interface CustomCheckboxProps {
  onChange?: () => void;
  checked: boolean;
  variant?: CheckboxVariants;
}

const CustomCheckbox = ({
  onChange,
  checked,
  variant = CheckboxVariants.PrimaryCheckbox,
}: CustomCheckboxProps) => {
  const classes = CustomCheckboxStyles();
  const handleChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className={classes[variant]} onClick={handleChange}>
      {checked && <AppArrowIcon />}
    </div>
  );
};

export default CustomCheckbox;
