import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'space-between',
        minHeight: '60px',
        borderRadius: '16px',
    },
    players: {
        display: 'flex',
        padding: '8px 0',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '4px'
    },
    tier1: {
        '&>div': {
            outline: `2px solid ${theme.colors.A_1}`,
        }
    },
    tier2: {
        '&>div': {
            outline: `2px solid ${theme.colors.A_2}`,
        }
    },
    tier3: {
        '&>div': {
            outline: `2px solid ${theme.colors.A_3}`,
        }
    },
    tier4: {
        '&>div': {
            outline: `2px solid ${theme.colors.A_4}`,
        }
    },
    profile: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    avatar: {
        width: '50px',
    },
    inactive: {
        filter: 'grayscale(70%) opacity(70%)',
    }
}));

export default useStyles;
