import { makeStyles } from "@mui/styles";
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: `min(1920px, 100%)`,
        overflowY: 'auto',
        alignItems: "stretch",
        alignSelf: 'center'
    },
}));

export { useStyles };
