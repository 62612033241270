import usePlayerSelectorStyles from './index.styles';

import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { white } from 'theme';
import { ITournamentPlayer } from 'models/tournamentPlayer.model';
import useIsMobileScreen from 'hooks/useIsMobile';

interface PlayerBracketProps {
  selectedPlayer: ITournamentPlayer | null;
  isSecondPlayer?: boolean;
  isFullDraw?: boolean;
}

const PlayerBracket = (props: PlayerBracketProps) => {
  const { isSecondPlayer, selectedPlayer, isFullDraw } = props;
  const classes = usePlayerSelectorStyles();
  const theme = useTheme();
  const isMobile = useIsMobileScreen();
  const bracketBackgroundColor: string = useMemo(() => {
    if (selectedPlayer && selectedPlayer.tier) {
      if (theme.colors.hasOwnProperty(selectedPlayer.tier)) {
        return theme.colors[selectedPlayer.tier] || white;
      }
    }
    return white;
  }, [selectedPlayer]);

  const bracketStyle = {
    backgroundColor: bracketBackgroundColor,
  };

  return (
    <div
      className={classes.root}
      style={{
        marginTop: isSecondPlayer ? 0 : 40,
        marginBottom: isSecondPlayer && !isFullDraw ? 40 : 0,
        justifyContent:
          isFullDraw && isMobile
            ? 'center'
            : isFullDraw && !isSecondPlayer
            ? 'flex-start'
            : 'flex-end',
      }}
    >
      <div className={classes.bracketHolder} style={bracketStyle}>
        <div className={classes.bracket}>
          {selectedPlayer?.player?.firstName || ''}{' '}
          {selectedPlayer?.player?.lastName || ''}
        </div>
      </div>
    </div>
  );
};

export default PlayerBracket;
