import { useState } from 'react';

import { Button, Card, CardContent, CardHeader } from '@mui/material';

import isMobileScreen from 'hooks/useIsMobile';
import { ILeaderboard } from 'models/leaderboard.model';
import { useFantasyTeamLeaderboard } from 'hooks/useLeaderboard';
import CloseIcon from 'assets/images/CloseIcon';
import LeaderboardIcon from 'assets/images/leaderboard.png';

import cx from 'classnames';
import useStyles from './index.styles';

interface IFantasyLeaderboardProps {
  gameId: string;
}

const FantasyLeaderboard = ({ gameId }: IFantasyLeaderboardProps) => {
  const { data } = useFantasyTeamLeaderboard({ gameId });
  const classes = useStyles();
  const isMobile = isMobileScreen();

  const [leaderboardIsOpened, setLeaderboardIsOpened] =
    useState<boolean>(false);

  const leaderboardClassNames = cx(classes.leaderboard, {
    [classes.leaderboardMobile]: isMobile,
  });

  const getRankClasses = (row: ILeaderboard): string =>
    cx(classes.leaderBoardRank, {
      [classes.winner]: row.leaderboardPlace === 1,
    });

  return (
    <div className={leaderboardClassNames}>
      {!leaderboardIsOpened && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setLeaderboardIsOpened(true)}
          startIcon={<img src={LeaderboardIcon} alt="Leaderboard" />}
        >
          Leaderboard (Score)
        </Button>
      )}
      {leaderboardIsOpened && (
        <>
          <Card className={classes.card}>
            <CardHeader
              disableTypography={true}
              title={
                <span className={classes.header}>
                  <img
                    className={classes.headerImage}
                    src={LeaderboardIcon}
                    alt="Leaderboard"
                  />
                  Leaderboard (Score)
                </span>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardContent}>
              {!!data.length ? (
                data
                  .slice()
                  .sort((a: ILeaderboard, b: ILeaderboard) =>
                    a.points > b.points ? -1 : 1,
                  )
                  .map((item, key) => (
                    <div
                      key={`leaderboard-${item.id}`}
                      className={classes.leaderBoardRow}
                    >
                      <div className={getRankClasses(item)}>{key + 1}</div>
                      <div className={classes.leaderBoardName}>
                        {item.owner.nickname}
                      </div>
                      <div className={classes.leaderBoardScore}>
                        {item.points}
                      </div>
                    </div>
                  ))
              ) : (
                <div className={classes.leaderBoardRow}>
                  There are no scores yet
                </div>
              )}
            </CardContent>
          </Card>
          <Button
            className={classes.closeButton}
            color="primary"
            variant="contained"
            size="large"
            onClick={() => setLeaderboardIsOpened(false)}
          >
            <CloseIcon />
          </Button>
        </>
      )}
    </div>
  );
};

export default FantasyLeaderboard;
