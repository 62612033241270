import { useMutation } from '@apollo/client';

import { AuthAPI } from 'api';
import { TokenStorage } from 'services';
import { AuthResponseType } from 'models/auth.model';
import { useUser } from 'hooks/useUser';

export const useAuth = () => {
    const [login, { loading: LoginIsProcessing }] = useMutation(AuthAPI.singIn());
    const [social, {loading: authIsProcessing}] = useMutation(AuthAPI.socialAuth(), { errorPolicy: 'all' });
    const [resetPasswordApi, {loading: resetPasswordLoading}] = useMutation(AuthAPI.resetPassword());
    const [createNewPasswordApi, {loading: createPasswordLoading}] = useMutation(AuthAPI.resetPasswordConfirm());
    const [checkEmailTokenLinkApi, { loading: checkLinkLoading }] = useMutation(AuthAPI.checkEmailTokenLink());


    const [register, { loading: registerProcessing }] = useMutation(
        AuthAPI.singUp(),
    );
    const [refresh] = useMutation(AuthAPI.refreshToken());

    const { client } = useUser();

    const signIn = (email: string, password: string) => {
        return login({ variables: { email, password } }).then((response) => {
            const { emailSignIn } = response.data;
            if (emailSignIn) {
                const { token, refreshToken } = emailSignIn;
                TokenStorage.storeRefreshToken(refreshToken);
                TokenStorage.storeToken(token);
                return client.resetStore().then(() => {
                    return emailSignIn as AuthResponseType;
                });
            }
        });
    };

    const socialAuth = (accessToken: string, provider: string) => {
        return social({ variables: { accessToken, provider } }).then((response) => {
            const { data, errors } = response;
            if (errors) {
                return {error: errors[0].message}
            }
            if (data) {
                const { socialAuth } = data;
                const { token, refreshToken } = socialAuth;
                TokenStorage.storeToken(token);
                TokenStorage.storeRefreshToken(refreshToken);
                // @ts-ignore
                window.location.reload()
            }

        });
    };

    const signUp = (email: string, password: string, nickname: string) => {
        return register({
            variables: { input: { email, password, nickname } },
        }).then((response) => {
            const { emailSignup } = response.data;
            return emailSignup;
        });
    };

    const logout = () => {
        TokenStorage.clear();
        localStorage.removeItem('role');
        // @ts-ignore
        window.location = window.location.origin
        return client.resetStore();
    };

    const refreshToken = (token: string) => {
        return refresh({ variables: { refreshToken: token } });
    };

    const resetPassword = (email: string) => {
        return resetPasswordApi({ variables: { input: { email } } });
    }

    const createNewPassword = (password: string, uid?: string, token?: string) => {
        return createNewPasswordApi({ variables: { input: { newPassword1: password, newPassword2: password, uid, token } } });
    }

    const verifyResetPasswordLink = (uid: string, token: string) => {
        return checkEmailTokenLinkApi({ variables: { input: { uid, token } } });
    }


    return {
        logout,
        login: signIn,
        socialAuth,
        register: signUp,
        resetPassword,
        createNewPassword,
        verifyResetPasswordLink,
        refreshToken,
        loading: resetPasswordLoading || createPasswordLoading || checkLinkLoading,
        LoginIsProcessing,
        authIsProcessing,
        registerProcessing,
    };
};
