import {
  ContactUs,
  Fantasy,
  FillBrackets,
  GameList,
  MyBrackets,
  NotFound,
  PaymentSystem,
  PickTeam,
  ViewPrediction,
} from 'components/pages';
import { Dashboard } from 'components/pages/Admin';
import Players from 'components/pages/Admin/Players';
import LoadingPage from 'components/pages/LoadingPage';
import Tournaments from 'components/pages/Admin/Tournaments';
import FillWinnerBracket from 'components/pages/Admin/FillWinnerBracket';
import AdminTournamentDetails from 'components/pages/Admin/TournamentDetails';
import GamePlayers from 'components/pages/Admin/GamePlayers';
import Users from 'components/pages/Admin/Users';
import Home from 'components/pages/Home';
import ViewFantasy from 'components/pages/ViewFantasy';
import Predictions from 'components/pages/Admin/Predictions';
import Scoreboard from 'components/pages/Scoreboard';
import Leaderboard from 'components/pages/Leaderboard';

export interface RouteType {
  path: string;
  element: JSX.Element;
  state?: {
    [key: string]: string | number;
  };
  index?: boolean;
}

export interface SpecialRouteType {
  path?: string;
  element: JSX.Element;
  index?: boolean;
  state?: {
    [key: string]: string | number;
  };
}

export type RoutesType = {
  common: {
    home: RouteType;
    login: RouteType;
    signUp: RouteType;
    forgotPassword: RouteType;
    resetPassword: RouteType;
    gameList: RouteType;
    pickTeam: RouteType;
    fantasy: RouteType;
    fillBrackets: RouteType;
    scoreboardChart: RouteType;
    contactUs: RouteType;
    leaderBoard: RouteType;
    viewPrediction: RouteType;
    viewFantasy: RouteType;
  };
  admin: {
    home: RouteType;
    users: RouteType;
    players: RouteType;
    predictions: RouteType;
    tournaments: RouteType;
    tournamentDetails: RouteType;
    gamePlayers: RouteType;
    fillBrackets: RouteType;
    viewPrediction: RouteType;
    viewFantasy: RouteType;
  };
  user: {
    myBrackets: RouteType;
    viewPrediction: RouteType;
    viewFantasy: RouteType;
    paymentSystem: RouteType;
  };
  special: {
    notFound: SpecialRouteType;
    loading: SpecialRouteType;
    noPermission: SpecialRouteType;
  };
};

const routes: RoutesType = {
  // ADMIN_DASHBOARD: {
  //   path: "/admin_dashboard"
  // },
  common: {
    home: {
      path: '/',
      element: <Home />,
      index: true,
    },
    leaderBoard: {
      path: '/leaderboard',
      element: <Leaderboard />,
    },
    scoreboardChart: {
      path: '/scoreboard',
      element: <Scoreboard />,
    },
    login: {
      path: '/login',
      element: <Home />,
    },
    signUp: {
      path: '/signup',
      element: <Home />,
    },
    forgotPassword: {
      path: '/forgot-password',
      element: <Home />,
    },
    resetPassword: {
      path: '/reset-password',
      element: <Home />,
    },
    gameList: {
      path: '/game_list',
      element: <GameList />,
    },
    viewPrediction: {
      path: '/view_prediction/:predictionId',
      element: <ViewPrediction />,
    },
    viewFantasy: {
      path: '/view_fantasy/:fantasyId',
      element: <ViewFantasy />,
    },
    pickTeam: {
      path: '/pick_team',
      element: <PickTeam />,
    },
    fantasy: {
      path: '/fantasy/:gameId/:fantasyId',
      element: <Fantasy />,
    },
    fillBrackets: {
      path: '/fill_brackets/:gameId/:predictionId',
      element: <FillBrackets />,
    },
    contactUs: {
      path: '/contact_us',
      element: <ContactUs />,
    },
  },
  user: {
    myBrackets: {
      path: '/my_brackets',
      element: <MyBrackets />,
    },
    viewPrediction: {
      path: '/view_prediction/:predictionId',
      element: <ViewPrediction />,
    },
    viewFantasy: {
      path: '/view_fantasy/:fantasyId',
      element: <ViewFantasy />,
    },
    paymentSystem: {
      path: '/payment_system',
      element: <PaymentSystem />,
    },
  },
  admin: {
    home: {
      path: '/admin',
      element: <Dashboard />,
      index: true,
    },
    users: {
      path: '/admin/users',
      element: <Users />,
    },
    predictions: {
      path: '/admin/predictions',
      element: <Predictions />,
    },
    players: {
      path: '/admin/players',
      element: <Players />,
    },
    tournaments: {
      path: '/admin/tournaments',
      element: <Tournaments />,
    },
    tournamentDetails: {
      path: '/admin/tournaments/:tournamentId',
      element: <AdminTournamentDetails />,
    },
    gamePlayers: {
      path: '/admin/tournaments/games/:gameId',
      element: <GamePlayers />,
    },
    fillBrackets: {
      path: '/admin/tournaments/:gameId/fill_brackets',
      element: <FillWinnerBracket />,
    },
    viewPrediction: {
      path: '/admin/view_prediction/:predictionId',
      element: <ViewPrediction />,
    },
    viewFantasy: {
      path: '/admin/view_fantasy/:fantasyId',
      element: <ViewFantasy />,
    },
  },
  special: {
    notFound: {
      path: '*',
      element: <NotFound />,
      index: true,
    },
    loading: {
      path: '*',
      element: <LoadingPage />,
      index: true,
    },
    noPermission: {
      path: '*',
      element: <NotFound />,
      index: true,
    },
  },
};

export { routes };
