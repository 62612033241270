import React from "react";

const AppFormGoogle = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5.50006C13.5273 5.49913 15.0056 6.03883 16.173 7.02356L19.443 3.90956C18.1877 2.75211 16.6799 1.90316 15.0393 1.43009C13.3987 0.957025 11.6704 0.872862 9.99161 1.18428C8.31279 1.4957 6.72967 2.19413 5.36787 3.22415C4.00608 4.25416 2.90311 5.5874 2.14648 7.11806L5.83298 9.95956C6.26292 8.6631 7.08986 7.53478 8.19669 6.73441C9.30351 5.93404 10.6341 5.5022 12 5.50006Z" fill="#F34747"/>
        <path d="M5.5 12.0002C5.50092 11.3065 5.61335 10.6176 5.833 9.95966L2.1465 7.11816C1.39242 8.63511 1 10.3061 1 12.0002C1 13.6942 1.39242 15.3652 2.1465 16.8822L5.833 14.0407C5.61335 13.3827 5.50092 12.6938 5.5 12.0002Z" fill="#FFC532"/>
        <path d="M22.55 10H12.05V14.5H18C17.6461 15.7724 16.8351 16.8694 15.7225 17.581L19.3805 20.401C21.718 18.303 23.0915 14.8915 22.55 10Z" fill="#5085ED"/>
        <path d="M15.721 17.581C14.5905 18.2281 13.3017 18.5464 12 18.5C10.6341 18.4979 9.30351 18.066 8.19669 17.2657C7.08986 16.4653 6.26292 15.337 5.83298 14.0405L2.14648 16.882C3.05705 18.7176 4.46171 20.2627 6.20246 21.3435C7.9432 22.4243 9.95099 22.998 12 23C14.6962 23.0733 17.3243 22.1473 19.379 20.4L15.721 17.581Z" fill="#57A75C"/>
    </svg>


}
export default AppFormGoogle;
