import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import validationSchema, {
    initialValues,
} from './index.schema';
import useStyles from 'components/form/PlayerForm/index.styles';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { DatePicker, TextField } from 'components/form/fields';

import SelectField from 'components/form/fields/SelectField';
import Loader from 'components/common/Loader';
import { useSnackbar } from 'notistack';
import { ITournamentFormValues } from "models/tournament.model";
import { useTournamentApi } from "hooks/useTournament";
import { useChampionships } from "hooks/useChampionship";
import { dateToDateTime } from "utils/date.utils";

interface TournamentFormProps {
    onClose: () => void;
}

const TournamentForm: React.FC<TournamentFormProps> = ({
                                                           onClose,
                                                       }: TournamentFormProps): React.ReactElement => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { addTournament, addTournamentLoading } = useTournamentApi();
    const { championships } = useChampionships();

    const championshipOptions = useMemo(() => championships.map(championship => ({
        key: championship.id,
        label: championship.title,
        value: championship.id
    })), [ championships ]);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values: ITournamentFormValues) => {
            const { start, end, ...rest } = values;
            addTournament({ start: dateToDateTime(start), end: dateToDateTime(end), ...rest })
                .then(response => {
                    if (response.success) {
                        enqueueSnackbar('Tournament created');
                        onClose();
                    } else {
                        if (response.errors?.title?.[0]) {
                            formik.setFieldError('title', response.errors.title[0]);
                        }
                        if (response.errors?.start?.[0]) {
                            formik.setFieldError('start', response.errors.start[0]);
                        }
                        if (response.errors?.end?.[0]) {
                            formik.setFieldError('end', response.errors.end[0]);
                        }
                        if (response.errors?.championshipId?.[0]) {
                            formik.setFieldError('championshipId', response.errors.championshipId[0]);
                        }
                        enqueueSnackbar("Tournament not saved", { variant: 'error' });
                    }

                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                });
        },
    });

    const { resetForm } = formik;

    const onCloseClick = (): void => {
        resetForm();
        onClose();
    }

    return (
        <form
            className={ classes.form }
            onSubmit={ formik.handleSubmit }
        >
            <TextField
                id="title-input"
                name="title"
                label="Title"
                placeholder="Enter title"
                value={ formik.values.title }
                onChange={ formik.handleChange }
                error={ formik.touched.title && Boolean(formik.errors.title) }
                helperText={ formik.touched.title && formik.errors.title }
            />
            <DatePicker
                id="start-input"
                name="start"
                label="Date start"
                placeholder="Enter date start"
                value={ formik.values.start }
                onChange={ formik.handleChange }
                error={ formik.touched.start && Boolean(formik.errors.start) }
                helperText={ formik.touched.start && formik.errors.start }
            />
            <DatePicker
                id="end-input"
                name="end"
                label="Date end"
                placeholder="Enter date end"
                value={ formik.values.end }
                onChange={ formik.handleChange }
                error={ formik.touched.end && Boolean(formik.errors.end) }
                helperText={ formik.touched.end && formik.errors.end }
            />
            <SelectField
                id="championship-id-input"
                label="Championship"
                name="championshipId"
                value={ formik.values.championshipId }
                onChange={ formik.handleChange }
                error={ formik.touched.championshipId && Boolean(formik.errors.championshipId) }
                helperText={ formik.touched.championshipId && formik.errors.championshipId }
                options={ championshipOptions }
            />
            <Stack
                direction="row"
                spacing={ { md: 1 } }
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={ onCloseClick }
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit">
                    Apply
                </Button>
            </Stack>
            { addTournamentLoading && <Loader/> }
        </form>
    );
};
export default TournamentForm;
