import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material/styles";

const useLoginFormStyles = makeStyles((theme: Theme) => ({
    FormLineTextStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },
    FormLineStyle: {
        background: '#000000',
        opacity: 0.1,
        width: '90px',
    },
    FormTitleAfter: {
        color: '#91969E',
        width: '132px',
        textAlign: 'center',
    },
    PagePopupSocNet: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 0',
        columnGap: 24
    },
    ButtonForgotPasswordStyle: {
        marginBottom: '16px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        minWidth: '300px',
    },
    formInput: {
    },
    description: {
        ...theme.typography.regular1216gray
    }
}));

export default useLoginFormStyles;
