import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.whiteGray,
    padding: '45px 24px 0 24px',
    flexGrow: 1,
    maxHeight: 'calc(100vh - 70px)',
    width: `min(${theme.breakpoints.values.lg}px, 100%)`,
    overflow: 'hidden',
  },
  holder: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerMobile: {
    padding: '60px 0',
  },
  tournamentOuter: {
    backgroundColor: '#105ea2',
    padding: '72px 0 88px 0',
    display: 'flex',
    flexGrow: 1,
  },
  pageHead: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabHeaderLabel: {
    '& button': {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      color: theme.colors.grayBlack,
      textTransform: 'capitalize',
      letterSpacing: 'normal',
    },
  },
  tournamentTitleText: {
    lineHeight: 1.1,
    color: theme.colors.grayBlack,
  },
  tournamentTitleTextMobile: {
    fontSize: '36px',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    overflow: 'hidden',
    gap: '24px',
  },
}));

export default useStyles;
