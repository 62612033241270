const BracketSlotIconRight = () => {
  return (
    <svg
      width="43"
      height="25"
      viewBox="0 0 43 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1H33.561V12.5M0 24H33.561V12.5M33.561 12.5H43"
        stroke="#979797"
        strokeWidth="0.5"
      />
    </svg>
  );
};
export default BracketSlotIconRight;
