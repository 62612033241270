import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexDirection: 'column',
  },
  title: {
    padding: '32px 32px 16px 32px',
  },
  closeBtn: {
    marginRight: '16px',
  },
  tieSubmit: {
    marginBottom: '32px',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    rowGap: '8px',
    columnGap: '8px',
  },
  tieButton: {
    padding: 0,
    minWidth: '48px',
    width: '48px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.colors.basicLine,
    ...theme.typography.regular1618gray,
    color: theme.colors.basicLine,
    '&:hover': {
      backgroundColor: '#48C2C5',
      borderColor: '#48C2C5',
    },
  },
  tieBtnActive: {
    backgroundColor: '#48C2C5',
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: '#48C2C5',
    },
  },
  scoreInput: {
    width: 80,
    paddingTop: 4,
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
      margin: 0,
    },
    '& input': {
      textAlign: 'center',
    },
  },
}));

export default useStyles;
