import { Outlet } from 'react-router-dom';
import { Stack, Toolbar } from '@mui/material';

import PageFooter from './PageFooter/PageFooter';
import PageHeader from 'components/layout/PageHeader';

import useDefaultLayoutStyles from './index.styles';

const Default = () => {
  const classes = useDefaultLayoutStyles();
  return (
    <div className={classes.container}>
      <PageHeader />
      <Toolbar />
      <Stack className={classes.content}>
        <Outlet />
      </Stack>
      <PageFooter />
    </div>
  );
};

export default Default;
