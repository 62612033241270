import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.colors.darkBlue,
  },
  divider: {
    height: 2,
    color: theme.colors.basicLine,
  },
  title: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
  },
  subtitle: {
    fontFamily: fontFamilies.montserratRegular.fontFamily,
    fontSize: 16,
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: 'white',
  },
  subtitleSuffix: {
    fontFamily: fontFamilies.montserratRegular.fontFamily,
    fontSize: 10,
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: 'white',
  },
}));

export default useStyles;
