import useStyles from "./index.styles";

import React from 'react';
import { TableDataType, TableRowValueType } from 'types/table.types';

interface GameLogoParams {
    data: TableDataType<TableRowValueType>
}

const GameLogo = ({ data: { row, onCellClicked } }: GameLogoParams): JSX.Element => {
    const classes = useStyles();
    return <div className={classes.gameLogo}>
        {row.logo && <img src={row.logo.toString()} alt=""/>}
    </div>
}

export default GameLogo;