import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  facebookBtnStyle: {
    padding: '8px',
    backgroundColor: 'transparent',
    display: 'flex',
    cursor: 'pointer',
  },
  FormTitlePopUp: {
    marginBottom: '16px',
  },
  AuthDialog: {
    backgroundColor: 'transparent',
  },
  invisible: {
    opacity: 0,
  },
  AuthDialogTitle: {
    backgroundColor: '#fff',
  },
  AuthDialogContent: {
    backgroundColor: '#fff',
  },
  PopupFooter: {
    color: '#111',
    background: '#fff',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '20px'
  },

  ButtonLogInStyle: {
    color: '#6939A8',
    marginLeft: '5px',
  },
  InputPassStyle: {
    margin: '20px 0 6px 0',
  },
  FormInputStyle: {
    margin: '0 0 8px 0',
  },
  FormFieldStyle: {
    margin: '20px 0 6px 0',
  },
  FormLabelColor: {
    color: '#555961',
  },
  FormLineTextStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '20px',
  },
  FormTitleAfter: {
    color: '#91969E',
    width: '132px',
    textAlign: 'center',
  },
  FormLineStyle: {
    background: '#000000',
    opacity: 0.1,
    width: '90px',
    marginTop: '7px',
  },
  PagePopupSocNet: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '120px',
    margin: '21px auto 0 auto',
  },
  ButtonCloseForm: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#090A0B',
    padding: 0,
    margin: '38px 38px 0 0',
  },
}));

export default useStyles;
