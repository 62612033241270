import { gql } from '@apollo/client';
import { USER_AUTH_INFO, USER_INFO } from 'api/fragments/user.fragment'
import { PAGE_INFO } from "api/fragments";

class UserAPI {
    me() {
        return gql`
            query me {
                me {
                    ...UserFields
                }
            }
            ${USER_AUTH_INFO}`
    }
    userList() {
        return gql`
            query userList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
                $search: String
                $id: String
                $ordering: String
            ) {
                userList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                    search: $search
                    id: $id            
                    ordering: $ordering
                ) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...UserFields
                        }
                    }
                }
            }
        ${PAGE_INFO}, ${USER_INFO}`;
    }

}

export default new UserAPI();