import React from 'react';
import { TableDataType } from 'types/table.types';
import { PlayerType } from "models/player.model";
import PlayersAvatar from "assets/images/playersAvatar.png";
import { Typography } from "@mui/material";
import { PlayerAvatarStyles } from './index.styles';
import { Person } from "@mui/icons-material";

interface PlayerAvatarParams {
    data: TableDataType<PlayerType>
}

const PlayerAvatar = ({ data: { row } }: PlayerAvatarParams): JSX.Element => {
    const classes = PlayerAvatarStyles();
    const player = row as PlayerType;

    return <div className={classes.playerTableRow}>
        <div className={classes.playerTableAvatarBlock}>
            {player.imageUrl ?
            <img className={classes.playerTableAvatar} src={player.imageUrl || PlayersAvatar} alt=""/>
                : <Person />}
        </div>
        <Typography className={classes.playerTableText}
                    variant="body1">{player.firstName || ''} {player.lastName || ''}</Typography>
    </div>
}

export default PlayerAvatar;
