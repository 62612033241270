import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    '& > .MuiFormControl-root': {
      marginBottom: '16px',
    }
  }
}));

export default useStyles;
