import { gql } from '@apollo/client';

class LeaderboardAPI {
  bracketPredictionLeaderboard() {
    return gql`
      query bracketPredictionLeaderboard(
        $gameId: ID!
        $search: String
        $ordering: String
        $first: Int
        $offset: Int
        $after: String
      ) {
        bracketPredictionLeaderboard(
          gameId: $gameId
          search: $search
          ordering: $ordering
          first: $first
          offset: $offset
          after: $after
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              owner {
                id
                nickname
              }
              leaderboardPlace
              newPoints
              isFriend
              isCurrent
            }
            cursor
          }
          total
          count
          pages
        }
      }
    `;
  }

  bracketPredictionLeaderboardGeneral() {
    return gql`
      query bracketPredictionLeaderboard(
        $gameId: ID!
        $search: String
        $ordering: String
        $first: Int
        $offset: Int
        $after: String
      ) {
        bracketPredictionLeaderboard(
          gameId: $gameId
          search: $search
          ordering: $ordering
          first: $first
          offset: $offset
          after: $after
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              owner {
                id
                nickname
              }
              leaderboardPlace
              newPoints
            }
            cursor
          }
          total
          count
          pages
        }
      }
    `;
  }

  randomBracketPredictionLeaderboard() {
    return gql`
      query randomBracketPredictionLeaderboard($limit: Int) {
        randomBracketPredictionLeaderboard(limit: $limit) {
          game {
            id
            title
          }
          leaderboard {
            id
            owner {
              id
              nickname
            }
            leaderboardPlace
            newPoints
          }
        }
      }
    `;
  }

  fantasyTeamLeaderboard() {
    return gql`
      query fantasyTeamLeaderboard(
        $gameId: ID!
        $search: String
        $ordering: String
        $first: Int
        $offset: Int
        $after: String
      ) {
        fantasyTeamLeaderboard(
          gameId: $gameId
          search: $search
          ordering: $ordering
          first: $first
          offset: $offset
          after: $after
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              owner {
                id
                nickname
              }
              leaderboardPlace
              points
              isFriend
              isCurrent
            }
            cursor
          }
          total
          count
          pages
        }
      }
    `;
  }

  fantasyTeamLeaderboardGeneral() {
    return gql`
      query fantasyTeamLeaderboard(
        $gameId: ID!
        $search: String
        $ordering: String
        $first: Int
        $offset: Int
        $after: String
      ) {
        fantasyTeamLeaderboard(
          gameId: $gameId
          search: $search
          ordering: $ordering
          first: $first
          offset: $offset
          after: $after
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              owner {
                id
                nickname
              }
              leaderboardPlace
              points
            }
            cursor
          }
          total
          count
          pages
        }
      }
    `;
  }

  randomFantasyTeamLeaderboard() {
    return gql`
      query randomFantasyTeamLeaderboard($limit: Int) {
        randomFantasyTeamLeaderboard(limit: $limit) {
          game {
            id
            title
          }
          leaderboard {
            id
            owner {
              id
              nickname
            }
            leaderboardPlace
            points
          }
        }
      }
    `;
  }
  addFriendMutation() {
    return gql`
      mutation addFriendToggle($input: AddFriendToggleInput!) {
        addFriendToggle(input: $input) {
          errors
          msg
        }
      }
    `;
  }

  fantasyTeamLeaderboardTotal() {
    return gql`
      query fantasyTeamLeaderboardTotal($gameId: ID!) {
        fantasyTeamLeaderboardTotal(gameId: $gameId) {
          total
        }
      }
    `;
  }

  bracketPredictionLeaderboardTotal() {
    return gql`
      query bracketPredictionLeaderboardTotal($gameId: ID!) {
        bracketPredictionLeaderboardTotal(gameId: $gameId) {
          total
        }
      }
    `;
  }
}

export default new LeaderboardAPI();
