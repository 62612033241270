import React, { useEffect, useRef } from 'react';

import { debounce } from 'utils/heplers.utils';

interface InfiniteScrollProps {
  onLoadMore: () => void;
  isLoading: boolean;
  totalElements: number;
  readyElements: number;
  children: React.ReactNode;
}

export const InfiniteScrollContainer: React.FC<InfiniteScrollProps> = ({
  onLoadMore,
  isLoading,
  totalElements,
  readyElements,
  children,
}) => {
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = debounce(() => {
    if (targetDivRef.current) {
      const targetDiv = targetDivRef.current;
      const divOffsetTop = targetDiv.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;

      if (divOffsetTop - scrollTop < windowHeight && !isLoading) {
        onLoadMore();
      }
    }
  }, 300);

  useEffect(() => {
    if (totalElements > readyElements) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, readyElements, totalElements]);

  useEffect(() => {
    if (
      targetDivRef.current &&
      targetDivRef.current?.offsetTop < window?.innerHeight &&
      !isLoading
    ) {
      onLoadMore();
    }
  }, [isLoading, onLoadMore, readyElements]);

  return (
    <div>
      {children}
      {isLoading ? null : <div ref={targetDivRef} />}
    </div>
  );
};
