import useAdminLayoutStyles from "./index.styles";
import React from "react";

import { Outlet } from "react-router-dom";
import PageHeader from "./PageHeader";
import { Toolbar } from '@mui/material'

const AdminLayout = () => {
    const classes = useAdminLayoutStyles();
    return <div className={classes.container}>
        <PageHeader />
        <Toolbar />
        <div className={classes.content}>
            <Outlet />
        </div>

    </div>

}

export default AdminLayout;
