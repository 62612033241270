import { useImperativeHandle, forwardRef, useState } from 'react';
import { InputBaseComponentProps } from '@mui/material';

const StripeInput = forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props;
    const [mountNode, setMountNode] = useState<any | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus(),
      }),
      [mountNode],
    );

    return (
      <Component
        onReady={setMountNode}
        options={{
          ...options,
        }}
        {...other}
      />
    );
  },
);

export default StripeInput;
