import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

export const paymentFormStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: 410,
    backgroundColor: theme.colors.white,
    padding: 24,
    borderRadius: 16,
  },
  formSize: {
    maxWidth: 408,
    minWidth: 340,
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    '& button': {
      cursor: 'pointer',
    },
  },
  formTitle: {
    fontSize: 22,
    fontFamily: fontFamilies.montserratRegular.fontFamily,
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 48,
    background: theme.colors.primary,
    color: theme.colors.white,
    '&:hover': {
      background: theme.colors.primary,
      opacity: 0.9,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.colors.basicLine,
    },
  },
}));
