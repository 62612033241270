import { useNavigate } from 'react-router';
import { Typography, Button, Stack, Box } from '@mui/material';

import { routes } from 'constants/routes';
import isMobileScreen from 'hooks/useIsMobile';

import cx from 'classnames';
import usePagePageHowToWorkStyles from './index.styles';
import { appTheme } from 'theme';

const TIERS = [
  {
    id: 1,
    title: 'tier 1',
    subTitle: 'Top8 Seeds',
    tierColor: appTheme.colors.A_1,
  },
  {
    id: 2,
    title: 'tier 2',
    subTitle: '9-16 Seeds',
    tierColor: appTheme.colors.A_2,
  },
  {
    id: 3,
    title: 'tier 3',
    subTitle: '17-32 Seeds',
    tierColor: appTheme.colors.A_3,
  },
  {
    id: 4,
    title: 'tier 4',
    subTitle: 'Unseeded Players',
    tierColor: appTheme.colors.A_4,
  },
];

const PageHowToWork = () => {
  const classes = usePagePageHowToWorkStyles();
  const isMobile = isMobileScreen();
  const navigate = useNavigate();
  const pageHowToWorkClassNames = cx(classes.pageHowToWork, {
    [classes.pageHowToWorkMobile]: isMobile,
  });
  const pageHowToWorkItemFirstClassNames = cx(classes.pageHowToWorkItemFirst, {
    [classes.pageHowToWorkItemFirstMobile]: isMobile,
  });
  const pageHowToWorkItemSecondClassNames = cx(
    classes.pageHowToWorkItemSecond,
    {
      [classes.pageHowToWorkItemCardMobile]: isMobile,
    },
  );
  const scoreboardButtonClassNames = cx(classes.scoreboardButton, {
    [classes.scoreboardButtonMobile]: isMobile,
  });
  return (
    <div className={pageHowToWorkClassNames}>
      <Stack
        className={classes.pageHowToWorkList}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Stack className={pageHowToWorkItemFirstClassNames} p={2}>
          <Typography className={classes.pageHowToWorkItemH4} variant="h4">
            How to play?
          </Typography>
          <ul className={classes.pageHowToWorkItemUl}>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                Log in with Email, Password & a unique Raqiteer Name
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                Once the Pre-Tournament Draw Ceremony. is Complete
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                PICK 6 PROS (2 Player Max per Tier)
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                FILL IN A DRAW
              </Typography>
            </li>
          </ul>
          <Typography className={classes.pageHowToWorkItemH4} variant="h4">
            how to win?
          </Typography>
          <ul className={classes.pageHowToWorkItemUl}>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                Accumulate points by choosing Players who win the most matches
                within that live' tournament.
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                A Player's rank (seed) within a tournament determines what Tier
                they'll be in, The lesser the fier the greater the rewarded
                points per win.
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                Checkout Tier Scoring Chart for a points per round breakdown
              </Typography>
            </li>
          </ul>
          <Typography className={classes.pageHowToWorkItemH4} variant="h4">
            prizes
          </Typography>
          <ul className={classes.pageHowToWorkItemUl}>
            <li>
              <Typography
                className={classes.pageHowToWorkItemBody}
                variant="regular1624"
              >
                TOP 5 Raqiteers on each Leaderboard [PICK 6] & [FULL DRAW] win
                tournament specific prizes!!
                <br />
                <br /> *based on Raqit entries and sponsorships.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Stack
          className={pageHowToWorkItemSecondClassNames}
          justifyContent="center"
          alignItems="center"
          px={10}
        >
          <Button
            className={scoreboardButtonClassNames}
            variant="contained"
            color="primary"
            onClick={(): void => navigate(routes.common.scoreboardChart.path)}
          >
            Tier Scoring Chart
          </Button>
          {TIERS.map((tier, index) => (
            <Stack
              key={tier.id}
              py={1}
              px={2}
              bgcolor="white"
              width="100%"
              textAlign="center"
              gap={1}
              borderBottom={
                index === TIERS.length - 1 ? 'none' : '2px solid #E3E3E3'
              }
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Box
                  className={classes.tierStrips}
                  borderTop={`8px solid ${tier.tierColor}`}
                  borderBottom={`8px solid ${tier.tierColor}`}
                />
                <Typography className={classes.tierTitle} variant="regular1624">
                  {tier.title}
                </Typography>
                <Box
                  className={classes.tierStrips}
                  borderTop={`8px solid ${tier.tierColor}`}
                  borderBottom={`8px solid ${tier.tierColor}`}
                />
              </Stack>
              <Typography color="#3B50A0" variant="regularMontserrat1618">
                {tier.subTitle}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </div>
  );
};
export default PageHowToWork;
