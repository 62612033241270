import { red, green, orange, yellow, blue, purple, lightBrown, lightBlue, redBrown, darkGreen, brown, lightGreen, lightGray } from 'theme';

const groupColors = [
    red,
    green,
    orange,
    yellow,
    blue,
    purple,
    lightBrown,
    lightBlue,
    redBrown,
    darkGreen,
    brown,
    yellow,
    lightBrown,
    lightBlue,
    lightGreen,
    lightGray
]

export { groupColors };