import usePageHeaderStyles from './index.styles';

import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';

import { useUser } from 'hooks/useUser';
import AppLogo from 'assets/images/AppLogo';
import { routes } from 'constants/routes';
import LoginButton from 'components/common/LoginButton';
import { RenderType } from 'types/common.type';
import isMobileScreen from 'hooks/useIsMobile';
import { Close, Menu } from '@mui/icons-material';
import LeaderBoardIcon from 'assets/images/LeaderBoardIcon';

const GamePageHeader = () => {
  const navigate = useNavigate();
  const isMobile = isMobileScreen();

  const { user } = useUser();
  const classes = usePageHeaderStyles();
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLDivElement>(null);

  const toggleMenu = (): void => {
    setAnchorMenu((currentState) => (currentState ? null : menuRef.current));
  };

  const onMenuClicked = (url?: string): void => {
    setAnchorMenu(null);
    if (url) navigate(url);
  };
  const menuContent: RenderType = useMemo(() => {
    return (
      <div className={classes.buttonsGroup}>
        <Button
          className={classes.buttonTitle}
          type="button"
          variant="text"
          color="secondary"
          onClick={() => onMenuClicked(routes.common.leaderBoard.path)}
        >
          <Box pr={1} display="flex" alignItems="center">
            <LeaderBoardIcon />
          </Box>
          leaderboard
        </Button>
        <Button
          className={classes.buttonTitle}
          type="button"
          color="primary"
          variant="text"
          onClick={() => onMenuClicked(routes.common.gameList.path)}
        >
          <Typography color="primary" variant="regularBebasNeue">
            play
          </Typography>
        </Button>
        {user && (
          <>
            <Button
              className={classes.buttonTitle}
              type="button"
              variant="text"
              color="secondary"
              onClick={() => onMenuClicked(routes.user.myBrackets.path)}
            >
              My Picks
            </Button>
          </>
        )}
        <LoginButton onMenuClicked={onMenuClicked} />
      </div>
    );
  }, [isMobile, user]);

  return (
    <AppBar color="inherit">
      <Toolbar ref={menuRef} className={classes.pageHeader}>
        <Link onClick={() => navigate(routes.common.home.path)}>
          <AppLogo />
        </Link>
        {isMobile ? (
          <IconButton
            className={classes.menuIconButton}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>): void =>
              toggleMenu()
            }
            color="inherit"
          >
            {anchorMenu ? <Close /> : <Menu />}
          </IconButton>
        ) : (
          menuContent
        )}
      </Toolbar>
      {isMobile && (
        <Drawer
          anchor="top"
          open={!!anchorMenu}
          onClose={toggleMenu}
          className={classes.mobileMenu}
        >
          {menuContent}
        </Drawer>
      )}
    </AppBar>
  );
};
export default GamePageHeader;
