import React from 'react';
import Routes from './Routes';
import AuthPopUp from 'components/layout/AuthPopUp';
import AuthContext from 'components/Route/AuthContext';
import { AuthComponent } from 'constants/auth';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from "react-router";
import { routes } from 'constants/routes';
import { loadStripe } from "@stripe/stripe-js";
import state from "apollo/state";
import { STRIPE_PUBLIC_KEY } from "config/env.config";

const stripe = loadStripe(STRIPE_PUBLIC_KEY || '');

const App = () => {
  const navigate = useNavigate();
  const [authIsOpen, setAuthIsOpen] = React.useState(false);
  const [authType, setAuthType] = React.useState(AuthComponent.LOGIN);
  state.stripePromise(stripe);
  const { logout } = useAuth();
    //const stripe = useReactiveVar(s)
  const toggleAuth = (isOpen: boolean, authType?: AuthComponent) => {
    setAuthIsOpen(isOpen);
    setAuthType(authType || AuthComponent.LOGIN);
  };

  const onLogout = () => {
    logout().then(() => {
        navigate(routes.common.home.path)
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isOpen: authIsOpen,
        authType: authType,
        setAuth: toggleAuth,
        logOut: onLogout,
      }}
    >
      <AuthPopUp />
      <Routes />
    </AuthContext.Provider>
  );
};

export default App;
