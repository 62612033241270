import useSignUpFormStyles from './index.styles';

import React from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import SignUpFormScheme, {
  SignupFormValuesType,
  SignUpInitialValues,
} from './index.schema';
import { useAuth } from 'hooks/useAuth';
import { Button, Divider, Typography } from '@mui/material';

import { PasswordField, TextField } from 'components/form';
import GoogleAuth from 'components/layout/AuthPopUp/GoogleAuth';
import Loader from 'components/common/Loader';

interface SignUpProps {
  onSignUp: () => void;
  setSocialAuthProcessing: (isProcessing: boolean) => void;
}

const SignupForm = (props: SignUpProps) => {
  const { onSignUp, setSocialAuthProcessing } = props;

  const { register, registerProcessing } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useSignUpFormStyles();

  const formik = useFormik({
    initialValues: SignUpInitialValues,
    validationSchema: SignUpFormScheme,
    onSubmit: (values: SignupFormValuesType) => {
      register(values.email, values.password, values.nickname).then(
        (response) => {
          if (response.errors) {
            //setApiErrors(formik, response.errors);
            if (response.errors?.email?.[0]) {
              formik.setFieldError('email', response.errors.email[0]);
            }
            if (response.errors?.password?.[0]) {
              formik.setFieldError('password', response.errors.password[0]);
            }
            if (response.errors?.nickname?.[0]) {
              formik.setFieldError('nickname', response.errors.nickname[0]);
            }
          } else {
            enqueueSnackbar('Registered successfully, You can log in now');
            onSignUp();
          }
        },
      );
    },
  });

  return (
    <div>
      {registerProcessing && <Loader />}
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className={classes.FormInputStyle}>
          <TextField
            id="email-input"
            name={'email'}
            label={'Email'}
            placeholder="Enter your email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className={classes.FormInputStyle}>
          <PasswordField
            id="password-input"
            name={'password'}
            label={'Password'}
            placeholder="Enter your password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>
        <div className={classes.FormInputStyle}>
          <TextField
            id="nickname-input"
            name={'nickname'}
            label={'Nickname'}
            placeholder="Enter nickname"
            value={formik.values.nickname}
            onChange={formik.handleChange}
            error={formik.touched.nickname && Boolean(formik.errors.nickname)}
            helperText={formik.touched.nickname && formik.errors.nickname}
          />
        </div>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Sign up
        </Button>
      </form>
      <div className={classes.FormLineTextStyle}>
        <Divider className={classes.FormLineStyle} />
        <Typography className={classes.FormTitleAfter} variant="h6">
          or sign up with
        </Typography>
        <Divider className={classes.FormLineStyle} />
      </div>
      <div className={classes.PagePopupSocNet}>
        <GoogleAuth
          onLogin={onSignUp}
          onLoginProcessing={setSocialAuthProcessing}
        />
      </div>
    </div>
  );
};

export default SignupForm;
