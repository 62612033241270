import { useMutation, useQuery } from '@apollo/client';
import { PredictionAPI } from 'api';
import {
  AdminMatchPredictionType,
  IPick6,
  IPick6TypeEdge,
  PredictionItemType,
  PredictionType,
  PredictionTypeEdge,
} from 'models/prediction.model';
import { IResponseError } from 'types/api.types';

export const usePrediction = (id: string, isAdmin?: boolean) => {
  const { data, refetch, loading, error } = useQuery(
    isAdmin
      ? PredictionAPI.predictionDetailAdmin()
      : PredictionAPI.predictionDetail(),
    {
      variables: { id },
      skip: id === 'create',
    },
  );

  let prediction: PredictionType | undefined;

  if (data?.bracketPredictionDetail) {
    prediction = data.bracketPredictionDetail;
  }
  return {
    prediction,
    refetch,
    predictionLoading: loading,
    predictionError: error,
  };
};

export const useFantasy = (id: string) => {
  const { data, loading, error } = useQuery(
    PredictionAPI.fantasyTeamDetails(),
    { variables: { id }, skip: id === 'create' },
  );

  let pick6: IPick6 | undefined;

  if (data?.fantasyTeamDetails) {
    pick6 = data.fantasyTeamDetails;
  }

  return { pick6, pick6Loading: loading, pick6Error: error };
};

export const usePredictionList = () => {
  const { data, loading, error, refetch } = useQuery(
    PredictionAPI.bracketPredictions(),
    { fetchPolicy: 'network-only' },
  );

  let predictions: Array<PredictionType> = [];

  if (data?.bracketPredictionList) {
    predictions = data.bracketPredictionList.edges.map(
      (edge: PredictionTypeEdge) => edge.node,
    );
  }

  return {
    predictions,
    predictionsLoading: loading,
    predictionsError: error,
    refetchPredictions: refetch,
  };
};

export const usePredictionListCount = () => {
  const { data, loading, error, refetch } = useQuery(
    PredictionAPI.bracketPredictionsCount(),
    { fetchPolicy: 'network-only' },
  );

  let predictionsCountList: Array<{ id: string; game: { id: string } }> = [];

  if (data?.bracketPredictionList) {
    predictionsCountList = data.bracketPredictionList.edges.map(
      (edge: PredictionTypeEdge) => edge.node,
    );
  }

  return {
    predictionsCountList,
    predictionsLoading: loading,
    predictionsError: error,
    refetchPredictions: refetch,
  };
};

export const useFantasyListCount = () => {
  const { data, loading, error } = useQuery(
    PredictionAPI.fantasyTeamListCount(),
    { fetchPolicy: 'network-only' },
  );

  let pick6CountList: Array<{ id: string; game: { id: string } }> = [];

  if (data?.fantasyTeamList) {
    pick6CountList = data.fantasyTeamList.edges.map(
      (edge: IPick6TypeEdge) => edge.node,
    );
  }

  return { pick6CountList, pick6Loading: loading, pick6Error: error };
};

export interface ICreatePredictionResponse {
  success: boolean;
  errors?: Array<IResponseError>;
  bracket: {
    id: string;
    paymentIntentId: string;
  };
  clientSecret: string;
}

export interface ICreateFantasyResponse {
  success: boolean;
  errors?: Array<IResponseError>;
  fantasyTeam: {
    id: string;
    paymentIntentId: string;
  };
  clientSecret: string;
}

export const usePredictionApi = () => {
  const [create, { loading: createPredictionLoading }] = useMutation(
    PredictionAPI.predictionCreate(),
    {
      errorPolicy: 'all',
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'bracketPredictionList' });
        cache.gc();
      },
    },
  );

  const [update, { loading: updatePredictionLoading }] = useMutation(
    PredictionAPI.predictionUpdate(),
    {
      errorPolicy: 'all',
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'bracketPredictionList' });
        cache.gc();
      },
    },
  );

  const [pick6create, { loading: createPick6Loading }] = useMutation(
    PredictionAPI.pick6Create(),
    {
      errorPolicy: 'all',
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'fantasyTeamList' });
        cache.gc();
      },
    },
  );

  const [pick6update, { loading: updatePick6Loading }] = useMutation(
    PredictionAPI.pick6Update(),
    {
      errorPolicy: 'all',
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'fantasyTeamList' });
        cache.gc();
      },
    },
  );

  const [bracketPredictionPaid, { loading: predictionPaidLoading }] =
    useMutation(PredictionAPI.completeBracketPredictionPayment(), {
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'bracketPredictionLeaderboard',
        });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'bracketPredictionList' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getActiveTournament' });
        cache.gc();
      },
    });

  const [fantasyTeamPaid, { loading: fantasyPaidLoading }] = useMutation(
    PredictionAPI.completeFantasyTeamPayment(),
    {
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'fantasyTeamLeaderboard' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'fantasyTeamList' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getActiveTournament' });
        cache.gc();
      },
    },
  );

  const [adminCreate] = useMutation(PredictionAPI.adminPredictionSave(), {
    update: (cache) => {
      // fieldName dynamic ?
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'bracketPredictionLeaderboard',
      });
      // garbage collector
      cache.gc();
    },
  });

  const createPrediction = (
    gameId: string,
    matchPredictions: Array<PredictionItemType>,
    tieBreaker?: number,
    tieDuration?: number,
    tieScore?: string,
  ) => {
    return create({
      variables: {
        input: {
          gameId,
          matchPredictions,
          tieBreaker,
          duration: tieDuration,
          score: tieScore,
        },
      },
    })
      .then((response) => {
        if (response.data.bracketPredictionCreate) {
          return response.data
            .bracketPredictionCreate as ICreatePredictionResponse;
        } else {
          return response as ICreatePredictionResponse;
        }
      })
      .catch((err) => {});
  };

  const updatePrediction = (
    bracketId: string,
    matchPredictions: Array<PredictionItemType>,
    tieBreaker?: number,
    tieDuration?: number,
    tieScore?: string,
  ) => {
    return update({
      variables: {
        input: {
          bracketId,
          matchPredictions,
          tieBreaker,
          duration: tieDuration,
          score: tieScore,
        },
      },
    })
      .then((response) => {
        if (response.data.bracketPredictionUpdate) {
          return response.data
            .bracketPredictionUpdate as ICreatePredictionResponse;
        } else {
          return response as ICreatePredictionResponse;
        }
      })
      .catch((err) => {});
  };

  const createFantasy = (
    gameId: string,
    players: Array<string>,
    tieBreaker?: number,
    tieDuration?: number,
    tieScore?: string,
  ) => {
    return pick6create({
      variables: {
        input: {
          gameId,
          playerIds: players,
          tieBreaker,
          duration: tieDuration,
          score: tieScore,
        },
      },
    })
      .then((response) => {
        if (response.data.fantasyTeamCreate) {
          return response.data.fantasyTeamCreate as ICreateFantasyResponse;
        } else {
          return response as ICreateFantasyResponse;
        }
      })
      .catch((err) => {});
  };

  const updateFantasy = (
    fantasyTeamId: string,
    players: Array<string>,
    tieBreaker?: number,
    tieDuration?: number,
    tieScore?: string,
  ) => {
    return pick6update({
      variables: {
        input: {
          fantasyTeamId,
          playerIds: players,
          tieBreaker,
          duration: tieDuration,
          score: tieScore,
        },
      },
    })
      .then((response) => {
        if (response.data.fantasyTeamUpdate) {
          return response.data.fantasyTeamUpdate as ICreateFantasyResponse;
        } else {
          return response as ICreateFantasyResponse;
        }
      })
      .catch((err) => {});
  };

  const saveAdminPrediction = (
    winnerBrackets: Array<AdminMatchPredictionType>,
  ) => {
    return adminCreate({ variables: { input: { winnerBrackets } } })
      .then((response) => {
        const { success } = response.data.adminUpdateWinnerBracket;
        return success;
      })
      .catch((err) => {});
  };

  const markPredictionAsPaid = (bracketPredictionId: string) => {
    return bracketPredictionPaid({
      variables: { input: { bracketPredictionId } },
    })
      .then((response) => {
        return response.data.completeBracketPredictionPayment;
      })
      .catch((error) => console.log(error));
  };

  const markFantasyAsPaid = (fantasyTeamId: string) => {
    return fantasyTeamPaid({ variables: { input: { fantasyTeamId } } })
      .then((response) => {
        return response.data.completeFantasyTeamPayment;
      })
      .catch((error) => console.log(error));
  };

  return {
    createPrediction,
    updatePrediction,
    saveAdminPrediction,
    createFantasy,
    updateFantasy,
    markPredictionAsPaid,
    markFantasyAsPaid,
    isLoading:
      createPredictionLoading ||
      updatePredictionLoading ||
      createPick6Loading ||
      updatePick6Loading ||
      predictionPaidLoading ||
      fantasyPaidLoading,
  };
};
