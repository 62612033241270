
import { Popover as MuiPopover, PopoverProps, Typography } from '@mui/material'
import React from 'react'

const Popover = (props: PopoverProps) => {
    const {
        id,
        anchorEl,
        onClose,
        open,
        children,
        ...rest
    } = props
    return <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        { ...rest }
    >
        { children }
    </MuiPopover>
}

export default Popover;