import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useTournamentViewStyles = makeStyles((theme: Theme) => ({
  tournamentGrid: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    ...theme.typography.semiBold1618black,
  },
  roundTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: '166px',
    flexGrow: 0,
    flexShrink: 0,
  },
  roundDescription: {
    ...theme.typography.regular1420gray,
  },
  bracketsFillContainer: {
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    margin: 'auto',
    marginBottom: '100px',
  },
  bracketIconButton: {
    border: `1px solid ${theme.colors.green}`,
    borderRadius: 0,
  },
}));

export { useTournamentViewStyles };
