import { makeStyles } from '@mui/styles';

const useLoaderStyles = makeStyles({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    padding: '20px',
  },
  TableLoader: {},
  LoaderOuter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
});

export default useLoaderStyles;
