import React from 'react';
import { TextField as MaterialTextField, TextFieldProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

interface ISelectOption {
  key: string;
  label: string;
  value: number | string;
}

interface SelectFieldProps {
  id: string;
  name: string;
  label: string;
  value: number | string;
  size?: "small" | "medium";
  variant?: "standard" | "filled" | "outlined";
  onChange: (e: React.ChangeEvent<any>) => void;
  error?: boolean | undefined;
  helperText?: string | false | undefined;
  options: ISelectOption[];
  disabled?: boolean;
}

const SelectField = ({ options, ...rest }: SelectFieldProps) => {
  return (
    <MaterialTextField
      InputLabelProps={{
        shrink: false,
      }}
      FormHelperTextProps={{
        variant: 'standard',
      }}
      select
      fullWidth={true}
      variant={'outlined'}
      autoComplete={'off'}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MaterialTextField>
  );
};

export default SelectField;
