import React from 'react';
import { AuthComponent } from 'constants/auth';

const AuthContext = React.createContext({
  isOpen: false,
  authType: AuthComponent.LOGIN,
  setAuth: (isOpen: boolean, authType?: AuthComponent) => {},
  logOut: () => {},
});

export default AuthContext;
