import { CardNumberElement } from '@stripe/react-stripe-js';
import React from 'react';
import { StripeTextField } from './StripeTextField';
import { StripeTextFieldProps } from 'types/payment.type';

const StripeTextFieldNumber = (
  props: StripeTextFieldProps<typeof CardNumberElement>,
) => (
  <StripeTextField
    label="Credit Card Number"
    stripeElement={CardNumberElement}
    {...props}
  />
);

export default StripeTextFieldNumber;
