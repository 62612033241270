import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const usePagePageHowToWorkStyles = makeStyles((theme: Theme) => ({
  pageHowToWork: {
    background:
      'linear-gradient(to right, #FFFFFF 0%, #FFFFFF 60%,  #3B50A0 60%, #3B50A0 100% )',
  },

  pageHowToWorkList: {
    maxWidth: '1128px',
    width: '100%',
    margin: '0 auto',
  },
  pageHowToWorkItemFirst: {
    maxWidth: '60%',
    width: '100%',
  },
  pageHowToWorkItemSecond: {
    maxWidth: '40%',
    width: '100%',
    backgroundColor: '#3B50A0',
  },
  pageHowToWorkItemUl: {
    listStyleType: 'disc',
    color: theme.colors.green,
    marginLeft: '20px',
  },
  pageHowToWorkItemBody: {
    color: 'black',
  },
  pageHowToWorkItemH4: {
    paddingBottom: '8px',
    paddingTop: '20px',
    fontSize: 36,
    lineHeight: '40px',
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.colors.green,
  },
  pageHowToWorkMobile: {
    background: ' #FFFFFF ',
  },
  pageHowToWorkItemFirstMobile: {
    flexDirection: 'column',
    maxWidth: '100%',
  },
  pageHowToWorkItemMobile: {
    flexDirection: 'column',
    // padding: '0 15px',
  },
  pageHowToWorkItemCardMobile: {
    maxWidth: 'unset',
    padding: '50px',
  },
  scoreboardButton: {
    border: '1px solid #FFFFFF80',
    textTransform: 'uppercase',
    width: '100%',
    marginBottom: 60,
  },
  scoreboardButtonMobile: {
    marginBottom: 30,
  },
  tierStrips: {
    width: '100%',
    height: 20,
  },
  tierTitle: {
    width: '100%',
    color: '#3B50A0',
    fontSize: 24,
    textTransform: 'uppercase',
    textWrap: 'nowrap',
  },
}));

export default usePagePageHowToWorkStyles;
