import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.whiteGray,
    padding: '16px 24px 24px 24px',
    flexGrow: 1,
    justifyContent: 'stretch',
    width: `min(${theme.breakpoints.values.lg}px, 100%)`,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 50,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  tableHeader: {
    '& .MuiGrid-root': {
      color: theme.colors.black,
      opacity: 0.6,
    },
  },
  players_wrapper: {
    padding: theme.spacing(2),
    marginTop: 'clamp(36px, 2rem, 64px)',
    width: '100%',
    '& .players_headline': {
      width: '100%',
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      color: theme.colors.grayBlack,
      textTransform: 'capitalize',
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    '& .players_content': {
      width: '100%',
      margin: 'clamp(4px, 0.5rem, 8px) 0',
      border: '1px solid black',
      borderRadius: 'clamp(4px, 1rem, 8px)',
      padding: 'clamp(2px, 0.5rem, 4px)',
      overflowX: 'scroll',
    },
  },
  headline: {},
  content_root: {},
  searchBox: {
    flex: 1,
    minWidth: 370,
    '& input': {
      height: 38,
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
      order: 3,
      width: '100%',
      marginBottom: 20,
    },
  },
}));

export default useStyles;
