import { FormatEditIconStyles } from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import AppEditIcon from "assets/images/AppEditIcon";
import {Button, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import FormEditAdminTable from "../../form/formEditAdminTable";

interface FormEditIconParams {
    data: TableDataType<unknown>
}

const FormEditIcon = ({ data: {row, onCellClicked }}: FormEditIconParams): JSX.Element => {
    const classes = FormatEditIconStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return <div className={classes.adminDashbordEdit}>

        <Dialog
            PaperProps={{
                className: classes.AuthDialog
            }}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle className={classes.AuthDialogTitle}>
                <IconButton className={classes.ButtonCloseForm}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.AuthDialogContent}>
                <FormEditAdminTable />
            </DialogContent>

        </Dialog>
        <Button
            variant="text"
            onClick={handleClickOpen}
        >
            <AppEditIcon />
        </Button>
    </div>
}

export default FormEditIcon;
