import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        overflow: 'hidden'
    },
    players: {
        flexDirection: 'column',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '24px',
        margin: '10px 0',
        padding: '8px 0',
        gap: '8px',
        backgroundColor: 'transparent',
        overflowY: 'auto'
    },
    applyBtn: {
        padding: 0,
        '& svg': {
            fill: theme.colors.primary
        },
        '&.Mui-disabled': {
            '& svg': {
                fill: theme.colors.lightGray
            },
        }

    },
    searchBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
        minWidth: '500px',
        '& input': {
            height: '32px',
            minWidth: '250px',
        },
        '& label': {
          display: 'none',
        },
    }
}));

export default useStyles;
