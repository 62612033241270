import { MatchType } from 'models/match.model';
import { ITournamentPlayer, MappedType } from 'models/tournamentPlayer.model';
import React from 'react';
import {
  BracketGameType,
  BracketGamesType,
} from 'components/pages/FillBrackets';
import { IGame } from 'models/game.model';
import { UserType } from 'models/auth.model';

export interface GamePlayerType {
  [id: string]: string;
}

export type AdminMatchPredictionType = {
  matchId: string;
  firstId?: string | null;
  secondId?: string | null;
  winnerId?: string | null;
};

export type AdminMatchPlayerType = {
  [id: string]: AdminMatchPredictionType;
};

export const GameBracketsContext = React.createContext({
  activeRound: -1 as number,
  games: [] as BracketGameType[],
  players: {} as MappedType<ITournamentPlayer>,
  predictions: {} as GamePlayerType | undefined,
  readonly: false as boolean,
  curBracket: 0 as number,
  selectedPlayers: {} as GamePlayerType | undefined,
  selectPlayer: (gameId: string, playerId: string, isFinal?: boolean) => {},
});

export const GameBracketsContextOLD = React.createContext({
  games: {} as BracketGamesType,
  players: {} as MappedType<ITournamentPlayer>,
  predictions: {} as GamePlayerType | undefined,
  readonly: false as boolean,
  selectedPlayers: {} as GamePlayerType | undefined,
  selectPlayer: (gameId: string, playerId: string, isFinal?: boolean) => {},
});

export const AdminGameBracketsContext = React.createContext({
  activeRound: '' as string,
  games: {} as BracketGamesType,
  players: {} as MappedType<ITournamentPlayer>,
  excludedPlayers: [] as Array<string>,
  playersList: [] as Array<ITournamentPlayer>,
  predictions: {} as AdminMatchPlayerType | undefined,
  selectPlayer: (gameId: string, player: AdminMatchPredictionType) => {},
});

export interface PredictionItemType {
  matchId: string;
  winnerId: string;
}

export interface BracketPredictionType {
  id: string;
  match?: MatchType;
  winner?: ITournamentPlayer;
}

export interface PredictionType {
  id: string;
  code: string;
  leaderboardPlace: number;
  game?: IGame;
  owner?: UserType;
  matchPredictions?: Array<BracketPredictionType>;
  newPoints?: number;
  tieBreaker: number;
  duration?: number;
  score?: string;
}

export interface IFantasyPlayer {
  id: string;
  player: ITournamentPlayer;
}

export interface IPick6 {
  id: string;
  code: string;
  leaderboardPlace: number;
  game?: IGame;
  owner: UserType;
  fantasyPlayers?: Array<IFantasyPlayer>;
  points: number;
  tieBreaker: number;
  duration?: number;
  score?: string;
}

export interface PredictionTypeEdge {
  node: PredictionType;
}

export interface IPick6TypeEdge {
  node: IPick6;
}
