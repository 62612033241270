import { usePlayer } from "hooks/usePlayers";
import ManagePlayers from "components/pages/Admin/Players/ManagePlayers";
import { useMemo } from "react";
import { IPlayerFormValues } from "components/form/PlayerForm/index.schema";
import { formatGender } from "utils/enum.utils";

type EditPlayerProps = {
    id: string;
    title: string;
    open: boolean;
    handleClose: () => void;
}
const EditPlayer = ({id, ...rest}: EditPlayerProps) => {
    const { player, isLoading } = usePlayer(id);
    const initialValues: IPlayerFormValues | undefined = useMemo(() => {
        if(player) {
            const { id, firstName, lastName, gender: enumGender, imageUrl } = player;
            return {
                playerId: id,
                firstName,
                lastName,
                imageUrl,
                gender: formatGender(enumGender, true),
            } as IPlayerFormValues
        }
        return undefined
    }, [player]);
    return <ManagePlayers
        player={initialValues}
        isLoading={isLoading}
        {...rest}
    />
}
export default EditPlayer