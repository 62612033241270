import useStyles from './index.styles';

import React, { useCallback, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Loader, Table } from "components/common";
import rows from "mock/adminDashboard.mock";
import tableConfig from "config/table.config";
import { Button } from "@mui/material";
import { useTable } from "hooks/useTable";
import { TableQueryInfoType, TableRowValueType } from "types/table.types";
import ManagePlayers from "components/pages/Admin/Players/ManagePlayers";
import GameDialog from "components/pages/Admin/TournamentDetails/GameDialog";
import { prepareUrl } from "utils/route.utils";
import { routes } from "constants/routes";
import { useNavigate } from "react-router";
import { useGameApi } from "hooks/useGame";

interface ITournamentGames {
    tournamentId: string;
}

const TournamentGames: React.FC<ITournamentGames> = ({
    tournamentId,
}: ITournamentGames): React.ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [gameId, setGameId] = useState<string | null>(null);

    const {
        rows,
    } = useTable(tableConfig.adminTournamentGames as TableQueryInfoType, { tournamentId: tournamentId});
    const {setIsEnabled, deleteGame, changeStateLoading, deleteLoading} = useGameApi()

    const onAddGame = (): void => {
        setGameId(null);
        setDialogIsOpen(true);
    };
    const onCellClicked = (game: TableRowValueType, action?: string): void => {
        if (action === 'enable') {
            setIsEnabled(game.id, true);
        } else if (action === 'disable') {
            setIsEnabled(game.id, false);
        } else if (action === 'delete') {
            deleteGame(game.id);
        } else if (action === 'predictions') {
            return navigate(prepareUrl(routes.admin.fillBrackets.path, { gameId: game.id }))
        } else if (action === 'players') {
            return navigate(prepareUrl(routes.admin.gamePlayers.path, { gameId: game.id }))
        } else {
            setGameId(game.id);
            setDialogIsOpen(true);
        }
    };

    return (
        <Stack className={classes.container}>
            <GameDialog
                title={gameId ? "Edit game" : 'Add game'}
                gameId={gameId || undefined}
                tournamentId={tournamentId}
                open={dialogIsOpen}
                handleClose={() => setDialogIsOpen(false)}
            />
            {(changeStateLoading || deleteLoading) && <Loader />}

            <Table
                rows={rows}
                columns={tableConfig.adminTournamentGames.columns}
                onCellClicked={onCellClicked}
                headerClassNames={classes.tableHeader}
            />
            <Stack className={classes.buttonGroup}>
                <Button
                    className={classes.addGameBtn}
                    variant="contained"
                    color="primary"
                    onClick={onAddGame}
                >
                    Add new game
                </Button>
            </Stack>

        </Stack>
    );
};
export default TournamentGames;
