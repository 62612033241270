import React from "react";
import {useFormik} from "formik";

import { Button } from "@mui/material";
import useContactUsFormStyles from "./index.styles";
import { TextField } from "components/form/fields";
import ContactUsFormScheme, { ContactUsFormValuesType, ContactUsIninitialValues } from './index.schema'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'notistack'


const ContactUsForm = () => {

    const { login } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const formik = useFormik({
        initialValues: ContactUsIninitialValues,
        validationSchema: ContactUsFormScheme,
        onSubmit: (values: ContactUsFormValuesType) => {
            /*login(values.email, values.textarea)
                .then(response => {
                    if (response.errors) {
                        response.errors[0]?.message && enqueueSnackbar(response.errors[0]?.message, { variant: 'error' })
                    } else {

                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                })*/
        }
    })

    const classes = useContactUsFormStyles();

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className={classes.FormInputStyle}>
                    <TextField
                        id="email-input"
                        name={'email'}
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>
                <div className={classes.FormInputStyle}>
                    <TextField
                        className={classes.TextAreaStyle}
                        id="filled-multiline-static"
                        multiline
                        rows={4}
                        placeholder={'Type something here...'}
                    />
                </div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Send message
                </Button>
            </form>
        </div>
    );
}

export default ContactUsForm;
