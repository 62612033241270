import { CardExpiryElement } from '@stripe/react-stripe-js';
import React from 'react';
import { StripeTextField } from './StripeTextField';
import { StripeTextFieldProps } from 'types/payment.type';

const StripeTextFieldExpiry = (
  props: StripeTextFieldProps<typeof CardExpiryElement>,
) => (
  <StripeTextField
    label="Expires"
    stripeElement={CardExpiryElement}
    {...props}
  />
);

export default StripeTextFieldExpiry;
