import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Toolbar,
} from '@mui/material';
import { Close, Menu } from '@mui/icons-material';

import { useUser } from 'hooks/useUser';
import { routes } from 'constants/routes';
import { RenderType } from 'types/common.type';
import isMobileScreen from 'hooks/useIsMobile';
import { useLiveTournament } from 'hooks/useTournament';
import LoginButton from 'components/common/LoginButton';
import AppLogo from 'assets/images/AppLogo';
import LeaderBoardIcon from 'assets/images/LeaderBoardIcon';

import cx from 'classnames';
import usePageHeaderStyles from './index.styles';

const PageHeader = () => {
  const navigate = useNavigate();
  const isMobile = isMobileScreen();
  const user = useUser();
  const classes = usePageHeaderStyles();
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLDivElement>(null);

  const toggleMenu = (): void => {
    setAnchorMenu((currentState) => (currentState ? null : menuRef.current));
  };

  const { tournament } = useLiveTournament();
  const onMenuClicked = (url?: string): void => {
    setAnchorMenu(null);
    if (url) navigate(url);
  };

  const appLogoClassNames = cx({
    [classes.small]: isMobile,
  });

  const menuContent: RenderType = useMemo(() => {
    return (
      <div className={classes.buttonsGroup}>
        {tournament && (
          <>
            <Button
              className={classes.buttonTitle}
              type="button"
              variant={isMobile ? 'text' : 'outlined'}
              onClick={() => onMenuClicked(routes.common.leaderBoard.path)}
              sx={{
                border: isMobile ? 'none' : '1px solid #FFFFFF80',
                width: 200,
                color: 'white',
              }}
            >
              <Box pr={1} display="flex" alignItems="center">
                <LeaderBoardIcon />
              </Box>
              leaderboard
            </Button>
            <Button
              className={classes.buttonTitle}
              type="button"
              color="primary"
              variant={isMobile ? 'text' : 'contained'}
              onClick={() => onMenuClicked(routes.common.gameList.path)}
              sx={{ width: 200, color: isMobile ? '#6939A8' : 'white' }}
            >
              play
            </Button>
          </>
        )}
        <LoginButton onMenuClicked={onMenuClicked} color="white" />
      </div>
    );
  }, [isMobile, tournament, user]);

  return (
    <AppBar color="inherit" sx={{ backgroundColor: '#0E1327' }}>
      <Toolbar ref={menuRef} className={classes.pageHeader}>
        <Link
          className={appLogoClassNames}
          onClick={() => navigate(routes.common.home.path)}
        >
          <AppLogo color="white" />
        </Link>
        {isMobile ? (
          <IconButton
            className={classes.menuIconButton}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>): void =>
              toggleMenu()
            }
            color="inherit"
          >
            {anchorMenu ? <Close /> : <Menu />}
          </IconButton>
        ) : (
          menuContent
        )}
      </Toolbar>
      {isMobile && (
        <Drawer
          anchor="top"
          open={!!anchorMenu}
          onClose={toggleMenu}
          className={classes.mobileMenu}
        >
          {menuContent}
        </Drawer>
      )}
    </AppBar>
  );
};
export default PageHeader;
