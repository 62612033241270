import {makeStyles} from "@mui/styles";
import { Theme } from '@mui/material/styles';

const usePlayerSelectorStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row-reverse'
    },
    bracket: {
        width: '100%',
        height: '100%',
        borderRadius: '6px',
    },
    bracketHolder: {
        width: '220px',
        margin: '12px 0',
        display: "flex",
        flexShrink: 0,
        justifyContent: 'stretch',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    bracketLines: {
        display: 'flex',
        alignSelf: 'stretch',
        width: '10px',
        flexShrink: 0,
    },
    bracketFigureLines: {
        display: 'flex',
        alignSelf: 'stretch',
        flexShrink: 0,
        width: '12px',
    },
    figureLine: {
        display: 'flex',
        width: '100%',
        flexShrink: 0,
        flexDirection: 'column',
    },
    centerLine: {
        display: 'flex',
        width: '100%',
        borderTop: `2px solid ${theme.colors.lightGray}`,
        alignSelf: 'center',
    },
    lines: {
        display: 'flex',
        flexGrow: 1,
    },
    topLine: {
        borderRight: `2px solid ${theme.colors.lightGray}`,
        borderBottomRightRadius: '8px',
        borderBottom: `2px solid ${theme.colors.lightGray}`,
    },
    bottomLine: {
        borderRight: `2px solid ${theme.colors.lightGray}`,
        borderTopRightRadius: '8px',
        borderTop: `2px solid ${theme.colors.lightGray}`,
    },
    bracketParents: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputStyle: {
        ...theme.typography.regular1216gray
    }
}));

export default usePlayerSelectorStyles;
