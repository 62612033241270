import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowY: 'auto',
    width: `min(${theme.breakpoints.values.lg}px, 100%)`,
    backgroundColor: theme.colors.whiteGray,
    padding: '72px 0 40px 0',
    flexGrow: 1,
  },
  containerMobile: {
    padding: '60px 0',
  },
  pageHead: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '24px',
  },
  tabHeaderLabel: {
    '& button': {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      color: theme.colors.grayBlack,
      textTransform: 'capitalize',
      letterSpacing: 'normal',
    },
  },
  tournamentTitleText: {
    lineHeight: 1.1,
    color: theme.colors.grayBlack,
  },
  tournamentTitleTextMobile: {
    fontSize: '36px',
  },
}));

export default useStyles;
