import { useQuery } from '@apollo/client';

import { UserAPI } from 'api';

export const useUser = () => {
    const { client, data, loading, error, refetch } = useQuery(UserAPI.me(), { errorPolicy: 'all'});
    let user = null;
    if (data?.me) {
        user = data.me;
    }

    return { user, userLoading: loading, userError: error, refetchUser: refetch, client };
};