import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignSelf: 'center',
    width: `min(${theme.breakpoints.values.lg}px, 100%)`,
    backgroundColor: theme.colors.whiteGray,
    padding: '40px 16px',
    display: 'flex',
    flexGrow: 1,
    height: 'calc(100vh - 96px)',
    overflow: 'hidden',
  },
  crumbs: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filters: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: '8px',
    paddingBottom: '8px',
  },
  autocomplete: {
    width: '300px',
  },
  tabsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '12px',
      '& h2': {
        lineHeight: '36px',
      },
    },
  },
  tabHeaderLabel: {
    '& button': {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      color: theme.colors.grayBlack,
      textTransform: 'capitalize',
      letterSpacing: 'normal',
    },
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  MyBracketsHeadtext: {
    fontWeight: 400,
  },

  MyBracketsHeadtextMobile: {
    fontSize: '36px',
  },
}));

export default useStyles;
