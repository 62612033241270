import { gql } from '@apollo/client';

export const MATCH_INFO = gql`
  fragment MatchFields on MatchType {
    id
    origId
    round {
      id
      title
      points
      pointsText
      coefficient
      start
      status {
        key
        val
      }
      end
    }
    first {
      id
    }
    second {
      id
    }
    winner {
      id
    }
    next {
      id
      round {
        id
        status {
          key
          val
        }
      }
    }
  }
`;
