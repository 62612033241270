import useStyles from './index.styles';

import React, { useMemo, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import tableConfig from "config/table.config";
import { useTable } from "hooks/useTable";
import { TableQueryInfoType } from "types/table.types";
import { PlayerType } from "models/player.model";
import PlayerRow from "components/pages/Admin/GamePlayers/PlayerRow";
import Input from "@mui/material/Input";
import { ITournamentPlayer, TierEnum } from "models/tournamentPlayer.model";
import SelectField from "components/form/fields/SelectField";
import { IconButton } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useTournamentPlayersApi } from "hooks/useTournamentPlayer";
import { formatTier } from "utils/enum.utils";
import { useSnackbar } from "notistack";
import { useExtQuery } from "hooks/useExtQuery";

interface IPlayerList {
    gameId: string
    tournamentPlayers: Array<ITournamentPlayer>
}

const PlayerList: React.FC<IPlayerList> = ({gameId, tournamentPlayers}: IPlayerList): React.ReactElement => {
    const classes = useStyles();
    const playersRef = useRef<HTMLDivElement | null>(null)
    const { enqueueSnackbar } = useSnackbar();

    const {
        rows,
        dataIsLoading,
        setSearch,
        loadMore,
    } = useExtQuery(tableConfig.adminPlayers as TableQueryInfoType, {ordering: ['first_name','last_name']});


    const playersTier: Record<string, TierEnum> = useMemo(() => {
        const result: Record<string, TierEnum> = {};
        tournamentPlayers.forEach((tournamentPlayer) => {
            result[tournamentPlayer.player.id] = tournamentPlayer.tier
        })
        return result
    }, [tournamentPlayers])

    const { addTournamentPlayers } = useTournamentPlayersApi();
    const [selectedTier, setSelectedTier] = useState<TierEnum | '0'>('0')
    const [selectedPlayers, setSelectedPlayers] = useState<Array<string>>([])
    const onSelectTier = (event: React.ChangeEvent<{ value: TierEnum | '0' }>) => {
        setSelectedTier(event.target.value)
    }
    const onSelectPlayer = (id: string): void => {
        if (selectedPlayers.includes(id)) {
            setSelectedPlayers((items: Array<string>) => {
                items.splice(items.indexOf(id), 1)
                return [...items]
            })
        } else {
            setSelectedPlayers((items: Array<string>) => {
                return [...items, id]
            })
        }
    }

    const onInfinityLoading = () => {
        const isBottom = () => {
            if (playersRef.current) {
                return playersRef.current?.scrollHeight - (playersRef.current.scrollTop + playersRef.current?.clientHeight) <=50
            }
            return false;

        }
        if (isBottom() && !dataIsLoading && loadMore) {
            loadMore();
        }
    }

    const applyIsEnabled = (): boolean => {
        return selectedPlayers.length > 0 && selectedTier !== '0'

    }
    const applyTier = (): void => {
        if (applyIsEnabled()) {
            if (selectedTier !== '0') {
                try {
                    addTournamentPlayers({
                        playerIds: selectedPlayers,
                        tier: formatTier(selectedTier, true),
                        gameId
                    }).then(() => {
                        setSelectedTier('0');
                        setSelectedPlayers([]);
                    })
                } catch (e) {
                    enqueueSnackbar('Something went wrong', { variant: 'warning' });
                }
            }
        }
    }

    const players = useMemo(() => {
        return rows as unknown as Array<PlayerType>
    }, [rows])

    return (
        <Stack className={classes.container}>
            <Stack className={classes.searchBox}>
                <Input
                    placeholder="search"
                    type="search"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <SelectField
                    id="tier-input"
                    label="Tier"
                    name="tier"
                    size="small"
                    value={selectedTier}
                    onChange={onSelectTier}
                    options={[
                        { key: '000', label: 'Select Tier', value: '0' },
                        { key: '001', label: 'Tier 1', value: TierEnum.TIER1 },
                        { key: '002', label: 'Tier 2', value: TierEnum.TIER2 },
                        { key: '003', label: 'Tier 3', value: TierEnum.TIER3 },
                        { key: '004', label: 'Tier 4', value: TierEnum.TIER4 },
                    ]}
                />
                <IconButton
                    className={classes.applyBtn}
                    disabled={!applyIsEnabled()}
                    onClick={applyTier}
                >
                    <Check />
                </IconButton>
            </Stack>
            <Stack ref={playersRef} className={classes.players} onScroll={onInfinityLoading}>
                {players.map((player) =>
                    <PlayerRow
                        key={player.id}
                        isSelected={selectedPlayers.includes(player.id)}
                        onSelect={onSelectPlayer}
                        player={player}
                        tier={playersTier.hasOwnProperty(player.id) ? playersTier[player.id] : undefined}
                    />

                )}
            </Stack>
        </Stack>
    );
};
export default PlayerList;
