import { PickTeamStyles } from "./index.styles";
import React from "react";

import { Typography } from "@mui/material";

function PickTeam() {

  const classes = PickTeamStyles();

  return <Typography className={classes.content} variant="h1">PickTeam</Typography>
}

export default PickTeam;
