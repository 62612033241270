import useStyles from './index.styles';

import React, { useCallback, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Table } from "components/common";
import rows from "mock/adminDashboard.mock";
import tableConfig from "config/table.config";
import { alpha, Button, Checkbox, useTheme } from "@mui/material";
import { useTable } from "hooks/useTable";
import { TableQueryInfoType, TableRowValueType } from "types/table.types";
import ManagePlayers from "components/pages/Admin/Players/ManagePlayers";
import GameDialog from "components/pages/Admin/TournamentDetails/GameDialog";
import { prepareUrl } from "utils/route.utils";
import { routes } from "constants/routes";
import { useNavigate } from "react-router";
import { PlayerType } from "models/player.model";
import { TierEnum } from "models/tournamentPlayer.model";
import { groupColors } from "constants/colors";

interface IPlayerRow {
    player: PlayerType;
    isSelected: boolean
    onSelect: (playerId: string) => void
    tier?: TierEnum
}

const PlayerRow: React.FC<IPlayerRow> = ({
    player, tier, onSelect, isSelected
}: IPlayerRow): React.ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const onCheckboxClicked = () => {
        onSelect(player.id)
    }

    const backgroundColor: string = useMemo(() => {
        if (tier && theme.colors.hasOwnProperty(tier)) {
            return theme.colors[tier] || '#FFF';
        }
        return '#fff';
    }, [tier])

    const style = {
        backgroundColor: alpha(backgroundColor, .4),
    }

    return (
        <Stack className={classes.container} style={style}>
            <Checkbox onChange={onCheckboxClicked} checked={isSelected}/>
            {player.firstName} {player.lastName}
        </Stack>
    );
};
export default PlayerRow;
