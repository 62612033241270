import React, { useMemo, useRef, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';

import { useTournamentViewStyles } from './index.styles';
import GameBracket from './GameBracket';
import { GameBracketsContext, GamePlayerType } from 'models/prediction.model';
import { MappedType, ITournamentPlayer } from 'models/tournamentPlayer.model';
import useDraggableScroll from 'hooks/useDragabbleScroll';
import { IGame } from 'models/game.model';
import { useTournamentPlayers } from 'hooks/useTournamentPlayer';
import { BracketGameType } from 'components/pages/FillBrackets';
import { MatchType } from 'models/match.model';
import BracketSlotIconLeft from 'assets/images/BracketSlotIconLeft';
import BracketSlotIconRight from 'assets/images/BracketSlotIconRight';
import useIsMobileScreen from 'hooks/useIsMobile';
import PlayerBracket from './PlayerBracket';

export interface BracketGamesType {
  [id: string]: BracketGameType;
}

interface TournamentViewProps {
  game: IGame;
  gameProgress?: number;
  isLoading?: boolean;
  bracketGames?: BracketGameType[];
  bracketR16Games?: BracketGameType[];
  predictions?: GamePlayerType;
  readonly?: boolean;
  selectedPlayers?: GamePlayerType;
  curBracket: number;
  setCurBracket: (val: number) => void;
  onSelectPlayer?: (
    gameId: string,
    playerId: string,
    isFinal?: boolean,
  ) => void;
}

export const slotRoundTitles = ['1slot', '2slot', '3slot', '4slot'];

export const SecondSlotIndex = 16;
export const ThirdSlotIndex = 32;
export const FourthSlotIndex = 48;

export const renderFullDrawTitle = (index: number) => {
  const title =
    index === 0
      ? '1st'
      : index === SecondSlotIndex
      ? '2nd'
      : index === ThirdSlotIndex
      ? '3rd'
      : '4th';
  return (
    <Box pb={0} pt={3}>
      <Typography fontSize={26} color="white">
        {title} Section
      </Typography>
    </Box>
  );
};

const TournamentViewNew = ({
  game,
  gameProgress,
  bracketGames = [],
  bracketR16Games = [],
  predictions,
  selectedPlayers,
  onSelectPlayer,
  readonly = false,
  curBracket,
  setCurBracket,
}: TournamentViewProps) => {
  const classes = useTournamentViewStyles();
  const isMobile = useIsMobileScreen();
  const tournamentRef = useRef<HTMLDivElement | null>(null);
  const { onMouseDown } = useDraggableScroll(tournamentRef);
  const { tournamentPlayers: _tournamentPlayers } = useTournamentPlayers({
    gameId: game.id,
  });
  const [isBracketCompleted, setIsBracketCompleted] = useState<boolean>(false);

  const tournamentPlayers: MappedType<ITournamentPlayer> = useMemo(() => {
    const result: MappedType<ITournamentPlayer> = {};
    _tournamentPlayers?.forEach((tournamentPlayer) => {
      result[tournamentPlayer.id] = { ...tournamentPlayer };
    });
    return result;
  }, [_tournamentPlayers]);

  const finalGameIds = useMemo(() => {
    const round = game?.rounds?.find((round) => round.matches?.length === 2);
    if (round) {
      return round?.matches;
    }
  }, [game?.rounds]);

  const activeRound = useMemo(() => {
    const round = game?.rounds?.findIndex((round) => round.status.key === 2);
    return round || -1;
  }, [game?.rounds]);

  const finalId = game?.rounds?.find((round) => round.matches?.length === 1);
  const pairsGameIds = useMemo(() => {
    const round = game?.rounds?.find((round) => round.matches?.length === 8);
    if (round) {
      const pairs = round.matches?.reduce(
        (acc, cur) => {
          const curInAcc = acc.findIndex((el) =>
            el.some((val) => val.next.id === cur.next.id),
          );
          if (curInAcc === -1) {
            const accEmptyIndex = acc.findIndex((el) => el.length === 0);
            acc[accEmptyIndex].push(cur);
          } else {
            acc[curInAcc].push(cur);
          }

          return acc;
        },
        [
          [] as MatchType[],
          [] as MatchType[],
          [] as MatchType[],
          [] as MatchType[],
        ],
      );
      return pairs;
    }
  }, [game?.rounds]);

  const onChampionSelect = () => {
    setIsBracketCompleted(true);
  };

  const onPlayerSelect = (
    gameId: string,
    playerId: string,
    isFinal?: boolean,
  ) => {
    onSelectPlayer && onSelectPlayer(gameId, playerId, isFinal);
  };

  const progressValue = Math.ceil(((gameProgress || 10) / 127) * 100);
  const iconSize = isMobile ? 80 : 150;

  return (
    <div
      ref={tournamentRef}
      onMouseDown={onMouseDown}
      className={classes.tournamentGrid}
    >
      {!readonly && (
        <Box my={2} color="#46C0C5" position="relative" border={'2px solid'}>
          <LinearProgress
            variant="determinate"
            color="inherit"
            value={progressValue}
            sx={{
              height: 30,
            }}
          />
          <Box
            sx={{ minWidth: 35 }}
            position="absolute"
            top={6}
            color="white"
            left={`${
              progressValue > 10
                ? progressValue / 2 - (isMobile ? 4 : 1)
                : progressValue + 2
            }%`}
          >
            <Typography variant="regularBebasNeue">{`${progressValue}%`}</Typography>
          </Box>
        </Box>
      )}
      <Stack
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Stack flexDirection="row" gap={2}>
          <Grid container width={iconSize} height={iconSize}>
            {slotRoundTitles.map((title, index) => (
              <Grid
                key={title}
                item
                xs={6}
                sx={{
                  backgroundColor: curBracket === index ? '#EAE4E8' : '#FFFBFC',
                }}
              >
                <IconButton
                  className={classes.bracketIconButton}
                  onClick={() => setCurBracket(index)}
                  sx={{
                    width: iconSize / 2,
                    height: iconSize / 2,
                    padding: isMobile ? 0.5 : 0,
                    paddingLeft: index === 0 || index === 2 ? 0.5 : 0,
                    paddingRight: index === 0 || index === 2 ? 0 : 0.5,
                    display: 'flex',
                    justifyContent:
                      index === 0 || index === 2 ? 'end' : 'start',
                  }}
                >
                  {index === 0 || index === 2 ? (
                    <BracketSlotIconRight />
                  ) : (
                    <BracketSlotIconLeft />
                  )}
                </IconButton>
              </Grid>
            ))}
          </Grid>
          <IconButton
            className={classes.bracketIconButton}
            onClick={() => setCurBracket(4)}
            style={{
              width: iconSize,
              height: iconSize,
              backgroundColor: curBracket === 4 ? '#EAE4E8' : '#FFFBFC',
            }}
          >
            <Grid container>
              <Grid
                item
                display="flex"
                justifyContent="end"
                flexDirection="column"
                xs={12}
                height={iconSize / 2}
              >
                <Typography
                  fontSize={isMobile ? 12 : 16}
                  lineHeight={isMobile ? '16px' : '24px'}
                  textTransform="uppercase"
                  fontWeight={600}
                >
                  Round
                </Typography>
                <Typography
                  fontSize={isMobile ? 12 : 16}
                  lineHeight={isMobile ? '16px' : '24px'}
                  textTransform="uppercase"
                  fontWeight={600}
                >
                  of 16
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                height={iconSize / 2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <BracketSlotIconRight />
              </Grid>
              <Grid
                item
                xs={6}
                height={iconSize / 2}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <BracketSlotIconLeft />
              </Grid>
            </Grid>
          </IconButton>
        </Stack>
        <IconButton
          className={classes.bracketIconButton}
          onClick={() => setCurBracket(5)}
          style={{
            width: iconSize,
            height: iconSize,
            backgroundColor: curBracket === 5 ? '#EAE4E8' : '#FFFBFC',
          }}
        >
          <Stack>
            <Typography
              fontSize={isMobile ? 12 : 16}
              textTransform="uppercase"
              fontWeight={600}
            >
              full
            </Typography>
            <Typography
              fontSize={isMobile ? 12 : 16}
              textTransform="uppercase"
              fontWeight={600}
            >
              draw
            </Typography>
          </Stack>
        </IconButton>
      </Stack>

      <div
        style={{
          overflowY: 'auto',
          padding: 0,
          textAlign: 'center',
        }}
      >
        <div className={classes.bracketsFillContainer}>
          {curBracket < 4
            ? renderFullDrawTitle(
                curBracket === 0
                  ? curBracket
                  : curBracket === 1
                  ? SecondSlotIndex
                  : curBracket === 2
                  ? ThirdSlotIndex
                  : FourthSlotIndex,
              )
            : null}
          <GameBracketsContext.Provider
            value={{
              activeRound: activeRound,
              games: curBracket === 4 ? bracketR16Games : bracketGames,
              curBracket,
              players: tournamentPlayers,
              predictions,
              readonly: readonly,
              selectedPlayers: selectedPlayers,
              selectPlayer: onPlayerSelect,
            }}
          >
            {((curBracket !== 4 && pairsGameIds?.[curBracket]?.length) ||
              (curBracket === 4 && finalGameIds?.length)) && (
              <Stack
                flexDirection="row"
                gap={2}
                margin="auto"
                pt={curBracket === 4 ? 6 : 0}
              >
                <GameBracket
                  gameId={
                    curBracket === 4
                      ? finalGameIds?.[0]?.id || ''
                      : pairsGameIds?.[curBracket]?.[0]?.id || ''
                  }
                  isRootElement
                />
                {curBracket === 4 && (
                  <Stack flexDirection="column" justifyContent="center" mt={9}>
                    <GameBracket
                      isFinal
                      finalLeftParent
                      gameId={finalGameIds?.[0]?.id || ''}
                    />
                    <GameBracket
                      isFinal
                      finalRightParent
                      gameId={finalGameIds?.[1]?.id || ''}
                    />
                    {curBracket === 4 && (
                      <Box mt={4}>
                        <GameBracket
                          isFinal
                          gameId={finalId?.matches?.[0]?.id || ''}
                        />
                      </Box>
                    )}
                  </Stack>
                )}
                <GameBracket
                  gameId={
                    curBracket === 4
                      ? finalGameIds?.[1]?.id || ''
                      : pairsGameIds?.[curBracket]?.[1]?.id || ''
                  }
                  isRootElement
                  isRightBracket
                />
              </Stack>
            )}
            {curBracket === 5 && pairsGameIds?.length && (
              <Stack pt={0} margin="auto">
                {renderFullDrawTitle(0)}
                <Stack flexDirection="row" gap={2} margin="auto" pt={0}>
                  <GameBracket
                    gameId={pairsGameIds?.[0]?.[0]?.id || ''}
                    isRootElement
                  />
                  <GameBracket
                    gameId={pairsGameIds?.[0]?.[1]?.id || ''}
                    isRootElement
                    isRightBracket
                  />
                </Stack>
                {renderFullDrawTitle(SecondSlotIndex)}
                <Stack flexDirection="row" gap={2} margin="auto" pt={0}>
                  <GameBracket
                    gameId={pairsGameIds?.[1]?.[0]?.id || ''}
                    isRootElement
                  />
                  <GameBracket
                    gameId={pairsGameIds?.[1]?.[1]?.id || ''}
                    isRootElement
                    isRightBracket
                  />
                </Stack>
                {renderFullDrawTitle(ThirdSlotIndex)}
                <Stack flexDirection="row" gap={2} margin="auto" pt={0}>
                  <GameBracket
                    gameId={pairsGameIds?.[2]?.[0]?.id || ''}
                    isRootElement
                  />
                  <GameBracket
                    gameId={pairsGameIds?.[2]?.[1]?.id || ''}
                    isRootElement
                    isRightBracket
                  />
                </Stack>
                {renderFullDrawTitle(FourthSlotIndex)}
                <Stack flexDirection="row" gap={2} margin="auto" pt={0}>
                  <GameBracket
                    gameId={pairsGameIds?.[3]?.[0]?.id || ''}
                    isRootElement
                  />
                  <GameBracket
                    gameId={pairsGameIds?.[3]?.[1]?.id || ''}
                    isRootElement
                    isRightBracket
                  />
                </Stack>
              </Stack>
            )}
          </GameBracketsContext.Provider>
        </div>
      </div>
    </div>
  );
};
export default TournamentViewNew;
