import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column'
    },
    buttonGroup: {
        alignItems: 'center',
        padding: '24px 0'
    },
    addGameBtn: {

    },
    tableHeader: {
        "& .MuiGrid-root": {
            color: theme.colors.black,
            opacity: 0.6,
        }
    }

}));

export default useStyles;
