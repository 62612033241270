import { makeVar } from "@apollo/client";
import { Stripe } from "@stripe/stripe-js";

export default {
    modalVar: makeVar({
        Component: null,
        props: null,
        options: null
    }),
    jwtToken: null,
    stripePromise: makeVar<Promise<Stripe | null> | null>(null),
    isNotificationsOpenVar: makeVar(false),
    userLang: makeVar({
        lang: 'en',
    })
}