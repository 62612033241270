import {
  TableConfigType,
  TableDataType,
  TableQueryInfoType,
  TableRowValueType,
} from 'types/table.types';
import {
  GameLogo,
  BracketsTeam,
  PlayerAvatar,
  PlayerButton,
  FormatEditIcon,
  AdminTournamentActions,
  PredictionDetailsButton,
  Pick6DetailsButton,
  UserActions,
} from 'components/table';
import { GameAPI, PlayersAPI, PredictionAPI, TournamentAPI } from 'api';
import AdminPlayerActions from 'components/table/AdminPlayersActions';
import { PlayerType } from 'models/player.model';
import { TournamentType } from 'models/tournament.model';
import { IPick6, PredictionType } from 'models/prediction.model';
import { formatGender } from 'utils/enum.utils';
import { IGame } from 'models/game.model';
import AdminGamesActions from 'components/table/AdminGamesActions';
import UserApi from 'api/user.api';
import Pick6Team from 'components/table/Pick6Team';
import { UserType } from 'models/auth.model';

const tableConfig: TableConfigType = {
  gameList: {
    columns: [
      {
        field: 'logo',
        headerName: ' ',
        flex: 0.2, // 7
        renderCell: (data: TableDataType<TableRowValueType>) => (
          <GameLogo data={data} />
        ),
      },
      {
        field: 'title',
        flex: 1.1, //34
      },
      {
        field: 'status',
        align: 'right',
        renderCell: (data: TableDataType<IGame>) => (
          <BracketsTeam data={data} />
        ),
      },
      {
        field: 'mobile',
        align: 'left',
        flex: 0.8, //12
        renderCell: (data: TableDataType<IGame>) => (
          <BracketsTeam data={data} isMobile={true} />
        ),
      },
    ],
  } as TableQueryInfoType,
  adminUsers: {
    dataApi: UserApi.userList,
    dataPath: 'userList',
    itemsPerPage: 20,
    columns: [
      {
        field: 'nickname',
        headerName: 'Nickname',
        flex: 1, // 7
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.3, // 7
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        renderCell: (data: TableDataType<UserType>) => (
          <UserActions data={data} />
        ),
      },
    ],
  } as TableQueryInfoType,
  adminPlayers: {
    dataApi: PlayersAPI.getPlayersList,
    dataPath: 'playerSearch',
    itemsPerPage: 20,
    columns: [
      {
        field: 'avatar',
        headerName: 'Name',
        flex: 1, // 7
        renderCell: (data: TableDataType<PlayerType>) => (
          <PlayerAvatar data={data} />
        ),
      },
      {
        field: 'gender',
        headerName: 'Gender',
        flex: 0.3, // 7
        renderCell: (data: TableDataType<PlayerType>) =>
          formatGender(data.row.gender),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        align: 'right',
        flex: 0.5, //38
        renderCell: (data: TableDataType<PlayerType>) => (
          <AdminPlayerActions data={data} />
        ),
      },
    ],
  } as TableQueryInfoType,
  adminTournaments: {
    dataApi: TournamentAPI.tournamentList,
    dataPath: 'tournamentList',
    itemsPerPage: 10,
    columns: [
      {
        field: 'title',
        headerName: 'Name',
        flex: 0.3, // 7
      },
      {
        field: 'championship',
        headerName: 'Championship',
        flex: 0.3, // 7
        renderCell: (data: TableDataType<TournamentType>) =>
          data.row.championship?.title,
      },
      {
        field: 'start',
        headerName: 'Start',
        flex: 0.3, // 7
        renderCell: (data: TableDataType<TournamentType>) => {
          const start = data.row.start.split('T')[0];
          return start;
        },
      },
      {
        field: 'end',
        headerName: 'End',
        flex: 0.3, //38
        renderCell: (data: TableDataType<TournamentType>) => {
          const end = data.row.end.split('T')[0];
          return end;
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        align: 'right',
        flex: 0.5, //38
        renderCell: (data: TableDataType<TournamentType>) => (
          <AdminTournamentActions data={data} />
        ),
      },
    ],
  } as TableQueryInfoType,
  choosePlayer: {
    columns: [
      {
        field: 'logo',
        headerName: 'Player name',
        flex: 0.5,
        renderCell: (data: TableDataType<PlayerType>) => (
          <PlayerAvatar data={data} />
        ),
      },
      {
        field: 'button',
        headerName: ' ',
        flex: 0.1,
        renderCell: (data: TableDataType<unknown>) => (
          <PlayerButton data={data} />
        ),
      },
    ],
  } as TableQueryInfoType,
  adminTournamentGames: {
    dataApi: GameAPI.gameList,
    dataPath: 'gameList',
    itemsPerPage: 10,
    columns: [
      {
        field: 'title',
        headerName: 'Title',
        flex: 0.3, // 7
      },
      {
        field: 'type',
        headerName: 'Game Type',
        flex: 0.2, //34
        renderCell: (data: TableDataType<IGame>) =>
          data.row.parent ? 'Half' : 'Full',
      },
      {
        field: 'duration',
        headerName: 'Duration',
        flex: 0.5, //34
        renderCell: (data: TableDataType<IGame>) => {
          const start = data.row.start.split('T')[0];
          const end = data.row.end.split('T')[0];

          return `${start} - ${end}`;
        },
      },
      {
        field: 'picksTeam',
        headerName: 'Pick6 teams',
        flex: 0.4, //12
        renderCell: (data: TableDataType<IGame>) => data.row.countFantasyTeams,
      },
      {
        field: 'bracketsTeam',
        headerName: 'Bracket teams',
        flex: 0.4, //12
        renderCell: (data: TableDataType<IGame>) =>
          data.row.countBracketPredictions,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        align: 'right',
        flex: 0.9, //34
        renderCell: (data: TableDataType<IGame>) => (
          <AdminGamesActions data={data} />
        ),
      },
    ],
  } as TableQueryInfoType,
  myPick6Brackets: {
    dataApi: PredictionAPI.fantasyTeamList,
    dataPath: 'fantasyTeamList',
    itemsPerPage: 50,
    columns: [
      {
        field: 'raqiteer',
        headerName: 'Raqiteer',
        flex: 0.4, //34
        renderCell: (data: TableDataType<IPick6>) =>
          data.row.owner?.nickname || '',
      },
      {
        field: 'game',
        headerName: 'Game',
        flex: 0.4, //34
        renderCell: (data: TableDataType<IPick6>) => data.row.game?.title || '',
      },
      {
        field: 'players',
        headerName: 'Players',
        align: 'left',
        flex: 1, //34
        renderCell: (data: TableDataType<IPick6>) => <Pick6Team data={data} />,
      },
      {
        field: 'points',
        align: 'right',
        headerName: 'Points',
        flex: 0.3, //12
        renderCell: (data: TableDataType<IPick6>) => data.row.points ?? 0,
      },
      {
        field: 'tieBreaker',
        align: 'right',
        headerName: 'Tiebreaker',
        flex: 0.3, //12
      },
      {
        field: 'actions',
        align: 'right',
        headerName: 'Actions',
        flex: 0.6, //34
        renderCell: (data: TableDataType<IPick6>) => (
          <Pick6DetailsButton data={data} />
        ),
      },
      {
        field: 'mobile',
        align: 'left',
        headerName: 'Actions',
        flex: 1, //34
        renderCell: (data: TableDataType<IPick6>) => (
          <Pick6DetailsButton data={data} isMobile={true} />
        ),
      },
    ],
  } as TableQueryInfoType,

  myBrackets: {
    dataApi: PredictionAPI.bracketPredictions,
    dataPath: 'bracketPredictionList',
    itemsPerPage: 50,
    columns: [
      {
        field: 'game',
        headerName: 'Game',
        flex: 0.7, //34
        renderCell: (data: TableDataType<PredictionType>) =>
          data.row.game?.title || '',
      },
      {
        field: 'raqiteer',
        headerName: 'Raqiteer',
        flex: 0.7, //34
        renderCell: (data: TableDataType<PredictionType>) =>
          data.row.owner?.nickname || '',
      },
      {
        field: 'newPoints',
        headerName: 'Points received',
        align: 'right',
        flex: 0.5, //12
        renderCell: (data: TableDataType<PredictionType>) =>
          data.row.newPoints ?? 0,
      },
      {
        field: 'tieBreaker',
        align: 'right',
        headerName: 'Tiebreaker',
        flex: 0.5, //12
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.8, //34
        align: 'right',
        renderCell: (data: TableDataType<PredictionType>) => (
          <PredictionDetailsButton data={data} />
        ),
      },
      {
        field: 'mobile',
        align: 'left',
        headerName: 'Actions',
        flex: 0.3,
        renderCell: (data: TableDataType<PredictionType>) => (
          <PredictionDetailsButton data={data} isMobile={true} />
        ),
      },
    ],
  } as TableQueryInfoType,
  adminDashboard: {
    columns: [
      {
        field: 'gameName',
        headerName: 'Game name',
        flex: 0.4, // 7
      },
      {
        field: 'statusAF',
        headerName: 'Status - active / finished',
        flex: 0.4, //12
      },
      {
        field: 'numberBrackets',
        headerName: '№ of brackets field',
        flex: 0.4, //34
      },
      {
        field: 'numberTickets',
        headerName: '№ of unique users purchased the tickets',
        flex: 0.5, //18
      },
      {
        field: 'closestRound',
        headerName: 'Closest round date',
        flex: 0.4, //18
      },
      {
        field: 'editButton',
        headerName: ' ',
        flex: 0.1, //18
        renderCell: (data) => <FormatEditIcon data={data} />,
      },
    ],
  },
};

export default tableConfig;
