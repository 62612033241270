import useMenuButtonStyle from "./index.styles";

import { Button } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cx from "classnames";

interface MenuButtonProps {
    path: string
    text: string
}

const MenuButton = ({path, text}: MenuButtonProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useMenuButtonStyle();

    const navClassName = cx({
        [classes.currentPath]: location.pathname === path
    })

    return <Button
        className={navClassName}
        onClick={() => navigate(path)}
        variant="text"
    >
        { text }
    </Button>
}

export default MenuButton;