import { gql } from "@apollo/client";

export const CHAMPIONSHIP_INFO = gql`
    fragment ChampionshipFields on ChampionshipType {
        id
        title
        logo
        logoUrl
        primaryColor
        secondaryColor
    }
`;
