import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GameAPI } from 'api';
import { IGame, IGameFormFields } from 'models/game.model';
import { IGame as ExtendedGame } from 'models/gameFields.model';
import { MutationResponseType } from 'models/api.model';
import { useTable } from 'hooks/useTable';
import tableConfig from 'config/table.config';
import { TableQueryInfoType } from 'types/table.types';
import { useMemo } from 'react';
interface IUseGame {
  getGame: (id: string) => void;
  gameIsLoading: boolean;
  game?: IGame;
}
export const useGame = (): IUseGame => {
  const [getGameData, { data, loading: gameIsLoading }] = useLazyQuery(
    GameAPI.gameDetails(),
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const game: IGame = useMemo(() => {
    if (data) {
      return data?.gameDetails;
    }
    return undefined;
  }, [data]);
  const getGame = (id: string): void => {
    try {
      getGameData({
        variables: { id },
      });
    } catch (err) {}
  };

  return { getGame, gameIsLoading, game };
};

export const useGameBrackets = (id: string) => {
  const { data, loading, error, refetch } = useQuery(
    GameAPI.getGameBracketsById(),
    { variables: { id }, notifyOnNetworkStatusChange: true },
  );

  let game: ExtendedGame | undefined;

  if (data?.gameDetails) {
    game = data.gameDetails;
  }

  return { game, gameLoading: loading, gameError: error, refetchGame: refetch };
};

export const useLazyGameBrackets = () => {
  const [getData, { data, loading, error, refetch }] = useLazyQuery(
    GameAPI.getGameBracketsById(),
  );

  let game: ExtendedGame | undefined;

  if (data?.gameDetails) {
    game = data.gameDetails;
  }
  const getGame = (id: string) => {
    getData({ variables: { id } });
  };

  return {
    getGame,
    game,
    gameLoading: loading,
    gameError: error,
    refetchGame: refetch,
  };
};

interface IUseGames {
  games: Array<IGame>;
  isLoading: boolean;
}
export const useGames = (tournamentId: string): IUseGames => {
  const { rows, dataIsLoading } = useTable(
    tableConfig.adminTournamentGames as TableQueryInfoType,
    { tournamentId: tournamentId },
  );
  return {
    games: rows as unknown as Array<IGame>,
    isLoading: dataIsLoading,
  };
};

export const useGameApi = () => {
  const [create, { loading: addGameLoading }] = useMutation(GameAPI.addGame(), {
    update: (cache) => {
      // fieldName dynamic ?
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'gameList' });
      // garbage collector
      cache.gc();
    },
    notifyOnNetworkStatusChange: true,
  });

  const [edit, { loading: editGameLoading }] = useMutation(GameAPI.editGame(), {
    update: (cache) => {
      // fieldName dynamic ?
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'gameList' });
      // garbage collector
      cache.gc();
    },
    notifyOnNetworkStatusChange: true,
  });

  const [changeState, { loading: changeStateLoading }] = useMutation(
    GameAPI.adminChangeGameState(),
    {
      update: (cache) => {
        // fieldName dynamic ?
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'gameList' });
        // garbage collector
        cache.gc();
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const [changeRound, { loading: changeRoundStatusLoading }] = useMutation(
    GameAPI.adminRoundStatusChange(),
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const changeRoundStatus = async (roundId: string, status: number) => {
    return changeRound({ variables: { input: { roundId, status } } });
  };

  const [remove, { loading: deleteLoading }] = useMutation(
    GameAPI.deleteGame(),
    {
      update: (cache) => {
        // fieldName dynamic ?
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'gameList' });
        // garbage collector
        cache.gc();
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const setIsEnabled = async (gameId: string, isActive: boolean) => {
    return changeState({ variables: { input: { isActive, gameId } } });
  };
  const deleteGame = async (gameId: string) => {
    return remove({ variables: { input: { gameId } } });
  };

  const addGame = async ({
    title,
    start,
    end,
    parentId,
    tournamentId,
  }: IGameFormFields) => {
    return create({
      variables: { input: { tournamentId, title, start, end, parentId } },
    }).then((response) => {
      const result = response as MutationResponseType<
        'adminCreateGame',
        'game',
        IGame
      >;
      return result.data.adminCreateGame;
    });
  };
  const editGame = async ({
    id,
    title,
    start,
    end,
    tieBreaker,
    duration,
    score,
  }: IGameFormFields) => {
    return edit({
      variables: {
        input: { id, title, start, end, tieBreaker, duration, score },
      },
    }).then((response) => {
      const result = response as MutationResponseType<
        'adminEditGame',
        'game',
        IGame
      >;
      return result.data.adminEditGame;
    });
  };

  return {
    addGame,
    editGame,
    deleteGame,
    changeRoundStatus,
    changeRoundStatusLoading,
    addGameLoading: addGameLoading || editGameLoading,
    deleteLoading,
    setIsEnabled,
    changeStateLoading,
  };
};
