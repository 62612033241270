import { makeStyles } from '@mui/styles';

const usePageFooterStyles = makeStyles({
    PageFooter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: 'wrap',
      padding: "24px",
    },
    PageFooterBody1: {
        fontWeight: 500,
        opacity: 1,
    },
    PageFooterSocNet: {
        display: "flex",
        justifyContent: "space-between",
        width: '168px',
    },
    PageFooterEmail: {
        margin: "0 25px",
    },
    PageFooterMobile: {
        flexDirection: 'column',
    },
    PageFooterSocNetClassNames: {
        flexDirection: 'column',
    },
    PageFooterEmailMobile: {
        margin: "25px 0",
    }
});

export default usePageFooterStyles;
