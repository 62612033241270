import { makeStyles } from '@mui/styles';

const useAdminPageHeaderStyles = makeStyles({
    pageHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttonsGroup: {
        padding: "24px",
        margin: "0 -10px",
        "& > button": {
            margin: "0 10px",
        }
    },
    logoutButton: {
        position: 'relative',
    }
});

export default useAdminPageHeaderStyles;
