import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useAdminDashboardStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.whiteGray,
    padding: '16px 24px 24px 24px',
    flexGrow: 1,
    justifyContent: 'stretch',
    width: `min(${theme.breakpoints.values.lg}px, 100%)`,
    overflowY: 'auto',
  },
  AdminDashboardTitleText: {
    padding: 0,
  },
  AdminDashboardButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '48px',
  },
  AdminDashboardButtonLabel: {
    '& button': {
      fontFamily: fontFamilies.montserratRegular.fontFamily,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      color: theme.colors.grayBlack,
      textTransform: 'capitalize',
      letterSpacing: 'normal',
    },
  },
  AdButtonItem: {
    fontFamily: fontFamilies.montserratMedium.fontFamily,
    fontWeight: 500,
    padding: '15px 24px',
  },
  AdButtonGroup: {
    margin: '0 -12px',
    '& > button': {
      margin: '0 12px',
    },
  },
  TabPanel: {
    margin: '48px 0 40px 0',
  },
  tableHeader: {
    '& .MuiGrid-root': {
      color: theme.colors.black,
      opacity: 0.6,
    },
  },
  adminDashboardBoxButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  adminDashboardButton: {
    lineHeight: '18px',
    marginTop: '48px',
    padding: '15px 101px',
  },
  AdminDashboardTitleTextMobile: {
    fontSize: '36px',
  },
  AdminDashboardButtonGroupMobile: {
    flexDirection: 'column',
    marginBottom: 0,
  },
  AdButtonGroupMobile: {
    margin: '22px 0',
    '& > button': {
      margin: '0',
    },
    '& button:nth-child(1)': {
      marginBottom: '10px',
    },
  },
  AdButtonItemMobile: {
    width: '100%',
  },
  adminDashboardBoxButtonMobile: {
    display: 'none',
  },
}));

export default useAdminDashboardStyles;
