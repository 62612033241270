
export enum UserRoles {
    ADMIN = 'Admin',
    USER = 'User',
}

export interface UserType {
    id: string;
    firstName?: string;
    lastName?: string;
    nickname: string;
    email: string;
    role: UserRoles;
    isAdmin: boolean;
    isActive: boolean;
    userId: number;
}

export interface ResponseErrorType {
    message: string;
}

export interface AuthResponseType {
    errors?: Array<ResponseErrorType>;
    user?: UserType;
}

