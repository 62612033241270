import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const ButtonGamePageStyles = makeStyles((theme: Theme) => ({
  ButtonGamePageBtn: {
    lineHeight: '18px',
    fontWeight: 500,
    padding: '7px 16px',
  },
}));

export { ButtonGamePageStyles };
