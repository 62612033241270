import { ButtonGamePageStyles } from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { Button, Stack } from '@mui/material';
import { PredictionType } from 'models/prediction.model';
import { useUser } from 'hooks/useUser';
import { UserRoles } from 'models/auth.model';
import {
  useFantasyListCount,
  usePredictionListCount,
} from 'hooks/usePrediction';

interface IPredictionDetailsButton {
  data: TableDataType<PredictionType>;
  isMobile?: boolean;
}

const PredictionDetailsButton = ({
  data: { row, onCellClicked },
  isMobile,
}: IPredictionDetailsButton): JSX.Element => {
  const classes = ButtonGamePageStyles();
  const { user } = useUser();
  const isAdmin = user.role === UserRoles.ADMIN;
  const { predictionsCountList, predictionsLoading } = usePredictionListCount();
  const predictionsCount = predictionsCountList?.filter(
    (item) => item?.game?.id === row.game?.id,
  )?.length;

  const onButtonClick = (action?: string) => {
    onCellClicked && onCellClicked(row, action);
  };

  return (
    <Stack
      className={classes.container}
      flexDirection={isMobile ? 'column' : 'row'}
      gap={1}
    >
      {isMobile && (
        <>
          <Stack>Game: {row.game?.title || ''}</Stack>
          <Stack>Points: {row.newPoints || ''}</Stack>
          <Stack>Tiebreaker: {row.tieBreaker || ''}</Stack>
        </>
      )}
      <Button
        className={classes.ButtonGamePageBtn}
        variant="contained"
        color="primary"
        disabled={
          (predictionsLoading && !isAdmin) ||
          (!isAdmin && predictionsCount >= 3)
        }
        onClick={() => onButtonClick()}
      >
        {isAdmin ? 'Details' : 'Add'}
      </Button>
      {!isAdmin && (
        <Button
          className={classes.ButtonGamePageBtn}
          variant="contained"
          color="primary"
          onClick={() => onButtonClick('edit')}
        >
          Edit
        </Button>
      )}
    </Stack>
  );
};

export default PredictionDetailsButton;
