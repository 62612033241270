import { TextField } from '@mui/material';
import StripeInput from './stripeInput';
import { StripeElement, StripeTextFieldProps } from 'types/payment.type';

export const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>,
) => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth={true}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: false,
      }}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...(other as any)}
    />
  );
};

export default StripeTextField;
