import { gql } from "@apollo/client";

export const TOURNAMENT_INFO = gql`
    fragment TournamentFields on TournamentType {
        id
        title
        start
        end
        isActive
    }
`;
