import useAdminDashboardStyles from './index.styles';

import React, { useState } from 'react';
import { Box, Button, Stack, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import tableConfig from 'config/table.config';
import { Table } from 'components/common';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { useNavigate } from "react-router";
import { routes } from "constants/routes";
import { useLiveTournament } from "hooks/useTournament";
import { TableRowValueType } from "types/table.types";
import { prepareUrl } from "utils/route.utils";
import { useGameApi } from "hooks/useGame";
import GameDialog from "components/pages/Admin/TournamentDetails/GameDialog";

const AdminDashboard = () => {
    const classes = useAdminDashboardStyles();
    const isMobile = isMobileScreen();
    const {tournament, games} = useLiveTournament()
    const {setIsEnabled, deleteGame} = useGameApi()

    let navigate = useNavigate();
    const [value, setValue] = React.useState('1');

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [gameId, setGameId] = useState<string | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const AdminDashboardTitleTextClassNames = cx(
        classes.AdminDashboardTitleText,
        {
            [classes.AdminDashboardTitleTextMobile]: isMobile,
        },
    );
    const AdminDashboardButtonGroupClassNames = cx(
        classes.AdminDashboardButtonGroup,
        {
            [classes.AdminDashboardButtonGroupMobile]: isMobile,
        },
    );
    const AdButtonGroupClassNames = cx(classes.AdButtonGroup, {
        [classes.AdButtonGroupMobile]: isMobile,
    });
    const AdButtonItemClassNames = cx(classes.AdButtonItem, {
        [classes.AdButtonItemMobile]: isMobile,
    });

    const onCellClicked = (game: TableRowValueType, action?: string): void => {
        if (action === 'enable') {
            setIsEnabled(game.id, true);
        } else if (action === 'disable') {
            setIsEnabled(game.id, false);
        } else if (action === 'delete') {
            deleteGame(game.id);
        } else if (action === 'predictions') {
            return navigate(prepareUrl(routes.admin.fillBrackets.path, { gameId: game.id }))
        } else if (action === 'players') {
            return navigate(prepareUrl(routes.admin.gamePlayers.path, { gameId: game.id }))
        } else {
            setGameId(game.id);
            setDialogIsOpen(true);
        }
    };
    return (
        <Stack className={classes.container}>
            <GameDialog
                title={"Edit game"}
                gameId={gameId || undefined}
                tournamentId={tournament?.id || ''}
                open={dialogIsOpen}
                handleClose={() => setDialogIsOpen(false)}
            />
            <Typography className={AdminDashboardTitleTextClassNames} variant="h1">
                Admin dashboard
            </Typography>

            <Box sx={{ width: '100%', typography: 'semiBold1618black' }}>
                <TabContext value={value}>
                    <Box className={AdminDashboardButtonGroupClassNames}>
                        <TabList
                            className={classes.AdminDashboardButtonLabel}
                            sx={{ borderBottom: 1, borderColor: 'divider' }}
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab label="Active Games" value="1" />
                        </TabList>
                        <div className={AdButtonGroupClassNames}>
                            <Button
                                className={AdButtonItemClassNames}
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={() => navigate(routes.admin.players.path)}
                            >
                                Manage players
                            </Button>
                            <Button
                                className={AdButtonItemClassNames}
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={() => navigate(routes.admin.users.path)}
                            >
                                Manage users
                            </Button>
                        </div>
                    </Box>
                    <TabPanel value="1">
                        <Table
                            rows={games as unknown as Array<TableRowValueType>}
                            onCellClicked={onCellClicked}
                            columns={tableConfig.adminTournamentGames.columns}
                            headerClassNames={classes.tableHeader}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </Stack>
    );
};
export default AdminDashboard;
