import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import BgContactUs from 'assets/images/bgContactUs.png';
import BgContactUsMobile from 'assets/images/bgContactUsMobile.png';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.whiteGray,
    padding: '16px 24px 24px 24px',
    flexGrow: 1,
    justifyContent: 'stretch',
    width: `min(${theme.breakpoints.values.lg}px, 100%)`,
    overflow: 'hidden',
    alignSelf: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 50,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  holder: {
    overflowX: 'auto',
  },
  pageTitle: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    color: theme.colors.green,
    fontSize: '70px',
    lineHeight: '80px',
    letterSpacing: '1px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '36px',
      lineHeight: '44px',
    },
  },
  content: {
    minWidth: 700,
    maxWidth: 700,
    display: 'grid',
    gridTemplateColumns: '3fr repeat(7, 2fr) 15px',
    gridTemplateRows: '30px 40px 20px repeat(4, 1fr) 20px 15px 40px 80px',
    rowGap: '8px',
  },
  vertLine: {
    gridArea: '4 / 5 / 10 / 6',
    borderLeft: `2px solid ${theme.colors.green}`,
  },
  bottomDescription: {
    gridArea: '10 / 2 / 10 / 8',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '-12px',
  },
  desc1: {
    ...theme.typography.regular1420,
    textAlign: 'center',
  },
  desc2: {
    ...theme.typography.medium1618,
    textAlign: 'center',
  },
  desc3: {
    ...theme.typography.regular1420,
    textAlign: 'center',
  },
  desc4: {
    marginTop: '16px',
    ...theme.typography.regular1420,
    textAlign: 'center',
  },
  title: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    fontSize: 24,
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridArea: '1 / 2 / 1 / 9',
  },
  subtitle: {
    ...theme.typography.regular1420gray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridArea: '3 / 2 / 3 / 9',
  },
  roundHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  blackBorder: {
    outline: `2px solid ${theme.colors.green}`,
    gridArea: '3 / 2 / 9 / 9',
    borderRadius: '16px',
  },
  tier1Bg: {
    gridArea: '4 / 1 / 4 / 10',
    backgroundColor: theme.colors.A_1,
    borderRadius: '16px',
  },
  tier2Bg: {
    gridArea: '5 / 1 / 5 / 10',
    backgroundColor: theme.colors.A_2,
    borderRadius: '16px',
  },
  tier3Bg: {
    gridArea: '6 / 1 / 6 / 10',
    backgroundColor: theme.colors.A_3,
    borderRadius: '16px',
  },
  tier4Bg: {
    gridArea: '7 / 1 / 7 / 10',
    backgroundColor: theme.colors.A_4,
    borderRadius: '16px',
  },
  header1: {
    gridArea: '2 / 2 / 2 / 2',
  },
  header2: {
    gridArea: '2 / 3 / 2 / 3',
  },
  header3: {
    gridArea: '2 / 4 / 2 / 4',
  },
  header4: {
    gridArea: '2 / 5 / 2 /5',
  },
  header5: {
    gridArea: '2 / 6 / 2 / 6',
  },
  header6: {
    gridArea: '2 / 7 / 2 / 7',
  },
  header7: {
    gridArea: '2 / 8 / 2 / 8',
  },
  header8: {
    gridArea: '2 / 9 / 2 / 9',
  },
  tier1Title: {
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    gridArea: '4 / 1 / 4 / 1',
  },
  tier2Title: {
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    gridArea: '5 / 1 / 5 / 1',
  },
  tier3Title: {
    gridArea: '6 / 1 / 6 / 1',
    textAlign: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  tier4Title: {
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    gridArea: '7 / 1 / 7 / 1',
  },

  tierName: {
    ...theme.typography.semiBold2232white,
    letterSpacing: '2px',
    textWrap: 'nowrap',
    padding: '0px 0px 0px 28px',
    textTransform: 'uppercase',
  },
  tierDescription: {
    ...theme.typography.regular1420,
    letterSpacing: '1px',
    padding: '0 8px 0px 28px',
    textWrap: 'nowrap',
  },
  tierDescriptionLast: {
    ...theme.typography.regular1420,
    letterSpacing: '1px',
    textWrap: 'nowrap',
    padding: '0 8px 8px 28px',
  },
  scoreValue: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    fontSize: 22,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    letterSpacing: '2px',
  },
  scoreVal1: {
    gridArea: '4 / 2 / 4 / 2',
  },
  scoreVal2: {
    gridArea: '5 / 2 / 5 / 2',
  },
  scoreVal3: {
    gridArea: '6 / 2 / 6 / 2',
  },
  scoreVal4: {
    gridArea: '7 / 2 / 7 / 2',
  },
}));

export default useStyles;
