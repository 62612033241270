import React, { useContext, useMemo } from 'react';
import { Button } from '@mui/material';
import AuthContext from 'components/Route/AuthContext';
import { AuthComponent } from 'constants/auth';
import { routes } from '../../../constants/routes';
import { useNavigate } from 'react-router';
import { useUser } from '../../../hooks/useUser';

interface ProfileDropdownProps {
  onLinkClick: () => void;
}

const ProfileDropdown = ({ onLinkClick }: ProfileDropdownProps) => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    user: { role },
  } = useUser();

  const dropdownList = useMemo(
    () => [
      // {
      //   title: 'Payment System',
      //   isRender: role.toLowerCase() === 'user',
      //   onClick: () => {
      //     onLinkClick();
      //     navigate(routes.user.paymentSystem.path);
      //   },
      // },
      {
        title: 'Log out',
        isRender: true,
        onClick: () => {
          onLinkClick();
          setAuth(true, AuthComponent.LOGOUT);
        },
      },
    ],
    [navigate, onLinkClick, setAuth, role],
  );

  return (
    <div className={'profile-dropdown'}>
      <ul className={'profile-dropdown__list'}>
        {dropdownList.map(({ isRender, title, ...rest }) =>
          isRender ? (
            <li className={'profile-dropdown__item'} {...rest} key={title}>
              <Button className={'profile-dropdown__button'}>{title}</Button>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  );
};

export default ProfileDropdown;
