import usePageFooterStyles from './index.styles';

import React from 'react';
import {Link, Typography} from '@mui/material';
import AppInstagram from "assets/images/AppInstagram";
import AppFacebook from "assets/images/AppFacebook";
import AppTwitter from "assets/images/AppTwitter";
import AppYoutube from "assets/images/AppYoutube";
import cx from "classnames";
import { routes } from 'constants/routes';
import isMobileScreen from "hooks/useIsMobile";
import AppLogo from 'assets/images/AppLogo';
import { useNavigate } from 'react-router';


const PageFooter = () => {
    const navigate = useNavigate();
    const classes = usePageFooterStyles();
    const isMobile = isMobileScreen();

    const PageFooterClassNames = cx(classes.PageFooter,{
        [classes.PageFooterMobile]: isMobile,
    });
    const PageFooterEmailClassNames = cx(classes.PageFooterEmail,{
        [classes.PageFooterEmailMobile]: isMobile,
    });
    return (
        <div className={PageFooterClassNames}>
            <Link
                
                onClick={() => navigate(routes.common.home.path)}
            >
                <AppLogo />
            </Link>
            <div className={classes.PageFooterSocNet}>
              
            </div>
           
        </div>
    )
}
export default PageFooter;
