import { gql } from '@apollo/client';
import { PLAYER_INFO } from 'api/fragments/player.fragment';
import { PAGE_INFO } from "api/fragments";
import { TOURNAMENT_PLAYER_INFO } from "api/fragments/tournamentPlayer.fragment";

class TournamentPlayerAPI {

    tournamentPlayerList() {
        return gql`
            query tournamentPlayerList($gameId: String) {
                tournamentPlayerList(gameId: $gameId) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...TournamentPlayersFields
                            player {
                                ...PlayerFields
                            }
                        }
                    }
                }
            }
        ${ PLAYER_INFO }, ${TOURNAMENT_PLAYER_INFO}, ${ PAGE_INFO }`;
    }

    adminCreateTournamentPlayers() {
        return gql`
            mutation adminCreateTournamentPlayers($input: CreateTournamentPlayersInput!) {
                adminCreateTournamentPlayers(input: $input) {
                    success
                    errors
                }
            }
        `;
    }
    adminDeleteTournamentPlayer() {
        return gql`
            mutation adminDeleteTournamentPlayer($input: DeleteTournamentPlayerInput!) {
                adminDeleteTournamentPlayer(input: $input) {
                    success
                    errors
                }
            }
        `;
    }

}

export default new TournamentPlayerAPI();
