import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const ButtonGamePageStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(theme.breakpoints.values.md)]:{
            alignItems: 'flex-start'
        }
    },
    ButtonGamePageBtn: {
        lineHeight: '18px',
        fontWeight: 500,
        padding: '7px 16px'
    },
}));

export {ButtonGamePageStyles};
