import React from 'react';
import { TextField as MaterialTextField, TextFieldProps } from '@mui/material';

const TextField = (props: TextFieldProps) => {
  return (
    <MaterialTextField
      InputLabelProps={{
        shrink: false,
      }}
      FormHelperTextProps={{
        variant: 'standard',
      }}
      fullWidth={true}
      variant={'outlined'}
      autoComplete={'off'}
      {...props}
    />
  );
};

export default TextField;
