import React from 'react';
import useStyles from './index.styles';
import { PlayerType } from "models/player.model";
import { Person } from "@mui/icons-material";

interface IPlayerAvatar {
    player: PlayerType
}

const PlayerAvatar = ({player}: IPlayerAvatar): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.container}>
        {player.imageUrl ?
            <img className={classes.playerAvatar} src={player.imageUrl} alt=""/>
            : <Person />}
    </div>
}

export default PlayerAvatar;
