import useStyles from './index.styles';

import React, { useEffect, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { Link, Stack, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import cx from "classnames";
import isMobileScreen from "hooks/useIsMobile";
import { useGame } from "hooks/useGame";
import PlayerList from "components/pages/Admin/GamePlayers/PlayerList";
import { useTournamentPlayers } from "hooks/useTournamentPlayer";
import TournamentPlayerList from "components/pages/Admin/GamePlayers/TournamentPlayerList";
import { prepareUrl } from "utils/route.utils";
import { routes } from "constants/routes";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router";


interface IAdminTournamentDetails {}

const GamePlayers: React.FC<IAdminTournamentDetails> = ({}: IAdminTournamentDetails): React.ReactElement => {
    const isMobile = isMobileScreen();
    const navigate = useNavigate();
    const params = useParams();
    const gameId = params.gameId || '';
    const classes = useStyles();
    const {game, getGame, gameIsLoading} = useGame()
    const { tournamentPlayers } = useTournamentPlayers({gameId});
    useEffect(() => {
      if (gameId) {
          getGame(gameId)
      }
    }, [gameId])

    const containerClassNames = cx(classes.container, {
      [classes.containerMobile]: isMobile,
    });

    return (
        <Stack className={containerClassNames} maxWidth="lg">
            <Stack className={classes.pageHead}>
                <Link
                    onClick={() => navigate(prepareUrl(routes.admin.tournamentDetails.path, { tournamentId: game?.tournament?.id }))}>
                    <ArrowBackIos />
                </Link>
                <Typography className={classes.tournamentTitleText} variant="h2">
                    {game?.title || 'title'}
                </Typography>
            </Stack>
            <Stack className={classes.content}>
                {gameId && <PlayerList gameId={gameId} tournamentPlayers={tournamentPlayers}/>}
                {gameId && <TournamentPlayerList tournamentPlayers={tournamentPlayers}/>}
            </Stack>
        </Stack>
    )
};
export default GamePlayers;
