import { IPaymentMethod } from "models/payment.model";

export const formatMoney = (amount: number): string => {
    return `${amount/100} USD`;
}

export interface IStripeCardResponse {

    paymentMethod: {
        id: string
        billing_details: {
            address: {
                city: string | null,
                country: string | null,
                line1: string | null,
                line2: string | null,
                postal_code: number | null,
                state: string | null
            },
            email: string | null,
            name: string | null,
            phone: string | null
        },
        card: {
            brand: string,
            checks: {
                address_line1_check: boolean | null,
                address_postal_code_check: boolean | null,
                cvc_check: boolean | null
            },
            country: string,
            exp_month: number,
            exp_year: number,
            funding: string,
            generated_from: string | null,
            last4: string,
            networks: {
                available: Array<string>,
                preferred: string | null
            },
            three_d_secure_usage: {
                supported: boolean
            },
            wallet: string | null
        },
        created: number,
        customer: string | null,
        livemode: boolean,
        type: string
    }
}

export const stripe2Api = (data: IStripeCardResponse): IPaymentMethod => {
    return {
        foreignId: data.paymentMethod.id,
        cardholderName: data.paymentMethod.billing_details.name,
        type: data.paymentMethod.type,
        livemode: data.paymentMethod.livemode,
        brand: data.paymentMethod.card.brand,
        expMonth: data.paymentMethod.card.exp_month,
        expYear: data.paymentMethod.card.exp_year,
        funding: data.paymentMethod.card.funding,
        last4: data.paymentMethod.card.last4,
        generatedFrom: data.paymentMethod.card.generated_from,
        threeDSecureUsage: data.paymentMethod.card.three_d_secure_usage.supported,
        addressLine1Check: data.paymentMethod.card.checks.address_line1_check,
        addressPostalCodeCheck: data.paymentMethod.card.checks.address_postal_code_check,
        cvcCheck: data.paymentMethod.card.checks.cvc_check,
        wallet: data.paymentMethod.card.wallet,
        city: data.paymentMethod.billing_details.address.city,
        country: data.paymentMethod.billing_details.address.country,
        addressLine1: data.paymentMethod.billing_details.address.line1,
        addressLine2: data.paymentMethod.billing_details.address.line2,
        postalCode: data.paymentMethod.billing_details.address.postal_code,
        state: data.paymentMethod.billing_details.address.state
    }
}