
interface IUrlParams {
    [key: string]: unknown;
}

export const prepareUrl = (path: string, params: IUrlParams): string => {
    let formatted = path;
    Object.keys(params).forEach(param => {
        const value = params[param];
        if (typeof value === 'string') {
            formatted = formatted.replace(`:${ param }`, value);
        } else if (typeof value === 'number') {
            formatted = formatted.replace(`:${param}`, value.toString());
        } else {
            formatted = formatted.replace(`:${param}`, '');
        }
    })
    return formatted
}