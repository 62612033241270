import { useStyles } from './index.styles';

import { PageTableList, PageHowToWork, HomeHeading } from 'components/common';
import { useLiveTournament } from 'hooks/useTournament';
import { Stack } from '@mui/material';

function Index() {
  const classes = useStyles();
  const { tournament, games, isLoading } = useLiveTournament();
  return (
    <Stack className={classes.container}>
      <HomeHeading tournament={tournament} />
      {!isLoading && <PageTableList games={games} isHomePage />}
      <PageHowToWork />
    </Stack>
  );
}

export default Index;
