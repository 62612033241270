import useSelectStyles from './index.styles';

import React, { ReactNode, useContext, useMemo, useState } from 'react';
import {
  MenuItem,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from '@mui/material';
import cx from 'classnames';
import { GameBracketsContext } from 'models/prediction.model';
import { ITournamentPlayer } from 'models/tournamentPlayer.model';

type SelectFieldProps = TextFieldProps & {
  onSelectPlayer: (value: string) => void;
  options: Array<ITournamentPlayer>;
  prediction?: string;
  isPredictionWrong?: boolean;
  isWinner?: boolean;
};

const SelectField = (props: SelectFieldProps) => {
  const {
    value,
    onSelectPlayer,
    options,
    variant,
    className,
    prediction,
    isPredictionWrong,
    isWinner,
    ...rest
  } = props;
  const { players } = useContext(GameBracketsContext);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const classes = useSelectStyles();

  const getPlayer = (id?: string): ITournamentPlayer | null => {
    if (id && players.hasOwnProperty(id) && players[id]) {
      return players[id];
    }
    return null;
  };

  const handleChanged = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode,
  ) => {
    onSelectPlayer(event.target.value as string);
    setIsHovered(false);
  };

  const selectOptions: Array<ITournamentPlayer> = useMemo(() => {
    if (prediction && (isHovered || !value)) {
      const player = getPlayer(prediction);
      if (player && !options.find((option) => option.id === prediction)) {
        return [...options, player];
      }
      return options;
    }
    return options;
  }, [getPlayer, isHovered, options, prediction, value]);

  const rootClasses = cx(classes.root, {
    [className || '']: !!className,
  });

  const textFieldValue: string = useMemo(() => {
    if (!value && prediction) return prediction;
    return isHovered && isWinner && prediction ? prediction : (value as string);
  }, [isHovered, isWinner, prediction, value]);

  return (
    <TextField
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      className={rootClasses}
      select
      SelectProps={{
        value: textFieldValue,
        onChange: handleChanged,
        inputProps: { MenuProps: { disableScrollLock: true } },
      }}
      fullWidth={true}
      style={{ color: 'white' }}
      {...rest}
    >
      {selectOptions.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          disabled={isPredictionWrong && option.id === prediction}
        >
          {`${option.player.firstName} ${option.player.lastName}`}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectField;
