import useAdminPlayerActionsStyles from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { PlayerType } from "models/player.model";
import { Button } from "@mui/material";

interface AdminPlayerParams {
    data: TableDataType<PlayerType>
}

const AdminPlayerActions = ({ data: {row, onCellClicked }}: AdminPlayerParams): JSX.Element => {
    const classes = useAdminPlayerActionsStyles();

    const player = row as PlayerType;
    const onButtonClick = () => {
        onCellClicked && onCellClicked(player as PlayerType);
    }
    return <div className={classes.playersBtnOuter}>
        <Button
            className={classes.playerTableButton}
            variant="contained"
            color="primary"
            onClick={onButtonClick}
        >
            Edit Player
        </Button>
    </div>
}
export default AdminPlayerActions;
