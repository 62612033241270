import useStyles from './index.styles';

import React from 'react';

import Typography from '@mui/material/Typography';
import { Container, Stack, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTournamentInfo } from "hooks/useTournament";
import cx from "classnames";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import isMobileScreen from "hooks/useIsMobile";
import TournamentGames from "components/pages/Admin/TournamentDetails/TournamentGames";
import { TabList } from "@mui/lab";
import RaqiteersTab from "components/pages/Admin/TournamentDetails/RaqiteersTab";

enum PageTabs {
    GAMES = '0',
    RAQITEERS = '1',
}

interface IAdminTournamentDetails {}

const AdminTournamentDetails: React.FC<IAdminTournamentDetails> =
  ({}: IAdminTournamentDetails): React.ReactElement => {
      const isMobile = isMobileScreen();
      const params = useParams();
      const tournamentId = params.tournamentId || '';
      const { tournament, refetchTournament } = useTournamentInfo(tournamentId);
      const classes = useStyles();
      const navigate = useNavigate();

      const [tabValue, setTabValue] = React.useState<PageTabs>(PageTabs.GAMES);

      const handleTabChange = (
          event: React.SyntheticEvent,
          activeTab: PageTabs,
      ) => {
          setTabValue(activeTab);
      };

      return (
          <div className={classes.container}>
              <Container maxWidth="lg">
                  <TabContext value={tabValue}>
                      <Stack className={classes.pageHead}>
                          <Typography className={classes.tournamentTitleText} variant="h2">
                              {tournament?.title || ''}
                          </Typography>
                          <TabList
                              className={classes.tabHeaderLabel}
                              sx={{ borderBottom: 1, borderColor: 'divider' }}
                              onChange={handleTabChange}
                              aria-label="Tournament info"
                          >
                              <Tab label="Games" value={PageTabs.GAMES} />
                              <Tab label="Raqiteers" value={PageTabs.RAQITEERS} />
                          </TabList>
                      </Stack>
                      <TabPanel value={PageTabs.GAMES}>
                          {!!tournament && <TournamentGames tournamentId={tournament.id}/>}
                      </TabPanel>
                      <TabPanel value={PageTabs.RAQITEERS}>
                          <RaqiteersTab tournamentId={tournamentId} />
                      </TabPanel>

                  </TabContext>
              </Container>
          </div>
      )
};
export default AdminTournamentDetails;
