import { gql } from '@apollo/client';
import { PLAYER_INFO, PLAYERS_LIST } from 'api/fragments/player.fragment';

class PlayersAPI {
  getPlayersList() {
    return gql`
      ${PLAYERS_LIST}
      query playerSearch(
        $offset: Int
        $before: String
        $after: String
        $first: Int
        $last: Int
        $ordering: [String]
        $tournamentId: [ID]
        $search: String
      ) {
        playerSearch(
          order: $ordering
          offset: $offset
          before: $before
          after: $after
          first: $first
          last: $last
          tournamentId: $tournamentId
          fullName: $search
        ) {
          ...CorePlayerListFields
        }
      }
    `;
  }

  playerDetails() {
    return gql`
      ${PLAYER_INFO}
      query playerDetails($id: ID!) {      
        playerDetails(id: $id) {
          ...PlayerFields
        }
      }
    `
  }

  addPlayer() {
    return gql`
      ${PLAYER_INFO}
      mutation adminCreatePlayer($input: CreatePlayerInput!) {
        adminCreatePlayer(input: $input) {
          success
          errors
          player {
            ...PlayerFields
          }
        }
      }
    `;
  }

  updatePlayer() {
    return gql`
      ${PLAYER_INFO}
      mutation adminUpdatePlayer($input: UpdatePlayerInput!) {
        adminUpdatePlayer(input: $input) {
          success
          errors
          player {
            ...PlayerFields
          }
        }
      }
    `;
  }

}

export default new PlayersAPI();
