import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  logoutButton: {
    position: 'relative',
  },
  buttonTitle: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: '24px',
  },
}));

export default useStyles;
