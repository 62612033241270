import { gql } from '@apollo/client';
import { PAGE_INFO } from 'api/fragments';
import { TOURNAMENT_PLAYER_INFO } from 'api/fragments/tournamentPlayer.fragment';
import { PLAYER_INFO } from 'api/fragments/player.fragment';

class PredictionAPI {
  predictionCreate() {
    return gql`
      mutation bracketPredictionCreate($input: BracketPredictionCreateInput!) {
        bracketPredictionCreate(input: $input) {
          success
          errors
          bracket {
            id
            paymentIntentId
          }
        }
      }
    `;
  }

  predictionUpdate() {
    return gql`
      mutation bracketPredictionUpdate($input: BracketPredictionUpdateInput!) {
        bracketPredictionUpdate(input: $input) {
          success
          errors
          bracket {
            id
            paymentIntentId
          }
        }
      }
    `;
  }

  pick6Create() {
    return gql`
      mutation fantasyTeamCreate($input: FantasyTeamCreateInput!) {
        fantasyTeamCreate(input: $input) {
          success
          errors
          fantasyTeam {
            id
            paymentIntentId
          }
        }
      }
    `;
  }

  pick6Update() {
    return gql`
      mutation fantasyTeamUpdate($input: FantasyTeamUpdateInput!) {
        fantasyTeamUpdate(input: $input) {
          success
          errors
          fantasyTeam {
            id
            paymentIntentId
          }
        }
      }
    `;
  }

  adminPredictionSave() {
    return gql`
      mutation adminUpdateWinnerBracket($input: UpdateWinnerBracketInput!) {
        adminUpdateWinnerBracket(input: $input) {
          success
        }
      }
    `;
  }
  predictionDetail() {
    return gql`
      query bracketPredictionDetail($id: ID!) {
        bracketPredictionDetail(id: $id) {
          owner {
            nickname
          }
          game {
            id
          }
          matchPredictions {
            id
            winner {
              id
            }
            match {
              id
            }
          }
          newPoints
          tieBreaker
          duration
          score
        }
      }
    `;
  }

  predictionDetailAdmin() {
    return gql`
      query bracketPredictionDetail($id: ID!) {
        bracketPredictionDetail(id: $id) {
          owner {
            nickname
            email
          }
          game {
            id
          }
          matchPredictions {
            id
            winner {
              id
            }
            match {
              id
            }
          }
          newPoints
          tieBreaker
          duration
          score
        }
      }
    `;
  }

  fantasyTeamDetails() {
    return gql`
            query fantasyTeamDetails($id: ID!) {
                fantasyTeamDetails(id: $id) {
                    id
                    game {
                        id
                        title
                    }
                    owner {
                        nickname
                    }
                    fantasyPlayers {
                        id
                        player {
                            ...TournamentPlayersFields
                            player {
                                ...PlayerFields
                            }
                        }
                    }
                    tieBreaker
                    duration
                    score
                    points
                }
            }
            ${TOURNAMENT_PLAYER_INFO}, ${PLAYER_INFO}`;
  }

  bracketPredictions() {
    return gql`
      query bracketPredictionList(
        $search: String
        $ownerId: ID
        $before: String
        $after: String
        $first: Int
        $last: Int
      ) {
        bracketPredictionList(
          search: $search
          ownerId: $ownerId
          before: $before
          after: $after
          first: $first
          last: $last
        ) {
          pageInfo {
            ...PageInfoFields
          }
          edges {
            node {
              id
              code
              leaderboardPlace
              game {
                id
                title
                start
                end
                isActive
              }
              owner {
                nickname
              }
              matchPredictions {
                id
                winner {
                  id
                }
                match {
                  id
                }
              }
              tieBreaker
              newPoints
            }
          }
        }
      }
      ${PAGE_INFO}
    `;
  }
  fantasyTeamList() {
    return gql`
          query fantasyTeamList($search: String, $ownerId: ID, $before: String, $after: String, $first: Int, $last: Int, $tournamentId: ID) {
            fantasyTeamList(search: $search, ownerId: $ownerId, before: $before, after: $after, first: $first, last: $last, tournamentId: $tournamentId) {
              pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            id
                            code
                            leaderboardPlace
                            game {
                                id
                                title
                                start
                                end
                                isActive
                            }
                            owner {
                                nickname
                            }
                            fantasyPlayers {
                                id
                                player {
                                    ...TournamentPlayersFields
                                    player {
                                        ...PlayerFields
                                    }
                                }
                            }
                            tieBreaker
                            points
                        }
                    }
            }
          }
          ${PAGE_INFO}, ${TOURNAMENT_PLAYER_INFO}, ${PLAYER_INFO}`;
  }

  bracketPredictionsCount() {
    return gql`
      query bracketPredictionList(
        $search: String
        $ownerId: ID
        $before: String
        $after: String
        $first: Int
        $last: Int
      ) {
        bracketPredictionList(
          search: $search
          ownerId: $ownerId
          before: $before
          after: $after
          first: $first
          last: $last
        ) {
          pageInfo {
            ...PageInfoFields
          }
          edges {
            node {
              id
              game {
                id
              }
            }
          }
        }
      }
      ${PAGE_INFO}
    `;
  }
  fantasyTeamListCount() {
    return gql`
      query fantasyTeamList(
        $search: String
        $ownerId: ID
        $before: String
        $after: String
        $first: Int
        $last: Int
        $tournamentId: ID
      ) {
        fantasyTeamList(
          search: $search
          ownerId: $ownerId
          before: $before
          after: $after
          first: $first
          last: $last
          tournamentId: $tournamentId
        ) {
          pageInfo {
            ...PageInfoFields
          }
          edges {
            node {
              id
              game {
                id
              }
            }
          }
        }
      }
      ${PAGE_INFO}
    `;
  }

  completeBracketPredictionPayment() {
    return gql`
      mutation completeBracketPredictionPayment(
        $input: CompleteBracketPredictionPaymentMutationInput!
      ) {
        completeBracketPredictionPayment(input: $input) {
          success
          errors
        }
      }
    `;
  }
  completeFantasyTeamPayment() {
    return gql`
      mutation completeFantasyTeamPayment(
        $input: CompleteFantasyTeamPaymentMutationInput!
      ) {
        completeFantasyTeamPayment(input: $input) {
          success
          errors
        }
      }
    `;
  }
}

export default new PredictionAPI();
