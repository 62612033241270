import React from 'react';
import { useFormik } from 'formik';
import validationSchema, { initialValues, IPlayerFormValues, } from './index.schema';
import useStyles from 'components/form/PlayerForm/index.styles';
import { usePlayers } from 'hooks/usePlayers';
import { useNavigate } from 'react-router';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { TextField } from 'components/form/fields';
import SelectField from 'components/form/fields/SelectField';
import Loader from 'components/common/Loader';
import { useSnackbar } from 'notistack';
import PictureField from "components/form/fields/PictureField";
import { GenderEnum, GenderEnumNumeric } from "models/player.model";

interface PlayerFormProps {
    player?: IPlayerFormValues
    isLoading?: boolean
    onClose: () => void;
}

const PlayerForm: React.FC<PlayerFormProps> = ({
                                                   player, isLoading, onClose,
                                               }: PlayerFormProps): React.ReactElement => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { addPlayer, addPlayerLoading, editPlayer, editPlayerLoading } = usePlayers();

    const formik = useFormik({
        initialValues: player || initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values: IPlayerFormValues) => {
            if (values.playerId) {
                editPlayer({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('Player saved');
                            onClose();
                        } else {
                            if (response.errors?.firstName?.[0]) {
                                formik.setFieldError('firstName', response.errors.firstName[0]);
                            }
                            if (response.errors?.lastName?.[0]) {
                                formik.setFieldError('lastName', response.errors.lastName[0]);
                            }
                            if (response.errors?.tier?.[0]) {
                                formik.setFieldError('tier', response.errors.tier[0]);
                            }
                            enqueueSnackbar("Player not saved", { variant: 'error' });
                        }

                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });
            } else {
                addPlayer({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('Player created');
                            onClose();
                        } else {
                            if (response.errors?.firstName?.[0]) {
                                formik.setFieldError('firstName', response.errors.firstName[0]);
                            }
                            if (response.errors?.lastName?.[0]) {
                                formik.setFieldError('lastName', response.errors.lastName[0]);
                            }
                            if (response.errors?.tier?.[0]) {
                                formik.setFieldError('tier', response.errors.tier[0]);
                            }
                            enqueueSnackbar("Player not saved", { variant: 'error' });
                        }

                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });
            }
        },
    });
    return (
        <form
            className={ classes.form }
            onSubmit={ formik.handleSubmit }
        >
            <PictureField preview={ formik.values.imageUrl }
                          onSelect={ value => formik.setFieldValue('image', value) }/>
            <TextField
                id="first-name-input"
                name="firstName"
                label="First name"
                placeholder="Enter first name"
                value={ formik.values.firstName }
                onChange={ formik.handleChange }
                error={ formik.touched.firstName && Boolean(formik.errors.firstName) }
                helperText={ formik.touched.firstName && formik.errors.firstName }
            />
            <TextField
                id="last-name-input"
                name="lastName"
                label="Last name"
                placeholder="Enter last name"
                value={ formik.values.lastName }
                onChange={ formik.handleChange }
                error={ formik.touched.lastName && Boolean(formik.errors.lastName) }
                helperText={ formik.touched.lastName && formik.errors.lastName }
            />
            <SelectField
                id="gender-input"
                label="Gender"
                name="gender"
                value={ formik.values.gender }
                onChange={ formik.handleChange }
                error={ formik.touched.gender && Boolean(formik.errors.gender) }
                helperText={ formik.touched.gender && formik.errors.gender }
                options={ [
                    { key: '001', label: 'Male', value: GenderEnumNumeric.MALE },
                    { key: '002', label: 'Female', value: GenderEnumNumeric.FEMALE },
                ] }
            />
            <Stack
                direction="row"
                spacing={ { md: 1 } }
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={ (e: React.MouseEvent<HTMLButtonElement>) => onClose() }
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!!isLoading}
                    type="submit">
                    Apply
                </Button>
            </Stack>
            { (editPlayerLoading || addPlayerLoading) && <Loader/> }
        </form>
    );
};
export default PlayerForm;
