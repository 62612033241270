import { object, string } from 'yup';

import { emailValidator } from "../index.validators";

export interface ContactUsFormValuesType {
    email: string,
    textarea: string,
}

export const ContactUsIninitialValues = {
        email: '',
        textarea: '',
}

const ContactUsFormScheme = object({
    email: string()
        .test(emailValidator)
        .required('Email must be valid'),

    textarea: string()
        .required('Text must be valid'),

})

export default ContactUsFormScheme;
