import useStyles from './index.styles';

import React, { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { appTheme } from 'theme';

const Scoreboard = () => {
  const classes = useStyles();
  const isMobile = isMobileScreen();

  const getHeaderClassName = useCallback(
    (index: number): string => {
      return cx(classes.title, classes.roundHeader, {
        [classes.header1]: index === 1,
        [classes.header2]: index === 2,
        [classes.header3]: index === 3,
        [classes.header4]: index === 4,
        [classes.header5]: index === 5,
        [classes.header6]: index === 6,
        [classes.header7]: index === 7,
      });
    },
    [classes],
  );

  return (
    <Stack className={classes.container}>
      <Stack className={classes.header}>
        <Typography className={classes.pageTitle} variant="h1">
          Score Chart
        </Typography>
      </Stack>
      <Stack
        className={classes.holder}
        width={'100%'}
        alignItems={isMobile ? 'flex-start' : 'center'}
      >
        <div className={classes.content}>
          <Stack className={classes.blackBorder} />
          <Stack className={classes.tier1Bg} />
          <Stack className={classes.tier2Bg} />
          <Stack className={classes.tier3Bg} />
          <Stack className={classes.tier4Bg} />

          <Stack className={getHeaderClassName(1)}>RD1</Stack>
          <Stack className={getHeaderClassName(2)}>RD2</Stack>
          <Stack className={getHeaderClassName(3)}>RD3</Stack>
          <Stack className={getHeaderClassName(4)}>RD of 16</Stack>
          <Stack className={getHeaderClassName(5)}>Quarters</Stack>
          <Stack className={getHeaderClassName(6)}>Semis</Stack>
          <Stack className={getHeaderClassName(7)}>Final</Stack>
          <Stack className={classes.subtitle}>
            Points received per win per round
          </Stack>
          <Stack className={classes.tier1Title}>
            <Stack className={classes.tierName}>Tier 1</Stack>
            <Stack className={classes.tierDescription}>Top 8 Seeds</Stack>
          </Stack>
          <Stack className={classes.tier2Title}>
            <Stack className={classes.tierName}>Tier 2</Stack>
            <Stack className={classes.tierDescription}>9-16 Seeds</Stack>
          </Stack>
          <Stack className={classes.tier3Title}>
            <Stack className={classes.tierName}>Tier 3</Stack>
            <Stack className={classes.tierDescription}>17-32 Seeds</Stack>
          </Stack>
          <Stack className={classes.tier4Title} pt={1}>
            <Stack className={classes.tierName}>Tier 4</Stack>
            <Stack className={classes.tierDescription}>Unseeded</Stack>
            <Stack className={classes.tierDescriptionLast}>Players</Stack>
          </Stack>
          <Stack
            style={{
              gridArea: '4 / 2 / 4 / 2',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            1
          </Stack>
          <Stack
            style={{
              gridArea: '4 / 3 / 4 / 3',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            2
          </Stack>
          <Stack
            style={{ gridArea: '4 / 4 / 4 / 4' }}
            className={classes.scoreValue}
          >
            3
          </Stack>
          <Stack
            style={{
              gridArea: '4 / 5 / 4 / 5',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            8
          </Stack>
          <Stack
            style={{
              gridArea: '4 / 6 / 4 / 6',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            12
          </Stack>
          <Stack
            style={{
              gridArea: '4 / 7 / 4 / 7',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            20
          </Stack>
          <Stack
            style={{ gridArea: '4 / 8 / 4 / 8' }}
            className={classes.scoreValue}
          >
            36
          </Stack>
          <Stack
            style={{
              gridArea: '5 / 2 / 5 / 2',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            2
          </Stack>
          <Stack
            style={{
              gridArea: '5 / 3 / 5 / 3',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            4
          </Stack>
          <Stack
            style={{ gridArea: '5 / 4 / 5 / 4' }}
            className={classes.scoreValue}
          >
            6
          </Stack>
          <Stack
            style={{
              gridArea: '5 / 5 / 5 / 5',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            12
          </Stack>
          <Stack
            style={{
              gridArea: '5 / 6 / 5 / 6',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            18
          </Stack>
          <Stack
            style={{
              gridArea: '5 / 7 / 5 / 7',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            30
          </Stack>
          <Stack
            style={{ gridArea: '5 / 8 / 5 / 8' }}
            className={classes.scoreValue}
          >
            44
          </Stack>
          <Stack
            style={{
              gridArea: '6 / 2 / 6 / 2',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            3
          </Stack>
          <Stack
            style={{
              gridArea: '6 / 3 / 6 / 3',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            6
          </Stack>
          <Stack
            style={{ gridArea: '6 / 4 / 6 / 4' }}
            className={classes.scoreValue}
          >
            9
          </Stack>
          <Stack
            style={{
              gridArea: '6 / 5 / 6 / 5',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            20
          </Stack>
          <Stack
            style={{
              gridArea: '6 / 6 / 6 / 6',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            28
          </Stack>
          <Stack
            style={{
              gridArea: '6 / 7 / 6 / 7',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            44
          </Stack>
          <Stack
            style={{ gridArea: '6 / 8 / 6 / 8' }}
            className={classes.scoreValue}
          >
            76
          </Stack>
          <Stack
            style={{
              gridArea: '7 / 2 / 7 / 2',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            4
          </Stack>
          <Stack
            style={{
              gridArea: '7 / 3 / 7 / 3',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            8
          </Stack>
          <Stack
            style={{ gridArea: '7 / 4 / 7 / 4' }}
            className={classes.scoreValue}
          >
            12
          </Stack>
          <Stack
            style={{
              gridArea: '7 / 5 / 7 / 5',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            28
          </Stack>
          <Stack
            style={{
              gridArea: '7 / 6 / 7 / 6',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            38
          </Stack>
          <Stack
            style={{
              gridArea: '7 / 7 / 7 / 7',
              borderRight: `2px solid ${appTheme.colors.whiteGray}`,
            }}
            className={classes.scoreValue}
          >
            58
          </Stack>
          <Stack
            style={{ gridArea: '7 / 8 / 7 / 8' }}
            className={classes.scoreValue}
          >
            98
          </Stack>
          <Stack className={classes.vertLine} />
          <Stack className={classes.bottomDescription} pt={3}>
            <Stack className={classes.desc1}>Option to fill out</Stack>
            <Stack className={classes.desc2}>Round of 16</Stack>
            <Stack className={classes.desc3}>Bracket</Stack>
            <Stack className={classes.desc4}>
              The 2nd week of the grandslam begins
            </Stack>
          </Stack>
        </div>
      </Stack>
    </Stack>
  );
};
export default Scoreboard;
