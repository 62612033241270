import { gql } from '@apollo/client';
import { USER_AUTH_INFO } from 'api/fragments/user.fragment'

class AuthAPI {
    getAccessTokens() {
        return gql`
          mutation verifyToken($input: String) {
            verifyToken(input: $input) {
              token
              refreshToken
              success
              errors
            }
          }
        `;
    }

    refreshToken() {
        return gql`
          mutation refreshToken($refreshToken: String) {
            refreshToken(refreshToken: $refreshToken) {
              token
              refreshToken
              payload
            }
         }`
    };

    singIn() {
        return gql`
          mutation emailSignIn($email: String!, $password: String!) {
            emailSignIn(email: $email, password: $password) {
                user {
                    ...UserFields
                }
                success
                errors
                token
                refreshToken
            }
       }
       ${USER_AUTH_INFO}`
    }
    socialAuth() {
        return gql`
          mutation socialAuth($accessToken: String!, $provider: String!) {
            socialAuth(accessToken: $accessToken, provider: $provider) {
                token
                refreshToken
            }
       }`
    }

    singUp() {
        return gql`
          mutation emailSignup($input: EmailSignupInput!) {
            emailSignup(input: $input) {
                success
                errors
            }
       }`
    }

    resetPassword() {
        return gql`
          mutation resetPassword($input: ResetPasswordInput!) {
            resetPassword(input: $input) {
              success
            }
         }`
    }

    checkEmailTokenLink() {
        return gql`
          mutation checkEmailTokenLink($input: CheckEmailTokenLinkInput!) {
            checkEmailTokenLink(input: $input) {
              success
              errors
            }
         }`
    }


    resetPasswordConfirm() {
        return gql`
          mutation resetPasswordConfirm($input: ResetPasswordConfirmInput!) {
            resetPasswordConfirm(input: $input) {
              success
            }
         }`
    }

}

export default new AuthAPI();