import React from 'react';
import useLoaderStyles from './index.styles';
import { CircularProgress } from '@mui/material';
import { LoaderVariants } from 'constants/loaderVariants';

interface LoaderProps {
  variant?: LoaderVariants;
}

const Loader: React.FC<LoaderProps> = ({
  variant = LoaderVariants.LoaderOuter,
}) => {
  const classes = useLoaderStyles();

  return (
    <div className={`${classes.loader} ${classes[variant]}`}>
      <CircularProgress />
    </div>
  );
};
export default Loader;
