import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    from,
    fromPromise,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';

import { createUploadLink } from 'apollo-upload-client';

import { AuthAPI } from 'api';
import { TokenStorage } from 'services';

import state from './state';
import { API_ROOT } from "config/env.config";


const getNewTokenByRefreshToken = (refreshToken: string | null) => {
    return fromPromise(
        client
            .mutate({
                mutation: AuthAPI.refreshToken(),
                variables: { refreshToken },
            })
            .then((response) => {
                if (response?.data) {
                    const { token, refreshToken } = response.data.refreshToken;
                    if (token && refreshToken) {
                        TokenStorage.storeToken(token);
                        TokenStorage.storeRefreshToken(refreshToken);
                        return token;
                    }
                }
            })
            .catch((error) => {
                if (
                    error.message === 'Invalid refresh token' ||
                    error.message === 'Refresh token is expired'
                ) {
                    TokenStorage.clear();
                    window.location.href = window.location.origin;
                }
            }),
    );
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    console.log('graphQLErrors', graphQLErrors);
    if (graphQLErrors) {
        const messages = graphQLErrors.map(({ message }) => message);
        if (
            messages.includes('Signature has expired') ||
            messages.includes('Error decoding signature')
        ) {
            return getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
                .filter((value) => Boolean(value))
                .flatMap((newToken) => {
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `JWT ${newToken}`,
                        },
                    });

                    return forward(operation);
                });
        }
    }
});

const httpLink = createUploadLink({
    uri: API_ROOT,
    credentials: 'include',
    headers: {
        authorization: TokenStorage.isAuthenticated() ? TokenStorage.getAuthenticationHeader() : '',
    }

});

const ApiLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        credentials: 'include',
        headers: {
            ...headers,
            authorization: TokenStorage.isAuthenticated()
                ? TokenStorage.getAuthenticationHeader()
                : '',
        },
    }));

    return forward(operation);
});

export const cache = new InMemoryCache({
    resultCaching: true,
    typePolicies: {
        Query: {
            fields: {
                userLang: {
                    read() {
                        return state.userLang();
                    },
                },
            },
        },
        PlayerType: { keyFields: ['id'] },
    },
    addTypename: true,
});

export const client = new ApolloClient({
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([errorLink, ApiLink.concat(httpLink)]),
});
