import { useQuery } from '@apollo/client';

import { ChampionshipAPI } from 'api';
import { IEdgeNode } from "models/api.model";
import { ChampionshipType } from "models/championship.model";


export const useChampionships = () => {
    const { data } = useQuery(ChampionshipAPI.championshipList());

    let championships: Array<ChampionshipType> = [];

    if (data?.championshipList) {
        championships = data.championshipList.edges.map((edge: IEdgeNode<ChampionshipType>) => edge.node);
    }

    return { championships };
}
