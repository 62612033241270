import { createRoot } from 'react-dom/client';
import { client } from 'apollo';

import App from './components/App';
import AppProviders from 'AppProviders';
import { appTheme } from 'theme';

import './styles/index.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <AppProviders theme={appTheme} apolloClient={client}>
    <App />
  </AppProviders>,
);
