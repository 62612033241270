import { number, object, string } from 'yup';
import { GenderEnum, GenderEnumNumeric } from "models/player.model";
import { FileWithPath } from "react-dropzone";

export interface IPlayerFormValues {
  playerId?: string;
  firstName: string;
  lastName: string;
  gender: GenderEnumNumeric;
  image?: FileWithPath
  imageUrl?: string
}

export const initialValues: IPlayerFormValues = {
  firstName: '',
  lastName: '',
  gender: GenderEnumNumeric.MALE,
  image: undefined,
  imageUrl: '',
};

const validationSchema = object({
  firstName: string()
    .min(1, 'First name min length should be of 1 character')
    .required('First name is required'),
  lastName: string()
    .min(1, 'Last name min length should be of 1 character')
    .required('Last name is required'),
  gender: string()
      .required('Gender is required'),
});

export default validationSchema;
