import { gql } from '@apollo/client';
import { PAGE_INFO } from "api/fragments/index";

export const PLAYER_INFO = gql`
  fragment PlayerFields on PlayerType {
    id
    image
    imageUrl
    firstName
    lastName
    country
    abbreviation
    gender
    dob
    proYear
    handness
    weight
    height
    singlesRanking
    highestSinglesRanking
  }
`;

const PLAYER_TYPE_EDGE = gql`
  ${PLAYER_INFO}
  fragment CorePlyerTypeEdgeFields on PlayerTypeEdge {
    cursor
    node {
      ...PlayerFields
    }
  }
`;

export const PLAYERS_LIST = gql`
  ${PAGE_INFO}
  ${PLAYER_TYPE_EDGE}
  fragment CorePlayerListFields on PlayerTypeConnection {
    pageInfo {
      ...PageInfoFields
    }
    edges {
      ...CorePlyerTypeEdgeFields
    }
  }
`;
