import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'

const useLoadingPageStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexGrow: 1,
    width: '100%',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.light
  },
}));

export default useLoadingPageStyles;
