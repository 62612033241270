import { gql } from "@apollo/client";

export const USER_AUTH_INFO = gql`
    fragment UserFields on AuthType {
        id
        email
        role
        nickname
        firstName
        lastName
        isActive
        userId
        isAdmin
    }
`;

export const USER_INFO = gql`
    fragment UserFields on UserType {
        id
        email
        role {
            name
            value
            label
        }
        nickname
        firstName
        lastName
        isActive
    }
`;
