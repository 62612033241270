import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material/styles";

const useDefaultLayoutStyles = makeStyles((theme: Theme) => ({
    container: {
       

    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: 'stretch',
        overflow: 'hidden',
    }

}));

export default useDefaultLayoutStyles;
