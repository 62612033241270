import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const BracketsTeamStyles = makeStyles((theme: Theme) => ({
  container: {
    flexDirection: 'column',
  },
  liveLabel: {
    color: '#FF0000',
  },
}));

export { BracketsTeamStyles };
