import React, {useContext} from 'react';
import AuthContext from 'components/Route/AuthContext';
import useLogOutStyles from "./index.styles";
import {Box, Button, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {routes} from "constants/routes";

interface LogOutProps {
  onClose: () => void
}

const LogOutForm = ({onClose}: LogOutProps) => {
  const {logOut} = useContext(AuthContext);
  const navigate = useNavigate();

  const classes = useLogOutStyles();

  const onLogout = () => {
    logOut()
    onClose()
    navigate(routes.common.home.path);
  }

  return (
    <Box>
        <Typography className={classes.subtitle}>Are you sure you want to leave the site?</Typography>
        <Stack
          direction="row"
          spacing={{md: 1}}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onLogout}
          >
            Yes
          </Button>
        </Stack>
    </Box>
  )
}

export default LogOutForm;