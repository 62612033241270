import useStyles from './index.styles';

import React, { useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { AccountCircle, Delete } from "@mui/icons-material";
import { FormHelperText, IconButton } from "@mui/material";
import { string } from "yup";

const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];
const MAX_FILE_SIZE= 20971520

interface IPictureField {
    preview?: string
    onSelect: (picture: FileWithPath | null) => void
    errorText?: string
}
const PictureField = ({ preview, onSelect, errorText }: IPictureField): JSX.Element => {
    const classes = useStyles();
    const [image, setImage] = useState<string | null>(preview || null);
    useEffect(() => {
        if (preview) {
            setImage(preview)
        }
    }, [preview])
    const handleFileInputClear = () => {
        setImage(null);
        onSelect(null)
    }

    const handleFileInputChange = (files: Array<FileWithPath>) => {
        const file = files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const fileURL = reader.result as string;
                if (fileURL) {
                    setImage(fileURL);
                    onSelect(file)
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        maxSize: MAX_FILE_SIZE,
        accept: SUPPORTED_FORMATS,
        onDrop: handleFileInputChange
    });

    return (
        <div className={classes.container}>
            { image && <IconButton
                className={ classes.closeIcon }
                onClickCapture={ handleFileInputClear }
                aria-label="clear"
                size="small">
                   <Delete/>
                </IconButton>
            }
            {image ? <div className={classes.preview}>
                <img className={classes.image} src={image} alt=""/>
            </div> :
            <div className={classes.dropzone} {...getRootProps()}>
                <input {...getInputProps()} />
                <span className={classes.dropzoneTitle}><AccountCircle/></span>
            </div>}
            {errorText && <FormHelperText error={true}>{errorText}</FormHelperText> }
        </div>
    )
};

export default PictureField;