import useStyles from "./index.styles";

import React, { useMemo } from "react";

import { ITournamentPlayer, TierEnum } from "models/tournamentPlayer.model";
import { Stack, Typography } from "@mui/material";
import { FantasyPick } from "components/common/index";
import PlayerRow from "../PlayerRow";
import { useTournamentPlayers } from "hooks/useTournamentPlayer";

const FANTASY_LENGTH = 6;

interface IFantasyPlayers {
    gameId: string
    selectedPlayers: Array<ITournamentPlayer>
    onSelectPlayer?: (player: ITournamentPlayer) => void,
    onUnselectPlayer?: (player: ITournamentPlayer) => void,
}

const FantasyPlayers = (props: IFantasyPlayers): JSX.Element => {
    const {gameId, selectedPlayers, onSelectPlayer, onUnselectPlayer} = props;
    const classes = useStyles();
    const { tournamentPlayers } = useTournamentPlayers({gameId})

    const selectedPlayerIds: Array<string> = useMemo(() => {
        return selectedPlayers.map((player => player.id))
    }, [selectedPlayers])

    const tier1Players: Array<ITournamentPlayer> = useMemo(() => {
        return tournamentPlayers.filter((player: ITournamentPlayer) => player.tier === TierEnum.TIER1).map(player => {
            return {...player, isSelected: selectedPlayerIds.indexOf(player.id) !== -1}
        })
    }, [tournamentPlayers, selectedPlayerIds]);

    const tier2Players: Array<ITournamentPlayer> = useMemo(() => {
        return tournamentPlayers.filter(player => player.tier === TierEnum.TIER2).map(player => {
            return {...player, isSelected: selectedPlayerIds.indexOf(player.id) !== -1}
        })
    }, [tournamentPlayers, selectedPlayerIds]);

    const tier3Players: Array<ITournamentPlayer> = useMemo(() => {
        return tournamentPlayers.filter(player => player.tier === TierEnum.TIER3).map(player => {
            return {...player, isSelected: selectedPlayerIds.indexOf(player.id) !== -1}
        })
    }, [tournamentPlayers, selectedPlayerIds]);

    const tier4Players: Array<ITournamentPlayer> = useMemo(() => {
        return tournamentPlayers.filter(player => player.tier === TierEnum.TIER4).map(player => {
            return {...player, isSelected: selectedPlayerIds.indexOf(player.id) !== -1}
        })
    }, [tournamentPlayers, selectedPlayerIds]);

    const playersIsSelectable = (tier: TierEnum): boolean => {
        if (selectedPlayers.length >= FANTASY_LENGTH) return false
        if ([TierEnum.TIER1, TierEnum.TIER2, TierEnum.TIER3].includes(tier)) {
            return selectedPlayers.filter(player => player.tier === tier).length < 2
        }
        return true;
    }
    const onSelect = (player: ITournamentPlayer): void => {
        if (onSelectPlayer) onSelectPlayer(player)
    }
    const onUnselect = (player: ITournamentPlayer): void => {
        if (onUnselectPlayer) onUnselectPlayer(player)
    }

    return <Stack className={classes.container}>
        <div className={classes.tier}>
            <Stack className={classes.tierTitle}>
                <Typography className={classes.tierTitleText} variant="h4">Tier 1</Typography>
                <Typography variant="subtitle2">Max picks - 2</Typography>
            </Stack>
            <Stack className={classes.tierPlayers}>
                {tier1Players.map(player =>
                    <FantasyPick
                        key={`tier-1-${player.id}`}
                        player={player}
                        isSelectable={playersIsSelectable(TierEnum.TIER1)}
                        isSelected={selectedPlayerIds.includes(player.id)}
                        onSelectPlayer={onSelect}
                        onUnselectPlayer={onUnselect}
                    />
                )}

            </Stack>
        </div>
        <div className={classes.tier}>
            <Stack className={classes.tierTitle}>
                <Typography className={classes.tierTitleText} variant="h4">Tier 2</Typography>
                <Typography variant="subtitle2">Max picks - 2</Typography>
            </Stack>
            <Stack className={classes.tierPlayers}>
                {tier2Players.map(player =>
                    <FantasyPick
                        key={`tier-2-${player.id}`}
                        player={player}
                        isSelectable={playersIsSelectable(TierEnum.TIER2)}
                        isSelected={selectedPlayerIds.includes(player.id)}
                        onSelectPlayer={onSelect}
                        onUnselectPlayer={onUnselect}
                    />
                )}

            </Stack>
        </div>
        <div className={classes.tier}>
            <Stack className={classes.tierTitle}>
                <Typography className={classes.tierTitleText} variant="h4">Tier 3</Typography>
                <Typography variant="subtitle2">Max picks - 2</Typography>
            </Stack>
            <Stack className={classes.tierPlayers}>
                {tier3Players.map(player =>
                    <FantasyPick
                        key={`tier-3-${player.id}`}
                        player={player}
                        isSelectable={playersIsSelectable(TierEnum.TIER3)}
                        isSelected={selectedPlayerIds.includes(player.id)}
                        onSelectPlayer={onSelect}
                        onUnselectPlayer={onUnselect}
                    />
                )}

            </Stack>
        </div>
        <Stack className={classes.tier4Players}>
            <Typography className={classes.tierTitleText} variant="h4">List of Tier 4 players</Typography>
            <Stack className={classes.tier4Rows}>
                {tier4Players.map((player) =>
                    <PlayerRow
                        key={player.id}
                        isSelectable={false}
                        isSelected={selectedPlayerIds.includes(player.id)}
                        onSelect={onSelect}
                        onUnselect={onUnselect}
                        player={player}
                    />

                )}
            </Stack>
        </Stack>

    </Stack>
}

export default FantasyPlayers