import React, { useState } from 'react';
import { useFormik } from 'formik';

import { Button, Stack } from '@mui/material';
import useLoginFormStyles from './index.styles';
import { TextField } from 'components/form/fields';
import ForgotPasswordFormScheme from './index.schema';
import { useAuth } from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import Loader from 'components/common/Loader';

interface IForgotPasswordForm {}

const ForgotPasswordForm = (props: IForgotPasswordForm) => {
    const { enqueueSnackbar } = useSnackbar();
    const { resetPassword, loading } = useAuth();

    const [emailIsSent, setEmailIsSent] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordFormScheme,
        onSubmit: (values) => {
            resetPassword(values.email)
                .then(response => {
                    const { success, errors } = response.data?.resetPassword;
                    if (success) {
                        enqueueSnackbar('Link sent, check Your email', {variant: 'success'})
                        setEmailIsSent(true);
                    } else {
                        enqueueSnackbar('Email not found', {variant: 'error'})
                    }
                })
        }
    })

    const classes = useLoginFormStyles();

    return (
        <div>
            {loading && <Loader />}
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Stack className={classes.formInput}>
                    <TextField
                        id="email-input"
                        name={'email'}
                        label={'Email'}
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Stack>
                {emailIsSent ?
                    <Stack className={classes.description}>We sent an email link to reset your password. Link expires in 24 hours.﻿ If you don’t see it, you may need to check your Spam folder.</Stack>
                    : <Stack className={classes.description}>Click below to have a password reset link sent to the email address provided. Be sure to check your Spam folder.</Stack>}
                <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    color={emailIsSent ? "inherit" : "primary"}
                >
                    {emailIsSent ? 'Resend Link' : 'Send recovery link'}
                </Button>
            </form>
        </div>
    );
};

export default ForgotPasswordForm;
