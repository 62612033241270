import useStyles from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { Button, Stack } from "@mui/material";
import { IGame } from "models/game.model";

interface IAdminGamesActions {
    data: TableDataType<IGame>
}

const AdminGamesActions = ({ data: {row, onCellClicked }}: IAdminGamesActions): JSX.Element => {
    const classes = useStyles();
    const onButtonClick = (action: string): void => {
        onCellClicked && onCellClicked(row, action);
    }
    return <Stack className={classes.container}>
        <Button
            size="small"
            variant="contained"
            color={row.isActive ? "warning" : 'success'}
            onClick={() => onButtonClick(row.isActive ? 'disable' : 'enable')}
        >
            {row.isActive ? 'Disable' : 'Enable'}
        </Button>
        <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(): void => onButtonClick('players')}
        >
            Players
        </Button>
        <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(): void => onButtonClick('edit')}
        >
            Edit
        </Button>
        <Button
            size="small"
            variant="contained"
            color="error"
            onClick={(): void => onButtonClick('delete')}
        >
            Delete
        </Button>
        <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(): void => onButtonClick('predictions')}
        >
            Winner Bracket
        </Button>
    </Stack>
}

export default AdminGamesActions;
