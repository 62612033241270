import { useState, useMemo, FormEvent, useEffect } from 'react';
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from './commonTextFields';
import { paymentFormStyles } from './index.styles';
import {
  CardNumberElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import { lightGray, redError, primary } from 'theme';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { usePaymentApi } from 'hooks/usePayments';
import { IStripeCardResponse, stripe2Api } from 'utils/stripe.utils';
import { Close } from '@mui/icons-material';

interface IPaymentForm {
  onCloseClick?: () => void;
  onCardAdded?: () => void;
}
const PaymentForm = ({ onCloseClick, onCardAdded }: IPaymentForm) => {
  const [state, setState] = useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: '',
    expiredError: '',
    cvcError: '',
  });
  const classes = paymentFormStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { addPaymentMethod } = usePaymentApi();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          lineHeight: '25px',
          color: primary,
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: lightGray,
          },
        },
        invalid: {
          color: redError,
        },
      },
    }),
    [],
  );

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    /*
        const paymentResult = await stripe.confirmCardPayment('pi_3L00jLEG2hGH8du8158Phsuh_secret_A1eEuNFwGKjYrfDqjOA6p3qZl', {
            payment_method: 'pm_1L00ebEG2hGH8du8cqL3SdWE',
        });
*/

    const formData = new FormData(event.target as HTMLFormElement);
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        name: formData.get('name') as string,
      },
    });
    addPaymentMethod(
      stripe2Api(payload as unknown as IStripeCardResponse),
    ).then((response) => {
      if (response.success) {
        if (onCardAdded) onCardAdded();
      }
    });
  };

  const onReset = () => {
    elements?.getElement(CardNumberElement)?.clear();
    elements?.getElement(CardCvcElement)?.clear();
    elements?.getElement(CardExpiryElement)?.clear();
  };

  const onElementChange =
    (field: string, errorField: string) =>
    ({
      complete,
      error,
    }:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent) => {
      setState({ ...state, [field]: complete, [errorField]: error?.message });
    };
  const { cardNumberError, expiredError, cvcError } = state;

  return (
    <Stack className={classes.container}>
      <form onSubmit={onSubmit} onReset={onReset}>
        <Box className={classes.formHeader}>
          <Typography className={classes.formTitle}>New card</Typography>
          {onCloseClick && (
            <IconButton onClick={onCloseClick}>
              <Close />
            </IconButton>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <StripeTextFieldNumber
              error={!!cardNumberError}
              labelErrorMessage={cardNumberError}
              onChange={onElementChange(
                'cardNumberComplete',
                'cardNumberError',
              )}
              inputProps={{
                options: {
                  ...options,
                  placeholder: 'XXXX XXXX XXXX XXXX',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <StripeTextFieldExpiry
              error={!!expiredError}
              labelErrorMessage={expiredError}
              onChange={onElementChange('expiredComplete', 'expiredError')}
              inputProps={{
                options: {
                  ...options,
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <StripeTextFieldCVC
              error={!!cvcError}
              labelErrorMessage={cvcError}
              onChange={onElementChange('cvcComplete', 'cvcError')}
              inputProps={{
                options: {
                  ...options,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              placeholder="Name on card"
              name="name"
              type="text"
              label="Card holder"
              fullWidth
              required
              InputLabelProps={{
                shrink: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button type="submit" disabled={!stripe} className={classes.submit}>
              Save Card
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};
export default PaymentForm;
