import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  useAddFriendApi,
  useFantasyTeamLeaderboard,
  useLeaderboard,
} from 'hooks/useLeaderboard';
import isMobileScreen from 'hooks/useIsMobile';

import cx from 'classnames';
import useStyles from './index.styles';
import { prepareUrl } from 'utils/route.utils';
import { formatNumberWithMask } from 'utils/number.utils';
import { InfiniteScrollContainer } from 'components/common/InfinityScrollContainer';
import { ILeaderboard } from 'models/leaderboard.model';
import { IDataEdges } from 'models/api.model';
import { useUser } from 'hooks/useUser';
import { routes } from 'constants/routes';

const LeaderboardList = ({
  gameId,
  hasActiveRounds,
  isHome,
}: {
  gameId: string;
  isHome?: boolean;
  hasActiveRounds?: boolean;
}) => {
  const classes = useStyles();
  const isMobile = isMobileScreen();
  const [search, setSearch] = useState('');
  const [loadingFullDraw, setLoadingFullDraw] = useState(false);
  const [loadingPick6, setLoadingPick6] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [fullDrawList, setFullDrawList] = useState<ILeaderboard[] | []>([]);
  const [fullDrawCursor, setFullDrawCursor] = useState('');
  const [pick6List, setPick6List] = useState<ILeaderboard[] | []>([]);
  const [pick6Cursor, setPick6Cursor] = useState('');
  const [refreshList, setRefreshList] = useState(false);
  const [selectedGameType, setSelectedGameType] = useState('full');

  const { user } = useUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ordering = isHome || !user ? undefined : '-is_current,-is_friend';
  const first = isHome ? 5 : 10;
  const offset = isHome ? undefined : 10;

  const tableClassNames = cx(classes.table, {
    [classes.tableMobile]: isMobile,
  });

  const {
    data: leaderboardFullDrawData,
    loading: fulldrawLoading,
    total: fullDrawTotal,
    totalUsers: fullDrawTotalUsers,
    cursor: leaderboardFullDrawCursor,
    refetch: fulldrawRefetch,
    fetchMore: fulldrawFetchMore,
  } = useLeaderboard({
    gameId,
    search,
    ordering: `${
      ordering ? `${ordering},leaderboard_place` : 'leaderboard_place'
    }`,
    first,
    isAuth: !!user,
  });

  const {
    data: leaderboardPick6Data,
    loading: pick6Loading,
    total: pick6Total,
    totalUsers: pick6TotalUsers,
    cursor: leaderboardPick6Cursor,
    refetch: pick6Refetch,
    fetchMore: pick6FetchMore,
  } = useFantasyTeamLeaderboard({
    gameId,
    search,
    ordering: `${
      ordering ? `${ordering},leaderboard_place` : 'leaderboard_place'
    }`,
    first,
    isAuth: !!user,
  });

  const { addFriendMutation } = useAddFriendApi();

  const handleAddToFriends = (id: string, type: string) => {
    addFriendMutation({ gameId, friendId: id, gameType: type }).then((res) => {
      if (res?.data?.addFriendToggle?.msg) {
        enqueueSnackbar(res?.data?.addFriendToggle?.msg);
      }
      fulldrawRefetch({
        first: fullDrawList.length,
      }).then((res) => {
        if (res?.data) {
          handleFulldrawListRefresh(res.data.bracketPredictionLeaderboard);
        }
      });
      pick6Refetch({
        first: pick6List.length,
      }).then((res) => {
        if (res?.data) {
          handlePick6ListRefresh(res.data.fantasyTeamLeaderboard);
        }
      });
    });
  };

  const handleGoToDetails = (id: string, type?: string) => {
    if (type === 'full') {
      if (user) {
        navigate(
          prepareUrl(routes.user.viewPrediction.path, { predictionId: id }),
        );
      } else {
        navigate(
          prepareUrl(routes.common.viewPrediction.path, { predictionId: id }),
        );
      }
    } else {
      if (user) {
        navigate(prepareUrl(routes.user.viewFantasy.path, { fantasyId: id }));
      } else {
        navigate(prepareUrl(routes.common.viewFantasy.path, { fantasyId: id }));
      }
    }
  };

  // first loading
  useEffect(() => {
    if (
      isFirstLoading &&
      (leaderboardFullDrawData?.length || leaderboardPick6Data?.length)
    ) {
      setIsFirstLoading(false);
    }
  }, [
    gameId,
    isFirstLoading,
    leaderboardFullDrawData?.length,
    leaderboardPick6Data?.length,
  ]);

  // refresh list after refetch
  useEffect(() => {
    if (refreshList && !fulldrawLoading && !pick6Loading) {
      setFullDrawList(leaderboardFullDrawData);
      setFullDrawCursor(leaderboardFullDrawCursor ?? '');
      setPick6List(leaderboardPick6Data);
      setPick6Cursor(leaderboardPick6Cursor ?? '');
      setRefreshList(false);
    }
  }, [
    fulldrawLoading,
    leaderboardFullDrawCursor,
    leaderboardFullDrawData,
    leaderboardPick6Cursor,
    leaderboardPick6Data,
    pick6Loading,
    refreshList,
  ]);

  // fullDrawList
  const handleFulldrawListRefresh = (newRes: IDataEdges<ILeaderboard>) => {
    const newData = newRes.edges.map((edge) => edge.node);
    setFullDrawList(newData);
    setFullDrawCursor(newRes.pageInfo?.endCursor ?? '');
  };

  const handleFulldrawListMerge = (newRes: IDataEdges<ILeaderboard>) => {
    const newData = newRes.edges.map((edge) => edge.node);
    setFullDrawList((prev) => [...prev, ...newData]);
    setFullDrawCursor(newRes.pageInfo?.endCursor ?? '');
  };

  useEffect(() => {
    if (!fullDrawList.length && leaderboardFullDrawData?.length) {
      setFullDrawList(leaderboardFullDrawData);
    }
  }, [fullDrawList.length, leaderboardFullDrawData]);

  useEffect(() => {
    if (!fullDrawCursor && leaderboardFullDrawCursor) {
      setFullDrawCursor(leaderboardFullDrawCursor);
    }
  }, [fullDrawCursor, leaderboardFullDrawCursor]);

  const handleFetchMoreFullDraw = async () => {
    setLoadingFullDraw(true);
    if (
      !loadingFullDraw &&
      !fulldrawLoading &&
      offset &&
      fullDrawTotal > fullDrawList.length
    ) {
      await fulldrawFetchMore({
        variables: { first: offset, after: fullDrawCursor },
      })
        .then((res) => {
          if (res?.data) {
            handleFulldrawListMerge(res.data.bracketPredictionLeaderboard);
          }
        })
        .finally(() => setLoadingFullDraw(false));
    }
  };

  // pick6List
  const handlePick6ListRefresh = (newRes: IDataEdges<ILeaderboard>) => {
    const newData = newRes.edges.map((edge) => edge.node);
    setPick6List(newData);
    setPick6Cursor(newRes.pageInfo?.endCursor ?? '');
  };

  const handlePick6ListMerge = (newRes: IDataEdges<ILeaderboard>) => {
    const newData = newRes.edges.map((edge) => edge.node);
    setPick6List((prev) => [...prev, ...newData]);
    setPick6Cursor(newRes.pageInfo?.endCursor ?? '');
  };

  useEffect(() => {
    if (!pick6List.length && leaderboardPick6Data?.length) {
      setPick6List(leaderboardPick6Data);
    }
  }, [pick6List.length, leaderboardPick6Data]);

  useEffect(() => {
    if (!pick6Cursor && leaderboardPick6Cursor) {
      setPick6Cursor(leaderboardPick6Cursor);
    }
  }, [pick6Cursor, leaderboardPick6Cursor]);

  const handleFetchMorePick6 = async () => {
    setLoadingPick6(true);
    if (
      !loadingPick6 &&
      !pick6Loading &&
      offset &&
      pick6Total > pick6List.length
    ) {
      await pick6FetchMore({
        variables: { first: offset, after: pick6Cursor },
      })
        .then((res) => {
          if (res?.data) {
            handlePick6ListMerge(res.data.fantasyTeamLeaderboard);
          }
        })
        .finally(() => setLoadingPick6(false));
    }
  };

  const handleSearch = async (value: string) => {
    debounce(() => {
      if (search && ordering) {
        fulldrawRefetch({
          search,
          ordering,
          first,
        });

        pick6Refetch({
          search,
          ordering,
          first,
        });
      }
    }, 1000);
    setSearch(value);
    setRefreshList(true);
  };

  const fullDrawListFriendsLastIndex = fullDrawList?.filter(
    (friend) => friend.isFriend || friend.isCurrent,
  ).length;

  const pick6ListFriendsLastIndex = pick6List?.filter(
    (friend) => friend.isFriend || friend.isCurrent,
  ).length;

  const renderNoResults = () => (
    <Typography className={classes.scorePoints}>No results</Typography>
  );

  const renderTableRow = (row: ILeaderboard, index: number, type?: string) => (
    <TableRow key={`${row.id}-${index}`} className={classes.tableRow}>
      <TableCell
        className={cx(classes.tableCell, classes.tableCollWidth)}
        align="left"
      >
        <Typography variant="regular1618">#{row.leaderboardPlace}</Typography>
      </TableCell>
      <TableCell
        className={cx(classes.tableCell, classes.tableCollPadding)}
        align="left"
        onClick={() =>
          isHome || row?.isCurrent || !user
            ? undefined
            : handleAddToFriends(row.owner?.id, type === 'full' ? '1' : '2')
        }
        sx={{
          cursor: isHome || row?.isCurrent || !user ? 'inherit' : 'pointer',
        }}
      >
        {isHome || row?.isCurrent || !user ? (
          <Typography
            variant="regular1618"
            textTransform="uppercase"
            sx={{ paddingLeft: 1 }}
          >
            {row.owner?.nickname || ''}
          </Typography>
        ) : (
          <Tooltip
            title={
              !isHome && row?.isFriend
                ? 'Remove from friends'
                : 'Add to friends'
            }
          >
            <Typography
              variant="regular1618"
              textTransform="uppercase"
              sx={{ paddingLeft: 1 }}
            >
              {row.owner?.nickname || ''}
            </Typography>
          </Tooltip>
        )}
      </TableCell>
      <TableCell
        className={cx(classes.tableCell, classes.lastTableCell)}
        align="right"
        onClick={
          hasActiveRounds ? () => handleGoToDetails(row.id, type) : undefined
        }
        sx={{ cursor: 'pointer' }}
      >
        {hasActiveRounds ? (
          <Tooltip title="View board">
            <Typography className={classes.scorePoints} variant="h6">
              {formatNumberWithMask(
                (type === 'full' ? row.newPoints : row.points) || 0,
              )}
            </Typography>
          </Tooltip>
        ) : (
          <Typography className={classes.scorePoints} variant="h6">
            {formatNumberWithMask(
              (type === 'full' ? row.newPoints : row.points) || 0,
            )}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );

  const renderTableRowDivider = (type?: string) => (
    <TableRow className={classes.tableRow}>
      <TableCell
        className={cx(classes.tableCell, classes.tableCollPadding)}
        colSpan={2}
        align="left"
      >
        <Typography
          variant="semiBoldMontserrat1618"
          textTransform="uppercase"
          color="white"
          sx={{ paddingLeft: 1 }}
        >
          {type === 'friends'
            ? 'Favorites'
            : `All users: ${
                type === 'full' ? fullDrawTotalUsers : pick6TotalUsers
              }`}
        </Typography>
      </TableCell>
      <TableCell
        className={cx(classes.tableCell, classes.lastTableCell)}
        align="right"
      >
        <Typography className={classes.scorePoints} variant="h6" />
      </TableCell>
    </TableRow>
  );

  return (
    <Stack gap={3} justifyContent="center" alignItems="center">
      {!isHome && !isFirstLoading && (
        <Box
          width="100%"
          maxWidth={924}
          alignSelf="center"
          bgcolor="white"
          borderRadius="5px"
          px={2}
        >
          <Input
            placeholder="FIND NAME"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            fullWidth
            endAdornment={
              search ? (
                pick6Loading || fulldrawLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconButton color="primary" onClick={() => handleSearch('')}>
                    <CloseIcon />
                  </IconButton>
                )
              ) : null
            }
            sx={{
              fontSize: isMobile ? 18 : 24,
              p: 1,
              py: isMobile ? 0.5 : 1,
              '&:before': {
                borderBottom: 'none',
              },
            }}
          />
        </Box>
      )}
      {isFirstLoading && (pick6Loading || fulldrawLoading) ? (
        <CircularProgress size={20} />
      ) : !pick6List?.length && !fullDrawList?.length ? (
        renderNoResults()
      ) : null}

      <Stack
        width="100%"
        flexDirection="column"
        gap={3}
        justifyContent="center"
      >
        {isMobile && !(isFirstLoading && (pick6Loading || fulldrawLoading)) && (
          <Stack flexDirection="row" gap={1} justifyContent="center">
            <Button
              className={cx(classes.gameButtonStyle, {
                [classes.selectedGameButtonStyle]: selectedGameType === 'full',
              })}
              variant="outlined"
              fullWidth
              disabled={!fullDrawList?.length}
              onClick={() => setSelectedGameType('full')}
            >
              <Typography
                variant="h3"
                color={selectedGameType === 'full' ? 'inherit' : 'white'}
                textAlign="center"
                fontSize={18}
                lineHeight="22px"
                overflow="hidden"
                textOverflow="ellipsis"
                textTransform="uppercase"
                fontFamily="Montserrat-Regular"
                sx={{ textWrap: 'nowrap' }}
              >
                full draw
              </Typography>
            </Button>
            <Button
              className={cx(classes.gameButtonStyle, {
                [classes.selectedGameButtonStyle]: selectedGameType === 'pick6',
              })}
              variant="outlined"
              fullWidth
              disabled={!pick6List?.length}
              onClick={() => setSelectedGameType('pick6')}
            >
              <Typography
                variant="h3"
                color={selectedGameType === 'pick6' ? 'inherit' : 'white'}
                textAlign="center"
                fontSize={18}
                lineHeight="22px"
                textTransform="uppercase"
                overflow="hidden"
                textOverflow="ellipsis"
                fontFamily="Montserrat-Regular"
                sx={{ textWrap: 'nowrap' }}
              >
                pick6
              </Typography>
            </Button>
          </Stack>
        )}
        <Stack width="100%" flexDirection="row" gap={3} justifyContent="center">
          {!!fullDrawList?.length &&
            (!isMobile || (isMobile && selectedGameType === 'full')) && (
              <div className={tableClassNames}>
                <InfiniteScrollContainer
                  onLoadMore={handleFetchMoreFullDraw}
                  isLoading={fulldrawLoading}
                  readyElements={fullDrawList.length}
                  totalElements={fullDrawTotal}
                >
                  <TableContainer component={Paper}>
                    <Table size="medium">
                      {!isMobile && (
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell
                              className={classes.tableCell}
                              colSpan={3}
                              padding="checkbox"
                            >
                              <Typography
                                py={1}
                                pr={1}
                                variant="h4"
                                color="white"
                                textAlign="center"
                                textTransform="uppercase"
                                fontFamily="Montserrat-Regular"
                              >
                                Full draw
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        {!isHome &&
                          !!fullDrawListFriendsLastIndex &&
                          renderTableRowDivider('friends')}
                        {fullDrawList.map((row, index) =>
                          !isHome &&
                          fullDrawListFriendsLastIndex === index &&
                          fullDrawList?.length >
                            fullDrawListFriendsLastIndex ? (
                            <Fragment key={`divider-${row.id}-${index}`}>
                              {renderTableRowDivider('full')}
                              {renderTableRow(row, index, 'full')}
                            </Fragment>
                          ) : (
                            renderTableRow(row, index, 'full')
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </InfiniteScrollContainer>
              </div>
            )}

          {!!pick6List?.length &&
            (!isMobile || (isMobile && selectedGameType === 'pick6')) && (
              <div className={tableClassNames}>
                <InfiniteScrollContainer
                  onLoadMore={handleFetchMorePick6}
                  isLoading={pick6Loading}
                  readyElements={pick6List.length}
                  totalElements={pick6Total}
                >
                  <TableContainer component={Paper}>
                    <Table size="medium">
                      {!isMobile && (
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell
                              className={classes.tableCell}
                              colSpan={3}
                              padding="none"
                            >
                              <Typography
                                py={1}
                                pr={1}
                                variant="h4"
                                color="white"
                                textAlign="center"
                                textTransform="uppercase"
                                fontFamily="Montserrat-Regular"
                              >
                                Pick6
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        {!isHome &&
                          !!pick6ListFriendsLastIndex &&
                          renderTableRowDivider('friends')}
                        {pick6List.map((row, index) =>
                          !isHome &&
                          pick6ListFriendsLastIndex === index &&
                          pick6List?.length > pick6ListFriendsLastIndex ? (
                            <Fragment key={`divider-pick6-${row.id}-${index}`}>
                              {renderTableRowDivider()}
                              {renderTableRow(row, index)}
                            </Fragment>
                          ) : (
                            renderTableRow(row, index)
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </InfiniteScrollContainer>
              </div>
            )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LeaderboardList;
