import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from '../../../theme';

const useContactUsFormStyles = makeStyles((theme: Theme) => ({
  FormInputStyle: {
    marginBottom: '24px',
    background: theme.colors.white,
    '& input': {
      color: theme.colors.lightGray,
      border: 0,
    },
    '& fieldset': {
      border: 0,
    },
  },
  FormLineTextStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '96px',
  },
  FormLineStyle: {
    background: theme.colors.black,
    opacity: 0.1,
    width: '90px',
  },
  FormTitleAfter: {
    color: '#91969E',
    width: '132px',
    textAlign: 'center',
  },
  ButtonForgotPasswordStyle: {
    marginBottom: '16px',
  },
  TextAreaStyle: {
    fontFamily: fontFamilies.montserratRegular.fontFamily,
    background: theme.colors.white,
    borderRadius: '8px',
    color: '#91969E',
    fontSize: '16px',
    lineHeight: '24px',
    padding: 0,
  },
}));

export default useContactUsFormStyles;
