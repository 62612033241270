import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexDirection: 'row',
    gap: '8px',
  },
  card: {
    width: '400px',
    padding: '16px',
    fontFamily: fontFamilies.montserratRegular.fontFamily,
    background: theme.colors.white,
    borderRadius: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      cursor: 'pointer',
    },
  },
  cardBottomn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardNumberField: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    marginBottom: 10,
    '& span': {
      letterSpacing: 2,
      fontSize: 22,
      display: 'flex',
      alignItems: 'center',
    },
  },
  saveCard: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
  },
}));

export default useStyles;
