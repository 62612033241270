import useStyles from './index.styles';

import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';

interface IPaymentPopup {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const ConfirmationPopup = ({
  title,
  isOpen,
  onClose,
  onSuccess,
}: IPaymentPopup) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      onClose={() => {}}
      open={isOpen}
      disableEscapeKeyDown={true}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle className={classes.title} sx={{ p: 1 }}>
          {title ? title : 'Do you want to confirm your selection?'}
        </DialogTitle>
        <Tooltip title="Close">
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent>
        <Stack
          className={classes.buttons}
          direction={'row'}
          justifyContent={'flex-end'}
        >
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            {onSuccess ? 'No' : 'OK'}
          </Button>
          {onSuccess && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => onSuccess()}
            >
              Yes
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationPopup;
