
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useSelectStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiSelect-select': {
            padding: '8.5px 14px'
        },
    }
}));

export default useSelectStyles;