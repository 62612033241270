import { makeStyles } from "@mui/styles";
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: `min(${theme.breakpoints.values.lg}px, 100%)`,
        padding: '40px 24px',
        alignSelf: 'center',
        backgroundColor: theme.colors.whiteGray,
        overflowY: "auto",
    },
    chosenPlayersList: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '24px',
        gap: '12px 36px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
            gap: '8px',
        }
    },
    tierTitleText: {
        fontWeight: 400,
    },
    chosenPlayers: {
        backgroundColor: 'rgba(207, 207, 235, .6);',
        borderRadius: '24px',
        padding: '24px 28px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
        }
    },
    chosenPlayersTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: '10px',
    },
}));

export default useStyles;
