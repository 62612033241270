const BracketSlotIconLeft = () => {
  return (
    <svg
      width="43"
      height="25"
      viewBox="0 0 43 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43 24L9.43902 24L9.43902 12.5M43 1L9.43902 1L9.43902 12.5M9.43902 12.5L-1.97487e-06 12.5"
        stroke="#979797"
        strokeWidth="0.5"
      />
    </svg>
  );
};
export default BracketSlotIconLeft;
