import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  pageTableList: {
    backgroundColor: theme.colors.green,
    flexGrow: 1,
  },
  title: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
  },
  fullListButton: {
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: '24px',
    border: '2px solid',
    borderColor: theme.colors.darkBlue,
  },
  gameButtonStyle: {
    borderRadius: '30px',
    backgroundColor: theme.colors.darkBlue,
    borderColor: theme.colors.darkBlue,
    maxWidth: 450,
    '&:hover': {
      border: '2px solid',
      borderColor: theme.colors.darkBlue,
      backgroundColor: `${theme.colors.darkBlue}cf`,
      color: 'white',
    },
  },
  selectedGameButtonStyle: {
    borderRadius: '30px',
    backgroundColor: theme.colors.white,
    border: '2px solid',
    borderColor: theme.colors.darkBlue,
    color: theme.colors.darkBlue,
    '&:hover': {
      backgroundColor: theme.colors.white,
      border: '2px solid',
      borderColor: theme.colors.darkBlue,
      color: theme.colors.darkBlue,
    },
  },
  pageTableListMobile: {
    padding: '30px 0 0 0',
  },
}));

export default useStyles;
