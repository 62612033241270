import useStyles from './index.styles';

import React, { useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { ITournamentPlayer, TierEnum } from "models/tournamentPlayer.model";
import SelectField from "components/form/fields/SelectField";
import { useTournamentPlayersApi } from "hooks/useTournamentPlayer";
import TournamentPlayerRow from "components/pages/Admin/GamePlayers/TournamentPlayerRow";
import { Loader } from "components/common";

interface ITournamentPlayerCount {
    tier1: number
    tier2: number
    tier3: number
    tier4: number
}

interface ITournamentPlayerList {
    tournamentPlayers: Array<ITournamentPlayer>
}

const TournamentPlayerList: React.FC<ITournamentPlayerList> = ({tournamentPlayers}: ITournamentPlayerList): React.ReactElement => {
    const classes = useStyles();

    const { deleteTournamentPlayer, deleteTournamentPlayersLoading} = useTournamentPlayersApi();
    const [selectedTier, setSelectedTier] = useState<TierEnum | '0'>('0')

    const onSelectTier = (event: React.ChangeEvent<{ value: TierEnum | '0' }>) => {
        setSelectedTier(event.target.value)
    }
    const onDelete = (player: ITournamentPlayer) => {
        deleteTournamentPlayer(player.id)
    }
    const count: ITournamentPlayerCount = useMemo(() => {
        let total: ITournamentPlayerCount = {
            tier1: 0,
            tier2: 0,
            tier3: 0,
            tier4: 0,
        }
        tournamentPlayers.forEach((player) => {
            switch (player.tier) {
                case TierEnum.TIER1: total.tier1 += 1; break;
                case TierEnum.TIER2: total.tier2 += 1; break;
                case TierEnum.TIER3: total.tier3 += 1; break;
                case TierEnum.TIER4: total.tier4 += 1; break;
            }
        })
        return total
    }, [tournamentPlayers])

    const filteredPlayers: Array<ITournamentPlayer> = useMemo(() => {
        if (selectedTier === '0') return tournamentPlayers
        return tournamentPlayers.filter((player: ITournamentPlayer) => player.tier === selectedTier)
    }, [tournamentPlayers, selectedTier])

    return (
        <Stack className={classes.container}>
            <Stack className={classes.searchBox}>
                <SelectField
                    id="tier-input"
                    label="Tier"
                    name="tier"
                    size="small"
                    value={selectedTier}
                    onChange={onSelectTier}
                    options={[
                        { key: '000', label: 'Select Tier', value: '0' },
                        { key: '001', label: 'Tier 1', value: TierEnum.TIER1 },
                        { key: '002', label: 'Tier 2', value: TierEnum.TIER2 },
                        { key: '003', label: 'Tier 3', value: TierEnum.TIER3 },
                        { key: '004', label: 'Tier 4', value: TierEnum.TIER4 },
                    ]}
                />
            </Stack>
            <Stack className={classes.count}>
                <span>Total: {tournamentPlayers.length}</span>
                <span className={classes.tier1}>Tier1: {count.tier1}</span>
                <span className={classes.tier2}>Tier2: {count.tier2}</span>
                <span className={classes.tier3}>Tier3: {count.tier3}</span>
                <span className={classes.tier4}>Tier4: {count.tier4}</span>
            </Stack>
            <Stack className={classes.players}>
                {deleteTournamentPlayersLoading && <Loader />}
                {filteredPlayers.map((player) =>
                    <TournamentPlayerRow
                        key={player.id}
                        player={player}
                        onDelete={onDelete}
                    />
                )}
            </Stack>
        </Stack>
    );
};
export default TournamentPlayerList;
