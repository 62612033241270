import React, { useEffect, useMemo, useState } from 'react';
import { PaymentSystemStyles } from './index.styles';
import { Stack, Typography } from '@mui/material';
import PaymentForm from 'components/form/paymentForm';
import { Elements } from '@stripe/react-stripe-js';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { usePaymentApi, usePaymentMethods } from "hooks/usePayments";
import state from "apollo/state";
import PaymentMethodList from "components/common/PaymentMethodList";

const PaymentSystem = () => {
    const classes = PaymentSystemStyles();
    const { payments } = usePaymentMethods()
    const { makeDefaultPaymentMethod } = usePaymentApi()

    const active: string = useMemo(() => {
        return payments.find(payment => payment.isDefault)?.id || ''
    }, [payments])

    return (
        <Stack className={ classes.container }>
            <Typography variant="h1" component="h2">
                Payment Info
            </Typography>
            <Typography
                className={ classes.subtitle }
                variant="subtitle1"
                component="p"
            >
                Raqit Player
            </Typography>
            <div className={classes.content}>
                <Stack className={classes.form}>
                    <Elements stripe={ state.stripePromise() }>
                        <PaymentForm/>
                    </Elements>
                </Stack>
                <Stack className={classes.paymentMethods}>
                    <PaymentMethodList
                        paymentMethods={payments}
                        setIsSelected={makeDefaultPaymentMethod}
                        selected={active}
                    />
                </Stack>
            </div>
        </Stack>
    );
};

export default PaymentSystem;
