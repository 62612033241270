import { useMutation, useQuery } from '@apollo/client';

import {
    ITournamentPlayer, ITournamentPlayerFormValues,
    TournamentPlayerParams
} from 'models/tournamentPlayer.model';
import { TournamentPlayerAPI } from 'api';
import { IEdgeNode } from "models/api.model";

export const useTournamentPlayers = (params?: TournamentPlayerParams) => {
    const { data, loading, error } = useQuery(TournamentPlayerAPI.tournamentPlayerList(), {
        variables: params,
    });

    let tournamentPlayers: Array<ITournamentPlayer> = [];

    if (data?.tournamentPlayerList) {
        tournamentPlayers = data.tournamentPlayerList.edges.map((edge: IEdgeNode<ITournamentPlayer>) => edge.node);
    }

    return { tournamentPlayers, tournamentPlayersLoading: loading, tournamentPlayersError: error };
}

export const useTournamentPlayersApi = () => {
    const [create, { loading: addTournamentPlayersLoading }] = useMutation(
        TournamentPlayerAPI.adminCreateTournamentPlayers(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'tournamentPlayerList' });
                // garbage collector
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );
    const [archive, { loading: deleteTournamentPlayersLoading }] = useMutation(
        TournamentPlayerAPI.adminDeleteTournamentPlayer(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'tournamentPlayerList' });
                // garbage collector
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );

    const addTournamentPlayers = async ({ playerIds, tier, gameId }: ITournamentPlayerFormValues) => {
        return create({ variables: { input: {playerIds, tier, gameId }} })
    };

    const deleteTournamentPlayer = (tournamentPlayerId: string) => {
        return archive({ variables: { input: {tournamentPlayerId} } })
    };

    return { addTournamentPlayers, deleteTournamentPlayer, addTournamentPlayersLoading, deleteTournamentPlayersLoading };

}
