import useFantasyStyles from './index.styles';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Container, Stack, Typography } from '@mui/material';
import { FantasyPick, TieBreaker } from 'components/common';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { ITournamentPlayer, TierEnum } from 'models/tournamentPlayer.model';
import { useTournamentPlayers } from 'hooks/useTournamentPlayer';
import PlayerRow from './PlayerRow';
import { useFantasy, usePredictionApi } from 'hooks/usePrediction';
import { useSnackbar } from 'notistack';
import { AuthComponent } from 'constants/auth';
import { useUser } from 'hooks/useUser';
import AuthContext from 'components/Route/AuthContext';
import FantasyLeaderboard from 'components/common/FantasyLeaderboard';
import ConfirmationPopup from '../../common/ConfirmationPopup';
import { TieBreakerType } from '../FillBrackets';
import { useLocation, useNavigate } from 'react-router';

const FANTASY_LENGTH = 6;

const Fantasy = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const gameId = params.gameId || '';
  const fantasyId = params.fantasyId || '';
  const { user } = useUser();
  const { setAuth } = useContext(AuthContext);

  const { createFantasy, updateFantasy } = usePredictionApi();
  const { pick6: pick6prediction } = useFantasy(fantasyId);
  const { tournamentPlayers } = useTournamentPlayers({ gameId });
  const classes = useFantasyStyles();
  const isMobile = isMobileScreen();

  const [tieBreakerPopupOpened, setTieBreakerPopupOpened] =
    useState<boolean>(false);
  const [tieBreakerValue, setTieBreakerValue] = useState<TieBreakerType | null>(
    null,
  );

  const [selectedPlayers, setSelectedPlayers] = React.useState<
    Array<ITournamentPlayer>
  >([]);

  const selectedPlayerIds = useMemo(
    () => selectedPlayers.map((player) => player.id),
    [selectedPlayers],
  );

  useMemo(() => {
    const players =
      pick6prediction?.fantasyPlayers?.map((player) => player.player) || [];
    setSelectedPlayers(players);
  }, [pick6prediction?.fantasyPlayers]);

  const [paymentMethodPopupOpened, setPaymentMethodPopupOpened] =
    useState<boolean>(false);

  const createFantasyTeam = () => {
    return createFantasy(
      gameId,
      selectedPlayers.map((player) => player.id),
      tieBreakerValue?.tieValue || 0,
      tieBreakerValue?.tieDuration || undefined,
      tieBreakerValue?.tieScore || undefined,
    )
      .then((response) => {
        if (response?.success) {
          enqueueSnackbar('Pick6 created', { variant: 'success' });
          navigate(
            `${location.pathname?.replace(
              '/create',
              `/${response?.fantasyTeam?.id}`,
            )}`,
            {
              replace: true,
            },
          );
        } else {
          enqueueSnackbar(
            response?.errors ? response.errors[0].message : 'Team not created',
            { variant: 'error' },
          );
        }
      })
      .catch(() => {
        enqueueSnackbar('Team not created', { variant: 'warning' });
      });
  };

  const updateFantasyTeam = () => {
    return updateFantasy(
      fantasyId,
      selectedPlayers.map((player) => player.id),
      tieBreakerValue?.tieValue || 0,
      tieBreakerValue?.tieDuration || undefined,
      tieBreakerValue?.tieScore || undefined,
    )
      .then((response) => {
        if (response?.success) {
          enqueueSnackbar('Pick6 updated', { variant: 'success' });
        } else {
          enqueueSnackbar(
            response?.errors ? response.errors[0].message : 'Team not created',
            { variant: 'error' },
          );
        }
      })
      .catch(() => {
        enqueueSnackbar('Team not created', { variant: 'warning' });
      });
  };

  const onPaymentSuccessResponse = (): void => {
    if (fantasyId && !fantasyId?.includes('create')) {
      updateFantasyTeam().then(() => {
        setPaymentMethodPopupOpened(false);
      });
    } else {
      createFantasyTeam().then(() => {
        setPaymentMethodPopupOpened(false);
      });
    }
  };

  const openPaymentWindow = (): void => {
    setPaymentMethodPopupOpened(true);
  };

  const playersIsSelectable = (tier: TierEnum): boolean => {
    if (selectedPlayers.length >= FANTASY_LENGTH) return false;
    if ([TierEnum.TIER1, TierEnum.TIER2, TierEnum.TIER3].includes(tier)) {
      return (
        selectedPlayers.filter((player) => player.tier === tier).length < 2
      );
    }
    return true;
  };
  const tier1Players: Array<ITournamentPlayer> = useMemo(() => {
    return tournamentPlayers
      .filter((player: ITournamentPlayer) => player.tier === TierEnum.TIER1)
      .map((player) => {
        return {
          ...player,
          isSelected: selectedPlayerIds.indexOf(player.id) !== -1,
        };
      });
  }, [tournamentPlayers, selectedPlayerIds]);

  const tier2Players: Array<ITournamentPlayer> = useMemo(() => {
    return tournamentPlayers
      .filter((player) => player.tier === TierEnum.TIER2)
      .map((player) => {
        return {
          ...player,
          isSelected: selectedPlayerIds.indexOf(player.id) !== -1,
        };
      });
  }, [tournamentPlayers, selectedPlayerIds]);

  const tier3Players: Array<ITournamentPlayer> = useMemo(() => {
    return tournamentPlayers
      .filter((player) => player.tier === TierEnum.TIER3)
      .map((player) => {
        return {
          ...player,
          isSelected: selectedPlayerIds.indexOf(player.id) !== -1,
        };
      });
  }, [tournamentPlayers, selectedPlayerIds]);

  const tier4Players: Array<ITournamentPlayer> = useMemo(() => {
    return tournamentPlayers
      .filter((player) => player.tier === TierEnum.TIER4)
      .map((player) => {
        return {
          ...player,
          isSelected: selectedPlayerIds.indexOf(player.id) !== -1,
        };
      });
  }, [tournamentPlayers, selectedPlayerIds]);

  const saveFantasyTeam = () => {
    if (!user) {
      setAuth(true, AuthComponent.LOGIN);
    } else {
      if (!tieBreakerValue) {
        setTieBreakerPopupOpened(true);
      } else {
        openPaymentWindow();
      }
    }
  };

  const onSelectTieBreaker = (value: TieBreakerType): void => {
    setTieBreakerValue(value);
    setTieBreakerPopupOpened(false);
    if (!user) {
      setAuth(true, AuthComponent.LOGIN);
    } else {
      openPaymentWindow();
    }
  };

  const onCloseTieBreaker = () => {
    setTieBreakerPopupOpened(false);
  };

  const onUnSelectPlayer = (player: ITournamentPlayer) => {
    setSelectedPlayers((selectedPlayers) =>
      selectedPlayers.filter(
        (selectedPlayer) => selectedPlayer.id !== player.id,
      ),
    );
  };

  const onSelectPlayer = (player: ITournamentPlayer) => {
    //if (player.isSelected) return onUnSelectPlayer(player);
    if (
      selectedPlayers.length >= FANTASY_LENGTH ||
      selectedPlayers.find((selectedPlayer) => selectedPlayer.id === player.id)
    )
      return;
    setSelectedPlayers((selectedPlayers) => [...selectedPlayers, player]);
  };

  const FantasyClassNames = cx(classes.Fantasy, {
    [classes.FantasyMobile]: isMobile,
  });

  return (
    <div className={FantasyClassNames}>
      <FantasyLeaderboard gameId={gameId || ''} />
      <TieBreaker
        isOpen={tieBreakerPopupOpened}
        onSelect={onSelectTieBreaker}
        onClose={onCloseTieBreaker}
        tieValue={pick6prediction?.tieBreaker}
        tieDuration={pick6prediction?.duration}
        tieScore={pick6prediction?.score}
      />
      <ConfirmationPopup
        isOpen={paymentMethodPopupOpened}
        onClose={() => setPaymentMethodPopupOpened(false)}
        onSuccess={onPaymentSuccessResponse}
      />
      <Container maxWidth="lg">
        <div className={classes.ChosenPlayers}>
          <div className={classes.ChosenPlayersTitle}>
            <Typography className={classes.ChosenPlayersTitleText} variant="h4">
              Chosen players
            </Typography>
            <Stack flexDirection="row" gap={1}>
              <Button
                className={classes.applyBtn}
                variant="contained"
                color="primary"
                disabled={selectedPlayers.length !== FANTASY_LENGTH}
                onClick={() => setTieBreakerPopupOpened(true)}
              >
                Tiebraker
              </Button>
              <Button
                className={classes.applyBtn}
                variant="contained"
                color="primary"
                disabled={selectedPlayers.length !== FANTASY_LENGTH}
                onClick={saveFantasyTeam}
              >
                Finish choosing a team
              </Button>
            </Stack>
          </div>
          <div className={classes.chosenPlayersList}>
            {selectedPlayers.map((player) => (
              <FantasyPick
                key={`tier-selected-${player.id}`}
                player={player}
                onSelectPlayer={onUnSelectPlayer}
                isSelectable={true}
                isActive={true}
              />
            ))}
          </div>
        </div>

        <div className={classes.tier}>
          <div className={classes.TierTitle}>
            <Typography className={classes.ChosenPlayersTitleText} variant="h4">
              Tier 1
            </Typography>
            <Typography variant="subtitle2">Max picks - 2</Typography>
          </div>
          <div className={classes.FantasyAvatarList}>
            {tier1Players.map((player) => (
              <FantasyPick
                key={`tier-1-${player.id}`}
                player={player}
                isSelectable={playersIsSelectable(TierEnum.TIER1)}
                isSelected={selectedPlayerIds.includes(player.id)}
                onSelectPlayer={onSelectPlayer}
                onUnselectPlayer={onUnSelectPlayer}
              />
            ))}
          </div>
        </div>
        <div className={classes.tier}>
          <div className={classes.TierTitle}>
            <Typography className={classes.ChosenPlayersTitleText} variant="h4">
              Tier 2
            </Typography>
            <Typography variant="subtitle2">Max picks - 2</Typography>
          </div>
          <div className={classes.FantasyAvatarList}>
            {tier2Players.map((player) => (
              <FantasyPick
                key={`tier-2-${player.id}`}
                player={player}
                isSelectable={playersIsSelectable(TierEnum.TIER2)}
                isSelected={selectedPlayerIds.includes(player.id)}
                onSelectPlayer={onSelectPlayer}
                onUnselectPlayer={onUnSelectPlayer}
              />
            ))}
          </div>
        </div>
        <div className={classes.tier}>
          <div className={classes.TierTitle}>
            <Typography className={classes.ChosenPlayersTitleText} variant="h4">
              Tier 3
            </Typography>
            <Typography variant="subtitle2">Max picks - 2</Typography>
          </div>
          <div className={classes.FantasyAvatarList}>
            {tier3Players.map((player) => (
              <FantasyPick
                key={`tier-3-${player.id}`}
                player={player}
                isSelectable={playersIsSelectable(TierEnum.TIER3)}
                isSelected={selectedPlayerIds.includes(player.id)}
                onSelectPlayer={onSelectPlayer}
                onUnselectPlayer={onUnSelectPlayer}
              />
            ))}
          </div>
        </div>

        <Typography className={classes.PlayersTitle} variant="h4">
          List of Tier 4 players
        </Typography>
        <Stack className={classes.tier4Rows}>
          {tier4Players.map((player) => (
            <PlayerRow
              key={player.id}
              isSelectable={playersIsSelectable(TierEnum.TIER4)}
              isSelected={selectedPlayerIds.includes(player.id)}
              onSelect={onSelectPlayer}
              onUnselect={onUnSelectPlayer}
              player={player}
            />
          ))}
        </Stack>
      </Container>
    </div>
  );
};
export default Fantasy;
