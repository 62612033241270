import { IconButton } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

import useScrollPosition from 'hooks/useScrollPosition';

import useStyles from './index.styles';
import useIsMobileScreen from 'hooks/useIsMobile';

const ButtonToTop = () => {
  const classes = useStyles();
  const isMobile = useIsMobileScreen();
  const scrollPosition = useScrollPosition();

  return scrollPosition > 300 ? (
    <IconButton
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      size="large"
      className={classes.scrollToTopButton}
      style={{
        left: isMobile ? '86%' : '93%',
      }}
    >
      <ArrowUpward />
    </IconButton>
  ) : null;
};

export default ButtonToTop;
