import React, { useMemo, useRef } from 'react';
import { TableStyles } from './index.styles';

import { Grid, GridSize, IconButton, Stack, Typography } from '@mui/material';
import {
  TableDataType,
  TableRowType,
  TableRowValueType,
} from 'types/table.types';
import cx from 'classnames';
import { PageInfoType } from 'models/api.model';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { RenderType } from 'types/common.type';

interface TableProps {
  rows?: Array<TableRowValueType>;
  isLoading?: boolean;
  columns: Array<TableRowType>;
  headerClassNames?: string;
  rowClassNames?: string;
  rowClassName?: (id: string) => string;
  onCellClicked?: (row: TableRowValueType, action?: string) => void;
  pageInfo?: PageInfoType;
  getNextPage?: () => void;
  getPrevPage?: () => void;
  onLazyLoading?: () => void;
  hasNextPage?: boolean;
  noRowsTitle?: string;
}

const Table = (props: TableProps) => {
  const {
    rows,
    isLoading,
    columns,
    headerClassNames = '',
    rowClassNames = '',
    rowClassName,
    onCellClicked,
    pageInfo,
    getNextPage,
    getPrevPage,
    onLazyLoading,
    hasNextPage,
    noRowsTitle,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<HTMLDivElement | null>(null);

  const onInfinityLoading = () => {
    const isBottom = () => {
      if (gridRef.current) {
        return (
          gridRef.current?.scrollHeight -
            (gridRef.current.scrollTop + gridRef.current?.clientHeight) <=
          50
        );
      }
      return false;
    };
    if (hasNextPage && isBottom() && !isLoading && onLazyLoading) {
      onLazyLoading();
    }
  };

  const columnsSize = useMemo(() => {
    const total = columns.reduce((result, current) => {
      return (result += current?.flex || 1);
    }, 0);
    return {
      total: total * 10,
      columns: columns.map((column) => (column?.flex || 1) * 10),
    };
  }, [columns]);

  const classes = TableStyles();

  const getColumnsWidth = (): number => columnsSize.total || 12;

  const getColumnWidth = (id: number): GridSize => {
    return (columnsSize.columns[id] as GridSize) || 10;
  };

  const renderCell = (
    row: Record<string, unknown>,
    column: TableRowType,
  ): JSX.Element | string | number => {
    if (typeof column.renderCell === 'function') {
      return column.renderCell({
        row,
        onCellClicked,
      } as TableDataType<unknown>);
    }
    if (column.field && row.hasOwnProperty(column.field)) {
      const value = row[column.field];

      if (typeof value === 'string' || typeof value === 'number') {
        return value;
      }
    }
    return '';
  };

  const getCellClassNames = (
    commonClass: string,
    column: TableRowType,
  ): string => {
    return cx(commonClass, {
      [classes.tableCellCenter]:
        column && column.align && column.align === 'center',
      [classes.tableCellRight]:
        column && column.align && column.align === 'right',
    });
  };

  const headerClasses = cx({
    [classes.tableHead]: true,
    [headerClassNames]: !!headerClassNames,
  });

  const rowClasses = cx(classes.tableRow, {
    [rowClassNames]: !!rowClassNames,
  });
  const getRowClassName = (id: string): string => {
    if (rowClassName) {
      return `${rowClasses} ${rowClassName(id)}`;
    } else {
      return rowClasses;
    }
  };
  const renderContent = () => {
    let renderedRows: Array<RenderType> = [];
    if (rows && rows.length) {
      renderedRows = rows.map((row, rowKey) => (
        <Grid
          key={`row-${rowKey}`}
          className={getRowClassName(row.id)}
          container
          columns={getColumnsWidth()}
          spacing={0}
        >
          {columns.map((column, cellKey) => (
            <Grid
              key={`cell-${rowKey}-${cellKey}`}
              className={getCellClassNames(classes.tableCell, column)}
              item
              xs={getColumnWidth(cellKey)}
            >
              {renderCell(row, column)}
            </Grid>
          ))}
        </Grid>
      ));
    }
    if (isLoading) {
      renderedRows.push(
        <Grid
          key="loading-row"
          className={rowClasses}
          container
          item
          spacing={0}
        >
          <Grid className={classes.tableCell} item xs={12}>
            Is Loading
          </Grid>
        </Grid>,
      );
    }
    if (!(rows && rows.length) && !isLoading) {
      renderedRows.push(
        <Grid
          key="not-found-row"
          className={rowClasses}
          container
          item
          spacing={0}
        >
          <Grid className={classes.tableCell} item xs={12}>
            {noRowsTitle ? noRowsTitle : 'Items not found'}
          </Grid>
        </Grid>,
      );
    }
    return renderedRows;
  };

  return (
    <Stack ref={ref} className={classes.root}>
      <Grid className={classes.tableRoot} container spacing={0}>
        <Grid
          className={headerClasses}
          columns={getColumnsWidth()}
          container
          item
          spacing={0}
        >
          {columns.map((column, id) => (
            <Grid
              key={`head-${id}`}
              item
              className={getCellClassNames(classes.headerCell, column)}
              xs={getColumnWidth(id)}
            >
              <Typography variant="tableHead" noWrap>
                {column.headerName}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Stack
          className={classes.grid}
          ref={gridRef}
          onScroll={onInfinityLoading}
        >
          {renderContent()}
        </Stack>
        {(pageInfo?.hasNextPage || pageInfo?.hasPreviousPage) && (
          <div className={classes.paginationRow}>
            {!onLazyLoading && (
              <>
                <IconButton onClick={() => getPrevPage && getPrevPage()}>
                  <ChevronLeft />
                </IconButton>
                <IconButton onClick={() => getNextPage && getNextPage()}>
                  <ChevronRight />
                </IconButton>
              </>
            )}
          </div>
        )}
      </Grid>
    </Stack>
  );
};

export default Table;
