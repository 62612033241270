import {makeStyles} from "@mui/styles";
import {Theme} from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
    },
    tier: {
        backgroundColor: 'white',
        borderRadius: '24px',
        marginTop: '31px',
        padding: '24px 28px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
        }
    },
    tierPlayers: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '24px',
        gap: '12px 36px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
            gap: '8px',
        }
    },
    tier4Players: {
      marginTop: '24px',
    },
    tier4Rows: {
        maxHeight: '400px',
        overflowY: 'auto',
        gap: '8px',
    },
    tierTitle: {
        justifyContent: "space-between",
    },
    tierTitleText: {
        fontWeight: 400,
    },
}));

export default useStyles;
