import { useNavigate } from 'react-router';
import { Link, Stack, Typography } from '@mui/material';

import { routes } from 'constants/routes';
import AppLogo from 'assets/images/AppLogo';
import isMobileScreen from 'hooks/useIsMobile';

import cx from 'classnames';
import usePageFooterStyles from './PageFooter.styles';

const PageFooter = () => {
  const navigate = useNavigate();
  const classes = usePageFooterStyles();
  const isMobile = isMobileScreen();

  const PageFooterClassNames = cx(classes.PageFooter, {
    [classes.PageFooterMobile]: isMobile,
  });
  const PageFooterEmailClassNames = cx(classes.PageFooterEmail, {
    [classes.PageFooterEmailMobile]: isMobile,
  });
  return (
    <div>
      <div className={PageFooterClassNames}>
        <Link onClick={() => navigate(routes.common.home.path)}>
          <AppLogo />
        </Link>
        <div className={classes.PageFooterSocNet}></div>
        <Stack flexDirection="row" m={1}>
          <Link
            className={PageFooterEmailClassNames}
            href="mailto:support@raqit.com"
          >
            <Typography variant="body2">
              Support Email: support@raqit.com
            </Typography>
          </Link>
          <Link href="/">
            <Typography variant="body2">Terms & Conditions</Typography>
          </Link>
        </Stack>
      </div>
      {/* <div className={classes.copyright}>
        <Typography className={classes.copyrightText}>
          Ⓒ Copyright 2023 Raqit.com
        </Typography>
      </div>
      <Link
        className={PageFooterEmailClassNames}
        href="mailto:companymail@gmail.com"
      >
        <Typography variant="body2">companymail@gmail.com</Typography>
      </Link>
      <Link href="tel:+37771234567">
        <Typography variant="body2">+3 (777) 123-45-67</Typography>
      </Link> */}
    </div>
  );
};
export default PageFooter;
