import useStyles from './index.styles';

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import PlayerForm from 'components/form/PlayerForm';
import { IPlayerFormValues } from "components/form/PlayerForm/index.schema";
import { Loader } from "components/common";

interface ManagePlayersProps {
    player?: IPlayerFormValues;
    isLoading?: boolean;
    title: string;
    open: boolean;
    handleClose: () => void;
}

const ManagePlayers: React.FC<ManagePlayersProps> = ({
    player,
    isLoading,
    title,
    open,
    handleClose,

}: ManagePlayersProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open}>
      <Stack
        direction="row"
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1 }}
      >
          {isLoading && <Loader />}
          <DialogTitle sx={{ p: 1 }}>{title}</DialogTitle>
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent>
        <PlayerForm onClose={handleClose} player={player} isLoading={isLoading}/>
      </DialogContent>
    </Dialog>
  );
};
export default ManagePlayers;
