import { MyBracketsStyles } from './index.styles';
import React from 'react';

import { Box, Stack, Tab, Typography } from '@mui/material';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PickSixTab from './PickSixTab';
import BracketTab from './BracketTab';

enum PageTabs {
  BRACKETS = '0',
  PICK_SIX = '1',
}

const MyBrackets = () => {
  const classes = MyBracketsStyles();
  const isMobile = isMobileScreen();
  const [tabValue, setTabValue] = React.useState<PageTabs>(PageTabs.BRACKETS);

  const handleTabChange = (
    event: React.SyntheticEvent,
    activeTab: PageTabs,
  ) => {
    setTabValue(activeTab);
  };

  const MyBracketsHeadTextClassNames = cx(classes.MyBracketsHeadtext, {
    [classes.MyBracketsHeadtextMobile]: isMobile,
  });

  return (
    <Stack className={classes.MyBrackets}>
      <TabContext value={tabValue}>
        <Box className={classes.tabsHeader}>
          <Typography className={MyBracketsHeadTextClassNames} variant="h2">
            View Draw
          </Typography>
          <TabList
            className={classes.tabHeaderLabel}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            onChange={handleTabChange}
            aria-label="lab API tabs example"
          >
            <Tab label="Pick6" value={PageTabs.PICK_SIX} />
            <Tab label="Full Draw" value={PageTabs.BRACKETS} />
          </TabList>
        </Box>
        <TabPanel className={classes.tabContent} value={PageTabs.PICK_SIX}>
          <PickSixTab />
        </TabPanel>
        <TabPanel className={classes.tabContent} value={PageTabs.BRACKETS}>
          <BracketTab />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default MyBrackets;
