import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '40px',
        height: '40px',
        outline: `3px solid ${theme.colors.white}`,
        borderRadius: '50%',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '0 0 auto',
        margin: '0 auto',
        '& svg': {
            width: '40px',
            height: '40px',
            fill: theme.colors.lightGray
        }
    },
    playerAvatar: {
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',
    },
}));

export default useStyles;
