const LeaderBoardIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_213_1398)">
        <path
          d="M6.99499 3.17955L7.14124 4.02997L6.37749 3.62913L5.61374 4.02997L5.75999 3.17955L5.14069 2.5783L5.99471 2.45372L6.37749 1.67914L6.76027 2.45372L7.61429 2.5783L6.99499 3.17955Z"
          fill="#FFDE14"
        />
        <path
          d="M9.95248 0.722221C9.95248 0.274444 9.93984 0 9.93984 0H2.81513C2.81513 0 2.79707 0.274444 2.80249 0.722221H0.978882V1.08333C0.978882 4.05708 3.60957 5.19277 3.63666 5.20541L3.65832 5.15485C4.08624 6.09374 4.72721 6.9261 5.67332 7.40276L5.74012 7.41901V8.76235L5.37901 8.84721V9.74998H7.36512V8.84721L7.00401 8.76235V7.41901L7.07262 7.40276C8.01151 6.93332 8.64706 6.11721 9.07498 5.19457V5.20541C9.1147 5.19277 11.9928 4.05708 11.9928 1.08333V0.722221H9.95248ZM1.33999 1.26389H2.81513C2.8575 2.44193 3.07911 3.60663 3.47235 4.71791C2.84957 4.37454 2.32604 3.87614 1.95245 3.271C1.57887 2.66587 1.3679 1.9745 1.33999 1.26389ZM7.14123 4.02999L6.37748 3.62916L5.61374 4.02999L5.75998 3.17958L5.14068 2.57833L5.99471 2.45375L6.37748 1.67916L6.75665 2.45375L7.61068 2.57833L6.99137 3.17958L7.14123 4.02999ZM9.28442 4.70527C9.67529 3.59792 9.89508 2.43747 9.93623 1.26389H11.6009C11.5143 3.29513 9.99401 4.33333 9.28442 4.70527Z"
          fill="#FFDF18"
        />
        <path
          d="M8.65971 10.2916H3.96527V11.375H8.65971V10.2916Z"
          fill="#FFDF18"
        />
        <path d="M9.56248 11.9166H3.24304V13H9.56248V11.9166Z" fill="#FFDF18" />
        <path
          d="M7.0979 3.43052L7.26943 4.42358L6.37749 3.95594L5.48554 4.42358L5.65707 3.43052L4.93304 2.72816L5.93152 2.58372L6.37749 1.67914L6.82526 2.58372L7.82193 2.72816L7.0979 3.43052Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_213_1398">
          <rect
            width="11.0211"
            height="13"
            fill="white"
            transform="translate(0.978882)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LeaderBoardIcon;
