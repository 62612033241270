import React, { useCallback, useState } from 'react';

import useStyles from './index.styles';

import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import tableConfig from 'config/table.config';
import { Table } from 'components/common';
import { Box, Button, Container, Stack } from '@mui/material';
import { useTable } from 'hooks/useTable';
import { TableQueryInfoType, TableRowValueType } from 'types/table.types';
import ManagePlayers from './ManagePlayers';
import EditPlayer from 'components/pages/Admin/Players/EditPlayer';

interface PlayersProps {}

const Players: React.FC<PlayersProps> =
  ({}: PlayersProps): React.ReactElement => {
    const classes = useStyles();
      console.log('PLAYERS');
      const {
      rows,
      pageInfo,
      dataIsLoading,
      dataError,
      setSearch,
      onLazyLoading
    } = useTable(tableConfig.adminPlayers as TableQueryInfoType, {ordering: ['first_name','last_name']});

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [playerId, setPlayerId] = useState<string>('');

    const onAddPlayer = () => {
      setPlayerId('');
      setIsOpen(true);
    };

    const onCellClicked = (player: TableRowValueType): void => {
      setPlayerId(player.id);
      setIsOpen(true);
    };

    const renderPlayers = useCallback(() => {
      return (
        <Table
          rows={rows}
          isLoading={dataIsLoading}
          columns={tableConfig.adminPlayers.columns}
          onCellClicked={onCellClicked}
          headerClassNames={classes.tableHeader}
          pageInfo={pageInfo}
          onLazyLoading={onLazyLoading}
        />
      );
    }, [rows, dataIsLoading]);

    return (
      <Stack className={classes.container}>
        {playerId ? (
          <EditPlayer
            id={playerId}
            title="Edit Player"
            open={isOpen}
            handleClose={() => setIsOpen(false)}
          />
        ) : (
          <ManagePlayers
            title="Add Player"
            open={isOpen}
            handleClose={() => setIsOpen(false)}
          />
        )}
      <div className={classes.header}>
        <Typography variant="h1">Players List</Typography>
        <Box className={classes.searchBox}>
          <Input
            placeholder="search"
            type="search"
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </Box>
        <Button variant="contained" color="primary" onClick={onAddPlayer}>
          Add Player
        </Button>
      </div>
      {dataError && (
        <Typography
          variant="h3"
          display="block"
          component="div"
          gutterBottom
        >
          {dataError.message}
        </Typography>
      )}
      {renderPlayers()}
      </Stack>
    );
  };
export default Players;
