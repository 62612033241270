import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { orange, winnerColor } from 'theme';

const BracketStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
  },
  tableHeader: {
    '& .MuiGrid-root': {
      color: theme.colors.black,
      opacity: 0.6,
    },
  },
  leaderRow: {
    outline: `2px solid ${winnerColor}`,
    outlineOffset: '-2px',
  },
}));

export { BracketStyles };
