import useStyles from './index.styles';

import { Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useUser } from 'hooks/useUser';
import AuthContext from 'components/Route/AuthContext';
import { AuthComponent } from 'constants/auth';
import { Popover, ProfileDropdown } from 'components/common/index';

import cx from 'classnames';

interface ILoginButton {
  onMenuClicked: () => void;
  color?: string;
}
const LoginButton = ({ onMenuClicked, color }: ILoginButton) => {
  const classes = useStyles();
  const { user } = useUser();
  const { setAuth } = useContext(AuthContext);

  const [popoverAnchor, setPopoverAnchor] = useState<
    (EventTarget & HTMLElement) | null
  >(null);

  const handleSignIn = () => {
    onMenuClicked();
    setAuth(true, AuthComponent.LOGIN);
  };

  const handleClosePopover = () => {
    onMenuClicked();
    setPopoverAnchor(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  return user ? (
    <>
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
      >
        <ProfileDropdown onLinkClick={handleClosePopover} />
      </Popover>
      <Button
        className={cx(classes.logoutButton, classes.buttonTitle)}
        variant="outlined"
        color={color ? undefined : 'primary'}
        onClick={handleOpenPopover}
        sx={{
          borderColor: color ? '#FFFFFF80' : 'inherit',
          color: color ? 'white' : 'inherit',
        }}
      >
        Hello, {user.nickname}
      </Button>
    </>
  ) : (
    <Button
      className={classes.buttonTitle}
      variant="outlined"
      color={color ? undefined : 'primary'}
      onClick={handleSignIn}
      sx={{
        borderColor: color ? '#FFFFFF80' : 'inherit',
        color: color ? 'white' : 'inherit',
      }}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
