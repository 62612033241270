import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const ButtonGamePageStyles = makeStyles((theme: Theme) => ({
    ButtonGamePage: {
        display: 'flex',
        alignItems: 'center',
        margin: "0 -12px",
        "& > h6": {
            margin: "0 12px",
        }
    },
    ButtonGamePageText: {
        color: theme.colors.redError,
        fontWeight: 500,
    },
    ButtonGamePageBtn: {
        lineHeight: '18px',
        fontWeight: 500,
        padding: '7px 16px'
    },
}));

export {ButtonGamePageStyles};
