import useStyles from './index.styles';

import React from 'react';
import Stack from '@mui/material/Stack';
import { Button, useTheme } from "@mui/material";
import { ITournamentPlayer, TierEnum } from "models/tournamentPlayer.model";
import PlayerAvatar from "components/common/PlayerAvatar";
import cx from "classnames";

interface IPlayerRow {
    player: ITournamentPlayer;
    isSelected: boolean
    isSelectable: boolean
    onSelect: (player: ITournamentPlayer) => void
    onUnselect: (player: ITournamentPlayer) => void
}

const PlayerRow: React.FC<IPlayerRow> = ({
                                             player, onSelect, onUnselect, isSelected, isSelectable
                                         }: IPlayerRow): React.ReactElement => {
    const classes = useStyles();

    const avatarClassNames = cx(classes.avatar, {
        [classes.inactive]: player.isRetired,
    });

    return (
        <Stack className={classes.container}>
            <Stack className={classes.profile}>
                <Stack className={avatarClassNames}><PlayerAvatar player={player.player}/></Stack>
                <Stack>{player.player.firstName} {player.player.lastName}</Stack>
            </Stack>
            <Stack className={classes.buttons}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={(player.isRetired && !isSelected) || (!isSelectable && !isSelected)}
                    onClick={() => isSelected ? onUnselect(player) : onSelect(player)}
                >
                    {isSelected ? 'Unselect' : 'Select'}
                </Button>
            </Stack>
        </Stack>
    );
};
export default PlayerRow;
