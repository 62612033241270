import useNotFoundPageStyles from "./index.styles";
import React from "react";
import { Typography } from "@mui/material";

function NotFound() {
  const classes = useNotFoundPageStyles();
  return <div className={classes.container}>
    <Typography variant="h1">Page not found</Typography>
  </div>
}

export default NotFound;
