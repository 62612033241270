import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '0 8px',
        padding: '8px',
        minHeight: '40px',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF'
    },
}));

export default useStyles;
