import React from 'react';
import { TextField as MaterialTextField, TextFieldProps } from '@mui/material';

const DatePicker = (props: TextFieldProps) => {
    return (
        <MaterialTextField
            type="date"
            InputLabelProps={{
                shrink: false,
            }}
            FormHelperTextProps={{
                variant: 'standard',
            }}
            fullWidth={true}
            variant={'outlined'}
            autoComplete={'off'}
            {...props}
        />
    );
};

export default DatePicker;
