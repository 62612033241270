import SpaceGroteskLight from 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Light.ttf';
import SpaceGroteskRegular from 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf';
import SpaceGroteskMedium from 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Medium.ttf';
import SpaceGroteskSemiBold from 'assets/fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.ttf';
import SpaceGroteskBold from 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf';

import MontserratLight from 'assets/fonts/Montserrat/Montserrat-Light.ttf';
import MontserratRegular from 'assets/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratMedium from 'assets/fonts/Montserrat/Montserrat-Medium.ttf';
import MontserratSemiBold from 'assets/fonts/Montserrat/Montserrat-SemiBold.ttf';
import MontserratBold from 'assets/fonts/Montserrat/Montserrat-Bold.ttf';

import BebasNeueRegular from 'assets/fonts/BebasNeue/BebasNeue-Regular.ttf';

export const spaceGroteskLight = {
  fontFamily: 'SpaceGrotesk-Light',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
        local('SpaceGrotesk'),
        local('SpaceGrotesk-Light'),
        url(${SpaceGroteskLight}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const spaceGroteskRegular = {
  fontFamily: 'SpaceGrotesk-Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
        local('SpaceGrotesk'),
        local('SpaceGrotesk-Regular'),
        url(${SpaceGroteskRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const spaceGroteskMedium = {
  fontFamily: 'SpaceGrotesk-Medium',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
        local('SpaceGrotesk'),
        local('SpaceGrotesk-Medium'),
        url(${SpaceGroteskMedium}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const spaceGroteskSemiBold = {
  fontFamily: 'SpaceGrotesk-SemiBold',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
        local('SpaceGrotesk'),
        local('SpaceGrotesk-SemiBold'),
        url(${SpaceGroteskSemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const spaceGroteskBold = {
  fontFamily: 'SpaceGrotesk-Bold',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
        local('SpaceGrotesk'),
        local('SpaceGrotesk-Bold'),
        url(${SpaceGroteskBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const montserratLight = {
  fontFamily: 'MontserratLight-Light',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
        local('Montserrat'),
        local('Montserrat-Light'),
        url(${MontserratLight}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const montserratRegular = {
  fontFamily: 'Montserrat-Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
        local('Montserrat'),
        local('Montserrat-Regular'),
        url(${MontserratRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const montserratMedium = {
  fontFamily: 'Montserrat-Medium',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
        local('Montserrat'),
        local('Montserrat-Medium'),
        url(${MontserratMedium}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const montserratSemiBold = {
  fontFamily: 'Montserrat-SemiBold',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
        local('Montserrat'),
        local('Montserrat-SemiBold'),
        url(${MontserratSemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const montserratBold = {
  fontFamily: 'Montserrat-Bold',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
        local('Montserrat'),
        local('Montserrat-Bold'),
        url(${MontserratBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const bebasNeueRegular = {
  fontFamily: 'BebasNeue-Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
        local('BebasNeue'),
        local('BebasNeue-Regular'),
        url(${BebasNeueRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
