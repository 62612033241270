
import React, { useState } from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import { TextField } from 'components/form/fields';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material'

const PasswordField = (props: TextFieldProps) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleToggleVisibility = () => {
        setShowPassword((prevState) => !prevState);
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <div className={'password-field'}>
            <TextField type={showPassword ? 'text' : 'password'}
               InputProps={{
                   endAdornment: <InputAdornment position="end">
                       <IconButton
                           aria-label="toggle password visibility"
                           onClick={handleToggleVisibility}
                           onMouseDown={handleMouseDownPassword}
                       >
                           {showPassword ? <Visibility/> : <VisibilityOff/>}
                       </IconButton>
                   </InputAdornment>
               }}
                {...props}
            />
        </div>
    )
}

export default PasswordField;