import { useViewPredictionStyles } from './index.styles';

import { useParams } from 'react-router-dom';
import { usePrediction } from 'hooks/usePrediction';
import { Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import isMobileScreen from 'hooks/useIsMobile';
import TournamentViewNew from 'components/common/TournamentViewNew';
import { GamePlayerType } from 'models/prediction.model';
import { useUser } from 'hooks/useUser';
import { UserRoles } from 'models/auth.model';
import {
  BracketGamesType,
  BracketGameType,
} from 'components/pages/FillBrackets';
import { useLazyGameBrackets } from 'hooks/useGame';
import { Loader } from 'components/common';
import LeaderboardModal from 'components/common/LeaderboardModal';

const ViewPrediction = () => {
  const params = useParams();
  const predictionId = params.predictionId || '';
  const { user } = useUser();
  const isAdmin = user?.role === UserRoles.ADMIN;
  const { prediction } = usePrediction(predictionId, isAdmin);
  const { game, getGame } = useLazyGameBrackets();
  const [bracketGames, setBracketGames] = useState<BracketGameType[]>([]);
  const [bracketR16Games, setBracketR16Games] = useState<BracketGameType[]>([]);
  const [curBracket, setCurBracket] = useState(0);

  useEffect(() => {
    if (prediction?.game?.id) {
      getGame(prediction.game.id);
    }
  }, [prediction]);

  const isMobile = isMobileScreen();
  const classes = useViewPredictionStyles();

  const ownerNickname: string | null = useMemo(() => {
    return prediction?.owner?.nickname || null;
  }, [prediction, user]);

  useEffect(() => {
    const result: BracketGamesType = {};
    game?.rounds?.forEach((round, index) => {
      round?.matches?.forEach((match) => {
        result[match.id] = {
          match,
          previous: match.previousMatches?.length
            ? [...match.previousMatches?.map((item) => item.id as string)]
            : [],
          round: index,
        };
      });
    });

    setBracketGames(Object.values(result));
    setBracketR16Games(
      Object.values(result).filter((match) => match?.round && match?.round > 1),
    );
  }, [game?.rounds]);

  const matchPredictions: GamePlayerType = useMemo(() => {
    const result: GamePlayerType = {};
    prediction?.matchPredictions &&
      prediction.matchPredictions.forEach((prediction) => {
        if (prediction.match?.id && prediction.winner?.id) {
          result[prediction.match.id] = prediction.winner.id;
        }
      });
    return result;
  }, [prediction?.matchPredictions]);

  const activeRound = useMemo(() => {
    const round = game?.rounds?.findIndex((round) => round.status.key === 2);
    return round;
  }, [game?.rounds]);

  const notStartedRound = useMemo(() => {
    const round = game?.rounds?.findIndex((round) => round.status.key === 1);
    return round;
  }, [game?.rounds]);

  const isLoading: boolean = useMemo(() => {
    return Object.keys(bracketGames).length === 0;
  }, [bracketGames]);

  return (
    <div className={classes.container}>
      <LeaderboardModal gameId={prediction?.game?.id || ''} />
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <Stack className={classes.content}>
          {!isLoading && game?.id ? (
            <>
              <Stack
                pb={isMobile ? 2 : 4}
                gap={isMobile ? 2 : 4}
                flexDirection="row"
              >
                <Stack className={classes.gameLogo}>
                  <img src={game?.tournament?.championship?.logoUrl} alt="" />
                </Stack>
                <Stack>
                  <Typography
                    variant={'h2'}
                    fontSize={isMobile ? 24 : 36}
                    lineHeight={isMobile ? '28px' : '44px'}
                    color="white"
                  >
                    {game?.title || ''}
                  </Typography>
                  <Typography
                    variant={'h4'}
                    fontSize={isMobile ? 16 : 20}
                    lineHeight={isMobile ? '18px' : '24px'}
                    color="white"
                  >
                    {ownerNickname && ownerNickname}
                  </Typography>
                  {((activeRound && activeRound !== -1) ||
                    notStartedRound === -1) && (
                    <Typography
                      variant={'regular1618'}
                      fontSize={isMobile ? 16 : 20}
                      lineHeight={isMobile ? '18px' : '24px'}
                      color="white"
                    >
                      Score: {prediction?.newPoints || 0}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <TournamentViewNew
                readonly={true}
                game={game}
                isLoading={isLoading}
                bracketGames={bracketGames}
                bracketR16Games={bracketR16Games}
                predictions={matchPredictions}
                curBracket={curBracket}
                setCurBracket={(val) => setCurBracket(val)}
              />
            </>
          ) : (
            <Loader />
          )}
        </Stack>
      </Container>
    </div>
  );
};
export default ViewPrediction;
