const AppArrowIcon = () => (
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75018 7L0 3.11062L1.50071 1.55531L3.75018 3.88938L7.50035 0L9 1.55531L3.75018 7Z"
      fill="#6939A8"
    />
  </svg>
);

export default AppArrowIcon;
