import { object, string, number } from 'yup';
import { isBefore } from "utils/date.utils";

export const initialValues = {
  title: '',
  start: '',
  end: '',
  parentId: '',
};

const validationSchema = object({
  title: string()
      .required('Title is required'),
  start: string()
      .required('Date start is required'),
  end: string()
      .required('Date End is required')
      .test(
          "start_time_test",
          "Start time must be before end time",
          function(value, context) {
            const { start } = context.parent;
            return isBefore(start, value);
          }
      )    ,
  parentId: string()
      .nullable(true),

});

export default validationSchema;
