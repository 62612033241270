import useAdminPredictionStyles from './index.styles';

import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { usePredictionApi } from 'hooks/usePrediction';
import { Button, Container, Link, Stack, Typography } from '@mui/material';
import cx from 'classnames';
import isMobileScreen from 'hooks/useIsMobile';
import AdminTournamentView from 'components/common/AdminTournamentView';
import {
  AdminMatchPlayerType,
  AdminMatchPredictionType,
  PredictionItemType,
} from 'models/prediction.model';
import { ITournamentPlayer, MappedType } from 'models/tournamentPlayer.model';
import { useSnackbar } from 'notistack';
import { useGameApi, useGameBrackets } from 'hooks/useGame';
import { useTournamentPlayers } from 'hooks/useTournamentPlayer';
import { prepareUrl } from 'utils/route.utils';
import { routes } from 'constants/routes';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { TieBreaker } from 'components/common';
import LeaderboardModal from 'components/common/LeaderboardModal';
import { TieBreakerType } from 'components/pages/FillBrackets';

const FillWinnerBracket = () => {
  const params = useParams();
  const navigate = useNavigate();
  const gameId = params.gameId || '';
  const { enqueueSnackbar } = useSnackbar();

  //    const [tieBreakerValue, setTieBreakerValue] = useState<number | null>(null);
  const [tieBreakerPopupOpened, setTieBreakerPopupOpened] =
    useState<boolean>(false);

  const { game, refetchGame, gameLoading } = useGameBrackets(gameId);
  const { editGame } = useGameApi();
  const [selectedPlayers, setSelectedPlayers] = useState<AdminMatchPlayerType>(
    {},
  );
  const { tournamentPlayers: _tournamentPlayers } = useTournamentPlayers({
    gameId,
  });
  const { saveAdminPrediction } = usePredictionApi();

  const onSelectTieBreaker = (value: TieBreakerType): void => {
    editGame({
      id: gameId,
      tieBreaker: value?.tieValue,
      duration: value?.tieDuration,
      score: value?.tieScore,
    });
    //        setTieBreakerValue(value);
    setTieBreakerPopupOpened(false);
  };

  const onCloseTieBreaker = () => {
    setTieBreakerPopupOpened(false);
  };

  const excludedPlayers: Array<string> = useMemo(() => {
    const result: Array<string> = [];
    Object.values(selectedPlayers).forEach((player) => {
      if (player.firstId && result.indexOf(player.firstId) === -1) {
        result.push(player.firstId);
      }
      if (player.secondId && result.indexOf(player.secondId) === -1) {
        result.push(player.secondId);
      }
    });
    return result;
  }, [selectedPlayers]);

  useEffect(() => {
    const savedPredictions: AdminMatchPlayerType = {};
    game?.rounds?.forEach((round) => {
      round.matches?.forEach((match) => {
        if (match.first || match.second || match.winner) {
          savedPredictions[match.id] = {
            matchId: match.id,
            firstId: match.first?.id,
            secondId: match.second?.id,
            winnerId: match.winner?.id,
          };
        }
      });
    });
    setSelectedPlayers((prevState) => ({ ...prevState, ...savedPredictions }));
  }, [game]);

  const tournamentPlayers: MappedType<ITournamentPlayer> = useMemo(() => {
    const result: MappedType<ITournamentPlayer> = {};
    _tournamentPlayers
      ?.filter(
        (player: ITournamentPlayer) =>
          excludedPlayers.indexOf(player.id) !== -1,
      )
      .forEach((player: ITournamentPlayer) => {
        result[player.id] = player;
      });
    return result;
  }, [_tournamentPlayers, excludedPlayers]);

  const playersForSelection = useMemo(
    () =>
      _tournamentPlayers &&
      _tournamentPlayers.sort((a: ITournamentPlayer, b: ITournamentPlayer) =>
        `${a.player.firstName} ${a.player.lastName}` <
        `${b.player.firstName} ${b.player.lastName}`
          ? -1
          : 0,
      ),
    [tournamentPlayers, excludedPlayers],
  );

  const isMobile = isMobileScreen();
  const classes = useAdminPredictionStyles();

  const FillBracketsClassNames = cx({
    [classes.viewPredictionMobile]: isMobile,
  });
  const onSelectPlayer = (gameId: string, player: AdminMatchPredictionType) => {
    setSelectedPlayers((selectedPlayers) => {
      return {
        ...selectedPlayers,
        [gameId]: {
          ...selectedPlayers[gameId],
          ...player,
        },
      };
    });
  };

  const savePrediction = async () => {
    const preparePredictions = (): Array<AdminMatchPredictionType> =>
      Object.values(selectedPlayers).map((prediction) => ({
        matchId: prediction.matchId,
        firstId: prediction.firstId || null,
        secondId: prediction.secondId || null,
        winnerId: prediction.winnerId || null,
      }));
    await saveAdminPrediction(preparePredictions() as Array<PredictionItemType>)
      .then((success) => {
        if (success) {
          enqueueSnackbar('Winner bracket saved');
        } else {
          enqueueSnackbar('Error', { variant: 'warning' });
        }
      })
      .catch((error) => {
        enqueueSnackbar('Error', { variant: 'warning' });
      });
  };

  const handleSavePrediction = () => {
    savePrediction().then(() => refetchGame());
  };

  return (
    <div className={classes.container}>
      <TieBreaker
        isOpen={tieBreakerPopupOpened}
        onSelect={onSelectTieBreaker}
        tieValue={game?.tieBreaker}
        tieDuration={game?.duration}
        tieScore={game?.score}
        onClose={onCloseTieBreaker}
      />
      <LeaderboardModal gameId={gameId} />
      <Container className={classes.content} maxWidth="lg">
        <Stack className={classes.crumbs}>
          <Link
            onClick={() =>
              navigate(
                prepareUrl(routes.admin.tournamentDetails.path, {
                  tournamentId: game?.tournament?.id,
                }),
              )
            }
          >
            <ArrowBackIos />
          </Link>
          <Typography className={FillBracketsClassNames} variant={'h1'}>
            {game?.title || ''}
          </Typography>
        </Stack>
        <div className={classes.headerBlock}>
          <div>
            <Typography variant={'h4'}>Prediction Details</Typography>
            {/* <Typography variant={'regular1618gray'}>
              First round correct receive 2 points
            </Typography> */}
          </div>
          <Stack className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={(): void => setTieBreakerPopupOpened(true)}
            >
              Tiebreaker: {game?.tieBreaker || 'Not set'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSavePrediction}
            >
              Save winner bracket
            </Button>
          </Stack>
        </div>
        {game && (
          <AdminTournamentView
            players={playersForSelection}
            excludedPlayers={excludedPlayers}
            game={game}
            tournamentPlayers={tournamentPlayers}
            onSelectPlayer={onSelectPlayer}
            selectedPlayers={selectedPlayers}
            savePrediction={savePrediction}
          />
        )}
      </Container>
    </div>
  );
};
export default FillWinnerBracket;
