import { object, string } from 'yup';

import { emailValidator } from "../index.validators";

export interface SignupFormValuesType {
    email: string,
    password: string,
    nickname: string
}

export const SignUpInitialValues = {
        email: '',
        password: '',
        nickname: ''
}

const SignUpFormScheme = object({
    email: string()
        .test(emailValidator)
        .required('Email must be valid'),

    password: string()
        .required('Password must be valid'),
    nickname: string()
        .required('Nickname is required'),

})

export default SignUpFormScheme;
