export function formatNumberWithMask(number: number) {
  // Convert number to string
  let numberString = number.toString();

  // Add leading zeros if necessary
  while (numberString.length < 3) {
    numberString = '0' + numberString;
  }

  // Apply the mask
  return (
    numberString.slice(0, 1) + numberString.slice(1, 2) + numberString.slice(2)
  );
}
