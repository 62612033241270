import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { Button, Stack } from '@mui/material';
import useLoginFormStyles from './index.styles';
import { PasswordField, TextField } from 'components/form/fields';
import { CreatePasswordFormScheme, CreatePasswordInitialValues } from './index.schema';
import { useAuth } from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import Loader from 'components/common/Loader';
import { useLocation, useParams, useSearchParams } from "react-router-dom";

interface IResetPasswordForm {}

const ResetPasswordForm = (props: IResetPasswordForm) => {
    const { enqueueSnackbar } = useSnackbar();
    const { createNewPassword, verifyResetPasswordLink, loading } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [ uuid64, setUuid64] = useState<string>('');
    const [ token, setToken] = useState<string>('')
    const params = useParams();

    useEffect(() => {
        const queryUuid = searchParams.get("uid64")
        const queryToken = searchParams.get("token")
        if (queryUuid) setUuid64(queryUuid)
        if (queryToken) setToken(queryToken)
    }, [searchParams])

    const formik = useFormik({
        initialValues: CreatePasswordInitialValues,
        validationSchema: CreatePasswordFormScheme,
        onSubmit: (values) => {
            createNewPassword(values.password, uuid64, token)
                .then(response => {
                    const { success, errors } = response.data?.resetPasswordConfirm;
                    if (success) {
                        enqueueSnackbar('Password changed')
                    } else {
                        enqueueSnackbar(errors[0], {variant: 'error'})
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, {variant: 'error'})
                });
        }
    })

    const classes = useLoginFormStyles();

    return (
        <div>
            {loading && <Loader />}
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="password-input"
                        name={'password'}
                        label={'Password'}
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </Stack>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="confirm-password-input"
                        name={'confirm_password'}
                        label={'Repeat password'}
                        placeholder="Repeat password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                        helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                    />
                </Stack>
                <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Reset password
                </Button>
            </form>
        </div>
    );
};

export default ResetPasswordForm;
