import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useSelectStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiSelect-select': {
      padding: '8.5px 14px',
      ...theme.typography.regularBebasNeue,
      color: 'white',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    '& .Mui-disabled': {
      color: '#ffffff99',
      '-webkit-text-fill-color': '#ffffff99',
    },
  },
}));

export default useSelectStyles;
