import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { debounce, get, omit } from 'lodash';
import { TableQueryInfoType, TableRowValueType } from 'types/table.types';
import { PageInfoType } from 'models/api.model';

type QueryParamValType = string | Array<String> | number | null | boolean;

interface QueryParamsType {
  [key: string]: QueryParamValType;
}

interface ResultEdgeType {
  node: TableRowValueType;
}

interface ResponseDataType {
  rowsCount: number;
  rows: Array<TableRowValueType>;
  pageInfo: PageInfoType;
}

export const useTable = (
  tableConfig: TableQueryInfoType,
  defaultParams: QueryParamsType = {},
) => {
  const itemsPerPage = () => tableConfig.itemsPerPage || null;
  const [params, setParams] = useState<QueryParamsType>({
    first: itemsPerPage(),
    after: null,
    search: '',
  });

  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const [responseData, setResponseData] = useState<ResponseDataType>({
    rowsCount: 0,
    rows: [],
    pageInfo: {},
  });
  const [isLazyLoading, setIsLazyLoading] = useState(false);

  const buildQuery = (params: QueryParamsType) => {
    let response = defaultParams;
    if (tableConfig && tableConfig.defaultSort) {
      response.ordering = tableConfig.defaultSort;
    }
    const excludeValues: Array<QueryParamValType> = [null, ''];
    return {
      ...response,
      ...omit(
        params,
        Object.keys(params).filter(
          (key: string) => excludeValues.indexOf(params[key]) !== -1,
        ),
      ),
    };
  };

  const { data, loading, error, refetch } = useQuery(
    tableConfig?.dataApi() || '',
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      variables: buildQuery(params),
    },
  );

  const debounceUpdateParams = useCallback(debounce(setParams, 1000), [
    setParams,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading) {
      if (data) {
        const edges: Array<ResultEdgeType> = get(
          data,
          `${tableConfig.dataPath}.edges`,
          [],
        );
        const pageInfo = get(data, `${tableConfig.dataPath}.pageInfo`, {});
        setResponseData({
          rowsCount: get(data, `${tableConfig.dataPath}.totalCount`, 0),
          rows: !isLazyLoading
            ? edges.map((edge) => edge.node)
            : responseData.rows.concat(edges.map((edge) => edge.node)),
          pageInfo,
        });
      } else {
        setResponseData({
          rowsCount: 0,
          rows: [],
          pageInfo: {},
        });
      }
    }
  }, [data, tableConfig.dataPath, loading]);

  useEffect(() => {
    setIsLazyLoading((prev) => (prev ? !prev : prev));
  }, [responseData.rows]);

  const refetchData = () => refetch(buildQuery(params));

  const resetPage = () => ({
    first: itemsPerPage(),
    after: null,
    before: null,
    last: null,
  });

  const onLazyLoading = () => {
    if (loading || !responseData.pageInfo.hasNextPage) return;
    setIsLazyLoading(true);
    onNextPage();
  };

  const onNextPage = () => {
    if (loading) return;
    setParams((params) => {
      return {
        ...params,
        before: null,
        after: responseData.pageInfo?.endCursor || '',
        first: itemsPerPage(),
        last: null,
      };
    });
  };

  const onPrevPage = () => {
    if (loading) return;
    setParams((params) => {
      return {
        ...params,
        before: responseData.pageInfo?.startCursor || '',
        first: null,
        last: itemsPerPage(),
        after: null,
      };
    });
  };

  const onPageChange = (event: unknown, page: number) => {
    if (loading) return;
    if (currentPage > page) {
      setCurrentPage((page) => page - 1);
      onPrevPage();
    } else if (currentPage < page) {
      setCurrentPage((page) => page + 1);
      onNextPage();
    }
  };

  const getFirstPage = () => ({
    first: itemsPerPage(),
    after: null,
    before: null,
    last: null,
  });

  const setSearch = (search: string) => {
    if (search !== searchStr) {
      debounceUpdateParams({
        ...params,
        search: search,
        ...getFirstPage(),
      });
      setSearchStr(search);
      setCurrentPage(0);
    }
  };

  const debounceSetParam = (name: string, search: string): void => {
    if (search !== searchStr) {
      debounceUpdateParams({
        ...params,
        [name]: search,
        ...getFirstPage(),
      });
      setSearchStr(search);
      setCurrentPage(0);
    }
  };

  const setParam = (
    key: string | QueryParamsType,
    value: QueryParamValType,
  ): void => {
    setCurrentPage(0);
    if (typeof key === 'object') {
      setParams((params) => {
        return { ...params, ...key, ...resetPage() };
      });
    } else {
      setParams((params) => {
        return { ...params, [key]: value || null, ...resetPage() };
      });
    }
  };

  const getParam = (key: string): QueryParamValType => {
    return params[key] || '';
  };

  return {
    rows: responseData.rows,
    pageInfo: responseData.pageInfo,
    rowsCount: responseData.rowsCount,
    itemsPerPage: itemsPerPage(),
    refetchData,
    onLazyLoading,
    onNextPage,
    onPrevPage,
    onPageChange,
    currentPage,
    setSearch,
    debounceSetParam,
    setParam,
    getParam,
    searchStr,
    dataIsLoading: loading,
    dataError: error,
  };
};
