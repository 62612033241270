export const API_ROOT = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_ROOT
    : (window as any).REACT_APP_API_ROOT

export const STRIPE_PUBLIC_KEY = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
    : (window as any).REACT_APP_STRIPE_PUBLIC_KEY

export const GOOGLE_CLIENT_ID = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID
    : (window as any).REACT_APP_GOOGLE_CLIENT_ID

export const FACEBOOK_APP_ID = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_FACEBOOK_APP_ID
    : (window as any).REACT_APP_FACEBOOK_APP_ID