import { FantasyStyles } from './index.styles';

import React from 'react';

import { useNavigate } from 'react-router';

import { usePredictionList } from 'hooks/usePrediction';
import tableConfig from 'config/table.config';
import { Table } from 'components/common';
import { TableQueryInfoType, TableRowType, TableRowValueType } from 'types/table.types';
import { routes } from 'constants/routes';
import { prepareUrl } from "utils/route.utils";
import { useTable } from "hooks/useTable";
import { reduceRedundantColumns } from "utils/filter.utils";

interface IRaqiteersTab {
  tournamentId: string
}
const RaqiteersTab = ({tournamentId}: IRaqiteersTab) => {

  const {
    rows,
    pageInfo,
    dataIsLoading,
    dataError,
    onNextPage,
    onPrevPage
  } = useTable(tableConfig.myBrackets as TableQueryInfoType, {tournamentId: tournamentId});


  const navigate = useNavigate();

  const classes = FantasyStyles();

  const { predictions } = usePredictionList();

  const onCellClicked = (row: TableRowValueType) => {
    navigate(prepareUrl(routes.user.viewPrediction.path, { predictionId: row.id } ));
  };

  return (
    <div>
      <Table
        rows={rows}
        columns={reduceRedundantColumns(tableConfig.myBrackets.columns, ['actions'])}
        headerClassNames={classes.tableHeader}
        onCellClicked={onCellClicked}
        pageInfo={pageInfo}
        getNextPage={onNextPage}
        getPrevPage={onPrevPage}

      />
    </div>
  );
};
export default RaqiteersTab;
