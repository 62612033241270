import useStyles from './index.styles';

import React from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogContent from '@mui/material/DialogContent';
import { Typography, Box, Button, Slider, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik } from 'formik';
import { number, object } from 'yup';
import { TieBreakerType } from 'components/pages/FillBrackets';
import moment from 'moment';

interface ITieBreaker {
  isOpen: boolean;
  tieValue?: number | null;
  tieDuration?: number;
  tieScore?: string;
  onClose: () => void;
  onSelect: ({ tieValue, tieDuration, tieScore }: TieBreakerType) => void;
}

const formatDefaultPickerValue = (tieDur?: number) => {
  return tieDur
    ? moment()
        .set('hours', Math.floor(tieDur / 60))
        .set('minutes', tieDur - Math.floor(tieDur / 60) * 60)
        .format('YYYY-MM-DD HH:mm')
    : moment().set('hours', 0).set('minutes', 0).format('YYYY-MM-DD HH:mm');
};

const TieBreaker = ({
  tieValue = null,
  tieDuration,
  tieScore,
  isOpen,
  onSelect,
  onClose,
}: ITieBreaker) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      tieValue,
      tieDuration: 0,
      tieScoreFirst: tieScore?.split(':')?.[0] || '',
      tieScoreSecond: tieScore?.split(':')?.[1] || '',
    },
    validationSchema: object({
      tieScoreFirst: number().min(0, 'Min 0').max(7, 'Max 7').nullable(true),
      tieScoreSecond: number().min(0, 'Min 0').max(7, 'Max 7').nullable(true),
    }),
    enableReinitialize: true,
    onSubmit: ({ tieValue, tieDuration, tieScoreFirst, tieScoreSecond }) => {
      tieValue &&
        onSelect({
          tieValue,
          tieDuration,
          tieScore:
            tieScoreFirst && tieScoreSecond
              ? `${tieScoreFirst}:${tieScoreSecond}`
              : undefined,
        });
    },
  });

  const getButtonClasses = (value: number) => {
    return cx(classes.tieButton, {
      [classes.tieBtnActive]: value === formik.values.tieValue,
    });
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle className={classes.title} sx={{ p: 1 }}>
          Tiebreaker
        </DialogTitle>
        <Tooltip title="Close">
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack className={classes.container} gap={2}>
            <Typography>
              Guess the number of Aces hit by the Champion
            </Typography>
            <Box className={classes.grid}>
              {Array.from(Array(20), (d, i) => i + 1).map((value: number) => (
                <Button
                  key={value}
                  onClick={() => formik.setFieldValue('tieValue', value)}
                  className={getButtonClasses(value)}
                  variant="outlined"
                >
                  {value}
                </Button>
              ))}
            </Box>
            <Box>
              <Stack flexDirection="row" gap={2}>
                <Typography paddingTop={1}>Length of Finals Match:</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampm={false}
                    maxTime={dayjs(formatDefaultPickerValue(400))}
                    defaultValue={dayjs(formatDefaultPickerValue())}
                    value={dayjs(
                      formatDefaultPickerValue(formik.values?.tieDuration),
                    )}
                    skipDisabled
                    onChange={(val) => {
                      formik.setFieldValue(
                        'tieDuration',
                        val?.hour() || val?.minute()
                          ? val?.hour() * 60 + val?.minute()
                          : 0,
                      );
                    }}
                    sx={{
                      width: 120,
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              <Slider
                name="tieDuration"
                min={0}
                max={400}
                step={1}
                value={formik.values?.tieDuration}
                onChange={formik.handleChange}
              />
            </Box>
            <Stack flexDirection="row" gap={1} pb={3}>
              <Typography alignItems="center">
                Exact Score of the last Set played in the Finals:
              </Typography>
              <TextField
                className={classes.scoreInput}
                id="tie-score-first-input"
                name="tieScoreFirst"
                type="number"
                value={formik.values.tieScoreFirst}
                onChange={formik.handleChange}
                error={
                  formik.touched.tieScoreFirst &&
                  Boolean(formik.errors.tieScoreFirst)
                }
                helperText={
                  formik.touched.tieScoreFirst && formik.errors.tieScoreFirst
                }
              />
              <Typography paddingTop={1.5}>:</Typography>
              <TextField
                className={classes.scoreInput}
                id="tie-score-first-input"
                name="tieScoreSecond"
                type="number"
                value={formik.values.tieScoreSecond}
                onChange={formik.handleChange}
                error={
                  formik.touched.tieScoreSecond &&
                  Boolean(formik.errors.tieScoreSecond)
                }
                helperText={
                  formik.touched.tieScoreSecond && formik.errors.tieScoreSecond
                }
              />
            </Stack>

            <Button
              className={classes.tieSubmit}
              fullWidth
              variant="contained"
              color="primary"
              disabled={!formik.values.tieValue}
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TieBreaker;
