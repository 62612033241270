import { ButtonGamePageStyles } from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { Button } from "@mui/material";
import { UserType } from "models/auth.model";

interface IUserActions {
    data: TableDataType<UserType>
}

const UserActions = ({ data: {row, onCellClicked }}: IUserActions): JSX.Element => {
    const classes = ButtonGamePageStyles();

    const onButtonClick = () => {
        onCellClicked && onCellClicked(row, 'predictions');
    }
    return <div className={classes.container}>
        <Button
            className={classes.ButtonGamePageBtn}
            variant="contained"
            color="primary"
            onClick={onButtonClick}
        >
            Predictions
        </Button>
    </div>
}

export default UserActions;
