import useFillBracketsStyles from './index.styles';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { MatchType } from 'models/match.model';
import { useUser } from 'hooks/useUser';
import AuthContext from 'components/Route/AuthContext';
import { AuthComponent } from 'constants/auth';
import { usePrediction, usePredictionApi } from 'hooks/usePrediction';
import { GamePlayerType, PredictionItemType } from 'models/prediction.model';
import { useGameBrackets } from 'hooks/useGame';
import { Loader, TieBreaker } from 'components/common';
import PaymentSuccessDialog from 'components/common/PaymentSuccessDialog';
import ConfirmationPopup from '../../common/ConfirmationPopup';
import LeaderboardModal from 'components/common/LeaderboardModal';
import TournamentViewNew from 'components/common/TournamentViewNew';
import { IGame } from 'models/game.model';

export interface BracketGameType {
  match?: MatchType;
  userChoice?: string;
  color?: string;
  previous: Array<string>;
  round: number;
}

export interface BracketGamesType {
  [id: string]: BracketGameType;
}

export interface TieBreakerType {
  tieValue: number;
  tieDuration?: number;
  tieScore?: string;
}

const FillBrackets = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const gameId = params.gameId || '';
  const predictionId = params.predictionId || '';
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { setAuth } = useContext(AuthContext);
  const { createPrediction, updatePrediction } = usePredictionApi();
  const { game, gameLoading, refetchGame } = useGameBrackets(gameId);
  const {
    prediction,
    refetch: refetchPrediction,
    predictionLoading,
  } = usePrediction(predictionId);
  const classes = useFillBracketsStyles();
  const isMobile = isMobileScreen();
  const [isLoginChecked, setIsLoginChecked] = useState<boolean>(false);
  const [selectedPlayers, setSelectedPlayers] = useState<GamePlayerType>({});
  const [isBracketFinalized, setIsBracketFinalized] = useState<boolean>(false);
  const [tieBreakerValue, setTieBreakerValue] = useState<TieBreakerType | null>(
    null,
  );
  const [tieBreakerPopupOpened, setTieBreakerPopupOpened] =
    useState<boolean>(false);
  const [paymentSuccessDialogOpened, setPaymentSuccessDialogOpened] =
    useState<boolean>(false);
  const [bracketGames, setBracketGames] = useState<BracketGameType[]>([]);
  const [gameProgress, setGameProgress] = useState<number>(0);
  const [bracketR16Games, setBracketR16Games] = useState<BracketGameType[]>([]);
  const [paymentMethodPopupOpened, setPaymentMethodPopupOpened] =
    useState<boolean>(false);

  const [curBracket, setCurBracket] = useState(0);
  const [loading, setLoading] = useState(false);

  const savePrediction = useCallback(() => {
    setLoading(true);
    const preparePredictions = () => {
      return Object.entries(selectedPlayers)
        .map(([matchId, winnerId]) => ({ matchId, winnerId }))
        .filter((prediction) => prediction.matchId && prediction.winnerId);
    };

    if (predictionId && predictionId !== 'create') {
      return updatePrediction(
        predictionId,
        preparePredictions() as Array<PredictionItemType>,
        tieBreakerValue?.tieValue || undefined,
        tieBreakerValue?.tieDuration || undefined,
        tieBreakerValue?.tieScore || undefined,
      )
        .then((response) => {
          if (response?.success) {
            refetchPrediction().then(() => setSelectedPlayers({}));
          }
          if (!response?.success) {
            enqueueSnackbar(
              response?.errors ? response.errors[0].message : 'Error',
              { variant: 'warning' },
            );
          }
        })
        .catch(() => {
          enqueueSnackbar('Error', { variant: 'warning' });
        })
        .finally(() => setLoading(false));
    } else {
      return createPrediction(
        gameId,
        preparePredictions() as Array<PredictionItemType>,
        tieBreakerValue?.tieValue || undefined,
        tieBreakerValue?.tieDuration || undefined,
        tieBreakerValue?.tieScore || undefined,
      )
        .then((response) => {
          if (response?.success) {
            enqueueSnackbar('Prediction created', { variant: 'success' });
            navigate(
              `${location.pathname?.replace(
                '/create',
                `/${response?.bracket?.id}`,
              )}`,
              {
                replace: true,
              },
            );
          } else {
            enqueueSnackbar(
              response?.errors ? response.errors[0].message : 'Error',
              { variant: 'warning' },
            );
          }
        })
        .catch(() => {
          enqueueSnackbar('Error', { variant: 'warning' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    createPrediction,
    enqueueSnackbar,
    gameId,
    location.pathname,
    navigate,
    predictionId,
    refetchPrediction,
    selectedPlayers,
    tieBreakerValue?.tieDuration,
    tieBreakerValue?.tieScore,
    tieBreakerValue?.tieValue,
    updatePrediction,
  ]);

  const openPaymentWindow = (): void => {
    setPaymentMethodPopupOpened(true);
  };
  useEffect(() => {
    if (user) {
      setIsLoginChecked(true);
    }
    if (isBracketFinalized && user) {
      setIsBracketFinalized(false);
      openPaymentWindow();
    }
  }, [isBracketFinalized, user]);

  useEffect(() => {
    if (predictionId !== 'create' && !gameLoading && !predictionLoading) {
      setSelectedPlayers({});
    }
  }, [gameLoading, predictionId, predictionLoading]);

  useEffect(() => {
    const result: BracketGamesType = {};
    game?.rounds?.forEach((round, index) => {
      round?.matches?.forEach((match) => {
        result[match.id] = {
          match,
          previous: match.previousMatches?.length
            ? [...match.previousMatches?.map((item) => item.id as string)]
            : [],
          round: index,
        };
      });
    });

    setBracketGames(Object.values(result));
    setBracketR16Games(
      Object.values(result).filter((match) => match?.round && match?.round > 1),
    );
  }, [game?.rounds]);

  const matchPredictions: GamePlayerType = useMemo(() => {
    const result: GamePlayerType = {};
    prediction?.matchPredictions &&
      prediction.matchPredictions.forEach((predict) => {
        if (predict.match?.id && predict.winner?.id) {
          result[predict.match.id] = predict.winner.id;
        }
      });
    return result;
  }, [prediction?.matchPredictions]);

  const activeRound = useMemo(() => {
    const round = game?.rounds?.findIndex((round) => round.status.key === 2);
    return round;
  }, [game?.rounds]);

  const notStartedRound = useMemo(() => {
    const round = game?.rounds?.findIndex((round) => round.status.key === 1);
    return round;
  }, [game?.rounds]);

  useEffect(() => {
    if (
      bracketGames?.length &&
      !loading &&
      !predictionLoading &&
      !gameLoading
    ) {
      const progress = bracketGames.filter(
        (game) =>
          game?.match?.id &&
          (game?.match?.winner?.id ||
            matchPredictions?.[game?.match?.id] ||
            selectedPlayers?.[game?.match?.id]),
      )?.length;
      setGameProgress(progress);
    }
  }, [
    bracketGames,
    gameLoading,
    loading,
    matchPredictions,
    predictionLoading,
    selectedPlayers,
  ]);

  const autoFillHandler = async () => {
    let autoFillBrackets: BracketGameType[] = [];
    await refetchGame().then((res) => {
      const result: BracketGamesType = {};
      const game: IGame = res?.data?.gameDetails;
      game?.rounds?.forEach((round, index: number) => {
        round?.matches?.forEach((match) => {
          result[match.id] = {
            match,
            previous: match.previousMatches?.length
              ? [...match.previousMatches?.map((item) => item.id as string)]
              : [],
            round: index,
          };
        });
      });
      autoFillBrackets = Object.values(result);
    });
    const result = { ...selectedPlayers };
    const finalGame = autoFillBrackets?.find(
      (bracket) => !bracket?.match?.next,
    );

    const getRandom = (players: Array<string | undefined>): string => {
      const filtered = players.filter((player) => !!player);
      return filtered[Math.floor(Math.random() * filtered.length)] || '';
    };

    const getWinner = (gameId: string): string => {
      const game = autoFillBrackets?.find((item) => item.match?.id === gameId);
      if (game) {
        if (game.match?.winner?.id) {
          return game.match.winner.id;
        } else if (
          game.previous.length &&
          (activeRound === -1 ||
            ((game.round || game.round === 0) &&
              (activeRound || activeRound === 0) &&
              game.round > activeRound))
        ) {
          let firstChildWinner = '',
            secondChildWinner = '';
          if (game.previous[0]) {
            firstChildWinner = getWinner(game.previous[0]);
          }
          if (game.previous[1]) {
            secondChildWinner = getWinner(game.previous[1]);
          }
          if (result[gameId]) {
            return result[gameId];
          } else {
            result[gameId] = getRandom([firstChildWinner, secondChildWinner]);
            return result[gameId];
          }
        } else {
          if (result[gameId]) {
            return result[gameId];
          } else {
            result[gameId] = getRandom([
              game.match?.first?.id,
              game.match?.second?.id,
            ]);
            return result[gameId];
          }
        }
      }
      return '';
    };

    const fillWinner = (gameId: string): void => {
      const winner = getWinner(gameId);
      if (winner && !result[gameId]) {
        result[gameId] = winner;
      }
    };
    if (finalGame && finalGame.match?.id) {
      fillWinner(finalGame.match.id);
    }
    setSelectedPlayers(result);
  };

  const onSelectTieBreaker = (tieValue: TieBreakerType | null): void => {
    setTieBreakerValue(tieValue);
    if (Object.keys(selectedPlayers).length > 0) {
      setIsBracketFinalized(true);
    } else {
      if (predictionId && !predictionId.includes('create')) {
        updatePrediction(
          predictionId,
          [],
          tieValue?.tieValue || undefined,
          tieValue?.tieDuration || undefined,
          tieValue?.tieScore || undefined,
        )
          .then((response) => {
            if (response?.success) {
              refetchPrediction().then(() => setSelectedPlayers({}));
            }
            if (!response?.success) {
              enqueueSnackbar(
                response?.errors ? response.errors[0].message : 'Error',
                { variant: 'warning' },
              );
            }
          })
          .catch(() => {
            enqueueSnackbar('Error', { variant: 'warning' });
          });
      } else {
        createPrediction(
          gameId,
          [],
          tieValue?.tieValue || undefined,
          tieValue?.tieDuration || undefined,
          tieValue?.tieScore || undefined,
        )
          .then((response) => {
            if (response?.success) {
              enqueueSnackbar('Prediction created', { variant: 'success' });
              navigate(
                `${location.pathname?.replace(
                  '/create',
                  `/${response?.bracket?.id}`,
                )}`,
                {
                  replace: true,
                },
              );
            }
            if (!response?.success) {
              enqueueSnackbar(
                response?.errors ? response.errors[0].message : 'Error',
                { variant: 'warning' },
              );
            }
          })
          .catch(() => {
            enqueueSnackbar('Error', { variant: 'warning' });
          });
      }
    }
    setTieBreakerPopupOpened(false);
  };

  const onCloseTieBreaker = () => {
    setTieBreakerPopupOpened(false);
  };

  const onSelectPlayer = useCallback(
    (gameId: string, playerId: string) => {
      setSelectedPlayers((selectedPlayers) => {
        const prev = selectedPlayers;
        if (prev[gameId] && prev[gameId] !== playerId) {
          const curRound = bracketGames?.find(
            (item) => item.match?.id === gameId,
          )?.round;
          const playerGames = Object.entries(selectedPlayers)
            .map(([matchId, winnerId]) => ({ matchId, winnerId }))
            .filter((item) => item.winnerId === prev[gameId]);
          playerGames.forEach((nextGame) => {
            const curNextGame = bracketGames?.find(
              (item) => item.match?.id === nextGame?.matchId,
            );
            if (
              curNextGame &&
              curNextGame?.match?.id &&
              curRound &&
              curNextGame?.round &&
              curNextGame?.round > curRound
            ) {
              prev[curNextGame?.match?.id] = '';
            }
          });
          prev[gameId] = playerId;
        } else if (!prev[gameId]) {
          prev[gameId] = playerId;
        }
        return { ...prev };
      });
      if (isLoginChecked) {
        savePrediction();
      }
      if (!isLoginChecked && Object.keys(selectedPlayers).length >= 3) {
        setIsLoginChecked(true);
        setAuth(true, AuthComponent.LOGIN);
      }
    },
    [bracketGames, isLoginChecked, savePrediction, selectedPlayers, setAuth],
  );

  const onFinalizeBracket = () => {
    if (!tieBreakerValue) {
      setTieBreakerPopupOpened(true);
    }
    if (tieBreakerValue) {
      if (!user) {
        setAuth(true, AuthComponent.LOGIN);
      }
      setIsBracketFinalized(true);
    }
  };

  const GameLogoClassNames = cx(classes.gameLogo, {
    [classes.gameLogoMobile]: isMobile,
  });

  const onPaymentSuccessResponse = (): void => {
    savePrediction()?.then(() => {
      setPaymentMethodPopupOpened(false);
    });
  };

  const selectedPlayersLength = useMemo(() => {
    return Object.values(selectedPlayers).filter((player) => !!player).length;
  }, [selectedPlayers]);

  return (
    <div className={classes.container}>
      <Typography
        className={classes.tournamentTitle}
        variant={'h2'}
        fontSize={30}
        fontWeight={600}
        textAlign="center"
        color="white"
      >
        raqit draw picks
      </Typography>
      <Container className={classes.content} maxWidth="lg">
        {game ? (
          <>
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={isMobile ? 'start' : 'center'}
              gap={2}
              pb={isMobile ? 2 : 5}
            >
              <Stack gap={isMobile ? 2 : 4} flexDirection="row">
                <Stack className={GameLogoClassNames}>
                  <img src={game?.tournament?.championship?.logoUrl} alt="" />
                </Stack>
                <Stack>
                  <Typography
                    variant={'h2'}
                    fontSize={isMobile ? 24 : 36}
                    lineHeight={isMobile ? '28px' : '44px'}
                    color="white"
                  >
                    {game?.title || ''}
                  </Typography>
                  {!isMobile && (
                    <Typography
                      variant={'h4'}
                      fontSize={isMobile ? 16 : 20}
                      lineHeight={isMobile ? '18px' : '24px'}
                      color="white"
                    >
                      Fill out your brackets
                    </Typography>
                  )}
                  {((activeRound && activeRound !== -1) ||
                    notStartedRound === -1) && (
                    <Typography
                      variant={'regular1618'}
                      fontSize={isMobile ? 16 : 20}
                      lineHeight={isMobile ? '18px' : '24px'}
                      color="white"
                    >
                      Score: {prediction?.newPoints || 0}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" gap={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(): void => setTieBreakerPopupOpened(true)}
                  sx={{
                    minWidth: isMobile ? 80 : 120,
                  }}
                >
                  Tiebreaker
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  disabled={gameLoading}
                  onClick={autoFillHandler}
                  sx={{
                    minWidth: isMobile ? 80 : 120,
                  }}
                >
                  Auto Fill
                  {gameLoading ? (
                    <CircularProgress size={16} sx={{ marginLeft: 0.5 }} />
                  ) : null}
                </Button>
                {!isLoginChecked ||
                (isLoginChecked && selectedPlayersLength > 1) ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onFinalizeBracket}
                    sx={{
                      minWidth: isMobile ? 80 : 120,
                    }}
                  >
                    Save
                  </Button>
                ) : null}
              </Stack>
            </Stack>

            <TournamentViewNew
              game={game}
              gameProgress={gameProgress}
              selectedPlayers={selectedPlayers}
              onSelectPlayer={onSelectPlayer}
              bracketGames={bracketGames}
              bracketR16Games={bracketR16Games}
              predictions={matchPredictions}
              curBracket={curBracket}
              setCurBracket={(val) => setCurBracket(val)}
            />
          </>
        ) : (
          <Loader />
        )}
      </Container>

      {/* dialogs */}
      <TieBreaker
        isOpen={tieBreakerPopupOpened}
        onSelect={onSelectTieBreaker}
        onClose={onCloseTieBreaker}
        tieValue={prediction?.tieBreaker}
        tieDuration={prediction?.duration}
        tieScore={prediction?.score}
      />
      <PaymentSuccessDialog
        title="Prediction created"
        isOpen={paymentSuccessDialogOpened}
        onClose={() => setPaymentSuccessDialogOpened(false)}
      />
      <ConfirmationPopup
        isOpen={paymentMethodPopupOpened}
        onClose={() => setPaymentMethodPopupOpened(false)}
        onSuccess={onPaymentSuccessResponse}
      />
      <LeaderboardModal gameId={gameId} />
    </div>
  );
};

export default FillBrackets;
