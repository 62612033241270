import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const CustomCheckboxStyles = makeStyles((theme: Theme) => ({
  PrimaryCheckbox: {
    width: 24,
    height: 24,
    border: `1px solid ${theme.colors.primary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
}));
