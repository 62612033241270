import { gql } from '@apollo/client';

export const GAME_INFO = gql`
  fragment GameFields on GameType {
    id
    title
    start
    end
    isActive
    hasActiveRounds
    tieBreaker
    duration
    score
    countFantasyTeams
    countBracketPredictions
    parent {
      id
    }
  }
`;
