import useGameListsStyles from './index.styles';

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Container, Stack, Typography } from '@mui/material';
import tableConfig from 'config/table.config';
import Table from 'components/common/Table';
import { TableRowValueType } from 'types/table.types';
import { routes } from 'constants/routes';
import { useLiveTournament } from 'hooks/useTournament';
import { prepareUrl } from 'utils/route.utils';
import useIsMobileScreen from 'hooks/useIsMobile';
import { reduceRedundantColumns } from 'utils/filter.utils';

const GameList = () => {
  const navigate = useNavigate();
  const classes = useGameListsStyles();
  const isMobile = useIsMobileScreen();
  const { tournament, games, isLoading } = useLiveTournament();
  const GameListStyle = useMemo(() => {
    return tournament?.championship
      ? {
          backgroundImage: `linear-gradient(180deg, ${tournament?.championship.primaryColor} 57%, ${tournament?.championship.secondaryColor} 57%)`,
        }
      : {};
  }, [tournament?.championship]);

  const onCellClicked = (row: TableRowValueType, action?: string) => {
    if (action && action === 'fill')
      navigate(
        prepareUrl(routes.common.fillBrackets.path, {
          gameId: row.id,
          predictionId: 'create',
        }),
      );
    if (action && action === 'pick')
      navigate(
        prepareUrl(routes.common.fantasy.path, {
          gameId: row.id,
          fantasyId: 'create',
        }),
      );
  };

  const preparedGames: Array<TableRowValueType> = useMemo(() => {
    return games.map((game) => {
      return {
        ...game,
        logo: tournament?.championship?.logoUrl,
      } as TableRowValueType;
    });
  }, [games, tournament]);

  const columns = useMemo(() => {
    return reduceRedundantColumns(
      tableConfig.gameList.columns,
      isMobile ? ['title', 'status'] : ['mobile'],
    );
  }, [isMobile]);
  return (
    <div className={classes.GameList} style={GameListStyle}>
      <Container className={classes.Container} maxWidth="lg">
        <div className={classes.header}>
          <div className={classes.GameListHeadItem}>
            <Stack className={classes.gameLogo}>
              <img src={tournament?.championship?.logoUrl} alt="" />
            </Stack>
            <Typography className={classes.GameListHeadText} variant="h2">
              {tournament?.title || ''}
            </Typography>
          </div>
        </div>

        <div className={classes.GameListTable}>
          <Table
            rows={preparedGames}
            isLoading={isLoading}
            columns={columns}
            headerClassNames={classes.tableHeader}
            onCellClicked={onCellClicked}
            noRowsTitle="There are no active tournaments at this moment"
          />
        </div>
      </Container>
    </div>
  );
};
export default GameList;
