import useStyles from './index.styles';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  InputAdornment,
  Link,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import isMobileScreen from 'hooks/useIsMobile';
import cx from 'classnames';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PickSixTab from './PickSixTab';
import BracketTab from './BracketTab';
import { useTable } from 'hooks/useTable';
import tableConfig from 'config/table.config';
import { TableQueryInfoType } from 'types/table.types';
import { UserType } from 'models/auth.model';
import { Input } from '@mui/material';
import { routes } from 'constants/routes';
import { ArrowBackIos, Search } from '@mui/icons-material';

enum PageTabs {
  BRACKETS = '0',
  PICK_SIX = '1',
}

const Predictions = (): JSX.Element => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const userId = useMemo(
    () => new URLSearchParams(search).get('userId'),
    [search],
  );
  const classes = useStyles();
  const isMobile = isMobileScreen();
  const [tabValue, setTabValue] = React.useState<PageTabs>(PageTabs.BRACKETS);

  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);

  const { rows, setParam } = useTable(
    tableConfig.adminUsers as TableQueryInfoType,
  );

  useEffect(() => {
    setParam('id', userId);
  }, [userId]);

  useEffect(() => {
    const users = rows as unknown as Array<UserType>;
    const user: UserType | undefined = users.find(
      (row: UserType) => row.id === userId,
    );
    if (user) {
      setSelectedUser(user);
    }
  }, [userId, rows]);
  const [inputValue, setInputValue] = React.useState<string>('');

  useEffect(() => {
    setParam('search', inputValue);
  }, [inputValue]);
  const [textSearch, setTextSearch] = useState<string>('');
  const handleTabChange = (
    event: React.SyntheticEvent,
    activeTab: PageTabs,
  ) => {
    setTabValue(activeTab);
  };

  const MyBracketsHeadTextClassNames = cx(classes.MyBracketsHeadtext, {
    [classes.MyBracketsHeadtextMobile]: isMobile,
  });

  const onSelectUser = (
    event: SyntheticEvent<Element, Event>,
    user: UserType | null,
  ) => {
    setSelectedUser(user);
  };
  return (
    <Stack className={classes.container}>
      <TabContext value={tabValue}>
        <Box className={classes.tabsHeader}>
          <Stack className={classes.crumbs}>
            {userId && (
              <Link onClick={() => navigate(routes.admin.users.path)}>
                <ArrowBackIos />
              </Link>
            )}

            <Typography className={MyBracketsHeadTextClassNames} variant="h2">
              Raqiteers
            </Typography>
          </Stack>
          <Stack className={classes.filters}>
            <Autocomplete
              size="small"
              disabled={!!userId}
              className={classes.autocomplete}
              value={selectedUser || null}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select user"
                />
              )}
              getOptionLabel={(option) =>
                `${option.nickname || ''} [${option.email || ''}]`
              }
              onChange={onSelectUser}
              options={rows as unknown as Array<UserType>}
              filterSelectedOptions={true}
            />
            {/* <Input
              placeholder="search"
              type="search"
              value={textSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              onChange={(e) => setTextSearch(e.target.value)}
            /> */}
            <TabList
              className={classes.tabHeaderLabel}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              onChange={handleTabChange}
              aria-label="page tabs"
            >
              <Tab label="Pick6" value={PageTabs.PICK_SIX} />
              <Tab label="Full Draw" value={PageTabs.BRACKETS} />
            </TabList>
          </Stack>
        </Box>
        <TabPanel className={classes.tabContent} value={PageTabs.PICK_SIX}>
          <PickSixTab userId={userId || selectedUser?.id} search={textSearch} />
        </TabPanel>
        <TabPanel className={classes.tabContent} value={PageTabs.BRACKETS}>
          <BracketTab userId={userId || selectedUser?.id} search={textSearch} />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default Predictions;
