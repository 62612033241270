import { PageTableList } from 'components/common';
import { useLiveTournament } from 'hooks/useTournament';
import { useEffect } from 'react';
import { green } from 'theme';

const Leaderboard = () => {
  const { games, isLoading } = useLiveTournament();

  useEffect(() => {
    document
      .getElementById('body')
      ?.style?.setProperty('background-color', green);
    return () => {
      document
        .getElementById('body')
        ?.style?.setProperty('background-color', 'unset');
    };
  }, []);

  return !isLoading ? <PageTableList games={games} /> : null;
};
export default Leaderboard;
