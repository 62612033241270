import useAdminTournamentActionsStyles from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { Button } from "@mui/material";
import { TournamentType } from "models/tournament.model";

interface AdminTournamentParams {
    data: TableDataType<TournamentType>
}

const AdminTournamentActions = ({ data: {row, onCellClicked }}: AdminTournamentParams): JSX.Element => {
    const classes = useAdminTournamentActionsStyles();

    const onButtonClick = (action: string) => {
        onCellClicked && onCellClicked(row, action);
    }
    return <div className={classes.tournamentBtnOuter}>
        <Button
            className={classes.tournamentTableButton}
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => onButtonClick('details')}
        >
            Details
        </Button>
        <Button
            className={classes.tournamentTableButton}
            size="small"
            variant="contained"
            color={row.isActive ? "warning" : 'success'}
            onClick={() => onButtonClick(row.isActive ? 'disable' : 'enable')}
        >
            {row.isActive ? 'Disable' : 'Enable'}
        </Button>
        <Button
            className={classes.tournamentTableButton}
            size="small"
            variant="contained"
            color={"error"}
            onClick={() => onButtonClick('delete')}
        >
            Delete
        </Button>

    </div>
}
export default AdminTournamentActions;
