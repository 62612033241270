import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    content: {
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
    },
    title: {
        padding: '32px 32px 16px 32px'
    },
    text: {
        textAlign: "justify",
        textAlignLast: "start"
    },
    closeBtn: {
        marginRight: '16px',
    },
    successIcon: {
        '& svg': {
            width: '100px',
            height: '100px',
            fill: '#25ae88',
        }
    }
}));

export default useStyles;
