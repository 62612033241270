import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: '8px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        rowGap: '8px',
        boxSizing: 'border-box',
    },
}));

export default useStyles;
