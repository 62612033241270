import { gql } from '@apollo/client';
import { GAME_INFO } from 'api/fragments/game.fragment';
import { PAGE_INFO } from 'api/fragments';
import { TOURNAMENT_INFO } from 'api/fragments/tournament.fragment';
import { ROUND_INFO } from 'api/fragments/round.fragment';
import { MATCH_INFO } from 'api/fragments/match.fragment';
import { CHAMPIONSHIP_INFO } from 'api/fragments/championship.fragment';

class GameAPI {
  gameDetails() {
    return gql`
            query gameDetails($id: ID!) {
                gameDetails(id: $id) {
                    ...GameFields
                    tournament {
                        ...TournamentFields
                    }
                }
            }
        ${GAME_INFO}, ${TOURNAMENT_INFO}`;
  }

  gameList() {
    return gql`
            query gameList($tournamentId: [ID]) {
                gameList(tournamentId: $tournamentId) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...GameFields
                        }
                    }
                    total
                }
            }
        ${GAME_INFO}, ${PAGE_INFO}`;
  }

  addGame() {
    return gql`
      mutation adminCreateGame($input: CreateGameInput!) {
        adminCreateGame(input: $input) {
          success
          errors
          game {
            ...GameFields
          }
        }
      }
      ${GAME_INFO}
    `;
  }

  editGame() {
    return gql`
      mutation adminEditGame($input: UpdateGameInput!) {
        adminEditGame(input: $input) {
          success
          errors
          game {
            ...GameFields
          }
        }
      }
      ${GAME_INFO}
    `;
  }

  deleteGame() {
    return gql`
      mutation adminDeleteGame($input: DeleteGameInput!) {
        adminDeleteGame(input: $input) {
          success
          errors
        }
      }
    `;
  }

  getGameBracketsById() {
    return gql`
            query gameDetails($id: ID!) {
                gameDetails(id: $id) {
                    ...GameFields
                    tournament {
                      id
                        championship {
                            logoUrl
                        }
                    }
                    rounds {
                        ...RoundFields
                        matches {
                            ...MatchFields
                            next {
                                id
                                winner {
                                    id
                                }
                            }
                            previousMatches {
                                id
                                origId
                                winner {
                                    id
                                }
                            }
                        }
                    }
                }
            }
            ${GAME_INFO}, ${ROUND_INFO}, ${MATCH_INFO}, `;
  }
  adminChangeGameState() {
    return gql`
      mutation adminChangeGameState($input: ChangeGameStateInput!) {
        adminChangeGameState(input: $input) {
          success
          errors
        }
      }
    `;
  }

  adminRoundStatusChange() {
    return gql`
      mutation adminRoundStatusChange($input: RoundStatusChangeInput!) {
        adminRoundStatusChange(input: $input) {
          success
        }
      }
    `;
  }
}

export default new GameAPI();
