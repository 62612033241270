import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const PlayerButtonStyles = makeStyles((theme: Theme) => ({
    playerTableButton: {
        lineHeight: '18px',
        fontWeight: 500,
        padding: '7px 16px'
    },
}));

export {PlayerButtonStyles};
