import useGameBracketStyles from './index.styles';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { alpha, useTheme } from '@mui/material';
import SelectField from '../SelectField';
import { groupColors } from 'constants/colors';
import cx from 'classnames';
import {
  AdminGameBracketsContext,
  AdminMatchPredictionType,
} from 'models/prediction.model';
import PlayerSelector from '../PlayerSelector';
import { ITournamentPlayer } from 'models/tournamentPlayer.model';
import { BracketGameType } from '..';

interface GameBracketProps {
  index?: number;
  isRootElement?: boolean;
  isLeftChildren?: boolean;
  gameId: string;
  onPlayerSelect?: (player: string, isLeft?: boolean) => void;
  setColor?: (color: string, isLeft?: boolean) => void;
}

interface BracketParentsType {
  left?: string;
  right?: string;
}

const GameBracket = (props: GameBracketProps) => {
  const {
    index,
    gameId,
    onPlayerSelect,
    isRootElement,
    isLeftChildren,
    setColor,
  } = props;

  const classes = useGameBracketStyles();
  const theme = useTheme();
  const { games, players, selectPlayer, predictions, activeRound } = useContext(
    AdminGameBracketsContext,
  );

  const getPlayer = (id?: string): ITournamentPlayer | null => {
    if (id && players.hasOwnProperty(id) && players[id]) {
      return players[id];
    }
    return null;
  };

  const bracket: BracketGameType | null = useMemo(() => {
    return gameId && games.hasOwnProperty(gameId) ? games[gameId] : null;
  }, [games, gameId]);

  const [leftParentSelected, setLeftParentSelected] =
    useState<ITournamentPlayer | null>(null);
  const [rightParentSelected, setRightParentSelected] =
    useState<ITournamentPlayer | null>(null);

  const [leftParentColor, setLeftParentColor] = useState<string>('');
  const [rightParentColor, setRightParentColor] = useState<string>('');

  const [selectedPlayer, setSelectedPlayer] = useState<string>('');

  const [backgroundColor, setBackgroundColor] = useState<string>('');

  const prediction: AdminMatchPredictionType | null = useMemo(
    () => (predictions && predictions[gameId]) || null,
    [predictions, gameId],
  );

  useEffect(() => {
    const winnerId = bracket?.match?.winner?.id || selectedPlayer;
    if (
      leftParentColor &&
      leftParentSelected &&
      leftParentSelected.id === winnerId
    ) {
      setBackgroundColor(leftParentColor);
    } else if (
      rightParentColor &&
      rightParentSelected &&
      rightParentSelected.id === winnerId
    ) {
      setBackgroundColor(rightParentColor);
    } else if (leftParentColor) {
      setBackgroundColor(leftParentColor);
    }
  }, [
    leftParentSelected,
    rightParentSelected,
    selectedPlayer,
    leftParentColor,
    rightParentColor,
    setBackgroundColor,
    bracket?.match?.winner?.id,
  ]);

  const bracketBackgroundColor: string = useMemo(() => {
    if (selectedPlayer) {
      const player = getPlayer(selectedPlayer);
      if (player && player.tier) {
        if (theme.colors.hasOwnProperty(player.tier)) {
          return theme.colors[player.tier] || groupColors[0];
        }
      }
    }
    return backgroundColor || groupColors[0];
  }, [backgroundColor, selectedPlayer, players]);

  const onChangeSelection = (playerId: string) => {
    setSelectedPlayer(playerId);
    gameId &&
      selectPlayer(gameId, {
        matchId: gameId,
        winnerId: playerId || '',
      });
  };

  useEffect(() => {
    if (gameId) {
      if (prediction) {
        const firstPlayerId: string | null | undefined = prediction.firstId;
        if (firstPlayerId && firstPlayerId !== leftParentSelected?.id) {
          if (players.hasOwnProperty(firstPlayerId)) {
            setLeftParentSelected(players[firstPlayerId]);
          }
        }
        const secondPlayerId: string | null | undefined = prediction.secondId;
        if (secondPlayerId && secondPlayerId !== rightParentSelected?.id) {
          if (players.hasOwnProperty(secondPlayerId)) {
            setRightParentSelected(players[secondPlayerId]);
          }
        }
      }
    }
  }, [leftParentSelected, rightParentSelected, prediction, gameId, players]);

  useEffect(() => {
    if (gameId) {
      if (predictions?.[gameId]) {
        const winnerId: string | null | undefined =
          predictions[gameId].winnerId;
        if (winnerId) {
          if (
            leftParentSelected?.id === winnerId ||
            rightParentSelected?.id === winnerId
          ) {
            if (selectedPlayer !== winnerId) {
              onChangeSelection(winnerId);
              onPlayerSelect && onPlayerSelect(winnerId, isLeftChildren);
            }
          }
        }
      }
    }
  }, [
    leftParentSelected,
    rightParentSelected,
    predictions,
    gameId,
    onPlayerSelect,
    isLeftChildren,
    selectedPlayer,
  ]);

  useEffect(() => {
    let first = getPlayer(bracket?.match?.first?.id);
    if (first) {
      setLeftParentSelected(first);
    }
    let second = getPlayer(bracket?.match?.second?.id);
    if (second) {
      setRightParentSelected(second);
    }
  }, [bracket, players]);

  const parents: BracketParentsType = useMemo(() => {
    const getBracketPlayers = (bracketId: string) => {
      const bracketFound =
        bracketId && games.hasOwnProperty(bracketId) ? games[bracketId] : null;
      if (bracketFound) {
        return [bracketFound.match?.first?.id, bracketFound.match?.second?.id];
      } else {
        return [];
      }
    };
    if (bracket?.match?.first?.id) {
      const bracket2Players = getBracketPlayers(bracket?.previous[0]);
      if (bracket2Players.indexOf(bracket?.match?.first?.id) !== -1) {
        return {
          left: bracket?.previous[0],
          right: bracket?.previous[1],
        };
      }
    }
    return {
      left: bracket?.previous[0],
      right: bracket?.previous[1],
    };
  }, [bracket, games]);

  useEffect(() => {
    if (!parents.left && !parents.right) {
      const colorNum = (index || 0) % groupColors.length;
      if (groupColors[colorNum]) {
        setBackgroundColor(
          groupColors[colorNum] ? groupColors[colorNum] : groupColors[0],
        );
      }
    }
  }, [parents, index]);

  useEffect(() => {
    backgroundColor && setColor && setColor(backgroundColor, isLeftChildren);
  }, [backgroundColor, isLeftChildren]);

  const onColorChanged = (color: string, isLeft?: boolean) => {
    isLeft ? setLeftParentColor(color) : setRightParentColor(color);
  };

  const playersSelection: Array<ITournamentPlayer> = useMemo(() => {
    const result = [];
    if (leftParentSelected) {
      result.push(leftParentSelected);
    }
    if (rightParentSelected) {
      result.push(rightParentSelected);
    }
    return result;
  }, [leftParentSelected, rightParentSelected]);

  const bracketStyle = {
    backgroundColor: alpha(bracketBackgroundColor, 0.4),
  };

  const onParentSelected = (id?: string, isLeft?: boolean) => {
    const player = getPlayer(id);
    if (
      isLeft &&
      leftParentSelected &&
      leftParentSelected.id === selectedPlayer
    ) {
      onChangeSelection('');
      onPlayerSelect && onPlayerSelect('', isLeftChildren);
    }
    if (
      !isLeft &&
      rightParentSelected &&
      rightParentSelected.id === selectedPlayer
    ) {
      onChangeSelection('');
      onPlayerSelect && onPlayerSelect('', isLeftChildren);
    }
    const prediction: AdminMatchPredictionType = {
      matchId: gameId,
      [isLeft ? 'firstId' : 'secondId']: id || '',
    };
    selectPlayer(gameId, prediction);

    if (isLeft) {
      setLeftParentSelected(player);
    }
    if (!isLeft) {
      setRightParentSelected(player);
    }
  };

  const onSelectPlayer = (id: string) => {
    const selectedPlayer = players?.hasOwnProperty(id);
    if (selectedPlayer) {
      onChangeSelection(id);
      onPlayerSelect && onPlayerSelect(id, isLeftChildren);
    }
  };

  const topLineClasses = cx(classes.lines, {
    [classes.topLine]: !isLeftChildren,
  });

  const bottomLineClasses = cx(classes.lines, {
    [classes.bottomLine]: isLeftChildren,
  });

  return (
    <div className={classes.root}>
      {!isRootElement && (
        <div className={classes.bracketFigureLines}>
          <div className={classes.figureLine}>
            <div className={topLineClasses}>&nbsp;</div>
            <div className={bottomLineClasses}>&nbsp;</div>
          </div>
        </div>
      )}
      <div className={`${gameId} ${classes.bracketHolder}`}>
        {playersSelection.length ? (
          <SelectField
            size="small"
            onSelectPlayer={onSelectPlayer}
            value={selectedPlayer}
            options={playersSelection}
            style={bracketStyle}
            disabled={
              bracket?.match?.round?.status?.key === 3 &&
              bracket?.match?.next?.round?.status?.key === 3
            }
          />
        ) : (
          <div className={classes.bracket} style={bracketStyle} />
        )}
      </div>
      {parents.left && parents.right ? (
        <>
          <div className={classes.bracketLines}>
            <div className={classes.centerLine} />
          </div>
          <div className={classes.bracketParents}>
            <GameBracket
              index={index ? index * 2 : 0}
              gameId={parents.left}
              setColor={onColorChanged}
              isLeftChildren={true}
              onPlayerSelect={onParentSelected}
            />
            <GameBracket
              index={index ? index * 2 + 1 : 1}
              setColor={onColorChanged}
              gameId={parents.right}
              onPlayerSelect={onParentSelected}
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.bracketLines}>
            <div className={classes.centerLine} />
          </div>
          <div className={classes.bracketParents}>
            <PlayerSelector
              selectedPlayer={leftParentSelected}
              onPlayerSelect={onParentSelected}
              gameId={gameId}
              isLeftChildren={true}
              disabled={bracket?.match?.round?.status?.key === 3}
            />
            <PlayerSelector
              selectedPlayer={rightParentSelected}
              onPlayerSelect={onParentSelected}
              gameId={gameId}
              disabled={bracket?.match?.round?.status?.key === 3}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GameBracket;
