import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { winnerColor } from "theme";

const FantasyStyles = makeStyles((theme: Theme) => ({
    container: {
        overflow: "hidden"
    },
    tableHeader: {
        "& .MuiGrid-root": {
            color: theme.colors.black,
            opacity: 0.6,
        }
    },
    leaderRow: {
        outline: `2px solid ${winnerColor}`,
        outlineOffset: '-2px',
    },
}));

export {FantasyStyles};