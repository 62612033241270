import React from 'react';
import { CardCvcElement } from '@stripe/react-stripe-js';
import { StripeTextField } from './StripeTextField';
import { StripeTextFieldProps } from 'types/payment.type';

const StripeTextFieldCVC = (
  props: StripeTextFieldProps<typeof CardCvcElement>,
) => (
  <StripeTextField label="CVC Code" stripeElement={CardCvcElement} {...props} />
);

export default StripeTextFieldCVC;
