import useStyles  from './index.styles';
import React, { useCallback } from 'react';
import { TableDataType } from 'types/table.types';

import { IFantasyPlayer, IPick6 } from "models/prediction.model";
import { ITournamentPlayer, TierEnum } from "models/tournamentPlayer.model";
import cx from "classnames";
import Stack from "@mui/material/Stack";
import PlayerAvatar from "components/common/PlayerAvatar";

interface IPick6Team {
    data: TableDataType<IPick6>
}
const Pick6Team = ({ data: {row, onCellClicked } }: IPick6Team): JSX.Element => {
    const classes = useStyles();
    const avatarClassNames = useCallback((player: ITournamentPlayer) => {
        return cx(classes.avatar, {
            [classes.inactive]: player.isRetired,
            [classes.tier1]: player.tier === TierEnum.TIER1,
            [classes.tier2]: player.tier === TierEnum.TIER2,
            [classes.tier3]: player.tier === TierEnum.TIER3,
            [classes.tier4]: player.tier === TierEnum.TIER4,
        });
    }, [classes]);

    return (
        <Stack className={classes.container}>
            <Stack className={classes.players}>
                {row.fantasyPlayers?.map((fantasyPlayer: IFantasyPlayer) =>
                    <Stack className={classes.profile} key={fantasyPlayer.id}>
                        <Stack className={avatarClassNames(fantasyPlayer.player)}><PlayerAvatar player={fantasyPlayer.player.player}/></Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}

export default Pick6Team;