import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useTournamentViewStyles = makeStyles((theme: Theme) => ({
  tournamentGrid: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    flexGrow: 1,
    ...theme.typography.semiBold1618black,
  },
  tournamentHeader: {
    display: 'flex',
    flexDirection: 'row',
    // borderBottom: '1px solid #E6E6E6',
  },
  roundTitle: {
    textAlign: 'center',
    width: '242px',
    flexGrow: 0,
    flexShrink: 0,
  },
  roundDescription: {
    ...theme.typography.regular1420gray,
  },
  bracketsFillContainer: {
    marginTop: '8px',
    position: 'relative',
    minWidth: '1152px',
    flexGrow: 1,
  },
}));

export { useTournamentViewStyles };
