import useStyles from "./index.styles";

import React, { useEffect, useMemo } from "react";

import { useTable } from "hooks/useTable";
import tableConfig from "config/table.config";
import { TableQueryInfoType, TableRowType, TableRowValueType } from "types/table.types";
import { Table } from "components/common";
import { Stack } from "@mui/material";
import { prepareUrl } from "utils/route.utils";
import { routes } from "constants/routes";
import { useNavigate } from "react-router";
import { IPick6 } from "models/prediction.model";
import { reduceRedundantColumns } from "utils/filter.utils";
import useIsMobile from "hooks/useIsMobile";
import { useExtQuery } from "hooks/useExtQuery";

interface IPickSixTab {
    userId?: string,
    search: string
}

const PickSixTab = ({userId, search}: IPickSixTab) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const {
        rows,
        dataIsLoading,
        setSearch,
        setParam,
        loadMore,
        hasNextPage
    } = useExtQuery(tableConfig.myPick6Brackets as TableQueryInfoType, {ownerId: userId || ''});
    useEffect(() => {
        setParam('ownerId', userId || '')
    }, [userId])

    useEffect(() => {
        setSearch(search)
    }, [search])

    const navigate = useNavigate();

    const onCellClicked = (row: TableRowValueType) => {
        navigate(prepareUrl(routes.admin.viewFantasy.path, { fantasyId: row.id } ));
    };

    const rowClassName = (id: string): string => {
        const row = rows.find(row => row.id === id) as unknown as IPick6 | undefined
        if (row && row.leaderboardPlace && row.leaderboardPlace === 1) {
            return classes.leaderRow
        }
        return ''
    }

    const tableColumns: Array<TableRowType> = useMemo(() => {
        return reduceRedundantColumns(tableConfig.myPick6Brackets.columns, isMobile ?
            ['id', 'game', 'points', 'tieBreaker', 'actions'] :
            ['mobile'])
    }, [isMobile])

    return (
        <Stack className={classes.container}>
            <Table
                rows={rows}
                hasNextPage={hasNextPage}
                rowClassName={rowClassName}
                isLoading={dataIsLoading}
                columns={tableColumns}
                onLazyLoading={loadMore}
                onCellClicked={onCellClicked}
            />
        </Stack>
    );
}
export default PickSixTab;