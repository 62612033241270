import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        '& .MuiPaper-root': {
            width: `min(700px, 95%)`,
            maxWidth: `min(700px, 95%)`,
            backgroundColor: theme.colors.whiteGray,
        }
    },
    container: {
        flexDirection: 'column',
        width: `min(500px, 100%)`,
    },
    title: {
        padding: '32px 32px 16px 32px'
    },
    closeBtn: {
        marginRight: '16px',
    },
    buttons: {
        flexDirection: 'row',
        gap: '8px',
        marginBottom: '25px'
    },
}));

export default useStyles;
