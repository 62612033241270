import { gql } from '@apollo/client';
import { CHAMPIONSHIP_INFO } from 'api/fragments/championship.fragment';

class ChampionshipAPI {
    championshipList() {
        return gql`
            query championshipList {
                championshipList {
                    edges {
                        node {
                            ...ChampionshipFields
                        }
                    }
                }
            }
            ${CHAMPIONSHIP_INFO}`
    }

}

export default new ChampionshipAPI();