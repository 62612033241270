import { makeStyles } from "@mui/styles";
import { Theme } from '@mui/material/styles'

const useFantasyStyles = makeStyles((theme: Theme) => ({
    Fantasy: {
        backgroundColor: theme.colors.whiteGray,
        padding: '40px 0'
    },
    tier: {
        backgroundColor: 'white',
        borderRadius: '24px',
        marginTop: '31px',
        padding: '24px 28px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
        }
    },
    TierTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    TierMaxPicksText: {
        fontSize: '18px',
        lineHeight: '32px',
    },
    chosenPlayersList: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '24px',
        gap: '36px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
            gap: '8px',
        }
    },
    FantasyAvatarList: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '24px',
        gap: '36px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
            gap: '8px',
        }
    },
    FantasyAvatarItem: {
        margin: '24px 25px 0 25px',
    },
    tier4Rows: {
        maxHeight: '400px',
        overflowY: 'auto',
        gap: '8px',
    },
    ChosenPlayers: {
        backgroundColor: 'rgba(207, 207, 235, .6);',
        borderRadius: '24px',
        padding: '24px 28px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px',
        }
    },
    ChosenPlayersTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: '10px',
    },
    PlayersTitle: {
        fontWeight: 400,
        margin: '40px 0 24px 0',
    },
    ChosenPlayersTitleText: {
        fontWeight: 400,
    },
    applyBtn: {
        lineHeight: '16px',
        padding: '15px 46px'
    },
    FantasyBox: {
        textAlign: 'center'
    },
    FantasyText: {
        opacity: 1,
        marginTop: '12px',
    },
    FantasyPopoverText: {
        backgroundColor: 'black',
        color: 'white',
        padding: '24px 26px',
    },
    tableRow: {
        height: '72px',
        backgroundColor: theme.colors.aqua,
    },
    FantasyMobile: {
        padding: '50px 0 40px 0',
    },
    paymentInfo: {
        gap: '8px',
    }

}));

export default useFantasyStyles;
