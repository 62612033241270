import { gql } from '@apollo/client';
import { TOURNAMENT_INFO } from 'api/fragments/tournament.fragment'
import { ROUND_INFO } from 'api/fragments/round.fragment';
import { PAGE_INFO } from 'api/fragments';
import { CHAMPIONSHIP_INFO } from 'api/fragments/championship.fragment';
import { GAME_INFO } from "api/fragments/game.fragment";
import { DocumentNode } from "graphql";

class TournamentAPI {
    tournamentList() {
        return gql`
            query tournamentList ($start_Lte: DateTime, $end_Gte: DateTime, $isActive: Boolean) {
                tournamentList (start_Lte: $start_Lte, end_Gte: $end_Gte, isActive: $isActive) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...TournamentFields
                            championship {
                                ...ChampionshipFields
                            }
                        }
                    }
                }
            }
            ${TOURNAMENT_INFO}, ${CHAMPIONSHIP_INFO},  ${PAGE_INFO}`
    }

    getActiveTournament(): DocumentNode {
        return gql `
            query getActiveTournament {
                getActiveTournament {
                    ...TournamentFields
                    championship {
                        ...ChampionshipFields
                    }
                    games {
                        edges {
                            node {
                                ...GameFields
                            }
                        }
                    }
                    
                }
            }
            ${TOURNAMENT_INFO}, ${CHAMPIONSHIP_INFO}, ${ GAME_INFO }`
    }

    getTournamentById() {
        return gql `
            query tournamentDetails($id: ID!) {
                tournamentDetails(id: $id) {
                    ...TournamentFields
                }
            }
            ${TOURNAMENT_INFO}`
    }

    getTournamentRoundsById() {
        return gql `
            query tournamentDetails($id: ID!) {
                tournamentDetails(id: $id) {
                    ...TournamentFields
                    rounds {
                        ...RoundFields
                    }
                    championship {
                        ...ChampionshipFields
                    }
                }
            }
            ${TOURNAMENT_INFO}, ${ROUND_INFO} ${CHAMPIONSHIP_INFO}`
    }
    getTournamentGamesById() {
        return gql `
            query tournamentDetails($id: ID!) {
                tournamentDetails(id: $id) {
                    ...TournamentFields
                }
            }
            ${TOURNAMENT_INFO}`
    }

    getTournamentInfoById() {
        return gql `
            query tournamentDetails($id: ID!) {
                tournamentDetails(id: $id) {
                    ...TournamentFields
                    championship {
                        ...ChampionshipFields
                    }
                }
            }
            ${TOURNAMENT_INFO}, ${CHAMPIONSHIP_INFO}`
    }

    addTournament() {
        return gql`
      ${TOURNAMENT_INFO},
      ${CHAMPIONSHIP_INFO}
      mutation adminCreateEmptyTournament($input: CreateEmptyTournamentInput!) {
        adminCreateEmptyTournament(input: $input) {
          success
          errors
          tournament {
            ...TournamentFields
            championship {
                ...ChampionshipFields
            }
          }
        }
      }
    `};
    updateTournament() {
        return gql`
            ${TOURNAMENT_INFO},
            ${CHAMPIONSHIP_INFO}
            mutation adminUpdateTournament($input: UpdateTournamentInput!) {
                adminUpdateTournament(input: $input) {
                    success
                    errors
                    tournament {
                        ...TournamentFields
                        championship {
                            ...ChampionshipFields
                        }
                    }
            }
        }
    `};
    deleteTournament() {
        return gql`
            mutation adminDeleteTournament($input: DeleteTournamentInput!) {
                adminDeleteTournament(input: $input) {
                    success
                    errors
            }
        }
    `};

}

export default new TournamentAPI();