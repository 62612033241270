import moment from 'moment';
import { TournamentType } from 'models/tournament.model';

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss+00:00';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_US_FORMAT = 'MMMM Do @ ha';

export const getCurrentDateTime = () => moment.utc().startOf('hour').toDate();

export const dateToDateTime = (date: string) =>
  moment(date).format(DATE_TIME_FORMAT);

export const formatDate = (date: string) =>
  date ? moment(date).format(DATE_FORMAT) : '';

export const formatToUSDate = (date: string) =>
  date ? moment(date).format(DATE_US_FORMAT) : '';

export const getTimeBeforeDate = (date: string | null) => {
  if (!date) return null;
  const duration = moment.duration(moment(date).diff(moment()));
  const hours = duration.asMinutes();
  const daysVal = Math.floor(hours / 1440);
  const hoursVal = Math.floor((hours % 1440) / 60);
  const minutesVal = Math.floor((hours % 1440) % 60);
  return `${daysVal} day(s) ${hoursVal}h ${minutesVal}m`;
};

export const tournamentDate = (tournament: TournamentType): string => {
  const now = moment();
  const start = moment(tournament.start);
  const end = moment(tournament.end);
  const diffStart = now.diff(start, 'days');
  const diffEnd = now.diff(end, 'days');
  if (diffStart >= 0 && diffEnd <= 0) {
    return `Time to play: ${getTimeBeforeDate(tournament.end)}`;
  } else if (diffStart < 0) {
    return `Start in ${getTimeBeforeDate(tournament.start)}`;
  } else if (diffEnd > 0) {
    return `Ended ${diffEnd} days ago`;
  }
  return '';
};
export const isBefore = (startTime: string, endTime?: string) => {
  return moment(startTime, DATE_FORMAT).isBefore(moment(endTime, DATE_FORMAT));
};
