import useStyles from './index.styles';

import React, { useMemo } from 'react';

import { useTable } from 'hooks/useTable';
import tableConfig from 'config/table.config';
import {
  TableQueryInfoType,
  TableRowType,
  TableRowValueType,
} from 'types/table.types';
import { Table } from 'components/common';
import { Stack } from '@mui/material';
import { prepareUrl } from 'utils/route.utils';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router';
import { reduceRedundantColumns } from 'utils/filter.utils';
import { IPick6 } from 'models/prediction.model';
import useIsMobileScreen from 'hooks/useIsMobile';
import { IGame } from 'models/game.model';
import { useUser } from 'hooks/useUser';
import { UserRoles } from 'models/auth.model';

const PickSixTab = () => {
  const classes = useStyles();
  const { user } = useUser();
  const isAdmin = user.role === UserRoles.ADMIN;
  const isMobile = useIsMobileScreen();
  const { rows, pageInfo, dataIsLoading, onLazyLoading } = useTable(
    tableConfig.myPick6Brackets as TableQueryInfoType,
  );

  const navigate = useNavigate();

  const onCellClicked = (row: TableRowValueType, action?: string) => {
    const game = row?.hasOwnProperty('game') && (row[`game`] as IGame);
    if (action && action === 'edit') {
      navigate(
        prepareUrl(routes.common.fantasy.path, {
          gameId: game && game.id,
          fantasyId: row.id,
        }),
      );
    } else {
      isAdmin
        ? navigate(
            prepareUrl(routes.user.viewFantasy.path, { fantasyId: row.id }),
          )
        : navigate(
            prepareUrl(routes.common.fantasy.path, {
              gameId: game && game.id,
              fantasyId: 'create',
            }),
          );
    }
  };

  const tableColumns: Array<TableRowType> = useMemo(() => {
    return reduceRedundantColumns(
      tableConfig.myPick6Brackets.columns,
      isMobile
        ? ['raqiteer', 'id', 'game', 'points', 'tieBreaker', 'actions']
        : ['mobile', 'raqiteer'],
    );
  }, [isMobile]);

  const rowClassName = (id: string): string => {
    const row = rows.find((row) => row.id === id) as unknown as
      | IPick6
      | undefined;
    if (row && row.leaderboardPlace && row.leaderboardPlace === 1) {
      return classes.leaderRow;
    }
    return '';
  };
  return (
    <Stack className={classes.container}>
      <Table
        rows={rows}
        isLoading={dataIsLoading}
        rowClassName={rowClassName}
        columns={tableColumns}
        pageInfo={pageInfo}
        onLazyLoading={onLazyLoading}
        onCellClicked={onCellClicked}
      />
    </Stack>
  );
};
export default PickSixTab;
