import React from 'react';
import { TableDataType } from 'types/table.types';

import { BracketsTeamStyles } from './index.styles';
import { Button, CircularProgress, Stack } from '@mui/material';
import { IGame } from 'models/game.model';
import moment from 'moment';
import { RenderType } from 'types/common.type';
import {
  useFantasyListCount,
  usePredictionListCount,
} from 'hooks/usePrediction';
import { useUser } from 'hooks/useUser';

interface BracketTeamParams {
  data: TableDataType<IGame>;
  isMobile?: boolean;
}
const BracketsTeam = ({
  data: { row, onCellClicked },
  isMobile = false,
}: BracketTeamParams): JSX.Element => {
  const classes = BracketsTeamStyles();
  const { user } = useUser();
  const { predictionsCountList, predictionsLoading } = usePredictionListCount();
  const { pick6CountList, pick6Loading } = useFantasyListCount();
  const predictionsCount = predictionsCountList?.filter(
    (item) => item?.game?.id === row.id,
  )?.length;
  const pick6Count = pick6CountList?.filter(
    (item) => item?.game?.id === row.id,
  )?.length;
  const onButtonClick = (action: string) => {
    onCellClicked && onCellClicked(row, action);
  };
  const renderStatus = (): RenderType => {
    const now = moment();
    const start = moment(row.start);
    const end = moment(row.end);
    const diffStart = now.diff(start, 'days');
    const diffEnd = now.diff(end, 'days');
    if (row.isActive) {
      return (
        <Stack gap={1} flexDirection={isMobile ? 'column' : 'row'}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            disabled={predictionsLoading || predictionsCount >= 3}
            onClickCapture={() => onButtonClick('fill')}
          >
            Fill Draw
            {user ? (
              predictionsLoading ? (
                <CircularProgress size={16} sx={{ marginLeft: 0.5 }} />
              ) : (
                ` (${predictionsCount} of 3)`
              )
            ) : null}
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            disabled={pick6Loading || pick6Count >= 3}
            onClick={() => onButtonClick('pick')}
          >
            Pick6
            {user ? (
              pick6Loading ? (
                <CircularProgress size={16} sx={{ marginLeft: 0.5 }} />
              ) : (
                ` (${pick6Count} of 3)`
              )
            ) : null}
          </Button>
        </Stack>
      );
    } else {
      if (diffStart >= 0 && diffEnd <= 0) {
        return <Stack className={classes.liveLabel}>• live</Stack>;
      } else if (diffStart < 0) {
        return `Start in ${-diffStart} days`;
      } else if (diffEnd > 0) {
        return `Ended ${diffEnd} days ago`;
      }
      return '';
    }
  };

  return (
    <Stack className={classes.container} gap={1} margin="auto">
      {isMobile && row.title}
      {renderStatus()}
    </Stack>
  );
};

export default BracketsTeam;
