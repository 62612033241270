import useformEditAdminTableStyles from "./index.styles";

import React from "react";
import {TextField} from "../fields";
import {Button} from "@mui/material";

const FormEditAdminTable = () => {
    const classes = useformEditAdminTableStyles();

    return (
        <div>
            <form>
                <div>
                    <TextField
                        id="text-input"
                        name={'text'}
                        label={"Game name"}
                        placeholder="Game name"
                    />
                    <TextField
                        id="text-input"
                        name={'text'}
                        label={"№ of brackets field"}
                        placeholder="№ of brackets field"
                    />
                    <TextField
                        id="text-input"
                        name={'text'}
                        label={"№ of unique users purchased the tickets"}
                        placeholder="№ of unique users purchased the tickets"
                    />
                </div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </form>
        </div>
    );
}

export default FormEditAdminTable;
