import React, { useCallback, useState } from 'react';

import useStyles from './index.styles';

import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import tableConfig from 'config/table.config';
import { Table } from 'components/common';
import { Box, Stack } from '@mui/material';
import { useTable } from 'hooks/useTable';
import { TableQueryInfoType, TableRowValueType } from 'types/table.types';
import { routes } from "constants/routes";
import { useNavigate } from "react-router";

const Users: React.FC = (): React.ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {
        rows,
        pageInfo,
        dataIsLoading,
        dataError,
        setSearch,
        onLazyLoading
    } = useTable(tableConfig.adminUsers as TableQueryInfoType);

    const onCellClicked = (user: TableRowValueType, action?: string): void => {
        switch(action) {
            case 'predictions':
                return navigate(`${routes.admin.predictions.path}?userId=${user.id}`)
        }
    }

    const renderUsers = useCallback(() => {
        return (
            <Table
                rows={ rows }
                isLoading={ dataIsLoading }
                columns={ tableConfig.adminUsers.columns }
                headerClassNames={ classes.tableHeader }
                onCellClicked={onCellClicked}
                pageInfo={ pageInfo }
                onLazyLoading={ onLazyLoading }
            />
        );
    }, [ rows ]);

    return (
        <Stack className={ classes.container }>
            <div className={ classes.header }>
                <Typography variant="h1">Users List</Typography>
                <Box className={ classes.searchBox }>
                    <Input
                        placeholder="search"
                        type="search"
                        onChange={ (e) => setSearch(e.target.value) }
                        fullWidth
                    />
                </Box>
            </div>
            { renderUsers() }
        </Stack>
    );
};
export default Users;
