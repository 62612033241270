import { useLayoutStyles } from './index.styles';

import React from 'react'
import { Toolbar } from '@mui/material'

import GamePageHeader from 'components/layout/GamePageHeader'
import { Outlet } from 'react-router-dom'

type LayoutProps = {
    children: JSX.Element;
};

const GameLayout = () => {

    const classes = useLayoutStyles();

    return <div className={classes.container}>
        <GamePageHeader />
        <Toolbar />
        <div className={classes.content}>
            <Outlet />
        </div>
    </div>

}

export default GameLayout;
