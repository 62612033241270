import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'

const useAdminPredictionStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: `min(${theme.breakpoints.values.lg}px, 100%)`,
        overflowY: 'auto',
    },
    buttons: {
        flexDirection: 'row',
        gap: '8px'
    },
    viewPredictionMobile: {
        fontSize: '36px',
    },
    content: {
        paddingTop: '49px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    headerBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '43px',
    },
    crumbs: {
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export default useAdminPredictionStyles;