import { PlayerButtonStyles } from './index.styles';

import React from 'react';
import { TableDataType } from 'types/table.types';
import { PlayerType } from "models/player.model";
import { Button } from "@mui/material";

interface PlayerButtonParams {
    data: TableDataType<unknown>
}

const PlayerButton = ({ data: {row, onCellClicked }}: PlayerButtonParams): JSX.Element => {
    const classes = PlayerButtonStyles();
    const player = row as PlayerType;
    const onButtonClick = () => {
        onCellClicked && onCellClicked(player);
    }

    return <Button
        className={classes.playerTableButton}
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        fullWidth
    >
        {!!player ? 'Remove from team' : 'Add to team'}
    </Button>
}
export default PlayerButton;
