import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    maxWidth: '450px',
    width: '100%',
  },
  tableHead: {
    backgroundColor: theme.colors.darkBlue,
  },
  tableRow: {
    backgroundColor: theme.colors.lightGreen,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.colors.darkGreen,
    },
    '&:nth-of-type(odd) > :last-child': {
      backgroundColor: '#028188',
    },
    '&:nth-of-type(odd) > :last-child h6': {
      color: 'white',
    },
  },
  tableCell: {
    borderBottom: '2px solid #2EDDD8',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    textWrap: 'nowrap',
  },
  lastTableCell: {
    backgroundColor: '#C7F9FA',
    width: '70px',
    '& h6': {
      color: '#57C0C0',
    },
  },
  tableCollWidth: {
    width: '10px',
  },
  tableCollPadding: {
    paddingLeft: '0',
  },
  scorePoints: {
    textTransform: 'uppercase',
    fontSize: 24,
    letterSpacing: '2px',
    fontFamily: theme.typography.regularBebasNeue.fontFamily,
  },
  tableMobile: {
    margin: 0,
    marginBottom: '75px',
    maxWidth: 'unset',
  },
  gameButtonStyle: {
    borderRadius: '30px',
    backgroundColor: theme.colors.darkBlue,
    borderColor: theme.colors.darkBlue,
    maxWidth: 450,
    '&:hover': {
      border: '2px solid',
      borderColor: theme.colors.darkBlue,
      backgroundColor: `${theme.colors.darkBlue}cf`,
      color: 'white',
    },
  },
  selectedGameButtonStyle: {
    borderRadius: '30px',
    backgroundColor: theme.colors.white,
    border: '2px solid',
    borderColor: theme.colors.darkBlue,
    color: theme.colors.darkBlue,
    '&:hover': {
      backgroundColor: theme.colors.white,
      border: '2px solid',
      borderColor: theme.colors.darkBlue,
      color: theme.colors.darkBlue,
    },
  },
}));

export default useStyles;
