const AppLogo = ({ color }: { color?: string }) => {
  return (
    <svg
      width="154"
      height="45"
      viewBox="0 0 154 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M149.564 28.0498C155.285 24.7468 155.285 19.3419 149.564 16.0389L125.416 2.09748C119.695 -1.20551 115.015 1.49694 115.015 8.10292V35.9858C115.015 42.5917 119.695 45.2942 125.416 41.9912L149.564 28.0498Z"
        fill="#48C2C5"
      />
      <path
        d="M136.284 21.3366C136.284 25.3362 133.041 28.5784 129.042 28.5784C125.042 28.5784 121.8 25.3362 121.8 21.3366C121.8 17.337 125.042 14.0949 129.042 14.0949C133.041 14.0949 136.284 17.337 136.284 21.3366Z"
        fill="white"
      />
      <path
        d="M124.701 15.3359C125.218 16.5486 125.903 17.781 127.103 18.434C127.663 18.739 128.263 18.8635 128.898 18.8519C129.603 18.8387 130.257 18.7462 130.928 19.0233C131.982 19.4589 132.858 20.5923 132.695 21.7758C132.569 22.6982 132.025 23.9493 131.242 24.5014C130.719 24.8694 131.218 25.7378 131.747 25.3654C132.692 24.6992 133.301 23.4953 133.584 22.3975C133.908 21.1468 133.508 19.8492 132.587 18.9468C132.138 18.5064 131.588 18.1765 130.991 17.9831C130.231 17.7377 129.488 17.8714 128.711 17.853C127.021 17.8118 126.149 16.1991 125.565 14.8307C125.313 14.2402 124.451 14.7494 124.701 15.3359Z"
        fill="#48C2C5"
      />
      <path
        d="M121.514 20.965C122.89 20.9718 124.619 21.1259 125.357 22.4852C125.684 23.0869 125.792 23.7791 126.175 24.3573C126.518 24.8753 126.985 25.3157 127.528 25.6212C128.61 26.2322 129.936 26.309 131.056 25.7497C132.08 25.2385 133.099 24.2255 133.355 23.0809C133.496 22.4531 132.531 22.1853 132.39 22.8147C132.166 23.8148 131.131 24.7584 130.161 25.0343C129.001 25.3634 127.709 24.8421 127.039 23.852C126.701 23.3512 126.583 22.7498 126.333 22.2069C126.081 21.6584 125.69 21.208 125.2 20.8581C124.132 20.0958 122.788 19.9705 121.514 19.9641C120.87 19.9609 120.869 20.9618 121.514 20.965Z"
        fill="#48C2C5"
      />
      <path
        d="M6.27105 6.52557V19.8576H9.7542C10.8352 19.8576 11.6956 19.7075 12.3365 19.4072C12.9767 19.107 13.4776 18.6774 13.8379 18.1161C14.1578 17.556 14.3784 16.8653 14.4985 16.0442C14.6186 15.2242 14.6787 14.2726 14.6787 13.1916C14.6787 12.1106 14.6186 11.1602 14.4985 10.339C14.3784 9.51908 14.1382 8.80763 13.7778 8.20709C13.0168 7.08648 11.5755 6.52557 9.45393 6.52557H6.27105ZM0.145508 43.519V0.760353H9.99442C17.2009 0.760353 20.8042 4.94454 20.8042 13.3117C20.8042 15.834 20.4138 17.9763 19.6331 19.7375C18.8524 21.4995 17.4808 22.9204 15.5194 24.0014L22.1254 43.519H15.6395L9.93437 25.2625H6.27105V43.519H0.145508Z"
        fill={color || '#3953A4'}
      />
      <path
        d="M39.3007 28.5655L36.298 13.0715H36.1779L33.1751 28.5655H39.3007ZM24.167 43.519L33.7156 0.760353H38.8203L48.3689 43.519H42.2433L40.4417 34.3307H32.0942L30.2925 43.519H24.167Z"
        fill={color || '#3953A4'}
      />
      <path
        d="M58.3379 33.4297C58.3379 34.9115 58.7479 36.0029 59.5691 36.7027C60.3894 37.4037 61.3603 37.7537 62.4817 37.7537C63.0418 37.7537 63.4818 37.674 63.8029 37.5134L61.0404 35.2914L64.1632 31.5681L66.6254 33.5499V33.4297V10.8493C66.6254 9.36877 66.2143 8.27739 65.3943 7.57635C64.5732 6.87652 63.6019 6.5254 62.4817 6.5254C61.3603 6.5254 60.3894 6.87652 59.5691 7.57635C58.7479 8.27739 58.3379 9.36877 58.3379 10.8493V33.4297ZM52.2124 10.8493C52.2124 9.12856 52.5127 7.60638 53.1132 6.28518C53.7138 4.96399 54.5141 3.86339 55.5154 2.98219C56.4763 2.14143 57.5664 1.50166 58.7884 1.06046C60.0091 0.620457 61.2402 0.399857 62.4817 0.399857C63.722 0.399857 64.9531 0.620457 66.175 1.06046C67.3957 1.50166 68.5067 2.14143 69.5081 2.98219C70.4689 3.86339 71.2496 4.96399 71.8502 6.28518C72.4507 7.60638 72.751 9.12856 72.751 10.8493V33.4297C72.751 35.1113 72.4507 36.5734 71.8502 37.8137L75.6336 40.8765L72.5108 44.5999L68.9075 41.6572C67.0654 43.1389 64.9231 43.8792 62.4817 43.8792C61.2402 43.8792 60.0091 43.6586 58.7884 43.2186C57.5664 42.7786 56.4763 42.118 55.5154 41.2368C54.5141 40.396 53.7138 39.3359 53.1132 38.0539C52.5127 36.7732 52.2124 35.2314 52.2124 33.4297V10.8493Z"
        fill={color || '#3953A4'}
      />
      <path
        d="M79.5366 0.760357H85.6626V43.519H79.5366V0.760357Z"
        fill={color || '#3953A4'}
      />
      <path
        d="M96.832 43.519V6.52557H89.7456V0.760353H110.044V6.52557H102.958V43.519H96.832Z"
        fill={color || '#3953A4'}
      />
    </svg>
  );
};
export default AppLogo;
