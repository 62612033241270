import useAdminPageHeaderStyles from './index.styles';

import React, { useState } from 'react';
import {
  useNavigate,
} from 'react-router-dom';

import { AppBar, Button, Link, Toolbar } from '@mui/material';
import { useUser } from 'hooks/useUser';
import AppLogo from 'assets/images/AppLogo';
import { routes } from 'constants/routes';
import { ProfileDropdown, Popover } from 'components/common';
import MenuButton from "components/common/MenuButton";

const AdminPageHeader = () => {
  const navigate = useNavigate();
  const [popoverAnchor, setPopoverAnchor] = useState<
    (EventTarget & HTMLElement) | null
  >(null);

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const { user } = useUser();
  const classes = useAdminPageHeaderStyles();

  return (
    <AppBar color="inherit">
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
      >
        <ProfileDropdown onLinkClick={handleClosePopover} />
      </Popover>
      <Toolbar className={classes.pageHeader}>
        <Link onClick={() => navigate(routes.admin.home.path)}>
          <AppLogo />
        </Link>
        <div className={classes.buttonsGroup}>
          <MenuButton path={routes.admin.users.path}  text="Users"/>
          <MenuButton path={routes.admin.predictions.path}  text="Predictions"/>
          <MenuButton path={routes.admin.players.path}  text="Players"/>
          <MenuButton path={routes.admin.tournaments.path} text="Tournaments" />
          {user && (
            <Button
              className={classes.logoutButton}
              variant="contained"
              color="secondary"
              onClick={handleOpenPopover}
            >
              Hello, {user.nickname}
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default AdminPageHeader;
