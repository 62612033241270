import React from 'react';
import { useFormik } from 'formik';

import { Button, Divider, Typography } from '@mui/material';
import useLoginFormStyles from './index.styles';
import { TextField, PasswordField } from 'components/form/fields';
import SignInFormScheme, {
  LoginFormValuesType,
  SignIninitialValues,
} from './index.schema';
import { useAuth } from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { AuthResponseType } from 'models/auth.model';
import Loader from 'components/common/Loader';
import GoogleAuth from 'components/layout/AuthPopUp/GoogleAuth';

interface LoginProps {
  onLogin: (response?: AuthResponseType) => void;
  onForgotPassword: () => void;
  setSocialAuthProcessing: (isProcessing: boolean) => void;
}

const LoginForm = (props: LoginProps) => {
  const { onLogin, onForgotPassword, setSocialAuthProcessing } = props;
  const { login, LoginIsProcessing } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: SignIninitialValues,
    validationSchema: SignInFormScheme,
    onSubmit: (values: LoginFormValuesType) => {
      login(values.email, values.password)
        .then((response) => {
          if (response?.errors) {
            response.errors[0]?.message &&
              enqueueSnackbar(response.errors[0]?.message, {
                variant: 'error',
              });
          } else {
            onLogin(response);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
  });

  const classes = useLoginFormStyles();

  return (
    <div>
      {LoginIsProcessing && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.FormInputStyle}>
          <TextField
            id="email-input"
            name={'email'}
            label={'Email'}
            placeholder="Enter your email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className={classes.FormInputStyle}>
          <PasswordField
            id="password-input"
            name={'password'}
            label={'Password'}
            placeholder="Enter your password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Typography
            className={classes.ButtonForgotPasswordStyle}
            variant="h3"
            onClick={onForgotPassword}
          >
            Forgot password?
          </Typography>
        </div>
        <Button
          type="submit"
          disabled={LoginIsProcessing}
          fullWidth
          variant="contained"
          color="primary"
        >
          Sign In
        </Button>
      </form>
      <div className={classes.FormLineTextStyle}>
        <Divider className={classes.FormLineStyle} />
        <Typography className={classes.FormTitleAfter} variant="h6">
          or sign up with
        </Typography>
        <Divider className={classes.FormLineStyle} />
      </div>
      <div className={classes.PagePopupSocNet}>
        <GoogleAuth
          onLogin={onLogin}
          onLoginProcessing={setSocialAuthProcessing}
        />
      </div>
    </div>
  );
};

export default LoginForm;
