import { object, string } from 'yup'

import { PASSWORD_REGEX } from 'constants/forms'

export interface ICreatePasswordFormValues {
    password: string
    confirm_password: string
}

export const CreatePasswordInitialValues = {
    password: '',
    confirm_password: '',
}

export const CreatePasswordFormScheme = object({
    password: string()
        .matches(
            PASSWORD_REGEX,
            'Password should contain uppercase and lowercase letters, numbers or special symbols and have 8-32 length'
        )
        .required('Enter password'),
    confirm_password: string()
        .test('password_same', 'Passwords should be equal', function (value) {
            const { password } = this.parent
            return password === value
        })
        .required('Confirm password'),
})
