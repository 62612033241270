import { gql } from '@apollo/client';
import { CREDIT_CARD_INFO } from "api/fragments/payment.fragment";

class PaymentAPI {
    paymentMethodList() {
        return gql`        
            query paymentMethodList {
                paymentMethodList {
                    edges {
                        node {
                            ...CreditCardFields
                        }                    
                    }
                }
            }
        ${ CREDIT_CARD_INFO }`;
    }

    setDefaultPaymentMethod() {
        return gql`
            mutation setDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
                setDefaultPaymentMethod(input: $input) {
                    success
                    errors
                }
            }
        `;
    }

    createPaymentMethod() {
        return gql`
            mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
                createPaymentMethod(input: $input) {
                    success
                    errors
                    paymentMethod {
                        ...CreditCardFields
                    }
                }
            }
        ${ CREDIT_CARD_INFO }`;
    }
}

export default new PaymentAPI();
