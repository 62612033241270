import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontFamilies } from 'theme';

const PaymentSystemStyles = makeStyles((theme: Theme) => ({
  container: {
    alignSelf: 'center',
    width: '100%',
    backgroundColor: theme.colors.whiteGray,
    padding: '40px 16px',
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  form: {
    maxWidth: '400px',
  },
  paymentMethods: {
    maxWidth: '600px',
  },
  subtitle: {
    fontFamily: fontFamilies.montserratRegular.fontFamily,
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.gray,
    height: 72,
  },
}));

export { PaymentSystemStyles };
