import useContactUsStyles from './index.styles'

import React from 'react'
import { Container, Typography } from "@mui/material";
import PageFooter from "../../layout/PageFooter";
import ContactUsForm from "../../form/contactUsForm";
import ButtonsNav from "../../common/ButtonsNav";
import isMobileScreen from "hooks/useIsMobile";
import cx from "classnames";

const ContactUs = () => {
    const classes = useContactUsStyles();
    const isMobile = isMobileScreen();

    const ContactUsClassNames = cx(classes.ContactUs, {
        [classes.ContactUsMobile]: isMobile,
    });
    const ContactUsContentClassNames = cx(classes.ContactUsContent, {
        [classes.ContactUsContentMobile]: isMobile,
    });
    const ContactUsTitleClassNames = cx(classes.ContactUsTitle, {
        [classes.ContactUsTitleMobile]: isMobile,
    });
    const ContactUsTitleTextClassNames = cx(classes.ContactUsTitleText, {
        [classes.ContactUsTitleTextMobile]: isMobile,
    });
    const ContactUsContentItemClassNames = cx(classes.ContactUsContentItem, {
        [classes.ContactUsContentItemMobile]: isMobile,
    });
    const ContactUsContentFormClassNames = cx(classes.ContactUsContentForm, {
        [classes.ContactUsContentFormMobile]: isMobile,
    });
    const ContactUsInfoClassNames = cx(classes.ContactUsInfo, {
        [classes.ContactUsInfoMobile]: isMobile,
    });
    const ContactUsFooterNavClassNames = cx(classes.ContactUsFooterNav, {
        [classes.ContactUsFooterNavMobile]: isMobile,
    });
    return (
        <div className={ContactUsClassNames}>
                <div className={ContactUsTitleClassNames}>
                    <Container maxWidth="lg">
                        <Typography className={ContactUsTitleTextClassNames} variant="h1">Contact us</Typography>
                    </Container>
                </div>
            <Container maxWidth="lg" className={ContactUsContentClassNames}>
                <div className={ContactUsContentItemClassNames}>
                    <div className={ContactUsInfoClassNames}>
                        <PageFooter />
                    </div>
                </div>
                <div className={ContactUsContentItemClassNames}>
                    <div className={ContactUsContentFormClassNames}>
                        <ContactUsForm />
                    </div>
                </div>
            </Container>

            <div className={ContactUsFooterNavClassNames}>
                <Container maxWidth="lg">
                    <ButtonsNav />
                </Container>
            </div>
        </div>
    )
}
export default ContactUs;
