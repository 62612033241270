import useFantasyPickStyles from './index.styles';

import React, { useRef } from 'react';

import { Stack, Typography } from '@mui/material';
import cx from 'classnames';
import isMobileScreen from "hooks/useIsMobile";
import { ITournamentPlayer, TierEnum } from "models/tournamentPlayer.model";

export interface FantasyPickProps {
    player: ITournamentPlayer,
    isSelectable?: boolean,
    isSelected?: boolean,
    onSelectPlayer?: (player: ITournamentPlayer) => void,
    onUnselectPlayer?: (player: ITournamentPlayer) => void,
    isActive?: boolean
}

const FantasyPick = (props: FantasyPickProps) => {
    const {
        player,
        isSelectable,
        onSelectPlayer,
        onUnselectPlayer,
        isSelected,
        isActive,
    } = props;

    const classes = useFantasyPickStyles();
    const isMobile = isMobileScreen();
    const playerRef = useRef<HTMLDivElement | null>(null);
    const onClick = ()  => {
        isSelected ?
            onUnselectPlayer && onUnselectPlayer(player) :
            onSelectPlayer && onSelectPlayer(player)
    };

    const avatarOuterClassName = cx(classes.avatarOuter,{
        //[classes.selected]: !!player.isSelected,
        [classes.tier1]: player.tier === TierEnum.TIER1,
        [classes.tier2]: player.tier === TierEnum.TIER2,
        [classes.tier3]: player.tier === TierEnum.TIER3,
        [classes.tier4]: player.tier === TierEnum.TIER4,
        [classes.inactive]: player.isRetired,
    })

    const FantasyBoxClassNames = cx(classes.profile, {
        [classes.boxInactive]: !isActive && (player.isRetired || (!isSelectable && !isSelected)),
        [classes.isSelected]: isSelected
    });

    return <div className={classes.container}>
        <Stack
            ref={playerRef}
            className={FantasyBoxClassNames}
            onClick={onClick}
        >
            <div className={avatarOuterClassName}>
                <img className={classes.avatar} src={player.player.imageUrl} alt={''}/>
            </div>
            <Typography className={classes.FantasyText} variant="body2">{`${player.player.firstName || ''} ${player.player.lastName || ''}`}</Typography>
        </Stack>
    </div>
}

export default FantasyPick;
