import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import validationSchema, { initialValues } from './index.schema';
import useStyles from 'components/form/PlayerForm/index.styles';
import { usePlayers } from 'hooks/usePlayers';
import { useNavigate } from 'react-router';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { DatePicker, TextField } from 'components/form/fields';
import SelectField from 'components/form/fields/SelectField';
import Loader from 'components/common/Loader';
import { useSnackbar } from 'notistack';
import PictureField from "components/form/fields/PictureField";
import { GenderEnum, GenderEnumNumeric } from "models/player.model";
import { IGame, IGameFormFields } from "models/game.model";
import { useGame, useGameApi, useGames } from "hooks/useGame";
import { useTournamentApi } from "hooks/useTournament";
import { useChampionships } from "hooks/useChampionship";
import { ITournamentFormValues } from "models/tournament.model";
import { dateToDateTime, formatDate } from "utils/date.utils";
import { ResponseStatusType } from "models/api.model";

interface IGameForm {
    tournamentId: string
    game?: IGame
    onClose: () => void;
}

const GameForm: React.FC<IGameForm> = ({
  tournamentId, game, onClose,
}: IGameForm): React.ReactElement => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { addGame, editGame, addGameLoading } = useGameApi();
    const { games } = useGames(tournamentId);
    const gameOptions = useMemo(() => games
        .filter((item) => !item.parent?.id)
        .map(item => ({
            key: item.id,
            label: item.title,
            value: item.id
        })), [games]);

    const formValues: IGameFormFields = useMemo(() => {
        const getParentId = (): string => {
            const parentGameId = game?.parent?.id;
            if (!parentGameId) return ''
            return  games.find((game: IGame) => game.id === parentGameId) ? parentGameId : ''
        }
        return {...initialValues, ...game,
            start: game?.start ? formatDate(game.start) : '',
            end: game?.end ? formatDate(game.end) : '',
            parentId: getParentId()}
    }, [game, games])
    const onGameResponse = (response: ResponseStatusType) => {
        if (response.success) {
            if (game?.id) {
                enqueueSnackbar('Game saved');
            } else {
                enqueueSnackbar('Game created');
            }
            onClose();
        } else {
            if (response.errors?.title?.[0]) {
                formik.setFieldError('title', response.errors.title[0]);
            }
            if (response.errors?.start?.[0]) {
                formik.setFieldError('start', response.errors.start[0]);
            }
            if (response.errors?.end?.[0]) {
                formik.setFieldError('end', response.errors.end[0]);
            }
            enqueueSnackbar("Game not saved", { variant: 'error' });
        }

    }
    const formik = useFormik({
        initialValues: formValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values: IGameFormFields, { resetForm }) => {
            const { start, end, ...rest } = values;
            if (game?.id) {
                editGame({id: game.id, title: values.title, start: start? dateToDateTime(start) : undefined, end: end ? dateToDateTime(end) : undefined })
                    .then(onGameResponse)
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });
            } else {
                addGame({start: start? dateToDateTime(start) : undefined, end: end ? dateToDateTime(end) : undefined, tournamentId, ...rest })
                    .then(onGameResponse)
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });

            }
        },
    });

    const { resetForm } = formik;
    const onCancelClick = (): void => {
        resetForm();
        onClose();
    }
    return (
        <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
        >
            <TextField
                id="title-input"
                name="title"
                label="Title"
                placeholder="Enter title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
            />
            <DatePicker
                id="start-input"
                name="start"
                label="Date start"
                placeholder="Enter date start"
                value={formik.values.start}
                onChange={formik.handleChange}
                error={formik.touched.start && Boolean(formik.errors.start)}
                helperText={formik.touched.start && formik.errors.start}
            />
            <DatePicker
                id="end-input"
                name="end"
                label="Date end"
                placeholder="Enter date end"
                value={formik.values.end}
                onChange={formik.handleChange}
                error={formik.touched.end && Boolean(formik.errors.end)}
                helperText={formik.touched.end && formik.errors.end}
            />
            <SelectField
                id="championship-id-input"
                label="Parent game"
                name="parentId"
                value={formik.values.parentId || ''}
                onChange={formik.handleChange}
                disabled={!!game?.id}
                error={formik.touched.parentId && Boolean(formik.errors.parentId)}
                helperText={formik.touched.parentId && formik.errors.parentId}
                options={gameOptions}
            />
            <Stack
                direction="row"
                spacing={{  md: 1 }}
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={onCancelClick}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit">
                    Apply
                </Button>
            </Stack>
            {addGameLoading && <Loader />}
        </form>
    );
};
export default GameForm;
