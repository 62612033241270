import useStyles from './index.styles';

import React, { useContext, useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Typography,} from '@mui/material';
import {Close} from '@mui/icons-material';
import LoginForm from 'components/form/loginForm';
import SignupForm from 'components/form/signupForm';
import {AuthComponent} from 'constants/auth';
import AuthContext from 'components/Route/AuthContext';
import {routes} from 'constants/routes';
import {useUser} from 'hooks/useUser';
import {AuthResponseType} from 'models/auth.model';
import LogOutForm from "../../form/logOutForm";
import ForgotPasswordForm from "components/form/ForgotPasswordForm";
import ResetPasswordForm from "components/form/ResetPasswordForm";
import cx from "classnames";

const AuthPopUp = () => {
    const classes = useStyles();
    const { user } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    const { refetchUser } = useUser();

    const { isOpen, authType, setAuth } = useContext(AuthContext);
    const [isSocialProcessing, setIsSocialProcessing] = useState<boolean>(false)
    const onSocialAuthProcessing = (isProcessing: boolean) => {
        setIsSocialProcessing(isProcessing)
    }

    useEffect(() => {
        if (user) setAuth(false);
    }, [user])

    useEffect(() => {
        if (location.pathname === routes.common.login.path) {
            setAuth(true, AuthComponent.LOGIN);
        } else if (location.pathname === routes.common.signUp.path) {
            setAuth(true, AuthComponent.SIGNUP);
        } else if (location.pathname === routes.common.forgotPassword.path) {
            setAuth(true, AuthComponent.FORGOT_PASSWORD);
        } else if (location.pathname.replaceAll('/', '') === routes.common.resetPassword.path.replaceAll('/', '')) {
            setAuth(true, AuthComponent.RESET_PASSWORD);
        }
    }, [location.pathname]);

    const onClose = () => {
        if (
            [
                routes.common.login.path,
                routes.common.signUp.path,
                routes.common.forgotPassword.path,
                routes.common.resetPassword.path
            ].indexOf(location.pathname) !== -1
        ) {
            navigate(routes.common.home.path);
        }
        setAuth(false);
    };

    const onSignUp = () => {
        setAuth(true, AuthComponent.LOGIN);
    };

    const onLogin = (response?: AuthResponseType) => {
        setAuth(false);
        if (response?.user?.isAdmin) {
            navigate(routes.admin.home.path);
        } else if (
            [routes.common.login.path, routes.common.signUp.path].indexOf(location.pathname) !== -1
        ) {
            navigate(routes.common.home.path);
        }
    };

    const onLogOut = () => setAuth(false, AuthComponent.LOGOUT);
    const onForgotPassword = () => setAuth(true, AuthComponent.FORGOT_PASSWORD);
    const getTitle = () => {
        switch(authType) {
            case AuthComponent.LOGIN: return 'Log in'
            case AuthComponent.SIGNUP: return 'Registration'
            case AuthComponent.LOGOUT: return 'Log Out'
            case AuthComponent.FORGOT_PASSWORD: return 'Forgot password'
            case AuthComponent.RESET_PASSWORD: return 'Reset password'
        }
        return ''

    }

    const getContent = () => {
        switch(authType) {
            case AuthComponent.LOGIN: return <LoginForm
                setSocialAuthProcessing={onSocialAuthProcessing}
                onLogin={onLogin}
                onForgotPassword={onForgotPassword}
            />
            case AuthComponent.SIGNUP: return <SignupForm
                setSocialAuthProcessing={onSocialAuthProcessing}
                onSignUp={onSignUp}
            />
            case AuthComponent.LOGOUT: return <LogOutForm onClose={onLogOut} />
            case AuthComponent.FORGOT_PASSWORD: return <ForgotPasswordForm />
            case AuthComponent.RESET_PASSWORD: return <ResetPasswordForm />
        }
        return null
    }

    const getFooter = () => {
        switch(authType) {
            case AuthComponent.SIGNUP:
                return <>
                    Already have an account?
                    <Link onClick={ () => setAuth(true, AuthComponent.LOGIN) }>
                        <Typography className={ classes.ButtonLogInStyle } variant="body1">
                            Log in
                        </Typography>
                    </Link>
                </>
            case AuthComponent.RESET_PASSWORD:
                return <>
                    <Link onClick={ () => setAuth(true, AuthComponent.LOGIN) }>
                        <Typography className={ classes.ButtonLogInStyle } variant="body1">
                            Log in
                        </Typography>
                    </Link>
                </>
            case AuthComponent.LOGIN:
            case AuthComponent.FORGOT_PASSWORD:
                return <>
                    Don`t have account?
                    <Link onClick={() => setAuth(true, AuthComponent.SIGNUP)}>
                        <Typography className={classes.ButtonLogInStyle} variant="body1">
                            Register
                        </Typography>
                    </Link>
                </>

        }
        return null;
    }
    const dialogClasses = cx(classes.AuthDialog, {
        [classes.invisible]: isSocialProcessing
    })

    return (
        <Dialog
            PaperProps={{
                className: dialogClasses,
            }}
            onClose={onClose}
            open={isOpen}
        >
            <DialogTitle className={classes.AuthDialogTitle}>
                {getTitle()}
                <IconButton onClick={onClose} className={classes.ButtonCloseForm}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.AuthDialogContent}>
                {getContent()}
            </DialogContent>
            <DialogActions className={classes.PopupFooter}>
                {getFooter()}
            </DialogActions>
        </Dialog>
    );
};

export default AuthPopUp;