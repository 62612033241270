import { makeStyles } from '@mui/styles';

const usePageFooterStyles = makeStyles({
  PageFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 24px',
  },
  PageFooterBody1: {
    fontWeight: 500,
    opacity: 1,
  },
  PageFooterSocNet: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '168px',
  },
  PageFooterEmail: {
    margin: '0 25px',
  },
  PageFooterMobile: {
    flexDirection: 'column',
  },
  PageFooterEmailMobile: {
    margin: '0',
  },
  copyright: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '20px',
  },
  copyrightText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#777',
    fontWeight: '400',
  },
});

export default usePageFooterStyles;
