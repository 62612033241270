import useStyles from './index.styles';

import { Typography, Button, Stack, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router';
import { routes } from 'constants/routes';
import isMobileScreen from 'hooks/useIsMobile';
import { TournamentType } from 'models/tournament.model';

import cx from 'classnames';
import moment from 'moment';

interface PageBannerProps {
  tournament?: TournamentType;
}

const HomeHeading = ({ tournament }: PageBannerProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = isMobileScreen();
  const container = cx(classes.container, {
    [classes.container]: isMobile,
  });

  return (
    <div className={classes.container}>
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="column"
          mt={4}
          mb={6}
          pt={12}
          border={2}
          color="#46C0C5"
        >
          <Box borderTop={2} borderBottom={2} color="#D9D9D9">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              margin="auto"
              maxWidth={400}
              minWidth={220}
              borderLeft={2}
              borderRight={2}
              color="#D9D9D9"
            >
              <Stack height={36}>
                <Box height="100%" borderRight={2} color="#D9D9D9" />
                <Box />
              </Stack>
              <Stack width="100%" py={7}>
                <Box borderBottom={2} color="#2EDDD833">
                  <Typography
                    className={classes.title}
                    textAlign="center"
                    lineHeight="60px"
                    fontSize={60}
                    color="#46C0C5"
                    letterSpacing="2px"
                  >
                    TWO GAMES
                  </Typography>
                  <Typography
                    className={classes.title}
                    textAlign="center"
                    lineHeight="60px"
                    fontSize={60}
                    color="white"
                    letterSpacing="2px"
                  >
                    TWO WAYS 2 WIN
                  </Typography>
                </Box>
                <Stack pt={2} alignItems="center">
                  <Typography
                    className={classes.subtitle}
                    letterSpacing="1.5px"
                    style={{ color: '#46C0C5' }}
                  >
                    for tennis fans worldwide
                  </Typography>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => navigate(routes.common.gameList.path)}
                    sx={{
                      height: 48,
                      textTransform: 'uppercase',
                      marginTop: 6,
                    }}
                  >
                    <Typography
                      variant="semiBoldMontserrat1618"
                      fontWeight={700}
                    >
                      fill draw
                    </Typography>
                    <Typography
                      variant="regularMontserrat1618"
                      color="white"
                      paddingLeft={1}
                      paddingRight={1}
                    >
                      or
                    </Typography>
                    <Typography variant="semiBoldMontserrat1618">
                      pick six
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
              <Stack height={36}>
                <Box height="100%" borderRight={2} color="#D9D9D9" />
                <Box />
              </Stack>
            </Box>
          </Box>
          <Box height={96} py={2}>
            <Typography
              className={classes.title}
              textAlign="center"
              lineHeight="40px"
              fontSize={40}
              color="white"
              letterSpacing="2px"
            >
              {tournament?.title}
            </Typography>
            {tournament?.start && (
              <Stack flexDirection="row" justifyContent="center">
                <Typography className={classes.subtitle}>
                  {moment(tournament.start).format('MMMM D')}
                </Typography>
                <Typography
                  className={classes.subtitleSuffix}
                  textAlign="start"
                >
                  TH
                </Typography>
                <Typography
                  className={classes.subtitle}
                  paddingLeft={1}
                  paddingRight={1}
                >
                  @
                </Typography>
                <Typography className={classes.subtitle}>
                  {moment(tournament.start).format('ha')}
                </Typography>
              </Stack>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};
export default HomeHeading;
