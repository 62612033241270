import useStyles from './index.styles';

import React, { useCallback, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Table } from "components/common";
import rows from "mock/adminDashboard.mock";
import tableConfig from "config/table.config";
import { alpha, Button, Checkbox, IconButton, useTheme } from "@mui/material";
import { useTable } from "hooks/useTable";
import { TableQueryInfoType, TableRowValueType } from "types/table.types";
import ManagePlayers from "components/pages/Admin/Players/ManagePlayers";
import GameDialog from "components/pages/Admin/TournamentDetails/GameDialog";
import { prepareUrl } from "utils/route.utils";
import { routes } from "constants/routes";
import { useNavigate } from "react-router";
import { PlayerType } from "models/player.model";
import { ITournamentPlayer } from "models/tournamentPlayer.model";
import { Delete } from "@mui/icons-material";
import { formatTier } from "utils/enum.utils";

interface ITournamentPlayerRow {
    player: ITournamentPlayer;
    onDelete: (player: ITournamentPlayer) => void
}

const PlayerRow: React.FC<ITournamentPlayerRow> = ({
    player,
    onDelete
}: ITournamentPlayerRow): React.ReactElement => {
    const theme = useTheme();

    const classes = useStyles();
    const backgroundColor: string = useMemo(() => {
        if (player.tier && theme.colors.hasOwnProperty(player.tier)) {
            return theme.colors[player.tier] || '#FFF';
        }
        return '#fff';
    }, [player])

    const style = {
        backgroundColor: alpha(backgroundColor, .4),
    }

    const onDeleteClick = () => {
        onDelete(player)
    }

    return (
        <Stack className={classes.container} style={style}>
            <Stack className={classes.tier}>{formatTier(player.tier)}</Stack>
            <Stack className={classes.name}>{player.player.firstName} {player.player.lastName}</Stack>
            <Stack className={classes.actions}>
                <IconButton onClick={onDeleteClick}><Delete /></IconButton>
            </Stack>
        </Stack>
    );
};
export default PlayerRow;
