import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material/styles'

const useAdminTournamnetActionsStyles = makeStyles((theme: Theme) => ({
    tournamentBtnOuter: {
      display: "flex",
      justifyContent: "flex-end",
        margin: '-8px'
    },
    tournamentTableButton: {
        margin: '8px'
    },
}));

export default useAdminTournamnetActionsStyles;
