import React from 'react';
import { ThemeProvider, Theme } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

type AppProvidersProps = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  children: React.ReactNode;
  theme: Theme;
};

const snackbarPosition: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const AppProviders = (props: AppProvidersProps) => {
  const { children, theme, apolloClient } = props;
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <StyledEngineProvider injectFirst>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            hideIconVariant={true}
            anchorOrigin={snackbarPosition}
          >
            <BrowserRouter>{children}</BrowserRouter>
          </SnackbarProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
