import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const FantasyStyles = makeStyles((theme: Theme) => ({
    tableHeader: {
        "& .MuiGrid-root": {
            color: theme.colors.black,
            opacity: 0.6,
        }
    }
}));

export {FantasyStyles};