import useStyles from './index.styles';
import React from 'react';

import { Stack } from '@mui/material';
import { IPaymentMethod } from "models/payment.model";
import CardItem from "components/common/CardItem";

interface IPaymentMethodList {
    selectorTitle?: string
    paymentMethods: Array<IPaymentMethod>
    selected?: string
    setIsSelected?: (id: string) => void
}
const PaymentMethodList = ({
    paymentMethods,
    setIsSelected,
    selected,
    selectorTitle
}: IPaymentMethodList) => {
    const classes = useStyles();
    const selectPaymentMethod = (id: string): void => {
        if (setIsSelected) setIsSelected(id)
    }
    return (
        <Stack className={classes.container}>
            {paymentMethods.map((item: IPaymentMethod) => (
                <CardItem
                    key={item.id}
                    card={item}
                    selectorTitle={selectorTitle}
                    isActive={selected === item.id}
                    setIsActive={selectPaymentMethod} />
            ))}
        </Stack>
    );
};

export default PaymentMethodList;
