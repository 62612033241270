import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';

import LeaderboardApi from 'api/leaderboard.api';
import { IAddFriendValues, ILeaderboard } from 'models/leaderboard.model';
import { IGame } from 'models/game.model';
import { IEdgeNode } from 'models/api.model';
export type FetchMoreArgs = {
  gameId?: string;
  search?: string;
  ordering?: string;
  first?: number;
  offset?: number;
  after?: string;
};

export const useLeaderboard = ({
  gameId,
  search,
  ordering,
  first,
  offset,
  isAuth,
}: {
  gameId?: string;
  search?: string;
  ordering?: string;
  first?: number;
  offset?: number;
  isAuth?: boolean;
}) => {
  const { data, loading, refetch, fetchMore } = useQuery(
    isAuth
      ? LeaderboardApi.bracketPredictionLeaderboard()
      : LeaderboardApi.bracketPredictionLeaderboardGeneral(),
    {
      variables: { gameId, search, ordering, first, offset },
      skip: !gameId,
    },
  );

  const { data: leaderboardTotal } = useQuery(
    LeaderboardApi.bracketPredictionLeaderboardTotal(),
    {
      variables: { gameId },
      skip: !gameId,
    },
  );

  if (
    data &&
    data.hasOwnProperty('bracketPredictionLeaderboard') &&
    data.bracketPredictionLeaderboard &&
    leaderboardTotal?.bracketPredictionLeaderboardTotal?.total
  ) {
    return {
      data: data.bracketPredictionLeaderboard.edges.map(
        (edge: IEdgeNode<ILeaderboard>) => edge.node,
      ) as Array<ILeaderboard>,
      loading,
      total: data.bracketPredictionLeaderboard.total || 0,
      totalUsers:
        leaderboardTotal?.bracketPredictionLeaderboardTotal?.total || 0,
      cursor: data.bracketPredictionLeaderboard.pageInfo?.endCursor,
      refetch: refetch as (
        variables?: FetchMoreArgs,
      ) => Promise<ApolloQueryResult<any>>,
      fetchMore: fetchMore as ({
        variables,
      }: {
        variables: FetchMoreArgs;
      }) => Promise<any>,
    };
  }
  return {
    data: [],
    loading,
    total: 0,
    totalUsers: leaderboardTotal?.bracketPredictionLeaderboardTotal?.total || 0,
    cursor: '',
    refetch: refetch as (
      variables?: FetchMoreArgs,
    ) => Promise<ApolloQueryResult<any>>,
    fetchMore: fetchMore as ({
      variables,
    }: {
      variables: FetchMoreArgs;
    }) => Promise<any>,
  };
};

interface IUseRandomLeaderboard {
  leaderboard: Array<ILeaderboard>;
  game?: IGame;
}

export const useRandomLeaderboard = (): IUseRandomLeaderboard => {
  const { data } = useQuery(
    LeaderboardApi.randomBracketPredictionLeaderboard(),
    { variables: { limit: 5 } },
  );
  let game,
    leaderboard: Array<ILeaderboard> = [];
  if (
    data &&
    data.hasOwnProperty('randomBracketPredictionLeaderboard') &&
    data.randomBracketPredictionLeaderboard
  ) {
    game = data.randomBracketPredictionLeaderboard.game as IGame;
    leaderboard = data.randomBracketPredictionLeaderboard
      .leaderboard as Array<ILeaderboard>;
  }
  return { game, leaderboard };
};

export const useFantasyTeamLeaderboard = ({
  gameId,
  search,
  ordering,
  first,
  offset,
  isAuth,
}: {
  gameId?: string;
  search?: string;
  ordering?: string;
  first?: number;
  offset?: number;
  isAuth?: boolean;
}) => {
  const { data, loading, refetch, fetchMore } = useQuery(
    isAuth
      ? LeaderboardApi.fantasyTeamLeaderboard()
      : LeaderboardApi.fantasyTeamLeaderboardGeneral(),
    {
      variables: { gameId, search, ordering, first, offset },
      skip: !gameId,
    },
  );

  const { data: leaderboardTotal } = useQuery(
    LeaderboardApi.fantasyTeamLeaderboardTotal(),
    {
      variables: { gameId },
      skip: !gameId,
    },
  );

  if (
    data &&
    data.hasOwnProperty('fantasyTeamLeaderboard') &&
    data.fantasyTeamLeaderboard &&
    leaderboardTotal?.fantasyTeamLeaderboardTotal?.total
  ) {
    return {
      data: data.fantasyTeamLeaderboard.edges.map(
        (edge: IEdgeNode<ILeaderboard>) => edge.node,
      ) as Array<ILeaderboard>,
      loading,
      total: data.fantasyTeamLeaderboard.total || 0,
      totalUsers: leaderboardTotal?.fantasyTeamLeaderboardTotal?.total || 0,
      cursor: data.fantasyTeamLeaderboard.pageInfo?.endCursor,
      refetch: refetch as (
        variables?: FetchMoreArgs,
      ) => Promise<ApolloQueryResult<any>>,
      fetchMore: fetchMore as ({
        variables,
      }: {
        variables: FetchMoreArgs;
      }) => Promise<any>,
    };
  }

  return {
    data: [] as Array<ILeaderboard>,
    loading,
    total: 0,
    totalUsers: leaderboardTotal?.fantasyTeamLeaderboardTotal?.total || 0,
    cursor: '',
    refetch: refetch as (
      variables?: FetchMoreArgs,
    ) => Promise<ApolloQueryResult<any>>,
    fetchMore: fetchMore as ({
      variables,
    }: {
      variables: FetchMoreArgs;
    }) => Promise<any>,
  };
};

export const useRandomFantasyTeamLeaderboard = (): IUseRandomLeaderboard => {
  const { data } = useQuery(LeaderboardApi.randomFantasyTeamLeaderboard());
  let game,
    leaderboard: Array<ILeaderboard> = [];

  if (
    data &&
    data.hasOwnProperty('randomFantasyTeamLeaderboard') &&
    data.randomFantasyTeamLeaderboard
  ) {
    game = data.randomFantasyTeamLeaderboard.game as IGame;
    leaderboard = data.randomFantasyTeamLeaderboard
      .leaderboard as Array<ILeaderboard>;
  }
  return { game, leaderboard };
};

export const useAddFriendApi = () => {
  const [update, { loading: addFriendLoading }] = useMutation(
    LeaderboardApi.addFriendMutation(),
  );

  const addFriendMutation = async ({
    friendId,
    gameId,
    gameType,
  }: IAddFriendValues) => {
    return update({ variables: { input: { friendId, gameId, gameType } } })
      .then((response) => {
        return response;
      })
      .catch((error) => console.log(error));
  };
  return {
    addFriendMutation,
    addFriendLoading,
  };
};
