import {makeStyles} from "@mui/styles";
import {Theme} from '@mui/material/styles'

const useFantasyPickStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: '12px 0',
        width: '140px',
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
    },
    boxInactive: {
        pointerEvents: "none",
        cursor: "default"
    },
    isSelected: {
        opacity: 0.5
    },
    FantasyText: {
        opacity: 1,
        marginTop: '12px',
    },
    avatarOuter: {
        outline: `3px solid ${theme.colors.yellow}`,
        borderRadius: '50%',
        width: '96px',
        height: '96px',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '0 0 auto',
        margin: '0 auto',
    },
    tier1: {
        outline: `3px solid ${theme.colors.A_1}`,
    },
    tier2: {
        outline: `3px solid ${theme.colors.A_2}`,
    },
    tier3: {
        outline: `3px solid ${theme.colors.A_3}`,
    },
    tier4: {
        outline: `3px solid ${theme.colors.A_4}`,
    },
    inactive: {
        outline: `3px solid ${theme.colors.lightGray}`,
        filter: 'grayscale(70%) opacity(70%)',
    },
    avatar: {
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',
    },
}));

export default useFantasyPickStyles;
