import useSelectStyles from './index.styles';

import React, { ReactNode } from 'react'
import {
    MenuItem, SelectChangeEvent,
    TextField,
    TextFieldProps,
} from '@mui/material'

import { ITournamentPlayer } from "models/tournamentPlayer.model";

type SelectFieldProps = TextFieldProps & {
    onSelectPlayer: (value: string) => void
    options: Array<ITournamentPlayer>
};

const SelectField = (props: SelectFieldProps) => {

    const { value, onSelectPlayer, options, variant, ...rest } = props;

    const classes = useSelectStyles();

    const handleChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
        onSelectPlayer(event.target.value as string);
    }

    const getOptionLabel = (option: ITournamentPlayer): string => {
        return `${option.player.firstName} ${option.player.lastName}`;
    }

    return <TextField
        className={classes.root}
        select
        SelectProps={{ value, onChange: handleChanged }}
        variant="outlined"
        fullWidth={true}
        {...rest }
    >
        {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {getOptionLabel(option)}
            </MenuItem>
        ))}
    </TextField>
}

export default SelectField;