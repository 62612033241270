import useStyles from './index.styles'

import React, { useMemo } from 'react'
import { useParams } from "react-router-dom";

import { Typography } from '@mui/material'
import { FantasyPick } from 'components/common'
import { ITournamentPlayer } from "models/tournamentPlayer.model";
import { useFantasy } from "hooks/usePrediction";
import FantasyPlayers from "components/common/FantasyPlayers";
import FantasyLeaderboard from "components/common/FantasyLeaderboard";

const ViewFantasy = () => {
    const params = useParams();
    const fantasyId = params.fantasyId || '';

    const { pick6 } = useFantasy(fantasyId)
    const classes = useStyles();
    const selectedPlayers: Array<ITournamentPlayer> = useMemo(() => {
        return pick6?.fantasyPlayers?.map((player => player.player)) || []
    }, [pick6])

    return (
        <div className={classes.container}>
            <FantasyLeaderboard gameId={pick6?.game?.id || ''}/>
            <div className={classes.chosenPlayers}>
                <div className={classes.chosenPlayersTitle}>
                    <Typography className={classes.tierTitleText} variant="h4">Chosen players</Typography>
                </div>
                <div className={classes.chosenPlayersList}>
                    {selectedPlayers.map(player =>
                        <FantasyPick
                            key={`tier-selected-${player.id}`}
                            player={player}
                            isSelectable={true}
                            isActive={true}
                        />
                    )}
                </div>
            </div>
            { pick6?.game?.id && <FantasyPlayers
                gameId={pick6.game.id}
                selectedPlayers={selectedPlayers}
            />}
        </div>
    )
}
export default ViewFantasy;
