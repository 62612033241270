import { useMutation, useQuery } from '@apollo/client';

import {
    ITournamentFormValues,
    ITournamentUpdateValues,
    TournamentParams,
    TournamentType,
    TournamentTypeEdge
} from 'models/tournament.model';
import { TournamentAPI } from 'api';
import { MutationResponseType } from "models/api.model";
import { IGame } from "models/game.model";

export const useTournaments = (params?: TournamentParams) => {
    const { data, loading, error, refetch } = useQuery(TournamentAPI.tournamentList(), {
        variables: params,
    });

    let tournaments;

    if (data?.tournamentList) {
        tournaments = data.tournamentList.edges.map((edge: TournamentTypeEdge) => edge.node);
    }

    return { tournaments, tournamentsLoading: loading, tournamentsError: error, refetchTournaments: refetch };
}

interface IGameNode {
    node: IGame
}
interface IUseLiveTournament {
    tournament?: TournamentType,
    games: Array<IGame>
    isLoading: boolean
}
export const useLiveTournament = ():IUseLiveTournament => {

    const { data, loading } = useQuery(TournamentAPI.getActiveTournament());

    let tournament: TournamentType | undefined, games: Array<IGame> = [];

    if (data?.getActiveTournament) {
        const { games: gameNodes, ...tournamentInfo } = data.getActiveTournament
        tournament = tournamentInfo;
        games = gameNodes.edges.map((gameNode: IGameNode) => gameNode.node)
    }

    return { tournament, games, isLoading: loading };
}


export const useTournament = (id: string) => {
    const { data, loading, error } = useQuery(TournamentAPI.getTournamentById(), { variables: { id } });

    let tournament: TournamentType | undefined;

    if (data?.tournamentDetails) {
        tournament = data.tournamentDetails;
    }

    return { tournament, tournamentLoading: loading, tournamentError: error };
}

export const useTournamentInfo = (id: string) => {
    const { data, loading, error, refetch } = useQuery(TournamentAPI.getTournamentInfoById(), { variables: { id } });

    let tournament: TournamentType | undefined;

    if (data?.tournamentDetails) {
        tournament = data.tournamentDetails;
    }

    return { tournament, tournamentLoading: loading, tournamentError: error, refetchTournament: refetch };
}

export const useTournamentGames = (id: string) => {
    const { data, loading, error, refetch } = useQuery(TournamentAPI.getTournamentGamesById(), { variables: { id } });

    let tournament: TournamentType | undefined;

    if (data?.tournamentDetails) {
        tournament = data.tournamentDetails;
    }

    return { tournament, tournamentLoading: loading, tournamentError: error, refetchTournament: refetch };
}


export const useTournamentRounds = (id: string) => {
    const { data, loading, error } = useQuery(TournamentAPI.getTournamentRoundsById(), { variables: { id } });

    let tournament: TournamentType | undefined;

    if (data?.tournamentDetails) {
        tournament = data.tournamentDetails;
    }

    return { tournament, tournamentLoading: loading, tournamentError: error };
}


export const useTournamentApi = () => {
    const [create, { loading: addTournamentLoading }] = useMutation(
        TournamentAPI.addTournament(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'tournamentList' });
                // garbage collector
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );
    const [archive] = useMutation(
        TournamentAPI.deleteTournament(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'tournamentList' });
                // garbage collector
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );
    const [update, { loading: editTournamentLoading }] = useMutation(
        TournamentAPI.updateTournament(),
        {
            update: (cache) => {
                // fieldName dynamic ?
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'tournamentList' });
                // garbage collector
                cache.gc();
            },
            notifyOnNetworkStatusChange: true,
        },
    );

    const addTournament = async ({ title, start, end, championshipId }: ITournamentFormValues) => {
        return create({ variables: { input: {title, start, end, championshipId }} })
            .then(response => {
                const result = response as MutationResponseType<'adminCreateEmptyTournament', 'tournament', TournamentType>;
                return result.data.adminCreateEmptyTournament
            });
    };

    const editTournament = async ({ tournamentId, isActive }: ITournamentUpdateValues) => {
        return update({ variables: { input: { tournamentId, isActive }} })
            .then(response => {
                const result = response as MutationResponseType<'adminUpdateTournament', 'tournament', TournamentType>;
                return result.data.adminUpdateTournament
            });
    };

    const deleteTournament = (tournamentId: string) => {
        return archive({ variables: { input: {tournamentId} } })
            .then(response => {
                const result = response as MutationResponseType<'adminDeleteTournament', 'tournament', TournamentType>;
                return result.data.adminDeleteTournament
            });
    };

    return { addTournament, addTournamentLoading, editTournament, editTournamentLoading, deleteTournament };

}
