import { commonParams } from "models/api.model";
import { PlayerType } from "models/player.model";

export enum TierEnum {
    TIER1 = 'A_1',
    TIER2 = 'A_2',
    TIER3 = 'A_3',
    TIER4 = 'A_4',
}

export interface TournamentPlayerParams extends commonParams{
    gameId?: string
}

export interface ITournamentPlayer {
    id: string;
    tier: TierEnum;
    isRetired: boolean;
    player: PlayerType
}

export interface ITournamentPlayerFormValues {
    playerIds: Array<string>;
    tier: string | number;
    gameId: string;
}
export interface MappedType<U> {
    [id: string]: U
}
