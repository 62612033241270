import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import TournamentForm from 'components/form/TournamentForm';

interface ManageTournamentProps {
  title: string;
  open: boolean;
  handleClose: () => void;
}

const ManageTournament: React.FC<ManageTournamentProps> = ({
  title,
  open,
  handleClose,
}: ManageTournamentProps): React.ReactElement => {

  return (
    <Dialog onClose={handleClose} open={open}>
      <Stack
        direction="row"
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <DialogTitle sx={{ p: 1 }}>{title}</DialogTitle>
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent>
        <TournamentForm onClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};
export default ManageTournament;
