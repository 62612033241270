import { SVGProps } from 'react';

const LeaderBoardBigIcon = (props: SVGProps<SVGElement>) => {
  return (
    <svg
      width={props?.width ? props?.width : '82'}
      height={props?.width ? props?.width : '97'}
      viewBox="0 0 62 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_8)">
        <path
          d="M33.7998 17.61L34.6098 22.32L30.3798 20.1L26.1498 22.32L26.9598 17.61L23.5298 14.28L28.2598 13.59L30.3798 9.29999L32.4998 13.59L37.2298 14.28L33.7998 17.61Z"
          fill="#FFDE14"
        />
        <path
          d="M50.18 4C50.18 1.52 50.11 0 50.11 0H10.65C10.65 0 10.55 1.52 10.58 4H0.47998V6C0.47998 22.47 15.05 28.76 15.2 28.83L15.32 28.55C17.69 33.75 21.24 38.36 26.48 41L26.85 41.09V48.53L24.85 49V54H35.85V49L33.85 48.53V41.09L34.23 41C39.43 38.4 42.95 33.88 45.32 28.77V28.83C45.54 28.76 61.48 22.47 61.48 6V4H50.18ZM2.47998 7H10.65C10.8846 13.5245 12.112 19.9752 14.29 26.13C10.8408 24.2282 7.94115 21.4679 5.87207 18.1164C3.80299 14.7648 2.63456 10.9357 2.47998 7ZM34.61 22.32L30.38 20.1L26.15 22.32L26.96 17.61L23.53 14.28L28.26 13.59L30.38 9.3L32.48 13.59L37.21 14.28L33.78 17.61L34.61 22.32ZM46.48 26.06C48.6448 19.927 49.8621 13.4999 50.09 7H59.31C58.83 18.25 50.41 24 46.48 26.06Z"
          fill="#FFDF18"
        />
        <path d="M43.02 57H17.02V63H43.02V57Z" fill="#FFDF18" />
        <path d="M48.02 66H13.02V72H48.02V66Z" fill="#FFDF18" />
        <path
          d="M34.3699 19L35.3199 24.5L30.3799 21.91L25.4399 24.5L26.3899 19L22.3799 15.11L27.9099 14.31L30.3799 9.29999L32.8599 14.31L38.3799 15.11L34.3699 19Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_8">
          <rect
            width="61.04"
            height="72"
            fill="white"
            transform="translate(0.47998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LeaderBoardBigIcon;
