import { object, string } from 'yup';

import { emailValidator } from "../index.validators";

export interface LoginFormValuesType {
    email: string,
    password: string,
}

export const SignIninitialValues = {
        email: '',
        password: '',
        remember: false
}

const SignInFormScheme = object({
    email: string()
        .test(emailValidator)
        .required('Email must be valid'),

    password: string()
        .required('Password must be valid'),

    remember: string()
        .required()
})

export default SignInFormScheme;
