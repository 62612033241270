import React, { Suspense } from 'react';
import { routes, RouteType, SpecialRouteType } from 'constants/routes';

import Loader from 'components/common/Loader';

import Layout from 'components/layout/Default';
import GameLayout from 'components/layout/GameLayout';
import { Navigate, useLocation, useRoutes } from "react-router";
import { UserRoles } from "models/auth.model";
import { useUser } from "hooks/useUser";
import AdminLayout from "components/layout/Admin";

const Routes = () => {
    const { pathname } = useLocation();
    const { user, userLoading } = useUser();
    const getAdminRoutes = (): Array<RouteType | SpecialRouteType> => {
        let currentRoutes: Array<RouteType | SpecialRouteType> = [routes.special.noPermission];
        if (userLoading) {
            currentRoutes.unshift(routes.special.loading);
        } else if (user?.role === UserRoles.ADMIN) {
            currentRoutes = [...Object.values(routes.admin), routes.special.notFound]
        }
        return currentRoutes;
    }

    const getUserRoutes = (): Array<RouteType | SpecialRouteType> => {
        let currentRoutes: Array<RouteType | SpecialRouteType> = [];
        if (userLoading) {
            currentRoutes = [...Object.values(routes.common), routes.special.loading];
        } else if (user?.role === UserRoles.USER) {
            currentRoutes = [...Object.values(routes.user), ...Object.values(routes.common), routes.special.notFound]
        } else {
            currentRoutes = [ ...Object.values(routes.common), routes.special.notFound ];
        }
        return currentRoutes;
    }

    let commonRoutes = [{
        path: routes.common.home.path,
        element: pathname === routes.common.home.path ? <Layout/> : <GameLayout />,
        children: getUserRoutes()
            .map(route => ({
                path: route.path,
                state: route.state,
                element: route.element,
                index: route.index
            }))
    }]

    let adminRoutes = [{
        path: routes.admin.home.path,
        element: <AdminLayout/>,
        children: getAdminRoutes()
            .map(route => ({
                path: route.path,
                state: route.state,
                element: route.element,
                index: route.index
            }))
    }]
    const getRoutesByRole = () => {
        if (user?.role === UserRoles.ADMIN) {
            return [...adminRoutes, {
                path: '/*',
                element: <Navigate replace to={ routes.admin.home.path } />,
            } ]
        }
        return [...adminRoutes, ...commonRoutes ]
    };
    const routing = useRoutes(getRoutesByRole())
    return <Suspense fallback={<Loader />}>{routing}</Suspense>

}

export default Routes;