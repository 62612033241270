import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles'

const ButtonsNavStyle = makeStyles((theme: Theme) => ({
    ButtonsNav: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    ButtonsNavItem: {
        display: 'flex',
    },
    ButtonsNavMobile: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    ButtonsNavItemMobile: {
        "& button": {
            fontSize: '14px',
        }
    },
}));

export {ButtonsNavStyle};
