import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Typography,
  Button,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';

import { routes } from 'constants/routes';
import isMobileScreen from 'hooks/useIsMobile';
import LeaderBoardBigIcon from 'assets/images/LeaderBoardBigIcon';
import { IGame } from 'models/game.model';

import cx from 'classnames';
import useStyles from './index.styles';
import LeaderboardList from 'components/table/LeaderboardList';
import ButtonToTop from 'components/common/ButtonToTop';

const PageTableList = ({
  games,
  isHomePage,
}: {
  games: Array<IGame>;
  isHomePage?: boolean;
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = isMobileScreen();
  const mensGame = games[0];
  const womensGame = games[1];
  const [selectedGame, setSelectedGame] = useState(mensGame?.id);

  const goToLeaderboard = () => {
    navigate(routes.common.leaderBoard.path);
  };
  const pageTableListClassNames = cx(classes.pageTableList, {
    [classes.pageTableListMobile]: isMobile,
  });

  useEffect(() => {
    if ((mensGame?.id || womensGame?.id) && !selectedGame) {
      setSelectedGame(mensGame?.id || womensGame?.id);
    }
  }, [mensGame?.id, selectedGame, womensGame?.id]);

  return (
    <>
      <Stack
        className={pageTableListClassNames}
        px={6}
        py={8}
        alignItems="center"
        gap={4}
        marginBottom={isHomePage ? 0 : '-48px'}
      >
        <Stack flexDirection="row" position="relative" width="fit-content">
          <div
            style={{
              position: 'absolute',
              left: isMobile ? 4 : -40,
              top: -10,
            }}
          >
            <LeaderBoardBigIcon
              width={isMobile ? 60 : 82}
              height={isMobile ? 70 : 97}
            />
          </div>
          <Box
            bgcolor="white"
            mx={isMobile ? 4 : 0}
            px={isMobile ? 4 : 8}
            borderRadius={50}
            height="fit-content"
          >
            <Typography
              className={classes.title}
              variant="h2"
              fontSize={isMobile ? 40 : 60}
              lineHeight={isMobile ? '40px' : '60px'}
              textTransform="uppercase"
              paddingTop={0.5}
              letterSpacing="5px"
            >
              leaderboards
            </Typography>
          </Box>
          <div
            style={{
              position: 'absolute',
              right: isMobile ? 4 : -40,
              top: -10,
            }}
          >
            <LeaderBoardBigIcon
              width={isMobile ? 60 : 82}
              height={isMobile ? 70 : 97}
            />
          </div>
        </Stack>
        {!!games?.length ? (
          <Stack width="100%" gap={3} px={2}>
            {isHomePage && (
              <Button
                className={classes.fullListButton}
                variant="contained"
                onClick={goToLeaderboard}
                sx={{ width: 200, margin: 'auto' }}
              >
                Full List
              </Button>
            )}
            <Stack
              flexDirection="row"
              gap={isMobile ? 1 : 3}
              justifyContent="center"
            >
              <Button
                className={cx(classes.gameButtonStyle, {
                  [classes.selectedGameButtonStyle]:
                    selectedGame === mensGame?.id,
                })}
                variant="outlined"
                fullWidth
                onClick={() => setSelectedGame(mensGame?.id)}
              >
                <Typography
                  variant="h3"
                  color={selectedGame === mensGame?.id ? 'inherit' : 'white'}
                  textAlign="center"
                  fontSize={isMobile ? 18 : 32}
                  lineHeight={isMobile ? '22px' : '32px'}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  textTransform="uppercase"
                  fontFamily="Montserrat-Regular"
                  sx={{ textWrap: 'nowrap' }}
                >
                  {mensGame ? mensGame?.title : <CircularProgress size={16} />}
                </Typography>
              </Button>
              {!!womensGame?.id && (
                <Button
                  className={cx(classes.gameButtonStyle, {
                    [classes.selectedGameButtonStyle]:
                      selectedGame === womensGame?.id,
                  })}
                  variant="outlined"
                  fullWidth
                  onClick={() => setSelectedGame(womensGame?.id)}
                >
                  <Typography
                    variant="h3"
                    color={
                      selectedGame === womensGame?.id ? 'inherit' : 'white'
                    }
                    textAlign="center"
                    fontSize={isMobile ? 18 : 32}
                    lineHeight={isMobile ? '22px' : '32px'}
                    textTransform="uppercase"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontFamily="Montserrat-Regular"
                    sx={{ textWrap: 'nowrap' }}
                  >
                    {womensGame?.title || <CircularProgress size={16} />}
                  </Typography>
                </Button>
              )}
            </Stack>

            <LeaderboardList
              key={selectedGame}
              gameId={selectedGame}
              hasActiveRounds={
                games?.find((game) => game.id === selectedGame)?.hasActiveRounds
              }
              isHome={isHomePage}
            />
          </Stack>
        ) : (
          <Box pt={3}>
            <Typography
              fontSize={24}
              color="white"
              letterSpacing="2px"
              variant="regularBebasNeue"
            >
              No active tournament
            </Typography>
          </Box>
        )}
      </Stack>
      {!isHomePage && <ButtonToTop />}
    </>
  );
};
export default PageTableList;
